//////////For Devices Action Types//////////////////////////////
//export const ALL_DEVICES_DATA = "get all devices";
export const UPDATE_TELEMETRY = "update telemetry";
//export const RESTART_DEVICE_TELEMETRY = "restart telemetry";
export const DEVICE_ID = "device id";
export const EDIT_CHANNEL = "edit channel";
export const UPDATE_DEVICE_TERMINAL = "update the device terminal";
export const TERMINAL_NETWORK_INFO = "terminal network info";
export const OPEN_HUB_INFO = "open hub info";
export const HUB_DATA = 'hub data of selected hub';
//export const UPDATES_CONFIG_DATA = "updates config data to store version no";


///////////////////for  company action Type ////////////////////////////
export const DATE_TYPE = "date type";

//////////////for users/account action type/////////////////////////////////////
export const EDIT_USER = "edit user";
export const USER_PROFILE = "user profile";
export const ASSIGN_PLAN = "assign plan";
export const ACCOUNT_UNDER_WORK = "account under work";
export const NEW_ACCOUNT_ADDED = "new account added";
export const ACCOUNT_INFO = "account information";
export const SELECTED_USER_INFO = "selected user info";
export const EDIT_ACCOUNT_INFO = "edit account info";

////////////For model action type///////////////////////////////
export const RESET_MODEL = "rest model";
// export const OPEN_CREATE_USER = "open create user";
export const OPEN_ADD_DEVICE = "open add device";
export const OPEN_INVITE_USER = "open invite user";
export const OPEN_ASSIGN_DEVICE = "open assign device";
export const OPEN_CANNEL = "open channel";
export const OPEN_UPDATE_TERMINAL = "open update terminal";
export const OPEN_TERMINAL_NETWORK_INFO = "open terminal network info";
export const OPEN_CALENDER = "open calender";
export const OPEN_CREATE_NEW_LOG = "open create new log";
export const OPEN_CREATE_NEW_SYSTEM_LOG = "open create new system log";
export const OPEN_CREATE_LOG_TYPE = "create log type";
export const ADDITIONAL_DATA_USAGE = "additional data usage";
export const OPEN_NEW_PLAN = "open new plan";
export const OPEN_CREATE_ACCOUNT = "create account";
export const OPEN_EDIT_PLAN = "open edit plan";
//export const OPEN_ASSIGN_PLAN = "open assign plan";

export const OPEN_CURRENT_ONLINE_TERMINALS="current online terminals"
export const OPEN_TIME_LINE = "open time line";
export const OPEN_ASSIGN_DEVICE_ACCOUNT = "open assign device account";
export const OPEN_ASSIGN_DEVICE_TO_TECH = "open assign device to technician"
export const OPEN_PLAN_SUBSCRIBER = "open subscriber plan";
export const OPEN_ASSIGN_SUBSCRIBERS = "open assign subscribers";
export const OPEN_CHANNEL = "open channel model";
export const OPEN_ACCOUNT_PERMISIONS = "oopsn account permissions";
export const OPEN_SITE = "open create or edit site";
export const OPEN_ADD_TERMIANL = "open add terminal";
export const OPEN_HUB = 'open hub';
export const OPEN_ADD_HUB = "open add hubs";
export const OPEN_EDIT_ACCOUNT = "open edit account";
export const OPEN_CREATE_UPDATE = "open create update";
export const OPEN_UPDATES_ALL_NOTES = "open updates all notes";
export const OPEN_JSON_DIFF_CHECKER = "open json diff checker";
export const OPEN_SYSTEM_VERSION_CONTROL = "open the system version model";
export const OPEN_ASSIGN_DEVICE_TO_SITE = "assign device to site";
export const OPEN_VIEW_PLAN_OF_DEVICE = "open view plan of device";
export const MODEL_TOGGLE_COMPONENT = "model toggle component";
export const OPEN_ASSIGN_DEVICE_TO_PLAN = "assign plan to device";
export const OPEN_UPLOAD_CSV = "open upload csv ";
export const OPEN_UPLOAD_HISTORY = "open upload history ";
export const OPEN_DETACH_PLAN = "open detach plan";
export const OPEN_ACTIVATE_PLAN = "open activate plan";
export const OPEN_FAKE_USAGE = "create fake usage";
export const OPEN_VIEW_UPDATE = "open view update ";
export const OPEN_VIEW_TERMINAL = "open view terminal info from account details ";
export const OPEN_USAGE_IOT="open usgae iot";
export const OPEN_TERMINAL_CONFIGURATION = "open terminal configuration";
export const OPEN_MULTIPLE_PLAN_ACTIONS = "open multiple plan actions like activate and detach";
export const OPEN_EDIT_USER = "open update model"
export const OPEN_VIEW_TERMINAL_ON_MAP = "open view terminal on map";
export const OPEN_VIEW_HUB_ON_MAP = "open view hub info on map";
export const OPEN_VIEW_PLAN_OF_DEVICE_ON_MAP="open view plan of device on map";
export const OPEN_VIEW_TELEMETRY="open view telemetry model";
export const OPEN_UPDATE_POYLGONS="open polygon update"
export const OPEN_UPDATES_CONFIG="open update config";

export const OPEN_VIEW_SITE_INFO_ON_MAP = "open view site inf on map";
export const OPEN_VIEW_SITE_INFO = "open view site info"
export const OPEN_VIEW_RESET_TELEMETRY="open reset telemetry"
export const OPEN_VIEW_REFRESH_CACHE = "open the refresh cache model showing latest update";
export const OPEN_CREATE_POOL = "open create manufacturer";
export const OPEN_VIEW_INVOKES = "open invokes view";
export const OPEN_VIEW_SUB_POOL = "open sub pool list";
export const OPEN_ADVANCED_CHART = "open advanced chart";
export const OPEN_TERMIANL_DUMP="open termianl dump"
/////////////////////////////////for model plan///////////////////////////////////

export const EDIT_PLAN = "edit plan";


///////////////////////////////for services modal//////////////////////////////
export const OPEN_NEW_SERVICE = "open new service";
export const OPEN_SERVICE = "open service";
export const SERVICE_DATA = "service data";

//////////////////////////////for sites model//////////////////////////////////////
export const NEW_SITE = "new site";
export const EDIT_SITE = "edit site";
/////////////////////////////for updates model//////////////////////////////
export const VIEW_UPDATE = "view update";

//////////////////////////////for commom store/////////////////////////////////
export const START_DATE = "start date";
export const END_DATE = "end date";
export const SET_DATE = "set the date for calender";
export const SET_PLAN_HISTORY = "plan history ";
export const RESET_COMPONENT = "reset component";
export const RESET_INNER_COMPONENT="resert inner componet";
export const RE_RENDER_COMPONENT="re render the coponent";
export const SET_ROLES = "store the list of roles";
export const SET_ACTION_TYPE = "set the action type being performed";
export const RE_RENDER_SIDE_BAR="re render side bar"