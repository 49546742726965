import React, { Component } from "react";
import styles from "./MapRightActionBtns.module.scss";
import { NOTIFICATION_OPTIONS } from "../../Util/constants";
import { getEntityName } from "../../Store/localStoreageManager/localStoreageManager";
import Controller from "../../Controller";
import { ToastContainer, toast } from "react-toastify";
import { DEVICES_HUB, ROOT, UPDATES } from "../../Util/routes";
import Icon from "../Icon/Icon";
import Satellite from "../../assets/Satellite.svg";
import { HISKY_ENTITY, START, STOP } from "../../Util/constTypes";
export default class MapRightActionBtns extends Component {
  _count = 0;
  constructor(props) {
    super(props);
    this.state = {
      isFullScreenMode: false,
      showPolygon: false,
      entityName: getEntityName().toLowerCase(),
      ismapModelOpen: false,
      applyZoom: false,
    };
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }
  handleKeyPress = (event) => {
    if (!document.fullscreen && this.state.isFullScreenMode) {
      this.setState({ isFullScreenMode: false }, () => {
        this.props.handleESCPress();
      });
    }
  };
  componentDidMount() {
    document.addEventListener("fullscreenchange", (e) =>
      this.handleKeyPress(e)
    );
    document.addEventListener("mozfullscreenchange", (e) =>
      this.handleKeyPress(e)
    );
    document.addEventListener("webkitfullscreenchange", (e) =>
      this.handleKeyPress(e)
    );
    document.addEventListener("msfullscreenchange", (e) =>
      this.handleKeyPress(e)
    );
  }
  componentWillUnmount() {
    document.removeEventListener("fullscreenchange");
  }
  gotoUpdatesHandlerOnFullscreen = () => {
    window.location.pathname = UPDATES;
  };
  setPolygonsHandler = () => {
    this.setState({ showPolygon: !this.state.showPolygon }, () => {
      this.props.showPolygonHandler();
      this.props.contralIntervalHttpCalles(START);
    });
  };
  showPolygonHandler = () => {
    this.props.checkStatusScreen("control", (res) => {
      if (!res) {
        if (window.location.pathname == ROOT) {
          if (!this.props.checkForLoading()) {
            if (this.props.checkForPolygons() == 0) {
              this.props.contralIntervalHttpCalles(STOP);
              this.props.loadHubAndBeams(this.setPolygonsHandler);
            } else {
              this.setState({ showPolygon: !this.state.showPolygon }, () => {
                this.props.showPolygonHandler();
              });
            }
          }
        } else if (window.location.pathname.includes(DEVICES_HUB)) {
          if (this.props.checkForPolygons() > 0) {
            this.setState({ showPolygon: !this.state.showPolygon }, () => {
              this.props.showPolygonHandler();
            });
          } else {
            this.props.showToastMessage != undefined &&
              this.props.showToastMessage();
          }
        }
        // if (!this.props.checkForLoading()) {
        //   //
        //   if (this.props.checkForPolygons() == 0) {
        //     this.props.contralIntervalHttpCalles(STOP);
        //     this.props.loadHubAndBeams(this.setPolygonsHandler);
        //   } else {
        //     this.setState({ showPolygon: !this.state.showPolygon }, () => {
        //       this.props.showPolygonHandler();
        //     });
        //   }
        // } else if (window.location.pathname == ROOT) {
        //   //show toastify msg
        //   const toastMsg = (
        //     <>
        //       <span className={styles.infoIcon}>
        //         <Icon defination={{ prefix: "fas", iconName: "info" }} />
        //       </span>
        //       No default Coverage
        //       {getEntityName() == HISKY_ENTITY && (
        //         <>
        //           ,&nbsp;
        //           <span
        //             onClick={
        //               this.state.isFullScreenMode
        //                 ? this.gotoUpdatesHandlerOnFullscreen
        //                 : this.props.gotoUpdates
        //             }
        //             style={{ cursor: "pointer", textDecoration: "underline" }}
        //           >
        //             Go to Updates
        //           </span>
        //         </>
        //       )}
        //     </>
        //   );
        //   toast.info(toastMsg, NOTIFICATION_OPTIONS);
        // } else if (window.location.pathname.includes(DEVICES_HUB)) {
        //   this.props.showToastMessage != undefined &&
        //     this.props.showToastMessage();
        // }
      }
    });
  };

  onClickCenterHandler = () => {
    this.props.checkStatusScreen("control", (res) => {
      if (!res) {
        this.setState({ showPolygon: false, applyZoom: false }, () => {
          this.props.recenterMapHandler();
        });
      }
    });
  };
  onMouseBtnHandler = () => {
    this.props.checkStatusScreen("control", (res) => {
      this.setState({ ismapModelOpen: res });
    });
  };
  fullScreenHandler = () => {
    this.setState({ isFullScreenMode: !this.state.isFullScreenMode }, () => {
      this.props.fullScreenModeHandler();
    });
  };
  onClickZoomHandler = () => {
    this.setState({ applyZoom: !this.state.applyZoom }, () => {
      this.props.applyZoomConvertToMarkers(this.state.applyZoom);
    });
  };
  render() {
    return (
      <div className={styles.actionWrapper}>
        <div
          className={
            this.state.isFullScreenMode && this.props.ismapModelOpen
              ? `${styles.rightActionsWrapper} ${styles.nopointerevent}`
              : `${styles.rightActionsWrapper}`
          }
        >
          {window.location.pathname == ROOT && (
            <button
              id="zoomin"
              onClick={this.onClickZoomHandler}
              disabled={this.props.disabled}
              className={styles.ZoomBtn}
              onMouseOver={this.onMouseBtnHandler}
              style={{
                cursor: this.state.ismapModelOpen ? "default" : "pointer",
              }}
            >
              <span className={styles.iconZoom}>
                <Icon
                  defination={{ prefix: "far", iconName: "search-location" }}
                />
              </span>
              <span className={styles.textZoom}>Zoom</span>
              {this.state.applyZoom && (
                <span className={styles.closeIcon}>
                  <Icon defination={{ prefix: "fas", iconName: "times" }} />
                </span>
              )}
            </button>
          )}
          {(Controller.getActionAuth("nms", "CENTER") ||
            Controller.getActionAuth("bss", "CENTER")) &&
            this.props.btnOptions.recenterBtn && (
              <button
                id="recenter"
                onClick={this.onClickCenterHandler}
                disabled={this.props.disabled}
                className={styles.recenterBtn}
                onMouseOver={this.onMouseBtnHandler}
                style={{
                  cursor: this.state.ismapModelOpen ? "default" : "pointer",
                }}
              >
                Reset
              </button>
            )}
          {(Controller.getActionAuth("nms", "SHOW_POLYGONS") ||
            Controller.getActionAuth("bss", "SHOW_POLYGONS")) &&
            this.props.btnOptions.showPolygonBtn && (
              <button
                id="polygonControl"
                onClick={this.showPolygonHandler}
                className={styles.showPolygonBtn}
                onMouseOver={this.onMouseBtnHandler}
                style={{
                  cursor: this.state.ismapModelOpen ? "default" : "pointer",
                }}
              >
                <span className={styles.tool}>
                  <img
                    className={styles.iconSat}
                    src={Satellite}
                    alt="Satellite"
                  />
                  show
                </span>

                <span
                  className={styles.closeIcon}
                  style={{
                    visibility: this.state.showPolygon ? "visible" : "hidden",
                  }}
                >
                  <Icon defination={{ prefix: "fas", iconName: "times" }} />
                </span>
              </button>
            )}

          {this.props.btnOptions.fullscreenBtn && (
            <button
              id="fullscreen"
              onClick={this.fullScreenHandler}
              className={
                !this.state.isFullScreenMode
                  ? styles.fullScreenBtn
                  : styles.exitFullScreen
              }
            ></button>
          )}
        </div>
        {window.location.pathname == ROOT && (
          <div
            className={
              this.state.isFullScreenMode && this.props.ismapModelOpen
                ? `${styles.rightActionsWrapper} ${styles.nopointerevent}`
                : `${styles.rightActionsWrapper}`
            }
          >
            <ToastContainer limit={1} />
          </div>
        )}
      </div>
    );
  }
}
