import React, { Component, Fragment } from "react";
import styles from "./SearchAutocomplete.module.scss";
import { withRouter } from "react-router-dom";
import {
  HISKY_ID,
  SUBSCRIBER_ENTITY,
  TECHNICIAN_ENTITY,
} from "../../../Util/constTypes";
import {
  getEntityName,
  getAccountId,
} from "../../../Store/localStoreageManager/localStoreageManager";
import SpinnerLoader from "../../../Componnet/SpinnerLoader/SpinnerLoader";
import { http } from "../../../RequestManager/Http";
import {
  getGlobleSearchByText,
  getGlobleSearchByTextForUsers,
  getGlobleSearchByTextForLogs,
  getGlobleSearchByTextForHbs,
  getGlobleSearchByTextForTerminals,
  getGlobleSearchByTextForTemplates,
  getGlobleSearchByTextForAccounts,
} from "../../../RequestManager/QueriesAndMutations";
import {
  ACCOUNTS,
  TEMPLATE,
  HBS,
  TERMINALS,
  USERS,
  TERMINAL_LINK,
  HBS_LINK,
  DEVICES,
  LOGS,
  HISKY_ENTITY,
  DEVICES_NAME,
  UNIT_ID,
  DESCRIPTION,
  PHONE_NUMBER,
} from "../../../Util/constTypes";
import Icon from "../../../Componnet/Icon/Icon";
import Controller from "../../../Controller";
import * as route from "../../../Util/routes";
import * as actionType from "../../../Store/actions/index";
import { connect } from "react-redux";

const TERMINAL_LABELS = {
  hiskyId: HISKY_ID,
  deviceName: DEVICES_NAME,
  isAssignedTo: "Assigned To",
}; //key- object property same as data in response - value is label to display
const HBS_LABELS = { macId: HISKY_ID, deviceName: DEVICES_NAME };
const USER_LABELS = {
  name: "name",
  email: "Email",
  phone: PHONE_NUMBER,
};
const TEMPLATE_LABELS = { title: "Plan Name", services: "Services" };
const ACCOUNT_LABELS = {
  name: "name",
  email: "Email",
  phone: PHONE_NUMBER,
};
const USER_LOGS_LABELS = { userIp: "User IP", description: DESCRIPTION };
const SYSTEM_LOGS_LABELS = { title: "Title", unitId: UNIT_ID };
class SearchAutocomplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      tab: ACCOUNTS,
      loading: false,
      data: {
        accounts: [],
        terminal: [],
        hbs: [],
        templates: [],
        users: [],
        logs: [],
      },
      systemLog: [],
      userLog: [],
      totalUsers: 0,
      totalTerminal: 0,
      totalHbs: 0,
      totalAccounts: 0,
      templateCount: 0,
      userLogTotalCount: 0,
      systemLogTotalCount: 0,
    };
    this.timer = null;
  }

  componentDidMount() {
    let tabInit = this.getUrlRouteHandler(this.props.history.location.pathname);
    this.setState(
      {
        tab: tabInit !== null ? tabInit : ACCOUNTS,
      },
      () => {}
    );
  }
  componentWillReceiveProps(nextProps) {
    let tabInit = this.getUrlRouteHandler(nextProps.history.location.pathname);
    if (tabInit !== null && this.state.tab !== tabInit) {
      this.updateTabHandler(tabInit);
    }
  }
  updateTabHandler = (tab) => {
    this.setState({ tab: tab }, () => {});
  };
  getTheNextTabOBySizeHandler = (tab) => {
    let tabData = [
      { tab: USERS, count: this.state.totalUsers },
      {
        tab: LOGS,
        count: this.state.systemLogTotalCount + this.state.userLogTotalCount,
      },
      { tab: HBS_LINK, count: this.state.totalHbs },
      { tab: TERMINAL_LINK, count: this.state.totalTerminal },
      { tab: TEMPLATE, count: this.state.templateCount },
      { tab: ACCOUNTS, count: this.state.totalAccounts },
    ];
    tabData.sort((t1, t2) => (t1.count < t2.count ? 1 : -1));
    if (tabData[0].count === 0) return tab;
    else return tabData[0].tab;
  };

  getUrlRouteHandler = (url) => {
    let urlArr = url.split("/");
    if (urlArr.includes(ACCOUNTS)) {
      return ACCOUNTS;
    } else if (urlArr.includes(USERS)) {
      return USERS;
    } else if (urlArr.includes(TERMINAL_LINK)) {
      return TERMINAL_LINK;
    } else if (urlArr.includes(HBS_LINK)) {
      return HBS_LINK;
    } else if (urlArr.includes(TEMPLATE)) {
      return TEMPLATE;
    } else if (urlArr.includes(DEVICES)) {
      return TERMINAL_LINK;
    } else if (urlArr.includes(LOGS)) {
      return LOGS;
    } else {
      return null;
    }
  };

  getSizeOfArraySearchHandler = (tab) => {
    switch (tab) {
      case ACCOUNTS:
        return this.state.totalAccounts;
      case TEMPLATE:
        return this.state.templateCount;
      case HBS_LINK:
        return this.state.totalHbs;
      case TERMINAL_LINK:
        return this.state.totalTerminal;
      case USERS:
        return this.state.totalUsers;
      case LOGS:
        return this.state.userLogTotalCount + this.state.systemLogTotalCount;
    }
  };
  updateStateDataHandler = (data) => {
    for (let key in data) {
      if (data[key] === null) {
        data[key] = [];
      }
    }
    let copyState = { ...this.state.data };

    copyState.logs = data.userLog.concat(data.systemLog);
    copyState.hbs = data.hbs;
    copyState.accounts = data.accounts;
    copyState.users = data.users;
    copyState.templates = data.templates;
    copyState.terminal = data.terminal;
    this.setState(
      {
        data: copyState,
        loading: false,
        userLog: data.userLog,
        systemLog: data.systemLog,

        totalUsers: data.totalUsers,
        totalTerminal: data.totalTerminal,
        totalHbs: data.totalHbs,
        totalAccounts: data.totalAccounts,
        templateCount: data.templateCount,
        userLogTotalCount: data.userLogTotalCount,
        systemLogTotalCount: data.systemLogTotalCount,
      },
      () => {
        let res = this.getTheNextTabOBySizeHandler(this.state.tab);

        this.setState({ tab: res }, () => {});
      }
    );
  };

  createHttpRequest = () => {
    this.setState({ loading: true }, () => {
      http(getGlobleSearchByText, {
        search: this.state.searchText,
        limit: 10,
        exact: false,
      })
        .then((res) => {
          this.updateStateDataHandler(res.data);
        })
        .catch((err) => {});
    });
  };
  onChangeHandler = (event) => {
    this.setState({ searchText: event.target.value }, () => {
      if (this.state.searchText.length >= 3) {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.createHttpRequest();
        }, 1000);

        this.props.open(true);
      } else if (this.state.searchText === "") {
        clearTimeout(this.timer);
        this.props.open(false);
      }
    });
  };
  onclickTabHandler = (tab) => {
    this.setState({ tab: tab });
  };
  getTableHeaderhandler = () => {
    let data = [
      {
        name: USERS,
        link: USERS,
        icon: <Icon defination={{ prefix: "far", iconName: "user" }} />,
        auth: getEntityName() === HISKY_ENTITY,
      },
      {
        name: LOGS,
        link: LOGS,
        icon: <Icon defination={{ prefix: "far", iconName: "folder" }} />,
        auth:
          Controller.getAuthComponent("nms", "USER_LOGS") ||
          Controller.getAuthComponent("bss", "USER_LOGS") ||
          Controller.getAuthComponent("nms", "SYSTEM_LOGS"),
      },
      {
        name: HBS,
        link: HBS_LINK,
        icon: (
          <Icon defination={{ prefix: "fal", iconName: "tablet-rugged" }} />
        ),
        auth: getEntityName() === HISKY_ENTITY,
      },
      {
        name: TERMINALS,
        link: TERMINAL_LINK,
        icon: (
          <Icon defination={{ prefix: "far", iconName: "tablet-rugged" }} />
        ),
        auth:
          Controller.getAuthComponent("nms", "TERMINAL") ||
          Controller.getAuthComponent("bss", "ACCOUNTS") ||
          Controller.getAuthComponent("nms", "ACCOUNTS"),
        //Controller.getAuthComponent("bss", "ACCOUNT"),
      },
      {
        name: TEMPLATE,
        link: TEMPLATE,
        icon: (
          <Icon defination={{ prefix: "far", iconName: "tablet-rugged" }} />
        ),
        auth: getEntityName() === HISKY_ENTITY,
      },
      {
        name: ACCOUNTS,
        link: ACCOUNTS,
        icon: <Icon defination={{ prefix: "far", iconName: "user" }} />,
        auth:
          Controller.getAuthComponent("bss", "ACCOUNTS") ||
          Controller.getAuthComponent("nms", "ACCOUNTS"),
      },
    ];

    return data
      .filter((tab) => tab.auth)
      .map((tab, index) => {
        return (
          <div
            className={styles.textTabHeader}
            key={index}
            onClick={() => this.onclickTabHandler(tab.link)}
          >
            <span className={styles.titleHeaderTable}>
              <span className={styles.icon}>{tab.icon}</span>
              {`${tab.name} (${this.getSizeOfArraySearchHandler(tab.link)})`}
            </span>
            <span
              className={
                this.state.tab === tab.link ? styles.mFadeIn : styles.mFadeOut
              }
            ></span>
          </div>
        );
      });
  };
  redirectAccountRouteByEntityHandler = () => {
    this.props.open(false);
    if (getEntityName() === TECHNICIAN_ENTITY) {
      this.props.history.push(route.ACCOUNTS_TECHNICIAN + "/" + getAccountId());
    } else if (getEntityName() === SUBSCRIBER_ENTITY) {
      this.props.history.push(route.ACCOUNTS_SUBSCRIBER + "/" + getAccountId());
    } else {
      if (this.state.tab === ACCOUNTS) {
        this.props.history.push(route.ACCOUNTS_TREE);
      } else {
        this.props.history.push(route.USERS);
      }
    }
  };
  onClickCellHandler = (item) => {
    if (this.state.tab === HBS_LINK) {
      this.props.history.push(`${route.DEVICES_HUB}/${item.id}:${item.macId}`);
      this.props.open(false);
    } else if (this.state.tab === ACCOUNTS) {
      this.redirectAccountRouteByEntityHandler();
    } else if (this.state.tab === USERS) {
      this.redirectAccountRouteByEntityHandler();
    } else if (this.state.tab === TERMINAL_LINK) {
      this.props.updateTerminalSelector(item.id);
      this.props.openTerminalNetworkInfo();
      this.props.open(false);
    } else if (this.state.tab === TEMPLATE) {
      this.props.history.push(route.PLANS_TEMPLATES);
      this.props.open(false);
    } else if (this.state.tab === LOGS) {
      if (item === "userLogs") {
        this.props.history.push(route.LOGS_USER);
        this.props.open(false);
      } else if (item === "system") {
        this.props.history.push(route.LOGS_SYSTEM);
        this.props.open(false);
      }
    }
  };
  getDataUserHandler = () => {
    if (this.state.totalUsers === 0) {
      return;
    } else {
      return (
        <div>
          <div>
            {this.state.data.users.map((item, index) => {
              return this.getSearchDataCellHandler(item, index, USER_LABELS);
            })}
          </div>
          <div
            className={`${styles.iconPlus} ${
              this.state.data.users.length === this.state.totalUsers
                ? styles.plusnoCursor
                : styles.plusCursor
            }`}
            style={{
              visibility: this.state.totalUsers > 10 ? "visible" : "hidden",
            }}
            onClick={
              this.state.data.users.length === this.state.totalUsers
                ? () => {}
                : () => this.onClickPlusHandler()
            }
          >
            <span className={styles.plusLabel}>Load More</span>
            <Icon defination={{ prefix: "fas", iconName: "plus" }} />
          </div>
        </div>
      );
    }
  };
  getDataHbsHandler = () => {
    if (this.state.totalHbs === 0) {
      return;
    } else {
      return (
        <div>
          <div>
            {this.state.data.hbs.map((item, index) => {
              return this.getSearchDataCellHandler(item, index, HBS_LABELS);
            })}
          </div>
          <div
            className={`${styles.iconPlus} ${
              this.state.data.hbs.length === this.state.totalHbs
                ? styles.plusnoCursor
                : styles.plusCursor
            }`}
            style={{
              visibility: this.state.totalHbs > 10 ? "visible" : "hidden",
            }}
            onClick={
              this.state.data.hbs.length === this.state.totalHbs
                ? () => {}
                : () => this.onClickPlusHandler()
            }
          >
            <span className={styles.plusLabel}>Load More</span>
            <Icon defination={{ prefix: "fas", iconName: "plus" }} />
          </div>
        </div>
      );
    }
  };
  getDataTerminalHandler = () => {
    if (this.state.totalTerminal === 0) {
      return;
    } else {
      return (
        <div>
          <div>
            {this.state.data.terminal.map((item, index) => {
              return this.getSearchDataCellHandler(
                item,
                index,
                TERMINAL_LABELS
              );
            })}
          </div>
          <div
            className={`${styles.iconPlus} ${
              this.state.data.terminal.length === this.state.totalTerminal
                ? styles.plusnoCursor
                : styles.plusCursor
            }`}
            style={{
              visibility: this.state.totalTerminal > 10 ? "visible" : "hidden",
            }}
            onClick={
              this.state.data.terminal.length === this.state.totalTerminal
                ? () => {}
                : () => this.onClickPlusHandler()
            }
          >
            <span className={styles.plusLabel}>Load More</span>
            <Icon defination={{ prefix: "fas", iconName: "plus" }} />
          </div>
        </div>
      );
    }
  };
  onFoucsHandler = () => {
    if (this.state.searchText.length >= 3) {
      this.props.open(true);
    }
  };
  getDataAccountHandler = () => {
    if (this.state.totalAccounts === 0) {
      return;
    } else {
      return (
        <div>
          <div>
            {this.state.data.accounts.map((item, index) => {
              return this.getSearchDataCellHandler(item, index, ACCOUNT_LABELS);
            })}
          </div>
          <div
            className={`${styles.iconPlus} ${
              this.state.data.accounts.length === this.state.totalAccounts
                ? styles.plusnoCursor
                : styles.plusCursor
            }`}
            style={{
              visibility: this.state.totalAccounts > 10 ? "visible" : "hidden",
            }}
            onClick={
              this.state.data.accounts.length === this.state.totalAccounts
                ? () => {}
                : () => this.onClickPlusHandler()
            }
          >
            <span className={styles.plusLabel}>Load More</span>
            <Icon defination={{ prefix: "fas", iconName: "plus" }} />
          </div>
        </div>
      );
    }
  };
  getDataTemplatesHandler = () => {
    if (this.state.templateCount === 0) {
      return;
    } else {
      return (
        <div>
          <div>
            {this.state.data.templates.map((item, index) => {
              return this.getSearchDataCellHandler(
                item,
                index,
                TEMPLATE_LABELS
              );
            })}
          </div>
          <div
            className={`${styles.iconPlus} ${
              this.state.data.templates.length === this.state.templateCount
                ? styles.plusnoCursor
                : styles.plusCursor
            }`}
            style={{
              visibility: this.state.templateCount > 10 ? "visible" : "hidden",
            }}
            onClick={
              this.state.data.templates.length === this.state.templateCount
                ? () => {}
                : () => this.onClickPlusHandler()
            }
          >
            <span className={styles.plusLabel}>Load More</span>
            <Icon defination={{ prefix: "fas", iconName: "plus" }} />
          </div>
        </div>
      );
    }
  };
  getDataLogsHandler = () => {
    let totalLogsCount =
      this.state.userLogTotalCount + this.state.systemLogTotalCount;
    if (totalLogsCount === 0) {
      return;
    } else {
      let systemLog;
      if (Controller.getAuthComponent("nms", "SYSTEM_LOGS")) {
        systemLog = this.state.systemLog.map((item, index) => {
          return this.getSearchDataCellHandler(item, index, SYSTEM_LOGS_LABELS);
        });
      }
      let userLogs;
      if (
        Controller.getAuthComponent("nms", "USER_LOGS") ||
        Controller.getAuthComponent("bss", "USER_LOGS")
      ) {
        userLogs = this.state.userLog.map((item, index) => {
          return this.getSearchDataCellHandler(item, index, USER_LOGS_LABELS);
        });
      }
      return (
        <Fragment>
          <div>
            {this.state.systemLogTotalCount > 0 &&
              Controller.getAuthComponent("nms", "SYSTEM_LOGS") && (
                <p className={styles.subTitle}>System logs</p>
              )}
            {systemLog}
            {this.state.userLogTotalCount > 0 &&
              (Controller.getAuthComponent("nms", "USER_LOGS") ||
                Controller.getAuthComponent("bss", "USER_LOGS")) && (
                <p className={styles.subTitle}>user logs</p>
              )}

            {userLogs}
            <div
              className={`${styles.iconPlus} ${
                this.state.data.logs.length === totalLogsCount
                  ? styles.plusnoCursor
                  : styles.plusCursor
              }`}
              style={{ visibility: totalLogsCount > 10 ? "visible" : "hidden" }}
              onClick={
                this.state.data.logs.length === totalLogsCount
                  ? () => {}
                  : () => this.onClickPlusHandler()
              }
            >
              <span className={styles.plusLabel}>Load More</span>
              <Icon defination={{ prefix: "fas", iconName: "plus" }} />
            </div>
          </div>
        </Fragment>
      );
    }
  };
  getSearchDataCellHandler = (item, index, labels) => {
    return (
      <div
        className={styles.dataCell}
        key={index}
        onClick={() => this.onClickCellHandler(item)}
      >
        <span className={styles.indexCell}>{`${index + 1}`}</span>
        <div className={styles.innerInfo}>
          {Object.values(labels).map((eachLabelName, index) => {
            let newLabel = eachLabelName;
            if (eachLabelName == "name" && labels === ACCOUNT_LABELS) {
              if (
                item.entity.name == SUBSCRIBER_ENTITY ||
                item.entity.name == TECHNICIAN_ENTITY
              ) {
                newLabel = "Full Name";
              } else {
                newLabel = "Company Name";
              }
            }
            if (eachLabelName == "name" && labels === USER_LABELS) {
              newLabel = "Full Name";
            }
            return (
              <span
                className={styles.label}
                key={index}
                style={{
                  textTransform:
                    eachLabelName.toLowerCase() !== HISKY_ID.toLowerCase()
                      ? "capitalize"
                      : "none",
                }}
              >
                {newLabel}:
              </span>
            );
          })}
        </div>
        <div className={styles.innerInfo}>
          {Object.keys(labels).map((key, index) => {
            let value = "";
            if (labels === ACCOUNT_LABELS) {
              //for all account data

              if (key == "name") {
                if (
                  item.entity.name == SUBSCRIBER_ENTITY ||
                  item.entity.name == TECHNICIAN_ENTITY
                ) {
                  value =
                    item.owner !== null && item.owner[key] !== null
                      ? `${item.owner.firstName} ${item.owner.lastName}`
                      : "N/A";
                } else {
                  value = item.name;
                }
              } else
                value =
                  item.owner !== null && item.owner[key] !== null
                    ? item.owner[key]
                    : "N/A";
            } else if (labels == USER_LABELS && key == "name") {
              value =
                item.firstName || item.lastName
                  ? `${item.firstName} ${item.lastName}`
                  : "N/A";
            } else if (labels == TERMINAL_LABELS && key == "isAssignedTo") {
              value =
                item.isAssignedTo != null ? item.isAssignedTo.name : "N/A";
            } else if (labels == TEMPLATE_LABELS && key == "services") {
              let services = [];
              if (item.services != null && item.services != undefined)
                services = item.services.map((val) => val.serviceType.name);

              value = services.length > 0 ? services.join(", ") : "N/A";
            } else value = item[key] ? item[key] : "N/A";
            return (
              <span
                key={index}
                style={{
                  whiteSpace:"break-spaces",
                  textTransform:
                    key !== "email" && !value.toLowerCase().includes("hisky")
                      ? "capitalize"
                      : "none",
                }}
              >
                {value.replace(/,/g, ' ')}
              </span>
            );
          })}
        </div>
      </div>
    );
  };
  getDataByTabHandler = () => {
    switch (this.state.tab) {
      case HBS_LINK:
        return this.getDataHbsHandler();
      case TERMINAL_LINK:
        return this.getDataTerminalHandler();
      case ACCOUNTS:
        return this.getDataAccountHandler();
      case USERS:
        return this.getDataUserHandler();
      case TEMPLATE:
        return this.getDataTemplatesHandler();
      case LOGS:
        return this.getDataLogsHandler();
    }
  };
  onClickPlusHandler = () => {
    let constTabType = "";
    if (this.state.tab === HBS_LINK) {
      constTabType = getGlobleSearchByTextForHbs;
    } else if (this.state.tab === ACCOUNTS) {
      constTabType = getGlobleSearchByTextForAccounts;
    } else if (this.state.tab === USERS) {
      constTabType = getGlobleSearchByTextForUsers;
    } else if (this.state.tab === TERMINAL_LINK) {
      constTabType = getGlobleSearchByTextForTerminals;
    } else if (this.state.tab === TEMPLATE) {
      constTabType = getGlobleSearchByTextForTemplates;
    } else if (this.state.tab === LOGS) {
      constTabType = getGlobleSearchByTextForLogs;
    }
    
    let params = {
      search: this.state.searchText,
      offset: 11,
    };
    if (this.state.tab === TERMINAL_LINK) {
      params.exact = false;
    }
    this.setState({ loading: true }, () => {
      http(constTabType, params)
        .then((res) => {
          this.updateStateonPlusDataHandler(res.data);
        })
        .catch((err) => {});
    });
  };
  updateStateonPlusDataHandler = (data) => {
    let copyState = { ...this.state.data };

    if (this.state.tab === LOGS) {
      let userLog = [...this.state.userLog];
      let systemLog = [...this.state.systemLog];
      let logs = data.userLog.concat(data.systemLog);
      copyState.logs = [...copyState.logs, ...logs];
      userLog = [...userLog, ...data.userLog];
      systemLog = [...systemLog, ...data.systemLog];
      this.setState({
        loading: false,
        data: { ...copyState },
        userLog: userLog,
        systemLog: systemLog,
      });
    } else {
      if (this.state.tab === HBS_LINK) {
        copyState.hbs = [...copyState.hbs, ...data.hbs];
      } else if (this.state.tab === ACCOUNTS) {
        copyState.accounts = [...copyState.accounts, ...data.accounts];
      } else if (this.state.tab === USERS) {
        copyState.users = [...copyState.users, ...data.users];
      } else if (this.state.tab === TERMINAL_LINK) {
        copyState.terminal = [...copyState.terminal, ...data.terminal];
      } else if (this.state.tab === TEMPLATE) {
        copyState.templates = [...copyState.templates, ...data.templates];
      }

      this.setState({
        loading: false,
        data: { ...copyState },
      });
    }
  };

  render() {
    return (
      <div className={styles.SearchAutocompletes}>
        <button className={styles.btn}>
          <span className={styles.icon}>
            <Icon defination={{ prefix: "fas", iconName: "search" }} />
          </span>
        </button>
        <input
          className={styles.input}
          name="searchText"
          placeholder="search"
          type="search"
          autoComplete="off"
          onFocus={this.onFoucsHandler}
          value={this.state.searchText}
          onChange={(e) => this.onChangeHandler(e)}
        />

        <div
          className={styles.resultSearch}
          style={{ display: this.props.autoVisible ? "block" : "none" }}
        >
          <div className={styles.tableContent}>
            <div className={styles.tablewrapper}>
              <div className={`${styles.tableRow} ${styles.header}`}>
                {this.getTableHeaderhandler()}
              </div>
              <div className={styles.dataWrapper}>
                <div className={styles.dataColumnWrapper}>
                  {this.getDataByTabHandler()}
                </div>
              </div>
            </div>
          </div>
          <div
            className={styles.spinnerWrapper}
            style={{ display: this.state.loading ? "block" : "none" }}
          >
            <SpinnerLoader />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateProps = (state) => {
  return {};
};
const mapStateDispatch = (dispatch) => {
  return {
    updateTerminalSelector: (device) =>
      dispatch(actionType.updateTerminalSelector(device)),
    openTerminalNetworkInfo: () =>
      dispatch(actionType.openTerminalNetworkInfo()),
  };
};
export default withRouter(
  connect(mapStateProps, mapStateDispatch)(SearchAutocomplete)
);
