import React, { Component } from "react";
import styles from "./UploadHistory.module.scss";
import SpinnerLoader from "../../../SpinnerLoader/SpinnerLoader";
import { http } from "../../../../RequestManager/Http";
import { getTerminalsUploadHistory } from "../../../../RequestManager/QueriesAndMutations";
import { PAGE_LIMIT, INTITAL_OFFSET } from "../../../../Util/constants";
import moment from "moment";
import Pagination from "../../../Pagination/Pagination";
import Icon from "../../../Icon/Icon";
import { CREATED_AT, INDEX, CLOSE } from "../../../../Util/constTypes";
import SingleButton from "../../../SingleButton/SingleButton";
import { connect } from "react-redux";
import * as actionType from "../../../../Store/actions/index";
class UploadHistory extends Component {
  state = {
    loading: false,
    data: [],
    activeIndex: 0,
    totalRecords: 0,
    pageNo: 1,
    offset: INTITAL_OFFSET,
    limit: PAGE_LIMIT,
    activeTableRow: "",
    originalData: [],
  };
  componentDidMount() {
    this.setState({ loading: true }, () => {
      this.createHttpRequestHandler();
    });
  }
  createHttpRequestHandler = () => {
    let data = {
      offset: this.state.offset,
      limit: this.state.limit,
    };

    http(getTerminalsUploadHistory, data)
      .then((res) => {
        this.setDataHandler(res.data);
      })
      .catch((err) => {});
  };
  downLoadFileHandler = (item) => {
    this.setState({ loading: true }, () => {
      var a = document.createElement("A");
      a.href = item.location;
      a.download = item.location;
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      this.setState({ loading: false });
    });
  };
  buildTableHeaderHandler = () => {
    let header = [
      INDEX,
      "Created By",
      CREATED_AT,
      "Success Terminals",
      "Failed Terminals",
      "Total Terminals",
      "Uploaded File",
      "Assigned To",
    ];
    return header.map((item, index) => {
      return (
        <div key={index} className={styles.textCell}>
          <strong>{item}</strong>
        </div>
      );
    });
  };
  buildTableRowHandler = () => {
    try {
      let listArrayTableRows = null;

      if (this.state.data.length > 0) {
        let indexPage = this.getIndexOfPageHandler(this.state.activeIndex + 1);
        listArrayTableRows = this.state.data[this.state.activeIndex].map(
          (item, index) => {
            return (
              <div
                key={index}
                className={styles.tableRow}
                style={this.getBackgroundColor(index)}
              >
                <div className={styles.textCell}>{index + indexPage}</div>
                <div
                  className={`${styles.textCell} ${styles.createdBy}`}
                  style={{
                    textTransform:
                      item.account != null &&
                      item.account.name.toLowerCase() === "hisky"
                        ? "unset"
                        : "",
                  }}
                >
                  {item.user != null &&
                    `${item.user.firstName} ${item.user.lastName}`}
                 
                  <span
                    className={styles.toolTip}
                    style={{
                      textTransform:
                        item.account != null &&
                        item.account.name.toLowerCase() === "hisky"
                          ? "unset"
                          : "",
                    }}
                  >
                    {item.user != null &&
                      `${item.user.firstName} ${item.user.lastName}`}
                    
                  </span>
                </div>
                <div className={`${styles.textCell} ${styles.date}`}>
                  {item.createdAt
                    ? this.convertDateHandler(item.createdAt)
                    : "N/A"}
                  <span className={styles.toolTip}>
                    {item.createdAt
                      ? this.convertDateHandler(item.createdAt)
                      : "N/A"}
                  </span>
                </div>
                <div className={styles.textCell}>
                  {item.successfulTerminalsCount}
                </div>
                <div className={styles.textCell}>
                  {item.failedTerminalsCount}
                </div>
                <div className={styles.textCell}>
                  {item.totalTerminalsCount}
                </div>
                <div className={styles.textCell}>
                  {item.location !== null && item.location !== "" ? (
                    <span
                      className={styles.download}
                      onClick={() => this.downLoadFileHandler(item)}
                    >
                      <Icon
                        defination={{ prefix: "far", iconName: "download" }}
                      />
                    </span>
                  ) : (
                    "Manual upload"
                  )}
                </div>
                <div
                  className={`${styles.textCell} ${styles.assignTo}`}
                  style={{
                    textTransform:
                      item.assignedToAccount != null &&
                      item.assignedToAccount.name.toLowerCase() === "hisky"
                        ? "unset"
                        : "",
                  }}
                >
                  {item.assignedToAccount != null
                    ? item.assignedToAccount.name
                    : "N/A"}
                  {item.assignedToAccount != null && (
                    <span
                      className={styles.toolTip}
                      style={{
                        textTransform:
                          item.assignedToAccount != null &&
                          item.assignedToAccount.name.toLowerCase() === "hisky"
                            ? "unset"
                            : "",
                      }}
                    >
                      {item.assignedToAccount.name}
                    </span>
                  )}
                </div>
              </div>
            );
          }
        );
      }
      return listArrayTableRows;
    } catch (error) {}
  };
  onClickPrevHandler = () => {
    let nextIndex =
      this.state.activeIndex - 1 < 0 ? 0 : this.state.activeIndex - 1;
    this.setState({
      activeIndex: nextIndex,
      pageNo: nextIndex + 1,
      activeTableRow: "",
      deviceShow: "",
    });
  };
  onClickPageNumberHandler = (pageNumber) => {
    if (this.state.data[pageNumber - 1] !== undefined) {
      this.setState({
        activeIndex: pageNumber - 1,
        pageNo: pageNumber,
        activeTableRow: "",
        deviceShow: "",
      });
    } else {
      /**
       * offset: 3 (even if pressed next+1 pageno)
       */
      let nuberOfRecordstoFetch =
        (pageNumber - (this.state.activeIndex + 1)) * PAGE_LIMIT;
      if (pageNumber - 1 > this.state.pageNo) {
        this.setState(
          {
            offset: (this.state.activeIndex + 1) * PAGE_LIMIT + 1,
            limit: nuberOfRecordstoFetch,
            pageNo: pageNumber,
            activeTableRow: "",
            deviceShow: "",
          },
          () => {
            this.setState({ loading: true }, () => {
              this.createHttpRequestHandler();
            });
          }
        );
      } else {
        this.setState(
          {
            offset: (pageNumber - 1) * PAGE_LIMIT + 1,
            pageNo: pageNumber,
            limit: PAGE_LIMIT,
            activeTableRow: "",
            deviceShow: "",
          },
          () => {
            this.setState({ loading: true }, () => {
              this.createHttpRequestHandler();
            });
          }
        );
      }
    }
  };
  getActiveIndexHandler = (noOfRecords) => {
    let nextIndex = 0;
    if (noOfRecords > PAGE_LIMIT) {
      nextIndex = this.state.pageNo - 1;
    } else {
      nextIndex =
        this.state.activeIndex + 1 === this.state.data.length
          ? this.state.activeIndex
          : this.state.activeIndex + 1;
    }
    return nextIndex;
  };
  onClickNextHandler = () => {
    /**
     * pageNo : is the prev page number here
     * ActiveIndex = PageNo-1
     * offset : (currentPAgeNo -1) * PAGE_LIMIT +1
     */
    if (this.state.data[this.state.pageNo] !== undefined) {
      this.setState({
        activeIndex: this.state.pageNo,
        pageNo: this.state.pageNo + 1,
        activeTableRow: "",
        deviceShow: "",
      });
    } else if (
      this.state.pageNo + 1 <=
      Math.ceil(this.state.totalRecords / PAGE_LIMIT)
    ) {
      this.setState({ pageNo: this.state.pageNo + 1 }, () => {
        this.setState(
          {
            loading: true,
            offset: (this.state.pageNo - 1) * PAGE_LIMIT + 1,
            limit: PAGE_LIMIT,
            activeTableRow: "",
            deviceShow: "",
          },
          () => {
            this.createHttpRequestHandler();
          }
        );
      });
    }
  };
  setDataHandler = (data) => {
    let chunk = [...this.state.data];
    /**
     * Store the records by spliting in to two arrays
     * If user requested for next+1 records
     */
    if (data.terminalUpdateHistory.length > PAGE_LIMIT) {
      chunk[this.state.pageNo - 2] = data.terminalUpdateHistory.slice(
        0,
        PAGE_LIMIT
      );
      chunk[this.state.pageNo - 1] =
        data.terminalUpdateHistory.slice(PAGE_LIMIT);
    } else {
      chunk[this.state.pageNo - 1] = data.terminalUpdateHistory;
    }
    let originalData = [
      ...this.state.originalData,
      ...data.terminalUpdateHistory,
    ];
    this.setState(
      {
        totalRecords: data.terminalUpdateHistoryCount,
        data: chunk,
        originalData: originalData,
      },
      () => {
        this.setState(
          {
            activeIndex: this.getActiveIndexHandler(
              data.terminalUpdateHistory.length
            ),
            loading: false,
          },
          () => {}
        );
      }
    );
  };
  convertDateHandler = (ts) => {
    return moment(ts).format("MM/DD/YYYY HH:mm");
  };
  getIndexOfPageHandler = (pageIndex) => {
    return 1 + (pageIndex - 1) * PAGE_LIMIT;
  };
  getBackgroundColor = (index) => {
    return index === this.state.activeTableRow
      ? { color: "#fff", backgroundColor: "#00BDD6" }
      : {};
  };
  render() {
    return (
      <div className={styles.UploadHistorys}>
        <div
          className={styles.info}
          style={{ opacity: this.state.loading ? 0.6 : 1 }}
        >
          <h3 className={styles.header}>
            <span className={styles.headerIcon}>
              <Icon defination={{ prefix: "fas", iconName: "history" }} />
            </span>
            Import Terminals
          </h3>
          <div className={styles.breakLine}></div>
          {/* table */}
          <div className={styles.form}>
            <div className={styles.tableWrapper}>
              <div
                className={styles.table}
                style={{
                  opacity:
                    this.state.loading || this.props.showLoader ? 0.6 : 1,
                }}
              >
                <div className={styles.tableRow}>
                  {this.buildTableHeaderHandler()}
                </div>
                <div className={styles.tableContent}>
                  <div className={styles.dataTable}>
                    {this.buildTableRowHandler()}
                  </div>
                  <div
                    className={styles.spinnerWrapper}
                    style={{ display: this.state.loading ? "block" : "none" }}
                  >
                    <SpinnerLoader msg="" />
                  </div>
                </div>
                <Pagination
                  style={styles}
                  totalRecordsPerPage={
                    this.state.data.length > 0
                      ? this.state.data[this.state.activeIndex].length
                      : ""
                  }
                  totalRecords={this.state.totalRecords}
                  totalPages={Math.ceil(this.state.totalRecords / PAGE_LIMIT)}
                  setPage={this.onClickPageNumberHandler}
                  prev={this.onClickPrevHandler}
                  data={this.state.data}
                  next={this.onClickNextHandler}
                  disabled={this.state.loading || this.state.data.length === 0}
                  pageNumber={this.state.activeIndex + 1}
                />
              </div>
            </div>{" "}
          </div>

          <div className={styles.btnWrapper}>
            <SingleButton
              text={CLOSE}
              click={this.props.resetModelSelector}
              disabled={this.state.loading}
            />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateProps = (state) => {
  return {};
};

const mapStateDispatch = (dispatch) => {
  return {
    resetModelSelector: () => dispatch(actionType.resetModelSelector()),
  };
};
export default connect(mapStateProps, mapStateDispatch)(UploadHistory);
