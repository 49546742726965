import React, { Component } from "react";
import SpinnerLoader from "../../../Componnet/SpinnerLoader/SpinnerLoader";
import styles from "./LogsAndAlerts.module.scss";
import Icon from "../../../Componnet/Icon/Icon";
import moment from "moment";
import { trimString, getEmptyRows } from "../../../Util/globalFunc";
import { UNIT_ID, DATE_AND_TIME, TITLE, ID } from "../../../Util/constTypes";
export default class LogsAndAlerts extends Component {
  state = {
    data: [],
    loading: true,
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.data.length > this.state.data) {
      this.setState({ data: nextProps.data, loading: false });
    } else if (nextProps.data.length === 0) {
      this.setState({ loading: false });
    }
  }
  componentDidMount() {
    this.setState({ data: this.props.data });
  }

  convertDateHandler = (date) => {
    return moment(date).format("MM/DD/YYYY HH:MM:SS");
  };
  buildTableHeader = () => {
    let arrHeader = [ID, UNIT_ID, DATE_AND_TIME, TITLE];
    return arrHeader.map((item, index) => {
      return (
        <div key={index} className={styles.textCell}>
          <strong>{item}</strong>
        </div>
      );
    });
  };
  buildTableRows = () => {
    if (this.state.data.length === 0) {
      return getEmptyRows(styles.tableRow, 8);
      // return <p className={styles.noDataMsg}>There is no logs</p>;
    } else {
      return this.state.data.map((item, index) => {
        return (
          <div key={index} className={styles.tableRow}>
            <div className={styles.textCell}>{index + 1}</div>
            <div className={styles.textCell}>
              
              <span className={styles.hiskyInfo}>
              {item.unitId!=null?trimString(item.unitId, 16):"N/A"}
                <span className={styles.toolTip}>{item.unitId!=null?trimString(item.unitId, 16):"N/A"}</span>
              </span>
            </div>
            <div className={styles.textCell}>
                <span className={styles.dateInfo}>
              {this.convertDateHandler(item.createdAt)}
              <span className={styles.toolTip}>{this.convertDateHandler(item.createdAt)}</span></span>
            </div>
            <div className={styles.textCell}>{item.title}</div>
          </div>
        );
      });
    }
  };
  render() {
    return (
      <div
        className={styles.LogsAndAlert}
        style={{ opacity: this.state.loading ? 0.5 : 1 }}
      >
        <div className={styles.header}>
          <h3 className={styles.title}>Logs & Alerts</h3>
          <span className={styles.icon}>
            <Icon defination={{ prefix: "far", iconName: "clipboard-list" }} />
          </span>
        </div>
        <div className={styles.breakLineTitle} />
        <div className={styles.tableRow}>{this.buildTableHeader()}</div>
        <div className={styles.tableDataWrapper}>{this.buildTableRows()}</div>
        <div
          className={styles.spinnerWrapper}
          style={{ display: this.state.loading ? "block" : "none" }}
        >
          <SpinnerLoader />
        </div>
      </div>
    );
  }
}
