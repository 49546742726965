import React, { Component } from "react";
import styles from "./Input.module.scss";
import Icon from "../Icon/Icon";
export default class Input extends Component {
  render() {
   
    const {
      type,
      label,
      value,
      name,
      inputWrapper,
      placeholder,
      error,
      errMsg,
      blur,
      disabled,
      id,
      min,
      onFoucs,
      autocomplete,
      step,
      hideError = false,
      isRegularText = false,
      inputStyles = {},
      iconStyles = {},
      iconDefination = {},
      format,
      sideError,
    } = this.props;

    return (
      <div className={inputWrapper}>
        <label className={this.props.labelStyles!=undefined ? this.props.labelStyles : (isRegularText ? styles.regularLabel : styles.label)}>
          {/* <span className={styles.text}>{label}</span> */}
          {label}
          {sideError && (
            <span
              style={{
                visibility: error ? "visible" : "hidden",
                color: isRegularText ? "#fff" : "",
              }}
              className={styles.sideError}
            >
              {errMsg}
            </span>
          )}
          {this.props.showSpinner != undefined && (
            <span
              className={styles.spinnerWrapper}
              style={{
                visibility: this.props.showSpinner ? "visible" : "hidden",
              }}
            >
              <Icon
                defination={{ prefix: "fal", iconName: "spinner" }}
                isSpinner={true}
              />
            </span>
          )}
        </label>
        <input
          name={name}
          className={styles.input}
          type={type}
          onChange={this.props.change}
          value={value}
          id={id === undefined ? label : id}
          min={min}
          step={step}
          autoComplete={!autocomplete ? "on" : "off"}
          disabled={disabled}
          placeholder={placeholder}
          onBlur={blur}
          onFocus={onFoucs}
          style={inputStyles}
        />
        {Object.keys(iconDefination).length > 0 && (
          <span className={iconStyles} onClick={this.props.iconAction}>
            <Icon defination={iconDefination} />
          </span>
        )}
        {!hideError && (
          <p
            className={this.props.info!==undefined ?styles.info:styles.error}
            style={{ visibility: error ? "visible" : "hidden" }}
          >
            {errMsg}
          </p>
        )}
      </div>
    );
  }
}
