import React, { Component } from "react";
import styles from "./TerminalsInfo.module.scss";
import Icon from "../../Icon/Icon";
import { ENTITY_NAME } from "../../../Util/constTypes";
import { getEntityName } from "../../../Store/localStoreageManager/localStoreageManager";
export default class TerminalsInfo extends Component {
  _isMouted = true;
  state = {
    view: true,
    onlineCount: 0,
    offlineCount: 0,
    mockupsCount: 0,
    hubsCount: 0,
    isSiteMode: false,
    isfullscreen: false,
    types: {
      online: true,
      offline: true,
      hubs: true,
    },
  };
  componentWillUnmount() {
    this._isMouted = false;
  }
  componentDidMount() {
    if (this._isMouted) {
      if (this.props.refToGoogleMap !== undefined) {
        this.props.refToGoogleMap(this.updateModeSiteHandler);
      }

      this.setState(
        {
          isSiteMode: this.props.isSiteMode,
          types: this.props.types,
          onlineCount: this.props.onlineCount,
          offlineCount: this.props.offlineCount,
          hubsCount: this.props.hubsCount,
        },
        () => {}
      );
    }
  }

  onClickClearHandler = () => {
    let obj = {
      online: true,
      offline: true,
      hubs: true,
    };
    this.setState({ types: obj }, () => {
      this.props.setData(obj);
    });
  };
  updateModeSiteHandler = (mode) => {
    if (this._isMouted) {
      this.setState({ isSiteMode: mode }, () => {});
    }
  };
  componentWillReceiveProps(nextprops) {
    this.setState({ types: nextprops.types });
  }
  startIntervalHandler = () => {
    if (this.props.checkForFullscreen() && this._isMouted) {
      this.props.sendCount((data) => {
        if (
          data.onlineCount != this.state.onlineCount ||
          data.offlineCount != this.state.offlineCount ||
          data.hubsCount != this.state.hubsCount
        ) {
          if (this._isMouted) {
            this.setState({
              onlineCount: data.onlineCount,
              offlineCount: data.offlineCount,
              hubsCount: data.hubsCount,
            });
          }
        }
      });
      if (this._isMouted) {
        setTimeout(() => {
          if (this.props.checkForFullscreen()) this.startIntervalHandler();
        }, 3000);
      }
    }
  };
  onClickTypeHandler = (type) => {
    if (this.props.loading) return;
    let copyTypes = { ...this.state.types };
    copyTypes[type] = !this.state.types[type];
    this.setState({ types: copyTypes }, () => {});
    if (type !== "hubs") {
      this.props.setData(copyTypes);
    } else {
      this.props.updateHubDataFilter();
    }
  };
  render() {
    if (this.props.checkForFullscreen()) this.startIntervalHandler();
    if (
      this.props.refToGoogleMap !== undefined &&
      this.props.isfullscreen !== undefined &&
      !this.props.isfullscreen
    ) {
      this.props.refToGoogleMap(this.updateModeSiteHandler);
    }
    return (
      <>
        {!this.state.isSiteMode ? (
          <div className={styles.infoWrapper}>
            {getEntityName() == ENTITY_NAME && !this.props.isHubFiltersOpen && (
              <div
                className={styles.itemWrapper}
                onClick={() => this.onClickTypeHandler("hubs")}
              >
                <span className={styles.statusHubs}>
                  <Icon defination={{ prefix: "fas", iconName: "circle" }} />
                </span>
                <span
                  className={!this.state.types.hubs ? styles.slantLine : ""}
                ></span>
                hubs :
                {this.props.checkForFullscreen()
                  ? this.state.hubsCount
                  : this.props.hubsCount}
              </div>
            )}
            <span className={styles.verticalLine}></span> Terminals -
            <div
              className={styles.itemWrapper}
              onClick={() => this.onClickTypeHandler("online")}
            >
              <span className={styles.statusIconGreen}>
                <Icon defination={{ prefix: "fas", iconName: "circle" }} />
              </span>
              <span
                className={!this.state.types.online ? styles.slantLine : ""}
              ></span>
              Online :{" "}
              {this.props.checkForFullscreen()
                ? this.state.onlineCount
                : this.props.onlineCount}
            </div>
            <div
              className={styles.itemWrapper}
              onClick={() => this.onClickTypeHandler("offline")}
            >
              <span className={styles.statusIconRed}>
                <Icon defination={{ prefix: "fas", iconName: "circle" }} />
              </span>
              <span
                className={!this.state.types.offline ? styles.slantLine : ""}
              ></span>
              Offline :{" "}
              {this.props.checkForFullscreen()
                ? this.state.offlineCount
                : this.props.offlineCount}{" "}
            </div>
           
            <div
              className={styles.itemWrapper}
              onClick={this.onClickClearHandler}
            >
              Clear
              <span className={styles.iconClear}>
                <Icon defination={{ prefix: "fal", iconName: "backspace" }} />
              </span>
            </div>
          </div>
        ) : (
          <div className={styles.infoWrapper}>
            <div className={styles.sitesInfoWrapper}>
              <span className={styles.statusIconSites}>
                <Icon defination={{ prefix: "fas", iconName: "circle" }} />
              </span>
              Sites
            </div>
          </div>
        )}
      </>
    );
  }
}
