import React, { Component, Fragment } from "react";
import { Apps } from "./App.module.scss";
import Layout from "./Container/Layout/Layout";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
/**font awesome */
import { config, library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fad } from "@fortawesome/pro-duotone-svg-icons";
import "@fortawesome/fontawesome-svg-core/styles.css"; // Import the CSS
import Login from "./Container/Login/FormLogin/FormLogin";
import ResetPassword from "./Container/Login/ResetPassword/ResetPassword";
import DashBoard from "./Container/DashBoard/DashBoard";
import Spinner from "./Componnet/Spinner/Spinner";
import { http } from "./RequestManager/Http";
import {
  getAccountPermissionsAndTokenValidation,
  getSystemVersion,
} from "./RequestManager/QueriesAndMutations";
import {
  getLocalStorageInfo,
  removeInfoLocalStorage,
  getUserId,
  storeVersionNo,
} from "./Store/localStoreageManager/localStoreageManager";
import Controller from "./Controller";
import PageNotFound from "./Container/PageNotFound/PageNotFound";
import { LOGIN, ROOT, RESETPASSWORD } from "./Util/routes";

/**
 * Adding the SVG icons to library
 */

config.autoAddCss = false; // Tell Font Awesome to skip adding the CSS automatically since it's being imported above
library.add(fas, far, fal, fab, fad);
class App extends Component {
  initLoad = false;
  state = {
    components: [],
    isAuth: null,
    componentsLength: 0,
    systemVersion: null,
  };
  componentWillMount() {
    document.getElementById("lds-spinners").style.display = "none";
  }
  componentDidMount() {
    this.checkAuthAndLoadRoutesHandler();
  }
  checkAuthAndLoadRoutesHandler = () => {
    let info = getLocalStorageInfo();
    if (info === null || info === "") {
      http(getSystemVersion, {})
        .then((res) => {
          this.systemVersion = res.data.systemVersion;
          storeVersionNo(this.systemVersion);
          this.setAuthHandler(false, this.systemVersion, this.initLoad);
        })
        .catch((err) => {
          this.setState({ loading: false });
        });
    } else {
      http(getAccountPermissionsAndTokenValidation, {
        user_id: parseInt(getUserId()),
      })
        .then((res) => {
          if (res.data.userAuth) {
            Controller.setDataPermission(
              res.data.permUser,
              res.data.userAuth.isUser,
              res.data.userAuth.settings
            );
            this.systemVersion = res.data.systemVersion;
            this.setAuthHandler(true, this.systemVersion, this.initLoad);
            if (this.props.history.location.pathname === `${LOGIN}`) {
              this.props.history.push(ROOT);
            }
          } else {
            this.removeLoggedInUserInfoHandler();
          }
        })
        .catch((err) => {
          this.removeLoggedInUserInfoHandler();
        });
    }
  };
  /**
   * update the user auth in state and store
   */
  setAuthHandler = (isAuth, version_number = null, initLoad) => {
    if (isAuth && this.state.componentsLength == 0) {
      this.setState(
        {
          isAuth: true,
          componentsLength: Controller.getNumOfRoutes(),
          systemVersion:
            version_number == null ? this.systemVersion : version_number,
        },
        () => {
          this.initLoad = initLoad;
          this.setTheDynamicRoutesHandler();
        }
      );
    } else {
      this.setState(
        {
          componentsLength: 0,
          components: [],
          isAuth: false,
          systemVersion:
            version_number == null ? this.systemVersion : version_number,
        },
        () => {
          this.initLoad = true;
          if (!window.location.pathname.includes("/reset-password")) {
            this.props.history.push(LOGIN);
          }
        }
      );
    }
  };
  storeComponets = (newc, updated = false) => {
    this._components = this._components.concat(newc);

    if (updated) {
      this.setState(
        {
          components: [...this._components],
        },
        () => {
          if (
            this.state.components.length == Controller.getNumOfRoutes() &&
            this.initLoad
          ) {
            this.initLoad = false;
            this.props.history.push(ROOT);
          }
        }
      );
    } else {
      return Promise.resolve();
    }
  };
  setTheDynamicRoutesHandler = async () => {
    ////import/////
    let dynamicRoutesList = Controller.getRouteArray();
    if (dynamicRoutesList.length > 0) {
      this._components = [];
      for (let i = 0; i < dynamicRoutesList.length; i++) {
        let file = await import(dynamicRoutesList[i].import + ".js");
        this.storeComponets(
          {
            class: file.default,
            route: dynamicRoutesList[i].route,
          },
          i + 1 == dynamicRoutesList.length
        );
      }
    }
  };
  /**
   * Remove the logged in user data on logoout
   */
  removeLoggedInUserInfoHandler = () => {
    removeInfoLocalStorage();
    this.setAuthHandler(false, this.systemVersion, this.initLoad);
  };

  getUrlIdHandler = () => {
    let url = document.URL.split("/");
    return url[url.length - 1];
  };

  render() {
    var routes;

    if (!this.state.isAuth) {
      routes = (
        <Switch>
          <Route
            path={LOGIN}
            component={() => (
              <Login
                setAuthuntication={this.setAuthHandler}
                systemVersion={this.state.systemVersion}
              />
            )}
          />
          <Route path={RESETPASSWORD + "/:id"} component={ResetPassword} />
        </Switch>
      );
    }

    return (
      <div className={Apps}>
        {this.state.isAuth !== null ? (
          <Fragment>
            {this.state.isAuth ? (
              this.state.components.length == this.state.componentsLength ? (
                <Layout setAuthuntication={this.setAuthHandler}>
                  <Switch>
                    {this.state.components.map((item, index) => {
                      return (
                        <Route
                          path={item.route}
                          component={item.class}
                          key={index}
                        />
                      );
                    })}
                    <Route path={ROOT} component={DashBoard} exact />

                    <Route component={PageNotFound} />
                  </Switch>
                </Layout>
              ) : (
                <Spinner />
              )
            ) : (
              <div>
                {routes}
                {window.location.pathname.includes("/reset-password") ? (
                  <Redirect to={RESETPASSWORD + "/" + this.getUrlIdHandler()} />
                ) : (
                  <Redirect to={LOGIN} />
                )}
              </div>
            )}
          </Fragment>
        ) : (
          <Spinner />
        )}
      </div>
    );
  }
}

export default withRouter(App);
