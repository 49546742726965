import React, { Component } from "react";
import styles from './MapLeftActionBtns.module.scss';
export default class MapLeftActionBtns extends Component {
  render() {
    return (
      <div className={styles.infoWrapper}>
        <ul>
          {this.props.showInfo.map((item, index) => {
            return <li key={index}>{item.label + ":" + item.count}</li>;
          })}
        </ul>
      </div>
    );
  }
}
