import React, { Component, Fragment } from "react";
import Logo from "../../../assets/logo.png";
import styles from "./ViewWindowInfo.module.scss";
import moment from "moment";
import {
  TERMINAL,
  MOCKUP,
  ENTITY_NAME,
  HUB,
  SITE,
  HISKY_ENTITY,
  HISKY_ID,
} from "../../../Util/constTypes";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getEntityName } from "../../../Store/localStoreageManager/localStoreageManager";
import * as actionType from "../../../Store/actions/index";
import Icon from "../../Icon/Icon";
import {
  DEVICES_HUB,
  DEVICES_TERMINAL,
  HUB_PP_SIMULATOR,
} from "../../../Util/routes";
const mac = "Mac-Id";
class ViewWindowInfo extends Component {
  convertDateHandler = (date) => {
    if (date == undefined || date === "") return "N/A";
    return moment(date).format("MM/DD/YYYY HH:mm:ss");
  };
  onClickViewDevicePlanInfoHandler = (item) => {
    if (item.plan !== null) {
      this.props.accountInfoSelector({ device: item });
      if (this.props.isFullScreenMode) {
        this.props.resetModelSelector();
        this.props.openViewAssignPlanToDeviceOnMapSelecter();
      } else {
        this.props.resetModelSelector();
        this.props.openViewAssignPlanToDeviceSelecter();
      }
    }
  };
  onClickVisitDeviceInfoHandler = (item) => {
    this.props.accountInfoSelector({ device: item });
    if (this.props.isFullScreenMode) {
      this.props.resetModelSelector();
      this.props.openViewTerminalOnMapSelector();
    } else {
      this.props.resetModelSelector();
      this.props.openViewTerminalSelector();
    }
  };
  openTerminalPageHandler = () => {
    this.props.history.push(
      DEVICES_TERMINAL + "/" + this.props.id + ":" + this.props.hiskyId
    );
  };
  openSiteInfoHandler = (item) => {
    this.props.accountInfoSelector({ site: item });
    if (this.props.isFullScreenMode) {
      this.props.resetModelSelector();
      this.props.openViewSiteInfoonMapSelecter();
    } else {
      this.props.resetModelSelector();
      this.props.openViewSiteInfoSelecter();
    }
  };
  openHubInfoWindow = () => {
    //pass id and open model
    this.props.hubDataSelector({hubId:parseInt(this.props.id)});
    if (this.props.isFullScreenMode) {
      this.props.resetModelSelector();
      this.props.openHubInfoOnMapSelector();
    } else {
      this.props.resetModelSelector();
      this.props.openHubInfo();
    }
  };
  openHubPageHandler = () => {
    this.props.history.push(
      `${DEVICES_HUB}/${this.props.id}:${this.props.macId}`
    );
  };
  getInfoWindowDataHandler = (type) => {
    switch (type) {
      case TERMINAL:
        return (
          <>
            <p className={styles.title}>{HISKY_ID}</p>
            <p className={styles.title}>{this.props.hiskyId}</p>
            {window.location.pathname !== HUB_PP_SIMULATOR && (
              <Fragment>
                <p className={styles.title} style={{ marginTop: "1rem" }}>
                  Last activity
                </p>
                <p className={styles.title}>
                  {this.convertDateHandler(this.props.lastActivityDate)}
                </p>
              </Fragment>
            )}
            {this.props.showMoreInfoBtn && (
              <div className={styles.btnWrapper}>
                {this.props.history.location.pathname === "/" &&
                  getEntityName() == ENTITY_NAME && (
                    <span
                      className={styles.icon}
                      onClick={this.openTerminalPageHandler}
                    >
                      <span className={styles.toolTip}>telemetry</span>
                      <Icon
                        defination={{
                          prefix: "fa",
                          iconName: "tablet-rugged",
                        }}
                      />
                    </span>
                  )}
                <button
                  onClick={() =>
                    this.onClickVisitDeviceInfoHandler(this.props.terminalInfo)
                  }
                  className={styles.btn}
                >
                  {`info`}
                </button>
                <button
                  onClick={() =>
                    this.onClickViewDevicePlanInfoHandler(
                      this.props.terminalInfo
                    )
                  }
                  className={styles.btn}
                >
                  {`Plan`}
                </button>
              </div>
            )}
          </>
        );
        break;
      case MOCKUP:
        return (
          <>
            <p className={styles.title}>{HISKY_ID}</p>
            <p className={styles.title}>{this.props.hiskyId}</p>
            {this.props.showMoreInfoBtn && (
              <div className={styles.btnWrapper}>
                <button
                  onClick={() =>
                    this.onClickVisitDeviceInfoHandler(this.props.terminalInfo)
                  }
                  className={styles.btn}
                >
                  {`info`}
                </button>
              </div>
            )}
          </>
        );
        break;

      case HUB:
        return (
          <>
            <p className={styles.title}>{HISKY_ID}</p>
            <p className={styles.title}>{this.props.macId}</p>

            {this.props.showMoreInfoBtn && (
              <div className={styles.btnWrapper}>
                {this.props.history.location.pathname === "/" &&
                  getEntityName() == HISKY_ENTITY && (
                    <span
                      className={styles.icon}
                      onClick={this.openHubPageHandler}
                    >
                      <Icon
                        defination={{
                          prefix: "fal",
                          iconName: "tablet-rugged",
                        }}
                      />
                    </span>
                  )}
                <button
                  onClick={() => this.openHubInfoWindow()}
                  className={styles.btn}
                >
                  {`info`}
                </button>
              </div>
            )}
          </>
        );
        break;
      case SITE:
        return (
          <>
            <p className={styles.title}>Site Name</p>
            <p className={styles.title}>{`${this.props.title}`}</p>
            <p className={styles.title} style={{ marginTop: "1rem" }}>
              {`Assigned terminals ${this.props.terminalInfo.siteTerminalCount}`}{" "}
            </p>
            {this.props.history.location.pathname === "/" && (
              <button
                onClick={() =>
                  this.openSiteInfoHandler(this.props.terminalInfo)
                }
                className={styles.btn}
              >
                {`info`}
              </button>
            )}
          </>
        );
        break;
    }
  };
  render() {
   
    return (
      <div className={styles.ViewWindow}>
        <img src={Logo} className={styles.img} alt="logo-hiSky" />
        {this.getInfoWindowDataHandler(this.props.type)}
      </div>
    );
  }
}
const mapStateProps = (state) => {
  return {};
};
const mapStateDispatch = (dispatch) => {
  return {
    resetModelSelector: () => dispatch(actionType.resetModelSelector()),
    openViewTerminalSelector: () =>
      dispatch(actionType.openViewTerminalSelector()),
    openViewTerminalOnMapSelector: () =>
      dispatch(actionType.openViewTerminalOnMapSelector()),
    openHubInfoOnMapSelector: () =>
      dispatch(actionType.openHubInfoOnMapSelector()),
    openViewAssignPlanToDeviceOnMapSelecter: () =>
      dispatch(actionType.openViewAssignPlanToDeviceOnMapSelecter()),
    openViewAssignPlanToDeviceSelecter: () =>
      dispatch(actionType.openViewAssignPlanToDeviceSelecter()),
    openViewSiteInfoonMapSelecter: () =>
      dispatch(actionType.openViewSiteInfoonMapSelecter()),
    openViewSiteInfoSelecter: () =>
      dispatch(actionType.openViewSiteInfoSelecter()),
    accountInfoSelector: (accountInfo) =>
      dispatch(actionType.accountInfoSelector(accountInfo)),
    openHubInfo: () => dispatch(actionType.openHubInfo()),
    hubDataSelector: (data) => dispatch(actionType.hubDataSelector(data)),
  };
};
export default withRouter(
  connect(mapStateProps, mapStateDispatch)(ViewWindowInfo)
);
