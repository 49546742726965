import React, { Component } from "react";
import CheckBox from "../../CheckBox/CheckBox";
import Icon from "../../Icon/Icon";
import styles from "./HubFilters.module.scss";
export default class HubFilters extends Component {
  hightOfContral = document.getElementById("contralLeft").clientHeight;

  getFiltersListHandler = () => {
    let list = (
      <div className={styles.listWrapper}>
        <span className={styles.filterTitle}>Show / Hide Hubs and related terminals</span>
        <div className={styles.itemsWrapper}>
          {this.getList(this.props.hubFilters)}
        </div>
      </div>
    );

    return list;
  };

  getList = (data) => {
    return data.map((item, index) => {
      return (
        <div className={styles.typesWrapper} key={index}>
          <div id={index}  className={styles.types}>
            <CheckBox
              labelMsg={""}
              checkBoxWrapper={styles.checkBoxWrapper}
              value={this.props.ischecked(item)}
              click={() => this.props.onCheck(item)}
              name={item.macId}
              customStyles={{padding:'0.5rem 0rem'}}
            />
            {/* <span>{item.deviceName}-{item.macId}</span> */}
            <div className={styles.details}>
              <span>{item.deviceName.toLowerCase()}</span>
              <span>{item.macId}</span>
            </div>
          </div>
          <div className={styles.countWrapper}>
            <span style={{ marginRight: "0.5rem" }}>
              Online : {item.onlineTerminalsCount}
            </span>
            <span> Offline : {item.offlineTerminalsCount}</span>
          </div>
        </div>
      );
    });
  };
  render() {    
    return (
      <div
        className={`${styles.mainWrapper}`}
        style={{
          marginTop: !this.props.showCustomPosition ? "1rem" : "0",
          height: !this.props.isFullScreen
            ? !this.props.showCustomPosition
              ? this.props.googleDiv - this.hightOfContral - 30 + "px"
              : this.props.googleDiv - this.hightOfContral - 40 - 40 + "px"
            : (this.props.showCustomPosition ? 100 - 20 : 100 - 15) + "vh",
        }}
      >
        <span className={styles.closeIcon} onClick={this.props.closeFilters}>
          <Icon defination={{ prefix: "far", iconName: "times" }} />
        </span>
        <div className={styles.mainListWrapper}>
          {this.getFiltersListHandler()}
        </div>
      </div>
    );
  }
}
