import moment from "moment";
import React from "react";
import {
  getCurrentUserRole,
  getEntityName,
  getVersionNo,
  storeVersionNo,
} from "../Store/localStoreageManager/localStoreageManager";
import validator from "validator";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {
  RESELLER,
  COMPANY,
  HI_SKY,
  SUBSCRIBER,
  TECHNICIAN,
  NMS_USER_LOGS,
  USER_LOGS_DISPLAY_NAME,
  SYSTEM_LOGS,
  SYSTEM_LOGS_DISPLAY_NAME,
  SYSTEM_LOGS_LINK,
  USER_LOGS_LINK,
  DYNAMIC,
  FIXED_LR,
  FIXED_RL,
  TERMINAL,
  HISKY_ENTITY,
  ADMINISTRATOR,
  DISABLE_ACCOUNT_ICON,
  NO_AUTH_DISABLE_ACCOUNT,
  ACTIVE_ACCOUNT,
  ATTACH_PLAN_ICON,
  NO_AUTH_ATTACH_PLAN,
  ACTIVATE_MULTIPLE_PLAN_ICON,
  NO_AUTH_MULTI_PLAN_ACTIVE,
  NEW_SERVICE,
  NO_AUTH_NEW_SERVICE,
  NEW_TEMPLATE,
  CREATE_USER,
  NO_AUTH_NEW_USER,
  NO_AUTH_NEW_TEMPLATE,
  NO_AUTH_DISABLE_USER,
  NEW_UPDATE,
  NO_AUTH_SET_COVERGAE,
  SET_COVERAGE,
  NO_AUTH_NEW_UPDATE,
  NEW_ACCOUNT,
  NO_AUTH_NEW_ACCOUNT,
  HISKY_ID,
  USER,
  VIEW,
  MAC,
  DEVICES_NAME,
  PO_ID,
  PLAN_NAME,
  ACTIVATED,
  DEACTIVATED,
  INACTIVE,
  DESCRIPTION,
  UNIT_ID,
  NAME,
} from "./constTypes";
import * as route from "./routes";
import Controller from "../Controller";
import {
  INTEGRATION_SERVER,
  EXCLUDED_DEFAULT_VALUE_FOR_EXACT,
  DEFAULT_EXACT_VALUE,
  LOGS_STATUS_LIST,
} from "./constants";
export const trimString = (str, len) => {
  if (str == null) return "N/A";
  let string = str;
  if (string.length > len) {
    string = str.substring(0, len) + "...";
  }
  return string;
};
export const trimStringAtStart = (str, count = 10) => {
  if (str == null) return "N/A";
  return str.length > 10 ? "..." + str.substring(str.length, count) : str;
};
export const checkValidDatesHandler = (date) => {
  let currentdate = moment().format("MM/DD/YYYY");
  let givenDate = moment(date).format("MM/DD/YYYY");
  return (
    moment(givenDate).isAfter(currentdate) ||
    moment(currentdate).isSame(givenDate)
  );
};
export const convertDateToTimeStamp = (ts) => {
  if (ts == 0) {
    return 0;
  }

  ts = moment.utc(moment(ts)).format();
  let date = new Date(ts);
  ts = date.getTime() / 1000;
  return ts;
};

export const converStringToJson = (json) => {
  var newJson = json.replace(/([a-zA-Z0-9]+?):/g, '"$1":');
  newJson = newJson.replace(/'/g, '"');
  var data = JSON.parse(newJson);
  return data;
};
export const convertDateHandler = (date) => {
  return moment(date).format("LLL");
};
/**
 * returns the empty rows when there is no data
 * @param {*} styles
 * @param {*} noOfRecords
 */
export const getServiceTypeTelemetry = (id) => {
  if (id == null || id == 0) {
    return "N/A";
  } else if (id == 1) {
    return "UDP";
  } else if (id == 2) {
    return "BLE";
  } else if (id == 3) {
    return "FMS";
  } else {
    return "N/A";
  }
};
export const getTypeOfTerminal = (id) => {
  if (parseInt(id) === 3 || parseInt(id) === 1) {
    return FIXED_RL;
  } else if (parseInt(id) === 4) {
    return FIXED_LR;
  } else if (parseInt(id) === 2) {
    return DYNAMIC;
  } else {
    return "";
  }
};
export const getEmptyRows = (styles, noOfRecords) => {
  return Array(noOfRecords)
    .fill()
    .map((_, i) => {
      return (
        <div key={i} className={styles}>
          &nbsp;
        </div>
      );
    });
};
/**
 * returns the number by placing , after each 3 numbers
 * @param {*} x
 */
export const numberWithCommas = (num) => {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};
/**
 * converting the bytes to string and to kb, MB, GB
 * @param {} bytes
 */
export const byteToString = (bytes) => {
  if (parseInt(bytes) <= 1024) {
    return parseFloat(bytes).toFixed(0) + " " + "Bytes".toString();
  }
  let kilobyte = 1024;
  let megabyte = 1024 * kilobyte;
  let gigabyte = 1024 * megabyte;
  let terabyte = 1024 * gigabyte;
  let ByteVal = bytes;
  ByteVal *= 1;
  if (ByteVal > terabyte)
    return parseFloat(ByteVal / terabyte).toFixed(3) + " " + "TB".toString();
  else if (ByteVal > gigabyte)
    return parseFloat(ByteVal / gigabyte).toFixed(3) + " " + "GB".toString();
  else if (ByteVal > megabyte)
    return parseFloat(ByteVal / megabyte).toFixed(3) + " " + "MB".toString();
  else if (ByteVal > kilobyte)
    return parseFloat(ByteVal / kilobyte).toFixed(3) + " " + "KB".toString();
  // else return parseFloat(ByteVal).toFixed(3) + " Bytes";
};

export const arrayTypePolygonData = (key) => {
  let polygon = {
    modulationTx: [
      { id: 0, name: "0–BPSK" },
      { id: 1, name: "1–OQPSK" },
      { id: 2, name: "2-QPSK" },
    ],
    modulationRx: [
      { id: 0, name: "0–BPSK" },
      { id: 1, name: "1–OQPSK" },
      { id: 2, name: "2-QPSK" },
    ],
    polRx: [
      { id: 0, name: "0-RH circular polarization" },
      { id: 1, name: "1–LH circular polarization" },
      { id: 2, name: "2–Horizontal linear polarization" },
      { id: 3, name: "3–Vertical linear polarization" },
    ],
    polTx: [
      { id: 0, name: "0-RH circular polarization" },
      { id: 1, name: "1–LH circular polarization" },
      { id: 2, name: "2–Horizontal linear polarization" },
      { id: 3, name: "3–Vertical linear polarization" },
    ],
    txFreqPol: [
      { id: 0, name: "0-Right" },
      { id: 1, name: "1-Left" },
    ],
    rxFreqPol: [
      { id: 0, name: "0-Right" },
      { id: 1, name: "1-Left" },
    ],
  };
  return polygon[key] !== undefined ? polygon[key] : [];
};
export const dataTypeBeam = (key, id) => {
  let polygon = {
    modulationTx: {
      0: "0–BPSK",
      1: "1–OQPSK",
      2: "2-QPSK",
    },
    modulationRx: {
      0: "0–BPSK",
      1: "1–OQPSK",
      2: "2-QPSK",
    },
    spreadFactorRx: id,
    spreadFactorTx: id,
    preIntervalTx: id + "mSec",
    symbolRateRx: id + "Ksym/sec",
    polRx: {
      0: "0-RH circular polarization",
      1: "1–LH circular polarization",
      2: "2–Horizontal linear polarization",
      3: "3–Vertical linear polarization",
    },
    polTx: {
      0: "0-RH circular polarization",
      1: "1–LH circular polarization",
      2: "2–Horizontal linear polarization",
      3: "3–Vertical linear polarization",
    },
    txFreqPol: {
      0: "0-Right",
      1: "1-Left",
    },
    rxFreqPol: {
      0: "0-Right",
      1: "1-Left",
    },
    txFreq: id + "MHz",
    rxFreq: id + "MHz",
    id: id,
    symbolRateTx: id + "Ksym/sec",
    displayName: id,
    provider: id,
    isPrimary: id,
    satLongitude: id + "°",
    hub: id,
    externalId: id,
    name: id,
    additionalBeam: id ? "YES" : "NO",
    createdAt: moment(id).format("YYYY/MM/DD HH:mm:ss"),
    updatedAt: moment(id).format("YYYY/MM/DD HH:mm:ss"),
  };
  if (id == null) return "N/A";
  return typeof polygon[key] == "object"
    ? polygon[key][id] == undefined
      ? id
      : polygon[key][id]
    : polygon[key] == undefined
    ? id
    : polygon[key];
};
export const formatTime = (time) => {
  let d = Number(time);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  if (h > 0) {
    return h + ":" + m <= 9
      ? "0" + m
      : m + ":" + s <= 9
      ? "0:" + s + "h"
      : s + " h";
  }
  if (m > 0) {
    return s <= 9 ? m + ":0" + s + " min" : m + ":" + s + " min";
  } else {
    return s + " sec";
  }
};
/**
 * Return the color per entity
 */
export const getColorByEntityType = (item) => {
  if (item === undefined) {
    return "green";
  }

  switch (item) {
    case SUBSCRIBER:
    case 5:
      return "gray";
    case COMPANY:
    case 4:
      return "green";
    case RESELLER:
    case 3:
      return "orange";
    case HI_SKY:
    case 1:
      return "blue";
    case TECHNICIAN:
    case 6:
      return "#9d2f42";
  }
};
/**
 * return the entity info
 */
export const getEntityInfo = () => {
  return [
    { name: HI_SKY, color: "blue" },
    { name: RESELLER, color: "orange" },
    { name: COMPANY, color: "green" },
    { name: SUBSCRIBER, color: "gray" },
    { name: TECHNICIAN, color: "#9d2f42" },
  ];
};
export const getPermActionsInfo = (isModel = false) => {
  let data = [
    { name: "Allowed", color: "#00bdd6", cursor: "pointer" },
    { name: "No Visibility", color: "#ff1a1a", cursor: "pointer" },
  ];
  if (isModel) {
    data.push({ name: "Not-allowed", color: "gray", cursor: "not-allowed" });
  }
  return data;
};
/**
 * return the log routes
 */
export const getLogsRouteList = () => {
  let data = [];
  if (
    Controller.getAuthComponent("nms", USER_LOGS_LINK) ||
    Controller.getAuthComponent("bss", USER_LOGS_LINK)
  ) {
    data.push({
      link: route.LOGS_USER,
      name: NMS_USER_LOGS,
      displayName: USER_LOGS_DISPLAY_NAME,
      iconDefination: {
        prefix: "fas",
        iconName: "clipboard",
      },
    });
  }
  if (
    Controller.getAuthComponent("nms", SYSTEM_LOGS_LINK) ||
    Controller.getAuthComponent("bss", SYSTEM_LOGS_LINK)
  ) {
    data.push({
      link: route.LOGS_SYSTEM,
      name: SYSTEM_LOGS,
      displayName: SYSTEM_LOGS_DISPLAY_NAME,
      iconDefination: {
        prefix: "fas",
        iconName: "clipboard-list",
      },
    });
  }

  return data;
};
/**
 * get entity id by entity name
 */
export const getEntityId = (name) => {
  switch (name.toLowerCase()) {
    case "hisky":
      return 1;
    case "vno":
      return 2;
    case "reseller":
      return 3;
    case "company":
      return 4;
    case "subscriber":
      return 5;
    case "technician":
      return 6;
  }
};
export const getRoleName = (id) => {
  switch (id) {
    case 1:
      return "admin";
    case 2:
      return "support";
    case 3:
      return "subscriber";
    case 4:
      return "technician";
  }
};
export const getRoleId = (name) => {
  switch (name.toLowerCase()) {
    case "reseller":
      return 1;
    case "company":
      return 1;
    case "subscriber":
      return 3;
    case "technician":
      return 4;
  }
};
/**
 * return the index based on key value pair match
 * @param {*} list
 * @param {*} feature
 */
const filterArray = (list, feature) => {
  let index = list.findIndex(function (el, key) {
    return el.name == feature;
  });
  return index;
};

/**
 * refactor the permissions obj structure
 */
export const getPermissions = (data) => {
  let permissionsListing = { nms: {}, bss: {} };
  let nmsPermissions = [];
  let bssPermissions = [];
  data.map((item) => {
    let permKeysList = item.Perm.permKey.split("__");

    let mainKey = item.Perm.appName.toLowerCase();
    let parentCopy;
    let isBSS = false;
    if (mainKey == "bss") {
      parentCopy = bssPermissions;
      isBSS = true;
    } else {
      parentCopy = nmsPermissions;
    }

    if (permKeysList.length == 2) {
      let jsonCopy = {
        permKey: item.Perm.permKey,
        name: permKeysList[1].toLowerCase(),
        displayName: item.Perm.displayName,
        perm: {
          View: item.View,
          Update: item.Update,
          Create: item.Create,
          Del: item.Del,
          // displayName:item.Perm.displayName
        },
        features: [],
      };
      parentCopy.push(jsonCopy);
    }
    if (permKeysList.length == 3) {
      let featureKey = permKeysList[1].toLowerCase();
      let featureIndex = filterArray(parentCopy, featureKey); // find the index of it dynamically
      if (featureIndex >= 0) {
        let featureCopy = parentCopy[featureIndex].features;
        let jsonObj = {
          permKey: item.Perm.permKey,
          name: permKeysList[2].toLowerCase(),
          View: item.View,
          Update: item.Update,
          Create: item.Create,
          Del: item.Del,
          displayName: item.Perm.displayName,
        };
        featureCopy.push(jsonObj);
        parentCopy[featureIndex].features = featureCopy;
      }
    }

    if (permKeysList.length > 3) {
      let featureKey = permKeysList[1].toLowerCase();
      let featureIndex = filterArray(parentCopy, featureKey); // find the index of it dynamically
      if (featureIndex >= 0) {
        let featureCopy = parentCopy[featureIndex].features;
        let subFeatureKey = permKeysList[3].toLowerCase();
        let jsonObj = {
          //permKey: item.Perm.permKey,
          name: subFeatureKey,
          View: item.View,
          Create: item.Create,
          Del: item.Del,
          Update: item.Update,
          displayName: item.Perm.displayName,
        };
        featureCopy.push(jsonObj);
        parentCopy[featureIndex].features = featureCopy;
      }
    }
    if (isBSS) {
      bssPermissions = parentCopy;
    } else {
      nmsPermissions = parentCopy;
    }
  });

  return [nmsPermissions, bssPermissions];
};
export const formatString = (str) => {
  return str.replace(/_/g, " ");
};

/**
 * validation of port
 */
export const isValidPort = (port) => {
  if (port >= 1100 && port <= 65535) {
    return true;
  }
  return false;
};

/**validation ip add */
export const checkIfNumber = (num) => {
  if (num == "") return false;
  return isNaN(num);
};
export const isValidIp = (ip, isUDP = null) => {
  if (isUDP == null) {
    if (ip == "") return false;
    let ipSplit = ip.toString().split(".");
    if (!validator.isIP(ip, 4)) return false;
    if (
      !checkIfNumber(ipSplit[0]) &&
      (parseInt(ipSplit[0]) == 127 ||
        parseInt(ipSplit[0]) > 255 ||
        parseInt(ipSplit[0]) <= 0)
    ) {
      return false;
    }

    if (
      !checkIfNumber(ipSplit[1]) &&
      (parseInt(ipSplit[1]) < 0 || parseInt(ipSplit[1]) > 255)
    ) {
      return false;
    }

    if (
      !checkIfNumber(ipSplit[2]) &&
      (parseInt(ipSplit[2]) < 0 || parseInt(ipSplit[2]) > 255)
    ) {
      return false;
    }

    if (
      (!checkIfNumber(ipSplit[3]) && parseInt(ipSplit[3]) < 0) ||
      parseInt(ipSplit[3]) > 254
    ) {
      return false;
    }

    return true;
  } else {
    return validator.isIP(ip, 4);
  }
};
/**validation password */
export const isValidPassword = (currentPassword, newPassword) => {
  if (currentPassword === newPassword) {
    return false;
  }
  // at least one number, one lowercase and one uppercase letter
  // at least six characters
  // var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
  // return re.test(newPassword);
  return true;
};
export const randomID = (id = "") => {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return id + "_" + Math.random().toString(36).substr(2, 9);
};

export const generateMarkerId = (id) => {
  return id.replace(/[^a-zA-Z0-9 ]/g, "");
};

export const claculateTypeTerminalHandler = (termianls) => {
  var dynamicTerminalCount = 0;
  var staticTerminalCount = 0;
  let temp = [];
  if (termianls === null) {
    return { dynamicTerminalCount, staticTerminalCount, temp };
  }

  for (let i = 0; i < termianls.length; i++) {
    if (
      termianls[i].terminal.terminalType === 1 ||
      termianls[i].terminal.terminalType === 3 ||
      termianls[i].terminal.terminalType === 4
    ) {
      staticTerminalCount++;
    } else {
      dynamicTerminalCount++;
    }

    termianls[i].type = TERMINAL;
    termianls[i].lng =
      termianls[i].lastLong !== null ? termianls[i].lastLong : 34.7818;
    termianls[i].lat =
      termianls[i].lastLat !== null ? termianls[i].lastLat : 32.0853;
    termianls[i].hiskyId = termianls[i].terminal.hiskyId;
    termianls[i].id = termianls[i].terminal.id;
    termianls[i].markerId = generateMarkerId(termianls[i].terminal.hiskyId);
    termianls[i].online =
      termianls[i].terminal.isOnline !== undefined
        ? termianls[i].terminal.isOnline
        : false;
  }
  return { dynamicTerminalCount, staticTerminalCount, termianls };
};
export const removeArrayElementHandler = (arr, index) => {
  arr.splice(index, 1);
  return arr;
};
export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};
export const dateConverstion = (date) => {
  const d = new Date(date);

  if (date != "" && date !== undefined) {
    return d;
  } else if (
    d.toString().toLowerCase() === "invalid date" ||
    date === undefined ||
    date === ""
  ) {
    return "";
  }
};
export const convertUtcTimeToLocal = (date) => {
  var stillUtc = moment.utc(date).toDate();
  var local = moment(stillUtc).local();

  return local;
};
export const isValidHiskyId = (hiskyId) => {
  var regExp = /^\d+$/;
  if (hiskyId.match(regExp) && hiskyId.length == 14 && !isNaN(hiskyId))
    return true;
  return false;
};
export const checkVersionApp = (version) => {
  if (getVersionNo() === version) {
    return true;
  }
  storeVersionNo(version);
  return false;
};

export const converToUTCFormart = (dateInput) => {
  let isoDateString = new Date(dateInput).toISOString();
  let convertedDate = `${isoDateString.split("T")[0]} ${
    isoDateString.split("T")[1].split(".")[0]
  }`;
  return convertedDate;
};

export const isAuthorized = () => {
  //if (window.location.host == INTEGRATION_SERVER) return true;
  if (getEntityName() == HISKY_ENTITY && getCurrentUserRole() != ADMINISTRATOR)
    return false;
  else return true;
};
export const getIsAuthorizedMsg = (actionType) => {
  if (actionType == DISABLE_ACCOUNT_ICON) {
    return NO_AUTH_DISABLE_ACCOUNT;
  } else if (actionType == ACTIVE_ACCOUNT) {
    return NO_AUTH_DISABLE_USER;
  } else if (actionType == ATTACH_PLAN_ICON) return NO_AUTH_ATTACH_PLAN;
  else if (actionType == ACTIVATE_MULTIPLE_PLAN_ICON)
    return NO_AUTH_MULTI_PLAN_ACTIVE;
  else if (actionType == NEW_SERVICE) {
    return NO_AUTH_NEW_SERVICE;
  } else if (actionType == NEW_TEMPLATE) {
    return NO_AUTH_NEW_TEMPLATE;
  } else if (actionType == CREATE_USER) {
    return NO_AUTH_NEW_USER;
  } else if (actionType == NEW_UPDATE) {
    return NO_AUTH_NEW_UPDATE;
  } else if (actionType == SET_COVERAGE) {
    return NO_AUTH_SET_COVERGAE;
  } else if (actionType == NEW_ACCOUNT) {
    return NO_AUTH_NEW_ACCOUNT;
  } else return actionType;
};
export const getTimeZoneDiff = () => {
  var offset = new Date().getTimezoneOffset(); // will return in munutes
  var diff = -(offset / 60);
  var diffString = diff > 0 ? `+${diff}` : diff.toString();
  return diffString;
};
export const getTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};
export const getDefaultValueForExact = () => {
  let exactValue =
    (EXCLUDED_DEFAULT_VALUE_FOR_EXACT.indexOf(window.location.pathname) > -1 &&
      getEntityName() != HISKY_ENTITY) ||
    window.location.pathname == route.LOGS_USER
      ? true
      : DEFAULT_EXACT_VALUE;

  return exactValue;
};
export const getDataFilterInfoList = (type) => {
  let list = {};
  list[route.DEVICES_TERMINALS] = [
    DEVICES_NAME,
    HISKY_ID,
    MAC,
    "Attached Plan Template Title",
    "UDP Destination IP",
    "UDP Destination URL",
    "UDP Destination port",
    "UDP Receive port",
    "IM dest port",
    "IM receive port",
    "Owner",
    "Assigned to account",
  ];
  list[route.DEVICES_HUBS] = [
    DEVICES_NAME,
    `${HISKY_ID}`,
    "Hub Local IP",
    "Hub Control Port Address",
  ];
  list[route.LOGS_USER] = [
    "Log GUID",
    "Entity Type",
    DESCRIPTION,
    "Log Type",
    "Company name (AKA account name)",
    "Owner full name (account owner full name)",
    "User full name (which user performed the logged action) ",
  ];
  list[route.LOGS_SYSTEM] = ["Title", DESCRIPTION, UNIT_ID];
  list[route.UPDATES] = [NAME, "Version Number"];
  list[route.PLANS_SERVICES] = [NAME];
  list[route.PLANS_TEMPLATES] = ["Title"];
  list["HistoryPlanCreate"] = ["Account Name", PLAN_NAME, PO_ID];
  list["HistoryPlanActivate"] = [
    "Account Name",
    PLAN_NAME,
    PO_ID,
    DEVICES_NAME,
    HISKY_ID,
  ];
  list["HistoryPlanDetach"] = [
    "Detached By – Account Name",
    PLAN_NAME,
    PO_ID,
    DEVICES_NAME,
    HISKY_ID,
  ];
  list["HistoryPlanAttach"] = [
    "Attached By - Account Name",
    PLAN_NAME,
    PO_ID,
    DEVICES_NAME,
    HISKY_ID,
  ];

  list["HistoryPlanAttach"] = [
    "From - Account Name",
    PLAN_NAME,
    PO_ID,
    DEVICES_NAME,
    HISKY_ID,
  ];
  list["HistoryPlanAssign"] = [
    "From - Account Name",
    PLAN_NAME,
    PO_ID,
    DEVICES_NAME,
    HISKY_ID,
  ];
  list[route.ACTIVITY] = [HISKY_ID, PLAN_NAME, "Account Name"];
  return list[type];
};
export const capitalizeFirstLetter = (data) => {
  let string = data.trim();
  let str = string.charAt(0).toUpperCase() + string.substring(1);
  return str;
};
export const checkForIpValue = (portIp) => {
  let data = portIp != undefined ? portIp.trim() : "";
  if (data == "" || data == null || data == undefined || !isValidIp(data))
    return "0.0.0.0";
  else return data;
};
export const checkForPortValue = (portValue) => {
  let data = portValue != undefined ? portValue.toString().trim() : "";
  if (
    data == undefined ||
    data == "" ||
    data == null ||
    isNaN(parseInt(data)) ||
    !isValidPort(data)
  )
    return "0";
  else return data;
};
// export const getUniqueId = () => {
//   return window.location.host === "iotsatcomnms.com" ? 'Network Id':'hiSky Id';
// }

export const convertDate = (ts, format = "MM/DD/YYYY HH:mm:ss") => {
  return moment(ts).format(format);
};

export const getServiceTypes = (services) => {
  let serviceTypes = services.map((type) => {
    return type.serviceType.name;
  });
  return serviceTypes.join(", ");
};
/**
 * return the list of Icons
 */
export const getAllLogStatusList = () => {
  return LOGS_STATUS_LIST;
};

/**
 * get Icon by id
 */
export const getIConById = (id) => {
  let obj = LOGS_STATUS_LIST.find((x) => x.id == id);
  return obj;
};
/**
 * Export data to excel file
 */
export const downloadAsExcel = (filename, dataToExport, closeLoader) => {
  const fileName = filename;
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const ws = XLSX.utils.json_to_sheet(dataToExport);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
  closeLoader();
};
/**
 * get mode type based on user (admin /user)
 */
export const isViewMode = () => {
  return getCurrentUserRole() == USER;
};
export const formatLatOrLng = (val) => {
  return Number.parseFloat(val).toFixed(3) + "°";
};
export const getPlanStatusInfo = () => {
  return [
    { name: ACTIVATED, color: "green" },
    { name: DEACTIVATED, color: "red" },
    { name: INACTIVE, color: "orange" },
  ];
};
export const formatAmount = (val, currency = "$") => {
  return `${currency}${val}`;
};
export const getBeamValues = (key, id) => {
  let obj = {
    txRxPolarity: {
      0: "Right hand circular",
      1: "Left hand circular",
      2: "Horizontal linear polarization",
      3: "Vertical linear polarization",
    },
    txRxModulation: {
      0: "BPSK",
      1: "OQPSK",
      2: "QPSK",
    },
    txInverse: {
      0: "Right",
      1: "Left",
    },
  };
  return obj[key[id]];
};
