import React, { Component } from "react";
import styles from "./SideBar.module.scss";
import { withRouter } from "react-router-dom";
import LinkExpand from "./LinkExpand/LinkExpand";
import SingleLink from "./SingleLink/SingleLink";

import {
  DASHBOARD,
  LOGS,
  SYSTEM_LOGS,
  NMS_USER_LOGS,
  BSS_USER_LOGS,
  SYSTEM_LOGS_DISPLAY_NAME,
  USER_LOGS_DISPLAY_NAME,
  CDR,
  DEVICES,
  PLANS,
  SUPPORT,
  ACCOUNTS,
  PREMISSIONS,
  SUBSCRIPTIONS,
  SERVICES,
  TEMPLATES,
  BSS_DASHBOARD,
  TERMINALS,
  UPDATES,
  REPORTS,
  USERS,
  SETTINGS,
  HUB_PP,
  PRODUCTION,
  STATISTICS
} from "../../Util/constTypes";
import { HUBS } from "../DashBoard/Type";
import * as route from "../../Util/routes";
import Controller from "../../Controller";

class AccountHisky extends Component {
  getSettingsSubLinks = () => {
    let list = [];

    if (Controller.getAuthComponent("settings", "simulator")) {
      list.push({
        link: route.HUB_PP_SIMULATOR,
        name: HUB_PP,
        iconDefination: {
          prefix: "fab",
          iconName: "simplybuilt",
        },
      });
    }
    return list;
  };
  render() {
    return (
      <div>
        <div className={styles.linkGroup}>
          <div
            className={styles.subTitle}
            style={{ textAlign: this.props.menu ? null : "center" }}
          >
            {this.props.menu ? "Nms" : ""}
          </div>
          <SingleLink
            name={DASHBOARD}
            menu={this.props.menu}
            iconDefination={{
              prefix: "fas",
              iconName: "chart-line",
            }}
            activeLink={this.props.activeLink}
            click={this.props.onClickLinkHandler}
            link={route.ROOT}
            linkName={DASHBOARD}
          />
          <LinkExpand
            prefix={DEVICES}
            menu={this.props.menu}
            clickReOpenMenu={this.props.onClickMenuBarHandler}
            selectLink={this.props.activeLink}
            click={this.props.onClickLinkHandler}
            iconDefination={{
              prefix: "fas",
              iconName: "tablet-alt",
            }}
            linkArray={[
              {
                link: route.DEVICES_TERMINALS,
                name: TERMINALS,
                showLink: Controller.getAuthComponent("nms", "TERMINAL"),
                iconDefination: {
                  prefix: "far",
                  iconName: "tablet-rugged",
                },
              },
              {
                link: route.DEVICES_HUBS,
                name: HUBS,
                showLink: Controller.getAuthComponent("nms", "HBS"),
                iconDefination: {
                  prefix: "fal",
                  iconName: "tablet-rugged",
                },
              },
            ]}
          />

          <SingleLink
            name={PREMISSIONS}
            menu={this.props.menu}
            iconDefination={{
              prefix: "fas",
              iconName: "user-cog",
            }}
            activeLink={this.props.activeLink}
            click={this.props.onClickLinkHandler}
            link={route.PERMISSIONS}
            linkName={PREMISSIONS}
          />

          <LinkExpand
            prefix={LOGS}
            menu={this.props.menu}
            clickReOpenMenu={this.props.onClickMenuBarHandler}
            selectLink={this.props.activeLink}
            click={this.props.onClickLinkHandler}
            iconDefination={{
              prefix: "far",
              iconName: "folder",
            }}
            linkArray={[
              {
                link: route.LOGS_USER,
                name: NMS_USER_LOGS,
                displayName: USER_LOGS_DISPLAY_NAME,
                showLink: Controller.getAuthComponent("nms", "USER_LOGS"),
                iconDefination: {
                  prefix: "fas",
                  iconName: "clipboard",
                },
              },
              {
                link: route.LOGS_SYSTEM,
                name: SYSTEM_LOGS,
                displayName: SYSTEM_LOGS_DISPLAY_NAME,
                showLink: Controller.getAuthComponent("nms", "SYSTEM_LOGS"),
                iconDefination: {
                  prefix: "fas",
                  iconName: "clipboard-list",
                },
              },
            ]}
          />
          <SingleLink
            name={UPDATES}
            menu={this.props.menu}
            iconDefination={{
              prefix: "fas",
              iconName: "edit",
            }}
            activeLink={this.props.activeLink}
            click={this.props.onClickLinkHandler}
            link={route.UPDATES}
            linkName={UPDATES}
          />
          <SingleLink
            name={route.STATISTICS}
            menu={this.props.menu}
            iconDefination={{
              prefix: "far",
              iconName: "analytics",
            }}
            activeLink={this.props.activeLink}
            click={this.props.onClickLinkHandler}
            link={route.STATISTICS}
            linkName={STATISTICS}
          />
       
          {this.getSettingsSubLinks().length > 0 && (
            <LinkExpand
              prefix={SETTINGS}
              menu={this.props.menu}
              clickReOpenMenu={this.props.onClickMenuBarHandler}
              selectLink={this.props.activeLink}
              click={this.props.onClickLinkHandler}
              iconDefination={{
                prefix: "fal",
                iconName: "cog",
              }}
              linkArray={this.getSettingsSubLinks()}
            />
          )}
          <div
            className={this.props.menu ? styles.subTitle : styles.subTitleClose}
            style={{
              textAlign: this.props.menu ? null : "center",
              marginTop: "4.5rem",
            }}
          >
            {this.props.menu ? "bss / OSS" : ""}
          </div>
          <SingleLink
            name={BSS_DASHBOARD}
            menu={this.props.menu}
            iconDefination={{
              prefix: "fas",
              iconName: "chart-line",
            }}
            activeLink={this.props.activeLink}
            click={this.props.onClickLinkHandler}
            link={route.ROOT}
            linkName={DASHBOARD}
          />
          {Controller.getAuthComponent("settings", "productionModule")&&<SingleLink
            name={PRODUCTION}
            menu={this.props.menu}
            iconDefination={{
              prefix: "far",
              iconName: "industry",
            }}
            activeLink={this.props.activeLink}
            click={this.props.onClickLinkHandler}
            link={route.PRODUCTION}
            linkName={PRODUCTION}
          />}

          <LinkExpand
            prefix={PLANS}
            menu={this.props.menu}
            clickReOpenMenu={this.props.onClickMenuBarHandler}
            selectLink={this.props.activeLink}
            click={this.props.onClickLinkHandler}
            iconDefination={{
              prefix: "far",
              iconName: "folder",
            }}
            linkArray={[
              {
                link: route.PLANS_SERVICES,
                name: SERVICES,
                showLink: Controller.getAuthComponent("bss", "SERVICES"),
                iconDefination: {
                  prefix: "fab",
                  iconName: "servicestack",
                },
              },
              {
                link: route.PLANS_TEMPLATES,
                name: TEMPLATES,
                showLink: Controller.getAuthComponent("bss", "TEMPLATES"),
                iconDefination: {
                  prefix: "fal",
                  iconName: "globe",
                },
              },
              {
                link: route.PLANS_HISTORY,
                name: SUBSCRIPTIONS,
                showLink: Controller.getAuthComponent("bss", "HISTORY"),
                iconDefination: {
                  prefix: "fal",
                  iconName: "history",
                },
              },
            ]}
          />
          <SingleLink
            name={USERS}
            menu={this.props.menu}
            iconDefination={{
              prefix: "fas",
              iconName: "users",
            }}
            activeLink={this.props.activeLink}
            click={this.props.onClickLinkHandler}
            link={route.USERS}
            linkName={USERS}
          />
          <SingleLink
            name={ACCOUNTS}
            menu={this.props.menu}
            iconDefination={{
              prefix: "far",
              iconName: "user",
            }}
            activeLink={this.props.activeLink}
            click={this.props.onClickLinkHandler}
            link="#"
            linkName={ACCOUNTS}
          />

          <SingleLink
            name={CDR}
            menu={this.props.menu}
            iconDefination={{
              prefix: "fal",
              iconName: "satellite",
            }}
            activeLink={this.props.activeLink}
            click={this.props.onClickLinkHandler}
            link={route.ACTIVITY}
            linkName={CDR}
          />
          <SingleLink
            name={REPORTS}
            menu={this.props.menu}
            iconDefination={{
              prefix: "fal",
              iconName: "file-chart-line",
            }}
            activeLink={this.props.activeLink}
            click={this.props.onClickLinkHandler}
            link={route.REPORTS}
            linkName={REPORTS}
          />
          <LinkExpand
            prefix={LOGS}
            menu={this.props.menu}
            clickReOpenMenu={this.props.onClickMenuBarHandler}
            selectLink={this.props.activeLink}
            click={this.props.onClickLinkHandler}
            iconDefination={{
              prefix: "far",
              iconName: "folder",
            }}
            linkArray={[
              {
                link: route.LOGS_USER,
                name: BSS_USER_LOGS,
                displayName: USER_LOGS_DISPLAY_NAME,
                showLink: Controller.getAuthComponent("bss", "USER_LOGS"),
                iconDefination: {
                  prefix: "fas",
                  iconName: "clipboard",
                },
              },
            ]}
          />
          <SingleLink
            name={SUPPORT}
            menu={this.props.menu}
            iconDefination={{
              prefix: "far",
              iconName: "user-headset",
            }}
            activeLink={this.props.activeLink}
            click={this.props.onClickLinkHandler}
            link={route.SUPPORT}
            isExternal={true}
            linkName={SUPPORT}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(AccountHisky);
