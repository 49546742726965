import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import * as route from "../../Util/routes";
import styles from "./RefreshCache.module.scss";
import Logo from "../../assets/logo.png";
import { getVersionNo, removeInfoLocalStorage } from "../../Store/localStoreageManager/localStoreageManager";
class RefreshCache extends Component {
  refreshCacheHandler = () => {
    removeInfoLocalStorage();
    this.props.setAuthuntication(false);
    this.props.history.push(route.LOGIN);
    window.location.reload(true);
  };
  render() {
    return (
      <div className={styles.RefreshCaches}>
        <div className={styles.wrapper}>
          <div className={styles.logoWrapper}>
            <img
              src={Logo}
              alt="hiSky-logo"
              className={styles.imgPlaceholder}
            />
          </div>
          <p className={styles.title}>
            <strong>New Version available</strong>
          </p>
          <p className={styles.subTitle}>
            <strong>( {getVersionNo()} )</strong>
          </p>
          <div className={styles.btnWrapper}>
            <button className={styles.btnOk} onClick={this.refreshCacheHandler}>
              Update
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(RefreshCache);
