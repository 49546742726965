import React, { Component, Fragment } from "react";
import styles from "./DashboardMarker.module.scss";
import Icon from "../../Icon/Icon";
import SpinnerLoader from "../../SpinnerLoader/SpinnerLoader";
import "../../../../node_modules/rc-slider/assets/index.css";
import "../../../../node_modules/rc-tooltip/assets/bootstrap.less";
import Slider from "rc-slider";
import Tooltip from "rc-tooltip";
import Logo from "../../../assets/logo.png";
import {
  LOCATION_NAME,
  UPDATE_DATE,
  STATUS,
  DNS,
  TERMINAL_TYPE,
  LATTITUDE,
  LONGITUDE,
  DEST_IP,
  CREATED_AT,
  RECV_PORT,
  DEST_PORT,
  START,
  STOP,
  MOCKUP,
  RESELLER_ENTITY,
  HISKY_ENTITY,
  UPD_NETWORK_CONFIG,
  IM_NETWORK_CONFIG,
  HISKY_ID,
  MAC,
  PO_ID,
  PLAN_ID,
  PLAN_NAME,
  SERVICE_TYPE,
  KML_CREATED,
  KML_DATE,
  TRANSPORT_LAYER,
  EXPIRATION_DATE,
  ACTIVATION_DATE,
  LOOPBACK,
  OWNER,
} from "../../../Util/constTypes";
import Controller from "../../../Controller";
import KeyValue from "../../KeyValue/KeyValue";
import { http } from "../../../RequestManager/Http";
import {
  getMarkerInfoOnDashboard,
  getHubofTerminal,
  getTerminalGpsTraceCount,
} from "../../../RequestManager/QueriesAndMutations";
import moment from "moment";
import { getEntityName } from "../../../Store/localStoreageManager/localStoreageManager";
import {
  checkForIpValue,
  checkForPortValue,
  formatLatOrLng,
  getTypeOfTerminal,
  dataTypeBeam,
} from "../../../Util/globalFunc";
import { TERMINAL_ONLINE_COLOR } from "../../../Util/constants";
const Handle = Slider.Handle;
const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;

  return (
    <Tooltip
      overlay={value}
      visible={dragging}
      placement="top"
      key={index}
      overlayStyle={{ zIndex: 1051 }}
      visible={false}
    >
      <Handle
        value={value}
        style={{ backgroundColor: "blue" }}
        {...restProps}
      />
    </Tooltip>
  );
};
const ONLINE = "Online";
const OFFLINE = "Offline";
class DashboardMarker extends Component {
  onlineCount = 0;
  offlineCount = 0;
  timer = null;
  hiskyId = "";
  // isMockup = true;
  isDynamic = false;
  _isMounted = true;
  _noGpsTrace = false;
  interval = null;
  //_checkedGpsTRace = false;
  id = "";
  selectedIndex = 0;
  state = {
    deltaMins: {
      value: 0,
      error: false,
      errMsg: "Priority level must be set",
    },
    termianls: [],
    networkIm: [],
    networkUdp: [],
    terminalId: "",
    loading: true,
    selectedTermianl: [],
    telemetry: [],
    intervalLoading: true,
    plan: [],
    coordinates: { lat: "", lng: "" },
    hubDetails: {},
  };
  componentDidMount() {
    this.checkforDashIdHandler();
    this.clearTimerHandler();
    if (this.props.stopMarkerInstance !== undefined) {
      this.props.stopMarkerInstance(this.stopMarkerInstanceHandler);
    }
    this.props.contralIntervalHttpCalles(STOP);

    let transmitting = this.props.getTransmittingTerminals();
    let deltaMinsCopy = { ...this.state.deltaMins };

    if (!this.props.showHubDetails) {
      this.countOnlineOffLineTermainlsHandler(this.props.termianls);
      this.id = this.props.termianls[0].id;
      this.hiskyId = this.props.termianls[0].hiskyId;

      this.isDynamic = this.props.termianls[0].isDynamic;
      deltaMinsCopy.value =
        transmitting[this.props.termianls[0].id] != undefined
          ? transmitting[this.props.termianls[0].id]
          : 0;
    }

    this.setState({
      loading: true,
      termianls: this.props.termianls,
      terminalId: this.props.termianls.length
        ? parseInt(this.props.termianls[0].id)
        : "",
      deltaMins: deltaMinsCopy,
    });
  }
  componentWillUnmount() {
    this.clearTimerHandler();
    this._isMounted = false;
  }
  checkforDashIdHandler = () => {
    if (document.getElementById("dashboradMarker") === null) {
      this.interval = setTimeout(() => {
        this.checkforDashIdHandler();
      }, 300);
    } else {
      clearTimeout(this.interval);
      this.interval = null;
      this.state.terminalId !== "" &&
        document.getElementById("polygonInfo") == null &&
        this.createHttpRequsetHandler();
    }
  };
  clearTimerHandler = () => {
    clearTimeout(this.timer);
    this.timer = null;
  };
  stopMarkerInstanceHandler = () => {
    this.clearTimerHandler();
    this._isMounted = false;
  };

  createHttpRequsetHandler = () => {
    if (document.getElementById("dashboradMarker") === null) {
      this.timer = null;
      clearTimeout(this.timer);
    } else {
      this.setState({ intervalLoading: true }, () => {
        if (this.props.showHubDetails) {
          http(getHubofTerminal, {
            terminalId: this.state.terminalId,
          })
            .then((res) => {
              let terminalHbs =
                res.data.terminalsHbs == null ? {} : res.data.terminalsHbs;
              this.setHubDetailsHandler(terminalHbs);
            })
            .catch((err) => {});
        } else {
          http(getMarkerInfoOnDashboard, {
            id: this.state.terminalId,
          })
            .then((res) => {
              this.setDataHandler(res.data.terminalInstance);
            })
            .catch((err) => {});
        }
      });
    }
  };
  setHubDetailsHandler = (data) => {
    this.setState({ hubDetails: data, intervalLoading: false, loading: false });
  };
  converDateHandler = (date) => {
    return moment(date).format("MM/DD/YYYY");
  };
  convertDataToLableValueHandler = (obj) => {
    let plan = [];
    let terminal = [];
    let telemetry = [];
    let networkIm = [];
    let networkUdp = [];
    let passKey = [
      "plan",
      "lastAssigned",
      "lastUpdate",
      "terminalType",
      "id",
      "createdAt",
      "updatedAt",
      "mockup",
      "isOnline",
      "lat",
      "long",
      "isActive",
      "lastTelemetry",
      "loopback",
      "destIpIm",
      "destPortIm",
      "recvPortIm",
      "destIp",
      "destPort",
      "recvPort",
      "macId",
      "destUrl",
      "connectionType",
    ];

    for (let key in obj) {
      if (!passKey.includes(key)) {
        terminal.push({
          label: key.replace(/([A-Z])/g, " $1").trim(),
          value: obj[key]!=null ?obj[key].toString():'N/A'
        });
      } else if (key === "terminalType") {
        terminal.push({
          label: TERMINAL_TYPE,
          value: getTypeOfTerminal(obj[key]),
        });
        this.isDynamic = obj[key] == 2;
      } else if (key === "isOnline") {
        terminal.push({
          label: STATUS,
          value: obj[key] ? ONLINE : OFFLINE,
        });
      }
      // else if (key === "macId") {
      //   terminal.push({
      //     label: MAC,
      //     value: obj[key] !== "" ? obj[key] : "N/A",
      //   });
      // }
      else if (key === "isActive") {
        terminal.push({
          label: "Is Active",
          value: obj[key] ? "YES" : "NO",
        });
      } else if (key == "loopback") {
        networkUdp.push({ label: LOOPBACK, value: obj[key] ? "On" : "Off" });
      } else if (key === "createdAt") {
        terminal.push({
          label: CREATED_AT,
          value: this.converDateHandler(obj[key]),
        });
      } else if (key === "updatedAt") {
        terminal.push({
          label: UPDATE_DATE,
          value: this.converDateHandler(obj[key]),
        });
      } else if (key == "recvPort") {
        networkUdp.push({
          label: RECV_PORT,
          value:
            obj.connectionType !== null && parseInt(obj.connectionType) == 1
              ? checkForPortValue(obj[key])
              : "N/A",
        });
      } else if (key == "destUrl") {
        networkUdp.push({
          label: DNS,
          value: obj.destUrl !== null ? obj.destUrl : "N/A",
        });
      } else if (key == "recvPortIm") {
        networkIm.push({
          label: RECV_PORT,
          value: checkForPortValue(obj[key]),
        });
      } else if (key == "destPort") {
        networkUdp.push({
          label: DEST_PORT,
          value: checkForPortValue(obj[key]),
        });
      } else if (key == "connectionType") {
        networkUdp.push({
          label: TRANSPORT_LAYER,
          value: obj[key] != null ? (obj[key] == 1 ? "UDP" : "TCP") : "N/A",
        });
      } else if (key == "destPortIm") {
        networkIm.push({
          label: DEST_PORT,
          value: checkForPortValue(obj[key]),
        });
      } else if (key == "destIp") {
        networkUdp.push({
          label: DEST_IP,
          value: checkForIpValue(obj[key]),
        });
      } else if (key == "destIpIm") {
        networkIm.push({
          label: DEST_IP,
          value: checkForIpValue(obj[key]),
        });
      } else if (key === "lastAssigned") {
        if (
          parseInt(obj[key].tAccount.entity.id) === 5 ||
          parseInt(obj[key].tAccount.entity.id) === 4
        ) {
          plan.push({
            label: OWNER,
            value: `${obj[key].tAccount.owner.firstName}-${obj[key].tAccount.owner.lastName}`,
          });
        } else {
          plan.push({
            label: OWNER,
            value: `${obj[key].tAccount.name}`,
          });
        }
      } else if (key === "plan" && obj[key] !== null) {
        let plan_obj = obj[key];

        for (let key_p in plan_obj) {
          if (key_p === "planActivateDate") {
            plan.push({
              label: ACTIVATION_DATE,
              value:
                plan_obj[key_p] != null
                  ? this.converDateHandler(plan_obj[key_p])
                  : "N/A",
            });
          } else if (key_p === "id") {
            plan.push({
              label: PLAN_ID,
              value: plan_obj[key_p],
            });
          } else if (key_p === "poId") {
            plan.push({
              label: PO_ID,
              value: plan_obj[key_p],
            });
          }
          // else if (key_p == "planPhoneNumber") {
          //   plan.push({
          //     label: PHONE_NUMBER,
          //     value:
          //       plan_obj[key_p] !== null && plan_obj[key_p].phoneNumber != null
          //         ? plan_obj[key_p].phoneNumber
          //         : "N/A",
          //   });
          // }
          else if (key_p == "template" && plan_obj[key_p].template !== null) {
            let serviceTypes = plan_obj[key_p].services.map((type) => {
              return type.serviceType.name;
            });
            plan.push({
              label: PLAN_NAME,
              value: plan_obj[key_p].title,
            });
            plan.push({
              label: SERVICE_TYPE,
              value: serviceTypes.join(", "),
            });

            plan.push({
              label: EXPIRATION_DATE,
              value: this.converDateHandler(plan_obj[key_p].discontinuedAt),
            });
          }
        }
      } else if (key === "lastTelemetry") {
        telemetry.push({
          label: "last Activity Date",
          value:
            obj[key] === null
              ? "N/A"
              : moment(obj[key].lastActivityDate).format("MM/DD/YYYY HH:mm:ss"),
        });
        telemetry.push({
          label: LATTITUDE,
          value:
            obj[key] === null
              ? formatLatOrLng(obj.lat)
              : formatLatOrLng(obj[key].lastLat),
        });
        telemetry.push({
          label: LONGITUDE,
          value:
            obj[key] === null
              ? formatLatOrLng(obj.long)
              : formatLatOrLng(obj[key].lastLong),
        });
      }
    }
console.log({
  termianl: terminal,
  plan: plan,
  telemetry: telemetry,
  networkIn: networkIm,
  networkUdp: networkUdp,
})
    return {
      termianl: terminal,
      plan: plan,
      telemetry: telemetry,
      networkIn: networkIm,
      networkUdp: networkUdp,
    };
  };
  countOnlineOffLineTermainlsHandler = (arr) => {
    this.onlineCount = 0;
    this.offlineCount = 0;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].isOnline) {
        this.onlineCount++;
      } else {
        this.offlineCount++;
      }
    }
    arr = arr.sort((a, b) => {
      if (a.isOnline !== b.isOnline) {
        return a.isOnline ? -1 : 1;
      } else {
        return a.hiskyId < b.hiskyId ? -1 : 1;
      }
    });
  };
  setDataHandler = (data) => {
    let res = this.convertDataToLableValueHandler(data);
    let copyList = [...this.state.termianls];
    copyList[this.selectedIndex].isOnline = data.isOnline;
    this.countOnlineOffLineTermainlsHandler(copyList);
    if (this._isMounted) {
      this.setState(
        {
          selectedTermianl: res.termianl,
          loading: false,
          plan: res.plan,
          intervalLoading: false,
          coordinates: res.coordinates,
          telemetry: res.telemetry,
          networkIm: res.networkIn,
          networkUdp: res.networkUdp,
          termianls: copyList,
        },
        () => {
          this.timer = setTimeout(() => {
            this.createHttpRequsetHandler();
          }, 5000);
        }
      );
    }
  };
  conveortDataHandler = (str) => {
    return str.length > 15 ? str.substring(0, 15) + "..." : str;
  };
  closeInfoDashboardMarker = () => {
    this.clearTimerHandler();
    this.props.contralIntervalHttpCalles(START);
    this.props.closeViewMarker();
  };
  onClickTelemetryHandler = () => {
    if (!this.state.selectedTermianl.length) return;
    this.clearTimerHandler();
    this.props.redirectToTelemetryPage({ id: this.id, hiskyId: this.hiskyId });
  };
  onClickTerminalHandler = (item, index) => {
    this.clearTimerHandler();
    this.id = item.id;
    this.hiskyId = item.hiskyId;
    this._noGpsTrace = false;
    let deltaMinsCopy = { ...this.state.deltaMins };
    deltaMinsCopy.value = 0;
    this.setState(
      {
        loading: true,
        intervalLoading: true,
        terminalId: item.id,
        deltaMins: deltaMinsCopy,
      },
      () => {
        this.selectedIndex = index;
        this.createHttpRequsetHandler();
      }
    );
  };
  getColorOfTerminalHandler = (item) => {
    if (item.isOnline) {
      return TERMINAL_ONLINE_COLOR;
    } else {
      return "red";
    }
  };
  onSliderChange = (value) => {
    if (this.state.loading) {
      return;
    }
    let deltaMinsCopy = { ...this.state.deltaMins };
    deltaMinsCopy.value = value;
    this.setState({ deltaMins: deltaMinsCopy }, () => {});
  };
  onAfterChange = (value) => {
    let deltaMinsCopy = { ...this.state.deltaMins };
    deltaMinsCopy.value = value;
    this.setState({ deltaMins: deltaMinsCopy });
  };
  cancelPolylineHandler = () => {
    //this._checkedGpsTRace = false;
    clearTimeout(this.timer);
    this.props.closeViewMarker();
    this.props.cancelPolyline(this.state.terminalId); //canceling
  };
  onClickApplyHandler = () => {
    //this._checkedGpsTRace = true;
    this._noGpsTrace = false;
    clearTimeout(this.timer);
    this.timer = null;

    //api call to get terminalgps new query
    this.setState({ loading: true }, () => {
      http(getTerminalGpsTraceCount, {
        delta_mins: this.state.deltaMins.value,
        terminal_id: this.state.terminalId,
      })
        .then((res) => {
          if (res.errors == undefined && res.data.terminalGpsTraceInt > 0) {
            this.props.closeViewMarker();
            this.props.resetPolyLineCenter();
            this.props.drawPolyline(
              this.state.terminalId,
              this.state.deltaMins.value
            );
            //this._checkedGpsTRace = false;
          } else {
            this.setState({ intervalLoading: true, loading: false }, () => {
              this._noGpsTrace = true;
              this.props.boundToMarker(this.state.terminalId);
              this.createHttpRequsetHandler();
            });
          }
        })
        .catch((err) => {});
    });
  };
  onClickClearHandler = () => {};
  onChangeDeltaMinHandler = (event) => {
    let copyDeltaMins = { ...this.state.deltaMins };
    copyDeltaMins.value = event.target.value;
    copyDeltaMins.error = false;
    this.setState({ deltaMins: copyDeltaMins });
  };
  getHubInformation = (type) => {
    let keys = {
      details: [
        "macId",
        "deviceName",
        "description",
        "firmware",
        "hardware",
        "createdAt",
        "locationName",
        "isActive",
        "lat",
        "long",
      ],
      beamInfo: [
        "name",
        "externalId",
        "provider",
        "txFreq",
        "rxFreq",
        "polTx",
        "polRx",
        "modulationTx",
        "modulationRx",
        "spreadFactorRx",
        "spreadFactorTx",
        "preIntervalRx",
        "preIntervalTx",
        "symbolRateRx",
        "symbolRateTx",
        "txFreqPol",
        "additionalBeam",
        "createdAt",
        "updatedAt",
        "satLongitude",
      ],
      network: [
        "ip",
        "controlPortAddress",
        "controlPortGw",
        "controlPortMask",
        "dataPortGw",
        "dataPortMask",
      ],
    };
    if (this.state.hubDetails == null) return;
    let hubDetails = { ...this.state.hubDetails };
    if (type == "beamInfo") {
      hubDetails =
        this.state.hubDetails.beam == null ? {} : this.state.hubDetails.beam;
    }
    if (type == "network") {
      hubDetails = this.state.hubDetails.hbsattrSet[0];
    }
    return keys[type].map((key) => {
      let label = key.replace(/([A-Z])/g, " $1").trim();
      let value =
        hubDetails[key] != undefined
          ? type == "beamInfo"
            ? dataTypeBeam(key, hubDetails[key])
            : hubDetails[key]
          : "";
      if (key == "macId") {
        label = HISKY_ID;
      }
      if (key == "name") {
        label = "Beam name";
      }
      if (key == "isActive") {
        label = "Enable";
        value = hubDetails[key] ? "YES" : "NO";
      }
      if (key == "createdAt" && type !== "beamInfo") {
        label = "Created Date";
        value =
          hubDetails[key] != "" ? moment(hubDetails[key]).format("LLL") : "N/A";
      }
      if (key == "locationName") {
        label = LOCATION_NAME;
      }
      if (key == "lat") {
        label = LATTITUDE;
      }
      if (key == "long") {
        label = LONGITUDE;
      }
      if (key == "additionalBeam") {
        label = KML_CREATED;
        value = hubDetails[key] ? "YES" : "NO";
      }
      if (key == "createdAt" && type == "beamInfo") {
        label = KML_DATE;
      }
      return (
        <KeyValue
          key={key}
          item={{
            label: label,
            value: value !== null ? value.toString() : "N/A",
          }}
          index={1}
          wrapperStyles={styles.item}
        />
      );
    });
  };
  getTerminalsInfoHandler = () => {
    let getTransmittingTerminals = this.props.getTransmittingTerminals();
    return (
      <>
        {" "}
        <div className={styles.topData}>
          <div className={styles.titleWrapper}>
            <div className={styles.titleInnerWrapper}>
              <p className={styles.title}>Terminal Details</p>
              {this._noGpsTrace && (
                <p className={styles.noGpsErr}>No GPS coordinates available</p>
              )}
            </div>
            <div className={styles.breakLine}></div>
          </div>
          {this.state.selectedTermianl.map((item, index) => {
            return (
              <KeyValue
                item={item}
                key={index}
                labelStyles={
                  item.label === "Status"
                    ? item.value === ONLINE
                      ? { color: "green" }
                      : { color: "red" }
                    : ""
                }
                index={index}
                wrapperStyles={styles.item}
              />
            );
          })}
        </div>
        <div className={styles.sectionData}>
          <div className={styles.titleWrapper}>
            <p className={styles.title}>{UPD_NETWORK_CONFIG}</p>
            <div className={styles.breakLine}></div>
          </div>
          {this.state.networkUdp.map((item, index) => {
            return (
              <KeyValue
                item={item}
                key={index}
                index={index}
                wrapperStyles={styles.itemTelemetry}
              />
            );
          })}
        </div>
        <div className={styles.sectionData}>
          <div className={styles.titleWrapper}>
            <p className={styles.title}>{IM_NETWORK_CONFIG}</p>
            <div className={styles.breakLine}></div>
          </div>
          {this.state.networkIm.map((item, index) => {
            return (
              <KeyValue
                item={item}
                key={index}
                index={index}
                wrapperStyles={styles.itemTelemetry}
              />
            );
          })}
        </div>
        <div className={styles.sectionData}>
          <div className={styles.titleWrapper}>
            <p className={styles.title}>Last Activity</p>
            <div className={styles.breakLine}></div>
          </div>
          {this.state.telemetry.map((item, index) => {
            return (
              <KeyValue
                item={item}
                key={index}
                index={index}
                wrapperStyles={styles.itemTelemetry}
              />
            );
          })}
        </div>
        <div className={styles.sectionData}>
          <div className={styles.titleWrapper}>
            <p className={styles.title}>Trail Timeline (Minutes)</p>
            <div className={styles.breakLine}></div>
          </div>
          <div className={styles.timerWrapper}>
            <div className={styles.silderWrapper}>
              <Slider
                onAfterChange={this.onAfterChange}
                onChange={this.onSliderChange}
                min={0}
                tipProps={{ visible: true }}
                max={180}
                defaultValue={0}
                handle={handle}
                value={this.state.deltaMins.value}
                trackStyle={[{ backgroundColor: "#00bdd6" }]}
              />
            </div>
            <div className={styles.inputsWrapper}>
              <input
                className={styles.inputTime}
                //disabled={true}
                value={this.state.deltaMins.value}
                onChange={this.onChangeDeltaMinHandler}
              />
              <button
                className={styles.btnApply}
                onClick={this.onClickApplyHandler}
                disabled={this.state.loading}
              >
                Apply
              </button>
              {getTransmittingTerminals[this.state.terminalId] != undefined && (
                <button
                  className={styles.btnApply}
                  onClick={this.cancelPolylineHandler}
                  disabled={this.state.loading}
                >
                  Clear
                </button>
              )}
            </div>
          </div>
        </div>
        <div className={styles.sectionData}>
          <div className={styles.titleWrapper}>
            <p className={styles.title}>
              Plan Details{" "}
              {Object(this.state.plan).length == 1 ? (
                <span className={styles.subTitle}>- No Plan Attached</span>
              ) : (
                ""
              )}
            </p>
            <div className={styles.breakLine}></div>
          </div>
          {this.state.plan.map((item, index) => {
            return (
              <KeyValue
                key={index}
                item={item}
                index={index}
                wrapperStyles={styles.item}
              />
            );
          })}
        </div>
      </>
    );
  };
  getHubDetailsHandler = () => {
    if (Object.keys(this.state.hubDetails).length == 0) {
      return (
        <div className={styles.topData}>
          <p className={styles.title}>No Hub Assigned</p>
        </div>
      );
    } else {
      return (
        <div className={styles.topData}>
          <div className={styles.titleWrapper}>
            <p className={styles.title}>Hub Details</p>
            <div className={styles.breakLine}></div>
          </div>
          {this.getHubInformation("details")}
          <div className={styles.titleWrapper}>
            <p className={styles.title}>Beam Details</p>
            <div className={styles.breakLine}></div>
          </div>
          {this.getHubInformation("beamInfo")}
          <div className={styles.titleWrapper}>
            <p className={styles.title}>Network configuration</p>
            <div className={styles.breakLine}></div>
          </div>
          {this.getHubInformation("network")}
        </div>
      );
    }
  };
  panToHubHandler = () => {
    if (Object.keys(this.state.hubDetails).length == 0) return;
    this.clearTimerHandler();
    this.props.contralIntervalHttpCalles(START);
    this.props.panToHub(this.state.hubDetails);
  };
  render() {
    return (
      <div className={styles.DashboardMarkers}>
        <div className={styles.info}>
          <h3 className={styles.header}>
            <span className={styles.headerIcon}>
              <Icon defination={{ prefix: "far", iconName: "tablet-rugged" }} />
            </span>
            <span>
              {this.props.showHubDetails ? "Hub info" : "Terminals info"}
              <span
                className={styles.spinnerIcon}
                style={{
                  visibility: !this.state.intervalLoading
                    ? "hidden"
                    : "visible",
                }}
              >
                <Icon
                  isSpinner={true}
                  defination={{ prefix: "fal", iconName: "spinner" }}
                />
              </span>
              <span
                style={{
                  display: !this.props.showHubDetails ? "inline-block" : "none",
                  fontSize: "1.3rem",
                }}
              >
                <span>
                  <span
                    className={styles.statusIcon}
                    style={{
                      color: TERMINAL_ONLINE_COLOR,
                    }}
                  >
                    <Icon defination={{ prefix: "fas", iconName: "circle" }} />
                  </span>
                  {ONLINE}&nbsp;{this.onlineCount}
                </span>
                <span>
                  <span
                    className={styles.statusIcon}
                    style={{
                      color: "red",
                    }}
                  >
                    <Icon defination={{ prefix: "fas", iconName: "circle" }} />
                  </span>
                  {OFFLINE}&nbsp;{this.offlineCount}
                </span>
              </span>
            </span>

            <div className={styles.iconCloseWrapper}>
              {this.props.showHubDetails ? (
                <>
                  {getEntityName() === HISKY_ENTITY && (
                    <span
                      className={
                        Object.keys(this.state.hubDetails).length == 0
                          ? `${styles.iconClose} ${styles.disabled}`
                          : styles.iconClose
                      }
                      style={{ color: "#00bdd6" }}
                      onClick={this.panToHubHandler}
                    >
                      <span className={styles.toolTip}>
                        {Object.keys(this.state.hubDetails).length == 0
                          ? "No Hub Assigned"
                          : "Pan to hub"}
                      </span>
                      <Icon
                        defination={{
                          prefix: "fal",
                          iconName: "tablet-rugged",
                        }}
                      />
                    </span>
                  )}
                </>
              ) : (
                ((getEntityName() === RESELLER_ENTITY &&
                  Controller.getAuthComponent("nms", "TERMINAL_DETAIL")) ||
                  getEntityName() === HISKY_ENTITY) && (
                  <span
                    style={{
                      cursor: this.state.selectedTermianl.length
                        ? "pointer"
                        : "not-allowed",
                      color: "#00bdd6",
                    }}
                    className={styles.iconClose}
                    onClick={this.onClickTelemetryHandler}
                  >
                    <span className={styles.toolTip}>Terminal page</span>
                    <Icon
                      defination={{ prefix: "far", iconName: "tablet-rugged" }}
                    />
                  </span>
                )
              )}
              <span
                className={styles.iconClose}
                style={{ display: this.state.loading ? "none" : "block" }}
                onClick={this.closeInfoDashboardMarker}
              >
                <Icon defination={{ prefix: "fal", iconName: "times" }} />
              </span>
            </div>
          </h3>
          <div className={styles.breakLine}></div>
          <div
            className={styles.data}
            style={{ opacity: this.state.loading ? 0.6 : 1 }}
          >
            <div className={styles.leftSide}>
              <ul className={styles.listWrapper}>
                {this.state.termianls.map((item, index) => {
                  return (
                    <li
                      key={index}
                      className={
                        parseInt(item.id) === parseInt(this.state.terminalId)
                          ? `${styles.itemTeraminl} ${styles.selectedTermianl}`
                          : styles.itemTeraminl
                      }
                      onClick={() => this.onClickTerminalHandler(item, index)}
                    >
                      <span className={styles.toolTip}>{item.title}</span>
                      <span>
                        <span
                          className={styles.statusIcon}
                          style={{
                            color: this.getColorOfTerminalHandler(item),
                          }}
                        >
                          {" "}
                          <Icon
                            defination={{ prefix: "fas", iconName: "circle" }}
                          />
                        </span>
                        {item.hiskyId}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className={styles.rightSide}>
              {!this.state.loading ? (
                <>
                  {this.props.showHubDetails
                    ? this.getHubDetailsHandler()
                    : this.getTerminalsInfoHandler()}
                </>
              ) : (
                <div className={styles.loadingWrapper}>
                  <div className={styles.spinnerWrapper}>
                    <SpinnerLoader />
                  </div>
                  <div>
                    <img alt="hisky-logo" src={Logo} className={styles.logo} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DashboardMarker;
