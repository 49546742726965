import React, { Component } from "react";
import styles from "./GoogleMapWrapper.module.scss";
import { http } from "../../../RequestManager/Http";
import {
  getTerminalStats,
  getTerminalStatsHisky,
  getDashboardSites,
  getBeamsAndHubs,
} from "../../../RequestManager/QueriesAndMutations";
import * as actionType from "../../../Store/actions/index";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Google from "../Google";
import moment from "moment";
import {
  TERMINAL,
  SITE,
  HISKY_ENTITY,
  STOP,
  OPEN,
  INSERT,
  UPDATE_SITES_DATA,
  HIDE,
  START,
} from "../../../Util/constTypes";
import TerminalsInfo from "../TerminalsInfo/TerminalsInfo";
import SpinnerLoader from "../../SpinnerLoader/SpinnerLoader";
import { checkVersionApp } from "../../../Util/globalFunc";
import { getEntityName } from "../../../Store/localStoreageManager/localStoreageManager";

class GoogleMapWrapper extends Component {
  _isMounted = true;
  _hub_marker = [];
  _mapSpinner = null;
  dashSitesinterval = null;
  _refHubFiltersControl = null;
  _refGoogleActions = null;
  online = true;
  siteOffline = true;
  siteOnline = true;
  changeModeFilterRef = null;
  updateSitesListRef = null;
  _disabled_site_btn_ref = null;
  offline = true;
  // mockups = true;
  hubs = true;
  unix_time = null;
  device_id = null;
  //delta_mins = null;
  dummy = false;
  _org_siteMarkers = [];
  _orginalTerminalsCount = {};
  _removedSitesList = {};
  _sitesList = [];
  _transmittingTerminals = {};
  _org_sites_Render = [];
  constructor(props) {
    super(props);
    this.state = {
      markers: [],
      siteRender: [],
      offlineArr: [],
      onlineArr: [],
      online: 0,
      offline: 0,
      loading: false,
      polyline: [],
      mockupMarkers: [],
      polygon: [],
      hubMarkers: [],
      counter: true,
      showmockups: false,
      isSiteMode: false,
      terminalMarkers: [],
      totalHbs: 0,
      isFullScreen: false,
      // searchHiskyId: "",
      types: {
        online: true,
        offline: true,
        // mockups: true,
        hubs: true,
      },
      excludedHubs: [],
      hubFilters: [],
      viewPolygonCord: { lat: 32.0853, lng: 34.7818 },
      isHubFiltersOpen: false,
    };
    this.timer = null;
  }
  /**
   * Life cycle actions
   */

  componentWillReceiveProps(nextProps) {
    if (nextProps.loading !== this.props.loading) {
      this.intializeDataHandler(nextProps);
    }
  }
  componentWillUnmount() {
    clearTimeout(this.timer);
    clearTimeout(this.dashSitesinterval);
    this.timer = null;
    this.dashSitesinterval = null;
    this._isMounted = false;
  }
  /**
   * ref functions
   */
  updateSitesListRefHandler = (func) => {
    this.updateSitesListRef = func;
  };
  updateFilterModeHandler = (funcOfContral) => {
    this.changeModeFilterRef = funcOfContral;
  };
  callBackSpinnerHandler = (ref) => {
    this._mapSpinner = ref;
  };
  /**
   * Global actions
   */

  getTransmittingTerminals = () => {
    return this._transmittingTerminals;
  };
  mockupsManager = (data) => {
    this.setState({ showmockups: data });
  };
  checkForFullscreenHandler = () => {
    return false;
  };
  isFullScreenHandler = (mode) => {
    this.setState({ isFullScreen: mode });
  };
  onSetDataHandler = (types) => {
    this.online = types["online"];
    this.offline = types["offline"];
    // this.mockups = types["mockups"];
    this.hubs = types["hubs"];
    let temp = [];
    let copyMarkerHubs = [];
    if (this.online) {
      temp = [...temp, ...this.state.onlineArr];
    }
    if (this.offline) {
      temp = [...temp, ...this.state.offlineArr];
    }

    if (this.hubs) {
      copyMarkerHubs = this.state.hubFilters.length
        ? JSON.parse(JSON.stringify(this.state.hubFilters))
        : JSON.parse(JSON.stringify(this._hub_marker));
    }
    this.setState(
      { markers: temp, types: { ...types }, hubFilters: copyMarkerHubs },
      () => {}
    );
  };
  /**
   *Intial actions
   */
  intializeDataHandler = (props) => {
    this.setState(
      {
        markers: [...props.terminalMarkers],
        terminalMarkers: props.terminalMarkers,
        onlineArr: props.onlineArr,
        offlineArr: props.offlineArr,
        online: props.online,
        offline: props.offline,
        hubMarkers: props.hubMarkers != undefined ? props.hubMarkers : [],
        hubFilters: props.hubFilters !== undefined ? props.hubFilters : [],
        totalHbs: props.totalHbs != undefined ? props.totalHbs : 0,
      },
      () => {
        this.getTerminalsHandler();
      }
    );
  };
  getTerminalsHandler = (isSpinner = null) => {
    let queryType = getTerminalStats;
    let params = {
      dummy: this.dummy,
      // search: this.state.searchHiskyId,
      start_from: this.unix_time,
      terminal_id: this.device_id,
      delta_mins:
        this._transmittingTerminals[this.device_id] != undefined
          ? this._transmittingTerminals[this.device_id]
          : null,
    };
    if (getEntityName() == HISKY_ENTITY && this.state.isHubFiltersOpen) {
      queryType = getTerminalStatsHisky;
      params.exclude = this.state.excludedHubs;
      params.activeOnly = true; //get only active hubs
    }
    http(queryType, params)
      .then((res) => {
        if (!checkVersionApp(res.data.systemVersion)) {
          this.props.openRefreshCacheSelector();
        }
        if (this.dashSitesinterval == null) {
          if (isSpinner == null) {
            this.setDataHandler(res);
          } else {
            this._mapSpinner(() => {
              this.setDataHandler(res);
            });
          }
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };
  mapSpinnerHandler = (callback, err) => {
    this._mapSpinner(callback, err);
  };
  setDataHandler = (res) => {
    if (this._isMounted) {
      let offlineCounts =
        res.data.accountTerminalTypesCounts.offlineCounts != null
          ? res.data.accountTerminalTypesCounts.offlineCounts
          : null;
      let onlineCounts =
        res.data.accountTerminalTypesCounts.onlineCounts != null
          ? res.data.accountTerminalTypesCounts.onlineCounts
          : null;
      this.props.checkCountTerminal(
        (offlineCounts != null
          ? offlineCounts.fixedLrCount + offlineCounts.fixedRlCount
          : 0) +
          (onlineCounts != null
            ? onlineCounts.fixedLrCount + onlineCounts.fixedRlCount
            : 0),
        (offlineCounts != null ? offlineCounts.dynamicCount : 0) +
          (onlineCounts != null ? onlineCounts.dynamicCount : 0)
      );
      if (Object.keys(this._transmittingTerminals).length) {
        this.refUpdatePolyLineId(this.device_id);
      }
      this.setState(
        {
          polyline:
            res.data.terminalGpsTrace !== null ? res.data.terminalGpsTrace : [],
          loading: false,
          online:
            res.data.onlineTerminals !== null
              ? res.data.onlineTerminals.length
              : 0,
          offline:
            res.data.offlineTerminals !== null
              ? res.data.offlineTerminals.length
              : 0,
          markers: this.setFilteredDataHandler(
            res.data.onlineTerminals !== null ? res.data.onlineTerminals : [],
            res.data.offlineTerminals
          ),
          onlineArr: res.data.onlineTerminals,
          offlineArr: res.data.offlineTerminals,
          counter: !this.state.counter,
          totalHbs:
            res.data.hbs != undefined
              ? res.data.hbs.length
              : this.state.hubFilters.length,
          hubFilters:
            res.data.hbs != undefined ? res.data.hbs : this.state.hubFilters,
        },
        () => {
          if (this._isMounted && !this.state.isSiteMode) {
            if (this._refHubFiltersControl != null)
              this._refHubFiltersControl(this.state.hubFilters);
            this.timer = setTimeout(() => {
              this.getTerminalsHandler();
            }, 10000);
          }
        }
      );
    }
  };
  setDataToIntitalStateHandler = () => {
    let types = { ...this.state.types };
    this.online = types["online"];
    this.offline = types["offline"];

    this.hubs = types["hubs"];
    let temp = [];
    if (this.online) {
      temp = [...temp, ...this.state.onlineArr];
    }
    if (this.offline) {
      temp = [...temp, ...this.state.offlineArr];
    }

    if (this._isMounted) {
      this.setState({ markers: temp, loading: false }, () => {
        // this.getTerminalsHandler();
      });
    }
  };
  /**
   * Http on and off actions
   */
  contralIntervalHttpCallesHandler = (type, isSpinner = null) => {
    if (type === STOP) {
      clearTimeout(this.timer);
      this.timer = null;
      clearTimeout(this.dashSitesinterval);
      this.dashSitesinterval = null;
    } else {
      if (this.state.isSiteMode) {
        clearTimeout(this.dashSitesinterval);
        this.dashSitesinterval = null;
        clearTimeout(this.timer);
        this.timer = null;
        this.getDashboardSites(isSpinner);
      } else {
        clearTimeout(this.dashSitesinterval);
        this.dashSitesinterval = null;
        clearTimeout(this.timer);
        this.timer = null;
        this.getTerminalsHandler(isSpinner);
      }
    }
  };

  siteLoadSetDisabledHanlder = (ref) => {
    this._disabled_site_btn_ref = ref;
  };
  /**
   * Site mode actions
   */
  showSitesHandler = () => {
    //clicked on call api, stop intervalsadads

    if (this._isMounted) {
      this.changeModeFilterRef(!this.state.isSiteMode);
      this.setState(
        { isSiteMode: !this.state.isSiteMode, loading: true },
        () => {
          if (this.state.isSiteMode) {
            clearTimeout(this.dashSitesinterval);
            this.dashSitesinterval = null;
            clearTimeout(this.timer);
            this.timer = null;
            //call http of sites list
            this.updateSitesListRef(OPEN, []);
            this.getDashboardSites();
          } else {
            clearTimeout(this.dashSitesinterval);
            this.dashSitesinterval = null;
            clearTimeout(this.timer);
            this.timer = null;
            this._disabled_site_btn_ref();
            this.resetSiteGlobleHandler();
            this.updateSitesListRef(HIDE, []);
            // callback(this.state.isSiteMode, []);
            this.cancelPoliylineDataHandler();
            this.setDataToIntitalStateHandler();
          }
        }
      );
    }
  };
  getDashboardSites = (isSpinner = null) => {
    ///set flag after the http make one req

    http(getDashboardSites, {
      dummy: this.dummy,
      start_from: this.unix_time,
      terminal_id: this.device_id,
      delta_mins:
        this._transmittingTerminals[this.device_id] != undefined
          ? this._transmittingTerminals[this.device_id]
          : null,
    })
      .then((res) => {
        if (this.timer == null && this._isMounted) {
          if (isSpinner !== null) {
            this._mapSpinner(() => {
              // this._disabled_site_btn_ref()
              this.parseForMarkersSiteHandler(res.data);
            });
          } else {
            this.parseForMarkersSiteHandler(res.data);
          }
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };
  // setSearchHiskyIdHandler = (hiskyId) => {
  //   this.contralIntervalHttpCallesHandler(STOP);
  //   this.setState(
  //     {
  //       online: 0,
  //       offline: 0,
  //       searchHiskyId: hiskyId,
  //       onlineArr: [],
  //       offlineArr: [],
  //       mockupMarkers: [],
  //     },
  //     () => {
  //       this._mapSpinner(() => {
  //         this.contralIntervalHttpCallesHandler(START, true);
  //       });
  //     }
  //   );
  // };
  parseForMarkersSiteHandler = (data) => {
    let arr = data.dashSites;
    let removedTerminals = [];
    /////chandge
    let markers = [];
    let site_list = [];
    let site_render = [];
    let onlineCount = 0;
    let offlineCount = 0;
    for (let i = 0; i < arr.length; i++) {
      let siteOnlineTerminalCount = 0;
      let siteOfflineTerminalCount = 0;
      for (let j = 0; j < arr[i].devices.length; j++) {
        let obj = arr[i].devices[j];
        if (
          this._removedSitesList[arr[i].site.id] == undefined &&
          ((this.siteOnline && obj.isOnline) ||
            (!obj.isOnline && this.siteOffline))
        ) {
          markers.push({
            id: obj.id,
            lng:
              obj.lastTelemetry == null ? obj.long : obj.lastTelemetry.lastLong,
            lat:
              obj.lastTelemetry == null ? obj.lat : obj.lastTelemetry.lastLat,
            type: TERMINAL,
            title: obj.deviceName,
            hiskyId: obj.hiskyId,
            terminalType: obj.terminalType,
            online: obj.isOnline,
            isOnline: obj.isOnline,
            name: obj.deviceName,
            deviceName: obj.deviceName,
            siteName: arr[i].site.name,
            terminal: obj,
            siteId: arr[i].site.id,
            lastActivityDate:
              obj.onlineTerminal === null
                ? ""
                : obj.onlineTerminal.lastActivityDate,
          });
          if (obj.isOnline) {
            onlineCount++;
          } else {
            offlineCount++;
          }
        } else {
          removedTerminals.push(parseInt(obj.id));
        }
        if (obj.isOnline) {
          //onlineCount++;
          siteOnlineTerminalCount++;
        } else {
          //offlineCount++;
          siteOfflineTerminalCount++;
        }
      }

      site_list.push({
        id: SITE + arr[i].site.id,
        lng: arr[i].site.lng,
        lat: arr[i].site.lat,
        type: SITE,
        siteId: arr[i].site.id,
        name: arr[i].site.name,
        title: arr[i].site.name,
        country: arr[i].site.legalAddress.country,
        siteTerminalCount: {
          online: siteOnlineTerminalCount,
          offline: siteOfflineTerminalCount,
        },
        checked:
          this._removedSitesList[arr[i].site.id] == undefined ? true : false,
      });
      //site marker
      if (this._removedSitesList[arr[i].site.id] == undefined) {
        //TODO
        site_render.push({
          id: SITE + arr[i].site.id,
          lng: arr[i].site.lng,
          lat: arr[i].site.lat,
          type: SITE,
          name: arr[i].site.name,
          title: arr[i].site.name + "-" + arr[i].site.legalAddress.country,
          siteId: arr[i].site.id,
          siteTerminalCount: arr[i].devices.length,
        });
      }
    }
    //update orignal markers only on first load and if there are more markers(like new site or devices are added)
    if (
      this._org_siteMarkers.length == 0 ||
      markers.length > this._org_siteMarkers.length
    ) {
      this._org_siteMarkers = JSON.parse(JSON.stringify(markers));
      this._orginalTerminalsCount = {
        online: onlineCount,
        offline: offlineCount,
      };
    }
    if (
      this._org_sites_Render.length == 0 ||
      arr.length != this._org_sites_Render.length
    ) {
      this._org_sites_Render = site_render;
    }

    if (this._isMounted) {
      if (Object.keys(this._transmittingTerminals).length) {
        this.refUpdatePolyLineId(this.device_id);
      }
      this.setState(
        {
          markers: markers,
          loading: false,
          siteRender: site_render,
          polyline: data.terminalGpsTrace !== null ? data.terminalGpsTrace : [],
        },
        () => {
          this._sitesList = site_list;
          this.updateSitesListRef(INSERT, {
            list: site_list,
            terminalsCount: { online: onlineCount, offline: offlineCount },
          });
          this._disabled_site_btn_ref();
          if (this._isMounted) {
            this.dashSitesinterval = setTimeout(() => {
              this.getDashboardSites();
            }, 10000);
          }
        }
      );
    }
  };
  filterSitesAndDevices = (sitesRemoved) => {
    let markers = JSON.parse(JSON.stringify(this._org_siteMarkers));
    let sitesList = this._sitesList;
    this._removedSitesList = sitesRemoved;
    let onlineCount = 0;
    let offlineCount = 0;
    let newMarkers = [];
    let newsites = [];
    let removedTerminals = [];
    markers.map((item) => {
      if (sitesRemoved[item.siteId] == undefined) {
        if (item.online) onlineCount++;
        else if (item.online != undefined) offlineCount++;
        newMarkers.push(item);
      } else {
        removedTerminals.push(parseInt(item.id));
      }
    });
    this._org_sites_Render.map((item) => {
      if (sitesRemoved[item.siteId] == undefined) {
        newsites.push(item);
      }
    });
    let newList = sitesList.map((item) => {
      item.checked = sitesRemoved[item.siteId] == undefined ? true : false;
      return item;
    });
    if (this._isMounted) {
      this.setState(
        { markers: newMarkers, loading: false, siteRender: newsites },
        () => {
          if (removedTerminals.indexOf(this.device_id) > -1) {
            this.updateTrannsmittingTerminals();
            //clear interval and call sites api immediately
            clearTimeout(this.dashSitesinterval);
            this.dashSitesinterval = null;
            this.getDashboardSites();
          }

          this.updateSitesListRef(UPDATE_SITES_DATA, {
            list: newList,
            terminalsCount: {
              online: onlineCount,
              offline: offlineCount,
              siteRender: newsites,
            },
          });
        }
      );
    }
  };
  resetSitesAndDevices = (callback) => {
    let markers = JSON.parse(JSON.stringify(this._org_siteMarkers));
    this.siteOnline = true;
    this.siteOffline = true;
    this._removedSitesList = {};
    if (this._isMounted) {
      this.setState({ markers: markers, loading: false }, () => {
        callback({
          terminalsCount: this._orginalTerminalsCount,
        });
      });
    }
  };
  resetSiteGlobleHandler = () => {
    this._org_siteMarkers = [];
    this._orginalTerminalsCount = {};
    this._removedSitesList = {};
    this._sitesList = [];
    this.siteOffline = true;
    this.siteOnline = true;
  };
  changeModeSiteonlineHanlder = (type, mode) => {
    if (type === "online") {
      this.siteOnline = mode;
    } else {
      this.siteOffline = mode;
    }

    clearTimeout(this.dashSitesinterval);
    this.dashSitesinterval = null;
    if (this._isMounted) {
      this._mapSpinner(() => {
        this.getDashboardSites("mapSpinner");
      });
    }
  };
  /**
   * Polyline actions
   */
  setPoliylineDataHandler = (id, time) => {
    this.device_id = parseInt(id);
    this.unix_time = moment().format("YYYY/MM/DD HH:mm:ss");
    this.dummy = false;
    let transmittingTerminalsCopy = { ...this._transmittingTerminals };
    transmittingTerminalsCopy[id] = time;
    this._transmittingTerminals = transmittingTerminalsCopy;
    if (!this.state.isSiteMode) {
      clearTimeout(this.timer);
      this.timer = null;
      this._mapSpinner(() => {
        this.getTerminalsHandler("isSpinner");
      });
    } else {
      clearTimeout(this.dashSitesinterval);
      this.timer = null;
      this._mapSpinner(() => {
        this.getDashboardSites("isSpinner");
      });
    }
  };
  cancelPoliylineDataHandler = (terminalId = null) => {
    this.updateTrannsmittingTerminals(terminalId);
    this.unix_time = null;
    this.device_id = null;
    this.dummy = false;
    this.setState({ polyline: [] }, () => {
      //check the if any dashboard markers are open : TODO
      if (document.getElementById("dashboradMarker") == null) {
        clearTimeout(this.timer);
        this.timer = null;
        this.getTerminalsHandler();
      }
    });
  };
  cancelPoliyLineOnSitesHandler = () => {
    this.updateTrannsmittingTerminals();
    this.unix_time = null;
    this.device_id = null;
    this.dummy = false;
    this.setState({ polyline: [] });
  };
  updateTrannsmittingTerminals = (terminalId) => {
    let transmittingTerminalsCopy = { ...this._transmittingTerminals };
    if (terminalId == null) {
      transmittingTerminalsCopy = {};
      this.device_id = null;
    } else {
      delete transmittingTerminalsCopy[terminalId];
    }
    this._transmittingTerminals = transmittingTerminalsCopy;
  };

  /**
   * Filter actions
   */
  setFilteredDataHandler = (online, offline) => {
    let temp = [];
    if (this.online) {
      temp = [...temp, ...online];
    }
    if (this.offline) {
      temp = [...temp, ...offline];
    }
    // if (this.mockups) {
    //   temp = [...temp, ...this.state.mockupMarkers];
    // }

    return temp;
  };
  sendCountToFullScreenhandler = (callback) => {
    callback({
      onlineCount: this.state.online,
      offlineCount: this.state.offline,
      // mockupsCount: this.state.mockupMarkers.length,
      hubsCount: this.state.hubMarkers.length,
    });
  };

  /**
   * Hub filter actions
   */
  loadHubsHandler = () => {
    let typesCopy = { ...this.state.types };
    typesCopy.hubs = true;
    let copyMarkerHubs = this.props.hubFilters.length
      ? JSON.parse(JSON.stringify(this.props.hubFilters))
      : JSON.parse(JSON.stringify(this._hub_marker));
    this.setState(
      {
        isHubFiltersOpen: !this.state.isHubFiltersOpen,
        types: typesCopy,
        hubFilters: copyMarkerHubs,
        excludedHubs: [],
      },
      () => {
        if (this.state.isHubFiltersOpen) {
          this.contralIntervalHttpCallesHandler(START);
          this.hubs = true;
          this._refHubFiltersControl(this.state.hubFilters);
        }
      }
    );
  };
  refHubFiltersControlsHandler = (ref) => {
    this._refHubFiltersControl = ref;
    if (this.state.isHubFiltersOpen) {
      this.loadHubsHandler();
    } else {
      //api call stop and call combined query of beams and hubs
      this.contralIntervalHttpCallesHandler(STOP);
      this.props.loadHubAndBeams(this.loadHubsHandler);
    }
  };
  refUpdatePolyLineIdHandler = (ref) => {
    this.refUpdatePolyLineId = ref;
  };
  excludedHubsHandler = (list) => {
    this.setState({ excludedHubs: list }, () => {
      clearTimeout(this.timer);
      this.timer = null;
      this._mapSpinner(() => {
        this.getTerminalsHandler("isSpinner");
      });
    });
  };
  setFilterHubMakrerHandler = () => {
    let typeCopy = { ...this.state.types };
    typeCopy.hubs = !this.state.types.hubs;
    if (!typeCopy.hubs) {
      this._hub_marker = JSON.parse(JSON.stringify(this.state.hubFilters));
      this.setState({ types: typeCopy, hubFilters: [] });
    } else {
      let copyMarkerHubs = JSON.parse(JSON.stringify(this._hub_marker));

      this._hub_marker = [];
      this.setState({ hubFilters: copyMarkerHubs, types: typeCopy }, () => {});
    }
  };

  render() {
    return (
      <>
        <Google
          setSearchHiskyId={this.setSearchHiskyIdHandler}
          sendCount={this.sendCountToFullScreenhandler}
          types={this.state.types}
          setPoliyLine={this.setPoliylineDataHandler}
          cancelPoliyLine={this.cancelPoliylineDataHandler}
          isFullScreen={this.isFullScreenHandler}
          updateSitesListRef={this.updateSitesListRefHandler}
          callBackSpinner={this.callBackSpinnerHandler}
          defaultZoom={11}
          defaultCenter={
            this.state.markers.length
              ? [this.state.markers[0].lat, this.state.markers[0].lng]
              : [32.0853, 34.7818]
          }
          polygon={this.state.loading ? [] : this.props.polygon}
          mockupMarkers={this.state.loading ? [] : this.state.mockupMarkers}
          markers={this.state.markers}
          btnOptions={{
            mockupBtn: true,
            recenterBtn: true,
            showPolygonBtn: true,
            fullscreenBtn: true,
          }}
          refToGoogleMap={this.updateFilterModeHandler}
          moreInfoClick={this.props.onClickWindowInfoHandler}
          headline={this.props.headline}
          maintainMockups={this.state.maintainMockups}
          mockupsManager={(data) => this.mockupsManager(data)}
          onSetDataHandler={this.onSetDataHandler}
          updateHubDataFilter={this.setFilterHubMakrerHandler}
          hubsCount={this.state.totalHbs}
          onlineCount={this.state.online}
          mockupsCount={this.state.mockupMarkers.length}
          offlineCount={this.state.offline}
          polyLine={this.state.polyline}
          siteRender={this.state.siteRender}
          changeModeSiteonline={this.changeModeSiteonlineHanlder}
          checkForLoading={this.props.checkForLoading}
          checkForPolygons={this.props.checkForPolygons}
          gotoUpdates={this.props.gotoUpdates}
          showSites={this.showSitesHandler}
          isSiteMode={this.state.isSiteMode}
          siteLoadSetDisabled={this.siteLoadSetDisabledHanlder}
          filterSitesAndDevices={this.filterSitesAndDevices}
          resetSitesAndDevices={this.resetSitesAndDevices}
          refHubFiltersControls={this.refHubFiltersControlsHandler}
          excludedHubs={this.excludedHubsHandler}
          hubFilters={this.state.hubFilters}
          viewPolygonCord={this.state.viewPolygonCord}
          hubMarkers={this.state.hubMarkers}
          contralIntervalHttpCalles={this.contralIntervalHttpCallesHandler}
          getTransmittingTerminals={this.getTransmittingTerminals}
          updateTrannsmittingTerminals={this.updateTrannsmittingTerminals}
          cancelPoliyLineOnSites={this.cancelPoliyLineOnSitesHandler}
          rightClickActive={true}
          refUpdatePolyLineId={this.refUpdatePolyLineIdHandler}
          excludedHubsList={this.state.excludedHubs}
          loadHubAndBeams={this.props.loadHubAndBeams}
          mapSpinner={this.mapSpinnerHandler}
        ></Google>
        {!this.state.isFullScreen && (
          <TerminalsInfo
            types={this.state.types}
            refToGoogleMap={this.updateFilterModeHandler}
            hubsCount={this.state.totalHbs}
            loading={this.props.loading}
            setData={this.onSetDataHandler}
            onlineCount={this.state.online}
            isSiteMode={this.state.isSiteMode}
            mockupsCount={this.state.mockupMarkers.length}
            offlineCount={this.state.offline}
            isFullScreen={this.state.isFullScreen}
            checkForFullscreen={this.checkForFullscreenHandler}
            updateHubDataFilter={this.setFilterHubMakrerHandler}
            isHubFiltersOpen={this.state.isHubFiltersOpen}
          />
        )}
        <div
          className={styles.spinnerWrapper}
          style={{ display: this.state.loading ? "block" : "none" }}
        >
          <SpinnerLoader />
        </div>
      </>
    );
  }
}
const mapStateProps = (state) => {
  return {};
};
const mapStateDispatch = (dispatch) => {
  return {
    reRenderComponentSelector: (data) =>
      dispatch(actionType.reRenderComponentSelector(data)),
    openRefreshCacheSelector: () =>
      dispatch(actionType.openRefreshCacheSelector()),
  };
};
export default withRouter(
  connect(mapStateProps, mapStateDispatch)(GoogleMapWrapper)
);
