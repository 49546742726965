import axios from "axios";
import Config from "../config";
import { uploadFile } from "./QueriesAndMutations";
import { getLocalStorageInfo } from "../Store/localStoreageManager/localStoreageManager";
import { CLOUDINARY_URL, FILE, PICTURE, CSV } from "../Util/constants";

export const requestApi = (data, type = null) => {
  let TOKEN = getLocalStorageInfo();
  if (type === null) {
    return axios
      .request({
        method: "post",
        data: data,
        url: Config.api.API_URL,
        headers: {
          Authorization: TOKEN ? `JWT ${TOKEN}` : null,
        },
        params: data.params !== undefined ? data.params : null,
      })
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  } else {
    if (type == PICTURE) {
      return axios
        .request({
          url: CLOUDINARY_URL,
          method: "post",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: data,
        })
        .then((res) => {
          return Promise.resolve(res);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    } else if (type == FILE || type == CSV) {
      let formData = new FormData();
      formData.append("operations", JSON.stringify(uploadFile(data.type)));
      formData.append("map", JSON.stringify({ File: ["variables.fileIn"] }));
      formData.append("File", data.value);
      return axios({
        url: Config.api.API_URL,
        method: "post",
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: TOKEN ? `JWT ${TOKEN}` : null,
        },
        data: formData,
      })
        .then((res) => {
          return Promise.resolve(res);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    }
  }
};
