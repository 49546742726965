//############ COMMON ############
export const INDEX = "#";
export const HISKY = "hiSky";
export const NMS = "NMS";
export const BSS = "BSS/OSS";
export const ADMINISTRATOR = "administrator";
export const HISKY_ADMIN_ROLE = "hiSky-admin";
//Accounts / AccountsTechnician / AccountSubscriber / Company / DeviceManagement / Extra / History
// HubsDevices / Logs >> UserLogs / PlansAssign / Sites / TemplatePlan / TerminalDevices / Updates / AccountSiteAndDevices
export const STATUS = "Status";
// AccountSubscriber / Company / Hubs >> AdvanceHubsData / Logs
export const ENTITY_NAME = "hiSky";
export const HOST_IP = "3.22.61.92";
// SideBar / TemplatePlan / Models > EditService
export const SELECT_PROMPT = "Select...";
// Accounts / Company / Logs
export const DATE_TIME = "Date";
export const DATE_AND_TIME = "Date/Time";
// AccountSubscriber / Hubs >> AdvanceHubsData / Logs
export const UNIT_ID = "Unit ID";
// AccountSubscriber / Hubs >> AdvanceHubsData / Logs
export const TITLE = "Title";
// AccountSubscriber / Hubs >> AdvanceHubsData / Logs
export const PRIORITY = "priority";
// AccountSubscriber / Hubs >> AdvanceHubsData / Logs
export const TYPE = "Type";
// Extra / HubsDevices / TerminalDevices
export const ID = "#";
// Hubs >> AdvanceHubsData / Logs
export const NAME_OF_FILE_LOGS = "System Logs";
// Hubs >> AdvanceHubsData / Logs
export const SYSTEM_LOGS = "systemLogs";
// Hubs >> AdvanceHubsData / Logs
export const USAGE_DATA = "usage";
// Accounts / Permissions >> PermissionDataHeader
export const ACCOUNT_PROFILE = "account profile";
// Accounts / Permissions >> PermissionDataHeader
export const PLAN_NAME = "Plan Name";
// History / PlanAssign / TemplatePlan / Models > PlanSubscriber > HistoryPlanSubsciber
export const PLAN_DURATION = "Plan Duration";
export const DURATION = "Duration";
// History / PlanAssign / TemplatePlan
export const ACTIVITY_DATE = "Create Date";
// History / PlanAssign / TemplatePlan
export const PO_ID = "PO ID";
// History / PlanAssign / TemplatePlan
export const SERVICE_NAME = "Service Name";
// History / PlanAssign / TemplatePlan
export const MONTHLY_COST = "Monthly Cost";
export const MONTHLY_USAGE = "monthly usage";
export const USAGE_LIMIT = "Usage Limit";
export const USAGE = "Usage";
export const USAGE_PRESENT = "Usage Present";
export const MONTHLY_USAGE_PRESENT = "monthly usage Percentage";
export const MONTHLY_BILLING = "billing month";
export const MONTHLY_USAGE_LIMIT = "monthly usage limit";
export const PLAN_USAGE = "Account Plans usage";
// History / PlanAssign / TemplatePlan
export const DISCONTINUED_AT = "Discontinued At";
// History / PlanAssign / TemplatePlan
export const COUNT = "Count";
export const CSV="CSV"
export const PDF="PDF"
// History / PlanAssign
export const ACCOUNT_NAME = "Account Name";
// History / PlanAssign
export const LOGS = "logs";
// Header >> SearchAutocomplete / SideBar
export const ACCOUNTS = "accounts";
export const PRODUCTION = "Production";
export const STATISTICS = "Statistics";
export const RESTART_LOG="Restart Log"
// Header >> SearchAutocomplete / SideBar
export const DEVICES = "devices";
// Header >> SearchAutocomplete / SideBar
export const TERMINALS = "terminals";
// Header >> SearchAutocomplete / SideBar
export const HBS = "HBS";
// Header >> SearchAutocomplete / SideBar
export const PHONE = "phone";
export const PHONE_NUMBER = "Phone number";
// Accounts / Sites
export const NAME = "Name";
export const HUB_PP = "simulator";
export const ACTIVATION_DATE = "Activation Date";
// HubsDevices / TerminalDevices / Updates
export const MAC = "MAC Address";
// HubsDevices / TerminalDevices
export const HISKY_ID =
  window.location.host == "iotsatcomnms.com" ? "Network Id" : "hiSky ID";
export const FRIMWARE = "Firmware";
// AccountSubscriber / TerminalDevices / AccountSiteAndDevices
export const UPDATE_DATE = "updated at";
export const ONLINE_STATUS = "online";
// HubsDevices / TerminalDevices
export const STATUS_ENABLE = "Enabled";
export const OWNER = "Owner";
export const STATUS_DISABLED = "Disabled";
// HubsDevices / TerminalDevices
export const DEVIVCE_HUB = "hub";
// HubsDevices / TerminalDevices
export const DEVICE_TERMINAL = "terminal configuration";
// HubsDevices / TerminalDevices
export const TIME_LINE_DEVICE = "terminal timeline";
// HubsDevices / TerminalDevices
export const ASSIGN_TERMIANL = "assign terminal";
// HubsDevices / TerminalDevices
export const SUBSCRIBER = "subscriber";
// SideBar / TreeView
export const COMPANY_ENTITY = "Company";
// Accounts / TreeView
export const SUBSCRIBER_ENTITY = "Subscriber";
// Accounts / TreeView
export const HISKY_ENTITY = "hiSky";
// Accounts / TreeView
export const RESELLER_ENTITY = "Reseller";
// Accounts / TreeView
export const TECHNICIAN_ENTITY = "Technician";
export const LOCAL_IP = "local IP";
// Accounts / TreeViewf
export const DESCRIPTION = "Description";
export const ASSGINED_ACCOUNT = "Assigned Account";
// Logs >> UserLogs / Updates
export const START_DATE = "Start Date";
export const END_DATE = "End Date";
//DataFilter / TableFilter
export const BUY = "buy";
export const RENT = "rent";
//DeviceItem / Models > AssignAccountDevice
export const CREATE_AT = "create at";
export const CREATION_DATE = "creation date";
export const SYSTEM_NAME="System Name"
export const PREVIEW="Preview"
// Logs >> UserLogs / Updates  / Models > PlanSubscriber > HistoryPlanSubsciber
export const STATIC = "Static";
export const DYNAMIC = "Dynamic";
export const FIXED_RL = "Fixed RL";
export const FIXED_LR = "Fixed LR";
export const PROMT = "Choose Type";
// Models > CreateNewTerminal / // Models > UpdateTerminal
export const SUBMIT = "Submit";
export const CANCEL = "Cancel";
export const COPY = "Copy";
export const ACTIVATE = "Activate";
export const CLOSE = "Close";
export const REFRESH = "Refresh";
export const NEXT = "Next";
export const PREV = "Prev";
//type of preview Models > UpdateConfig
export const ALL = "All";
export const INCLUDES = "Includes";
export const EXCLUDES = "Excludes";
export const BETWEEN = "Between";
//############ COMPONENTS ############
// Models > PlanSubscriber > HistoryPlanSubsciber
export const COUNT_PLANS = "count plans";

//Models > smsnotification > buttons
export const VERIFY = "Verify";
export const RESEND = "Resend";

//############ CONTAINERS ############
export const DOWNLOAD_FAILURE = "DownloadFailure";
//----------- Accounts -----------
//HEADER
export const LABELS_PLAN_USAGE_CHART = [
  "0%-10%",
  "10%-20%",
  "20%-30%",
  "30%-40%",
  "40%-50%",
  "50%-60%",
  "60%-70%",
  "70%-80%",
  "80%-90%",
  "90%-100%",
  "100+",
];
export const EMAIL = "Email";
export const FIRST_NAME = "First name";
export const LAST_NAME = "Last name";
export const JOIN_DATE = "Join date";
export const ACCOUNT_TYPE = "Account type";
export const NAME_OF_FILE_ACCOUNTS = "Accounts";
export const POST_CODE = "post code";
export const CRM_ID = "CRM ID";
export const PERMISSION_TYPE = "Permission Type";
export const DELETED_AT = "Deleted At";
//TOOL-TIP TEXT
export const DELETE = "delete";
export const ASSIGN_PLAN = "Assign plan";
export const ACTIVE_ACCOUNT = "Enable/Disable";
export const ASSIGN_DEVICE_ACCOUNT = "assign device";
export const REASON_DELETE_ACCOUNT = "Reason for deletion";
export const ACCOUNT_PERMISSIONS = "permissions";
export const ASSIGN_DEVICE_TECH = "technician devices";
export const ALL_CHILDREN_DEVICES = "Account devices";
export const ACCOUNT_PHONE_NUMBERS = "Account phone numbers";
export const CREATE_PLAN = "create plan";
export const SUBSCRIBTION = "assign Plan";
export const DISABLE_COLOR = "#bdbdbd";
export const AVAILABLE_DEVICES = "Available devices";
export const USAGE_CHART = "usage";
export const TYPE_UPLOAD_APK = "APK";
export const TYPE_UPLOAD_HUBPP = "HUB";
export const TYPE_UPLOAD_FIRMWARE = "FIRMWARE";
//ACCOUNT PAGE
export const ACCOUNT_PAGE = "accounts";
export const TREE_PAGE = "tree";
export const TREE_VIEW = "tree-view";
export const NO_DATA_VIEW = "no data";
export const INCLUDE_DESCENDATS = "Include descendants";
export const EXCLUDE_DESCENDATS = "Exclude descendants";
//----------- AccountSubscriber -----------
export const DEVICES_NAME = "Device Name";
export const DEVICE_TYPE = "device type";
export const STYPE = "sType";
//----------- Company -----------
export const COMPANY_NAME = "Company name";
export const REGISTER_USERS = "Register users";
export const ASSIGN_RESOURCE = "Assigned resources";
//----------- DashBoard ---------
export const ONLINE = "online";
export const OFFLINE = "offline";
export const HUBS = "hubs";
export const HUB = "hub";
export const SITE = "site";
export const MOCKUP = "mockup";
export const TERMINAL = "terminal";
export const STATIC_TERMINAL = "staticTerminal";
export const DYNAMIC_TERMINAL = "dynamicTerminal";
export const DOT_MARKER = "dot";
export const CLUSTER = "cluster";
export const BEAM = "beam";
//----------- Extra ---------
export const REPORT_DURATION = "Duration (seconds)";
export const RECEIVE_SEND_BYTES = "receive/Send (bytes)";
export const DATE = "Date";
export const CALL_DURATION = "call Duration (seconds)";
export const FILE_NAME = "Activity";
//----------- Header >> SearchAutocomplete ---------
export const TEMPLATE = "templates";
//export const USERS="users"
export const TERMINAL_LINK = "terminal";
export const HBS_LINK = "hbs";

//----------- History ---------
export const NAME_OF_FILE_HISTORY = "History";
export const ACTIVATED_DATE = "activated date";
export const ASSIGNED_DATE = "assigned date";
export const DETTACH_DATE = "detached date";
export const ATTACHED_DATE = "Attached date";
export const ATTACHED_By = "Attached by";
export const ATTACHED_TO = "Attached to";
//export const CREATED_DATE = 'created date';
//----------- HubsDevices ---------
export const STATUS_HUBSDEVICES = "Enable";
export const FILE_NAME_HUBSDEVICES = "devices";
export const CHANNEL_LIST = "hbsChannels";
export const HUB_ALERT_LIST = "hbsAlerts";
//----------- Logs >> UserLogs ---------
export const LOG_TYPE = "log type";
export const GUID = "GUID";
export const USER_IP = "user IP";
export const NAME_OF_FILE_USERLOGS = "User Logs";
export const FIRST_LAST_NAME = "full name";
//system log
export const DECODE_STATUS_ICON = {
  1: {
    name: "stream",
    type: "far",
    color: "00bdd6",
    tool: "Software",
  },
  2: {
    name: "brackets",
    type: "far",
    color: "#00bdd6",
    tool: "Digital",
  },
  3: {
    name: "microchip",
    type: "far",
    color: "#00bdd6",
    tool: "Hardware",
  },
  4: {
    name: "ticket",
    type: "far",
    color: "#00bdd6",
    tool: "Ticket",
  },
  5: {
    name: "siren",
    type: "far",
    color: "#ff1a1a",
    tool: "Alert",
  },
  6: {
    name: "info-square",
    type: "far",
    color: "#00bdd6",
    tool: "Info",
  },
  7: {
    name: "tablet-rugged",
    type: "far",
    color: "#00bdd6",
    tool: "Terminal",
  },
  8: {
    name: "tablet-rugged",
    type: "fal",
    color: "#00bdd6",
    tool: "Hub",
  },
};
export const SYSTEM_LOG = "log";
//----------- Permissions >> PermissionDataHeader ---------
export const CREATE_SITE = "create site";
//----------- PlansAssign ---------
export const NAME_OF_FILE_PLANSASSIGNED = "Plans-Assigned";
export const ATTACH_DATE = "Attach date";
//----------- SideBar ---------
export const DASHBOARD = "dashboard";
export const SYSTEM_LOGS_SIDEBAR = "system-logs";
export const NMS_USER_LOGS = "nmsuserlogs";
export const BSS_USER_LOGS = "bssuserlogs";
export const BSS_SYSTEM_LOGS = "bsssystemlogs";
export const BSS_LOGS = "bssLogs";
export const USER_LOGS_DISPLAY_NAME = "user-logs";
export const SYSTEM_LOGS_DISPLAY_NAME = "system logs";
export const CDR = "activity";
export const PLANS = "plans";
export const SUPPORT = "support";
export const PREMISSIONS = "permissions";
export const SETTINGS = "Settings";
export const ENTITY_PERMMSIONS = "entity";
export const ROLE_PREMISSIONS = "role";
export const SERVICES = "services";
export const FEATURES = "features";
export const SITES = "sites";
export const TEMPLATES = "templates";
export const SUBSCRIPTIONS = "history";
export const ASSIGN_PLANS = "plans assign";
export const BSS_DASHBOARD = "bssdashboard";
export const UPDATES = "updates";
export const TECHNICIAN = "technician";
export const ACCOUNT = "account";
export const REPORTS = "Reports";
export const USERS = "Users";
export const USERNAME = "Username";
export const STREET_ADDRESS = "street Address";
//----------- Sites ---------
export const SITE_NAME = "site name";
export const GROUP = "group";
export const COMPANY_LEGAL_NAME = "comapany name";
export const BILLING_ADDRESS = "billing address";
export const LEGAL_ADDRESS = "legal address";
export const COUNTRY = "country";
export const BILLING = "billing";
//----------- TemplatePlan ---------
export const NAME_OF_FILE = "Templates";
export const EXPIRATION_DATE = "Expiration Date";
//----------- Terminal ---------
export const START = "start";
export const STOP = "stop";
//----------- TerminalDevices ---------
export const FILE_NAME_TERMINALDEVICES = "Terminal Devices";
//----------- TreeView ---------
export const HI_SKY = "hisky";
export const RESELLER = "reseller";
export const COMPANY = "company";
export const PARENT = "parent";
//----------termianl dump----------------//
export const PROCCRSS_LOG_ID = "Proccess Log ID -";
export const REQUEST_STATE = "Request State";
export const PROCCRSS_LOG = "Proccess Log";
export const RECORD = " Record";
export const RESULT_FILE = "Result File";
export const MESSAGE = "Message";
export const SOURCE_PORT = "source port";
export const TIME = "time";

//----------- Updates ---------
export const VERSION_NO = "version NO. ";
export const UPLOAD_BY = "upload by";
export const BUNDLE_TYPE = "bundle type";
export const FILE_TYPE = "file type";
export const CREATED_DATE = "created date";
export const DOWNLOAD = "";
export const VERSION = "Version";
export const BUNDLE = "Bundle";
export const DOWNLOADS = "Downloads";

//----------- UsageData ---------
export const WEEK = "7 days";
export const FULL_DAY = "24 hours";
export const MONTH = "6 weeks";
export const HALF_DAY = "12 hours";
export const CUSTOM = "Custom";
export const BYTES = "Bytes";
export const SECONDS = "Seconds";
export const MINUTE = "Minutes";
export const HOURS = "Hours";
export const SIX_HOURS = "6 Hours";
//-----------Extra > Services
export const SERVICE_TYPE = "Service Type";
export const CREATED_AT = "Created At";
export const RECEIVED_AT = "Received At";
export const IS_ACTIVE = "enable";
export const ENROLLED_ON = "create date";
export const TERMINATED_ON = "terminated on";
export const NAME_OF_FILE_SERVICES = "Services";

//-----------Reports
export const ACTION_TYPE = "Action Type";
//-----------Terminal Network Info
export const FIRMWARE = "Firmware";
export const HARDWARE = "Hardware";
export const FIRMWARE_VERSION = "Firmware version";
export const HARDWARE_VERSION = "Hardware version";
export const LAT = "Lat";
export const LONG = "Long";
export const LOCATION_NAME = "Location";
export const DEFAULT_LOCATION_NAME = "Default Location";
export const TERMINAL_TYPE = "Terminal Type";
export const DEST_IP = "Destination IP";
export const DEST_PORT = "Destination Port";
export const RECV_PORT = "Receive Port";
export const DNS = "Data Server";
export const LOOPBACK = "Loopback";
export const UPD_NETWORK_CONFIG = "Network Configuration";
export const IM_NETWORK_CONFIG = "IM Network Configuration";
export const NO_SELECTION = "No Selection";
export const DEFAULT_IP = "0.0.0.0";
export const TRANSPORT_LAYER = "Transport layer";
export const SUGGEST_BOX_ID = "suggestBoxID";
export const TILTE_DATA_SERVER_NETWORK_CONFIG = "Data server configuration";
//----------Updates - ViewUpdate
export const TOTAL_DOWNLOADS = "Total Downloads";
export const UPLOAD_FROM = "Upload From";
export const URL = "Url";
export const RELEASE_TYPE = "Release Type";
//-----------Billing
export const UTC_LEGAL_MESSAGE =
  "Please note that usage is calculated according to UTC time";
export const LOCAL_TIME_LEGAL_MESSAGE =
  "Usage is reported according to local time";
export const PARTNER_ACCOUNT_NAME = "Account Name";
export const TEMPLATE_MONTH_DURATION = [
  { id: 1, name: 1 },
  { id: 3, name: 3 },
  { id: 6, name: 6 },
  { id: 12, name: 12 },
  { id: 18, name: 18 },
  { id: 24, name: 24 },
  { id: 36, name: 36 },
  { id: 48, name: 48 },
  { id: 60, name: 60 },
];
export const PO_NO = "PO no.";
export const BULK_UPDATE = "bulk update";
export const BULK_EXPORT = "bulk export";
export const PLAN_ID = "Plan ID";
export const PLAN_DATA_ALLOWANCE = "Plan Data Allowance";
export const MONTHLY_FEE = "Monthly Fee";
export const OVERUSAGE_FEE = "Overusage Fee";
export const OVERUSAGE_UNIT = "Overusage Unit";
export const DATA_USAGE = "Data Usage";
export const OVERUSAGE_DATA = "Overusage Data";
export const TOTAL_OVERUSAGE_FEE = "Total Overusage Fee";
export const TOTAL_MONTHLEY_CHARGE = "Total Monthly Charge";
export const SELECT_RESELLER = "Select Reseller";
export const SELECT_TYPE = "Select Type";
export const IP = "IP";
export const CONTROL_PORT_ADDRESS = "Control Port Address";
export const CONTROL_PORT_GW = "Control Port Gw";
export const CONTROL_PORT_MASK = "Control Port Mask";
export const DATA_PORT_ADDRESS = "Data Port Address";
export const DATA_PORT_MASK = "Data Port Mask";
export const DATA_PORT_GW = "Data Port Gw";
export const FROM_NUMBER = "From number";
export const To_NUMBER = "To number";
export const ASSIGNED_TO = "Assigned To";
//############## Store ############### //
export const ASSIGN_TERMINAL_DEVICE = "Assgin terminal device";
export const DELETE_ACCOUNT = "delete account";
export const LINK_DEVICE = "Link Device";
export const DETACH_PLAN_FROM_DEVICE = "detach plan from the terminal";
export const UPDATE_PLAN_TO_DEVICES = "update plan to devices";
export const RELOAD_SITES = "reload  tree sites";
export const RELOAD_DEVICES = "reload user devices";
export const ACTIVATE_DEVICE_PLAN = "activate device plan";
export const ADDED_USAGE_DATA = "added usage data";
export const RELOAD_USAGE = "reload usage";
export const UPDATE_TECH_TREE = "update tech tree";
export const RELOAD_TECH_SITE = "reload tech tree";
export const RELOAD_CHANNELS = "reload channels";
export const UPDATE_CONFIG_TECH_DATA = "update the config trch";
export const UPDATE_TERMINAL_CONFIG_DATA = "update the config data of terminal";
export const RELOAD_MAP = "relaod google maps";
export const UPDATE_EDIT_SITE_COMPLETE = "reload edit site complete";
export const MULTIPLE_ACTIVATE_PLAN = "activate multiple plans";
export const RELOAD_UPDATES_SUMMARY = "reload upload summary analytics";
export const RELOAD_UPDATES = "reload updates table";
export const RELOAD_AVAILABLE_DEVICES = "reload available devices";
export const RESTART_INTERVAL = "restart interval";
export const UPDATE_ON_CREATE_PLAN_VOIP =
  "update data on tables on creating voip plan";
//############### Permissions ###############//
export const VIEW = "View";
export const UPDATE = "Update";
export const CREATE = "Create";
export const USER = "user";
export const CONTINUE = "continue";
export const BACK = "back";
//for sidebar _LINK
//for butttons _BTN
//for icons _ICON
export const UNATTACH="unattach"
export const CREATE_USER_BTN = "CREATE_USER";
export const EXPORT_BTN = "EXPORT_BTN";
export const EXPORT_TERMINALS = "EXPORT_TERMINALS";
export const EXPORT_SYSTEM_LOGS = "EXPORT_SYSTEM_LOGS";
export const BSS_EXPORT_SYSTEM_LOGS = "BSS_EXPORT_SYSTEM_LOGS";
export const EXPORT_USER_LOGS = "EXPORT_USER_LOGS";
export const BSS_EXPORT_USER_LOGS = "BSS_EXPORT_USER_LOGS";
export const EXPORT_PLANS_ASSIGN = "EXPORT_PLANS_ASSIGN";
export const EXPORT_ACTIVITY = "EXPORT_ACTIVITY";
export const EXPORT_REPORTS = "EXPORT_REPORTS";
export const SYSTEM_LOGS_LINK = "SYSTEM_LOGS";
export const USER_LOGS_LINK = "USER_LOGS";
export const CREATE_ACCOUNT_BTN = "CREATE_ACCOUNT";
export const TREE_SEARCH_BTN = "TREE_SEARCH";
export const ASSIGN_DEVICE_ICON = "ASSIGN_DEVICE";
export const ACCOUNT_PERMISSIONS_ICON = "ACCOUNT_PERMISSIONS";
export const CREATE_PLAN_ICON = "CREATE_PLAN";
export const EDIT_PROFILE_ICON = "UPDATE_PROFILE_ACCOUNTS";
export const DATA_USAGE_ICON = "DATA_USAGE";
export const DISABLE_ACCOUNT_ICON = "DISABLE_ACCOUNT_ACCOUNTS";
export const ASSIGN_PLAN_ICON = "ASSIGN_PLAN";
export const ATTACH_PLAN_ICON = "ATTACH_PLAN";
export const ACTIVATE_MULTIPLE_PLAN_ICON = "ACTIVATE_MULTIPLE_PLAN";
export const TERMINAL_DETAILS_INFO_ICON = "TERMINAL_DETAILS_INFO";
export const ATTACHED_PLAN_INFO_ICON = "ATTACHED_PLAN_INFO";
export const TERMINAL_USAGE_ICON = "TERMINAL_USAGE";
export const ACTIVATE_PLAN_ICON = "ACTIVATE_PLAN";
export const FAKE_USAGE_ICON = "FAKE_USAGE_ICON";
export const DETACH_PLAN_ICON = "DETACH_PLAN";
export const CREATE_SITE_ICON = "CREATE_SITE";
export const EDIT_SITE_ICON = "EDIT_SITE";
export const LINK_DEVICES_ICON = "LINK_DEVICES";
export const DELETE_SITE_ICON = "DELETE_SITE";
export const ASSIGN_TECHNICIAN_DEVICES_ICON = "ASSIGN_TECHNICIAN_DEVICES";
export const TERMINALS_CURRENT_LOGIN_REGISTRATION =
  "terminalsCurrentLoginRegistrationStatus";
export const DELETE_TERMINAL_ICON = "DELETE_TERMINAL";
export const ACCOUNT_USAGE_ICON = "ACCOUNT_USAGE";
export const EDIT_CONFIGURATION_ICON = "EDIT_CONFIGURATION";
export const DISABLE_USER_ICON = "DISABLE_ACCOUNT_USERS";
export const UPDATE_USER_ICON = "UPDATE_PROFILE_USERS";
export const TERMINAL_DUMP = "Network Traffic";
/* telemetry */
export const CONNECTION_TYPES = [
  { id: 1, name: "UDP" },
  { id: 4, name: "TCP" },
];
export const TELEMETRY = "Telemetry";
export const LONGITUDE = "Longitude";
export const LATTITUDE = "Latitude";
export const HEADING = "Heading";
export const ELEVATION = "Elevation";
export const BATTERY_STATUS = "Battery Status";
export const BATTERY_TEMPARATURE = "Battery Temperature";
export const BATTERY_VOLTAGE = "Battery Voltage";
export const BATTERY_CURRENT = "Battery Current";
export const MODEM_TEMPARATURE = "Modem Temperature";
export const SNR1 = "SNR1";
export const SNR_REGISTRATION = "Registration";
export const SNR_LOGIN = "Login";
export const SNR2 = "SNR2";
export const TERMINAL_EBNO = "Terminal Rx EsN0";
export const HUB_EBNO = "HUB Rx EsN0";
export const IP_INFORMATION = "IP Source Location";
export const SOURCE_IP = "IP Source";
export const COUNTRY_IP = "IP Country";
export const CITY_IP = "IP City";
export const ADVANCED_CHART = "Realtime Network";
export const TERMINAL_SERVICE = "Terminal service";
export const SNR1_HISTORY = "Network History";
export const WHOIS_LOOKUP = "Whois Lookup";
export const SNR_TELEMETRY_HISTORY = "snr telemetry history";
export const USAGE_HISTORY = "usage history";
export const LAST_REGISTRATION_AT = "Last Registration At";
export const LAST_LOGIN_AT = "Last login at";
export const REGISTRATION_COLOR = "#9c27b0";
export const LOGIN_COLOR = "green";
export const SNR1_COLOR = "#00BDD6";
export const SNR2_COLOR = "#2415D8";
export const INBOUND_COLOR = "#00BDD6";
export const OUTBOUND_COLOR = "#F7A186";
//updates
export const SATELLITE = "satellite file";
export const COVERAGE = "Coverage Settings";
export const MOBILE_RELEASE = "Mobile Release";
export const HUBPP_RELEASE = "hubpp release";
export const READ_ONLY = "readonly";
export const KML_CREATED = "Added by user";
export const KML_DATE = "Added by user date";
//hubpp simulator
export const EXECUTED_BY = "Executed By";
export const EXECUTED_AT = "Executed At";
export const OPEN = "open";
export const INSERT = "insert";
export const CLEAR = "clear";
export const HIDE = "hide";
// dashboard map sites
export const UPDATE_SITES_DATA = "update sites check or uncheck";
///////////// history
export const CREATED = "created";
export const ACTIVATED = "Activated";
export const DEACTIVATED = "Deactivated";
export const INACTIVE = "Inactive";
export const ATTACHED = "Attached";
export const DETACH = "Detached";
export const ASSIGNED = "Assigned";
//////Right Click action
export const LOCATE_BEAM = "Locate beam";
export const CLOSE_LOCATE_BEAM = "close locate beam stop glowing";
export const LOCATE_TERMINALS = "locate terminals";
export const SHOW_TRAIL = "show trail";
export const CANCEL_TRAIL = "cancel trail";
export const GO_TO_PAGE = " got to terminal details page";
export const ASSIGNED_HUB_DETAILS = " assigned hub for a terminal";
export const DELETE_POLY_DOT = "delete added poly dot";
///////////////////Pool manager /////////////////////////////

export const VIEW_POOL = "view pool";
export const DELETE_POOL_ACTION = "delete pool action";
export const EDIT_POOL = "edit pool";
export const EXPORT_PDF = "export pdf";
export const EXPORT_EXCEL = "export excel";
export const CLOSE_ACTION_MENU = "close menu";
export const POOL_HISTORY_BY_ID = "pool history by id";
export const POOL_HISTORYS = "pool historys";
export const OPEN_MENU_POOL = "open menu pool";
export const CREATE_NEW_POOL = "create new pool";
export const EXPORT_POOL = "export pool";
export const USED = "Used";
export const FREE = "Free";
export const POOL_NAME = "Pool Name";
export const SUB_POOL_NAME = "Subpool Name";
export const MANAUFACTURER_NAME = "Manufacturer";
export const EXTENSION = "Extension";
export const PREFIX = "Prefix";
export const RANGE_MIN = "Start Range";
export const RANGE_MAX = "End Range";
export const EDIT_TERMINAL_POOL = "edit terminal pool";
export const TERMINAL_PLACEHOLDER_IP="Valid Range: 1.0.0.0 - 255.255.255.254"
export  const TERMINAL_PLACEHOLDER_PORT="valid Range: 1100-65535"
export const SUB_POOL = "Subpool";
export const CREATE_NEW_SUB_POOL = "create new Subpool";
export const EDIT_SUB_POOL = "edit Subpool";
export const SUB_POOL_LIST = "Subpool listing";
export const SUB_POOL_TERMINALS = "Subpool terminals list";
export const DELETE_SUB_POOL_ACTION = "delete pool action";
export const SUB_POOL_HISTORY_BY_ID = "get a Subpool history by Subpool id";
export const EXPORT_SUBPOOL = "export sub pools";
//TABS
export const ACCOUNT_CREDENTAILS = "Account Credentials";
export const ACCOUNT_DETAILS = "Account Details";
export const ACCOUNT_DEVICES = "Account Devices";
export const USER_DETAILS = "User Details";
export const CREDENTAILS = "Credentials";
export const COVERAGE_TAB = "Coverage";
export const MOBILE_TAB = "Mobile";
export const HUBPP_TAB = "Hubpp";
/////////Statiscis/////////////////
export const RAM_USAGE = "RAM Usage";
export const CPU_USAGE = "CPU Usage";
export const SINCE = "Since";
/////////////////notificatio msgs///////////////
export const NO_AUTH_MESSAGE = "unauthorized action";
export const NO_AUTH_ATTACH_PLAN = "Not authorized for attaching a plan";
export const NO_AUTH_LINK_DEVICES = "Not authorized for link device";
export const NO_AUTH_MULTI_PLAN_ACTIVE = "Not authorized for activating plans";
export const NO_AUTH_CREATE_PLAN = "Not authorized for create plan";
export const NO_AUTH_DISABLE_ACCOUNT = "Not authorized for disabling account";
export const NO_AUTH_DISABLE_USER = "Not authorized for disable user";
export const NO_AUTH_SET_COVERGAE = "Not authorized to set coverage";
export const NO_AUTH_NEW_SERVICE = "Not authorized to create service";
export const NO_AUTH_NEW_TEMPLATE = "Not authorized to create template";
export const NO_AUTH_NEW_USER = "Not authorized to create user";
export const NO_AUTH_NEW_UPDATE = "Not authorized to create update";
export const NO_AUTH_NEW_ACCOUNT = "Not authorized to create account";
////////////////////////////////
export const NEW_SERVICE = "new service";
export const NEW_TEMPLATE = "new template";
export const CREATE_USER = "create user";
export const NEW_UPDATE = "new update";
export const SET_COVERAGE = "set coverage";
export const NEW_ACCOUNT = "new account";
/////////////////devices transimtting code types////////////////
export const ONLINE_CODE = "terminal is online";
export const TELEMETRY_CODE = "telemetry data is present";
export const DEFAULT_CODE = "Point to default loation";

/////////////////////////timeline/////////////////////
export const DEVICE_TIMELINE = "device timeline";
export const UPDATE_TIMELINE = "update timeline";
export const TERMINAL_ACTIVITY = "Terminal Activity";
export const TERMINAL_TIMELINE = "terminal timeline";
export const CHART_AND_TIMELINE = "chart aand time line in horizontal";
export const EVENTS = "Events";
export const GOOGLE = "google";
export const NO_PLAN_MSG = "No plan attached";
export const NO_PLAN_ACTIVE_MSG = "Plan not activated";
/////////////////////////telemtry and event report model/////////////////////
export const TELEMETRY_HISTORY = "telemetry history";
export const EVENT_REPORTS = "Log reports";
export const MIN_TIMER = 1000;
////////////////////////global////////////////////
export const IOT = "IOT";
export const VOIP = "VOIP";
export const BOTH = "BOTH";
export const IM = "IM";
export const INTITATOR_LIST = [
  { id: 0, name: "Incoming call" },
  { id: 1, name: "Outgoing call" },
];
export const FAKE_USAGE_IOT = "fakeUsageIot";
export const FAKE_USAGE_VOIP = "fakeUsageVoip";
export const FAKE_USAGE_IM = "fakeUsageIM";
export const OUTBOUND = "Outbound";
export const INBOUND = "Inbound";
export const OUT_CALL = "Outgoing";
export const IN_CALL = "Incoming";
export const INBOUND_BYTE = "Inbound Bytes";
export const OUTBOUND_BYTE = "Outbound Bytes";
export const INCOMING_SECONDS = "Incoming seconds";
export const OUTGOING_SECONDS = "Outgoing seconds";
export const PLAN_ACTIVATION_REPORT = "plan activation";
export const USAGE_SUMMARY_REPORT = "usage summary report";
export const DELETE_USER = "delete user";
export const VIEW_DELETE_USER = "view delete user";
export const EDIT = "edit";

/////////////////////////////////////////////socket///////////////////////////////////////////////////////
export const DEV_SERVER = "dev.hiskynms.com";
export const QA_SERVER = "qa.hiskynms.com";
export const INTEGRATION_SERVER = "integration.hiskynms.com";
export const HISKY_360 = "hisky360.com";
export const HISKY_360_WWW = "www.hisky360.com";
export const DEMO_SERVER = "demo.hiskynms.com";
export const SANDBOX_SERVER = "sandbox.hiskynms.com";
export const DEV_SERVER_JOOLUE = "hisky-nms-dev.jooule.com";
export const INDIA_SERVER = "hisky360.in";
export const INDIA_SERVER_WWW = "www.hisky360.in";
export const INTEGRATION_SERVER_JOOULE = "hisky-nms-integration.jooule.com";
export const SIMULATION_SERVER_JOOULE = "hisky-nms-simulation.jooule.com";
//export const SOCKET_END_POINT="wss://qa.hiskynms.com:8000/subscriptions"
export const SOCKET_END_POINT = "wss://iotarsat.com.ar:8000/subscriptions"
//   window.location.host == HISKY_360 || window.location.host == HISKY_360_WWW
//     ? "wss://hisky360.com:8000/subscriptions"
//     : window.location.host == QA_SERVER
//     ? "wss://qa.hiskynms.com:8000/subscriptions"
//     : window.location.host == INTEGRATION_SERVER
//     ? "ws://integration.hiskynms.com:8000/subscriptions"
//     : window.location.host == DEMO_SERVER
//     ? "ws://demo.hiskynms.com:8000/subscriptions"
//     : window.location.host == SANDBOX_SERVER
//     ? "ws://sandbox.hiskynms.com:8000/subscriptions"
//     : window.location.host == INDIA_SERVER ||
//       window.location.host == INDIA_SERVER_WWW
//     ? "wss://hisky360.in:8000/subscriptions"
//     : window.location.host == INTEGRATION_SERVER_JOOULE
//     ? "wss://hisky-nms-integration.jooule.com:8000/subscriptions"
//     : window.location.host == SIMULATION_SERVER_JOOULE
//     ? "wss://hisky-nms-simulation.jooule.com:8000/subscriptions"
//     : window.location.host == DEV_SERVER_JOOLUE
//     ? "wss://hisky-nms-dev.jooule.com:8000/subscriptions"
//     : "ws://dev.hiskynms.com:8000/subscriptions";

//////////////////////////////error or info msgs from BE
export const NO_DATA_TO_EXPORT = "info: no data available";

////////////////////////
export const PAIRED_DATE = "Paired Date";
export const BEAM_HIGHLIGHT_COLOR = "yellow";
