import React, { Component } from "react";
import styles from "./HubMoreInfo.module.scss";
import Input from "../../../Input/Input";
import SingleButton from "../../../SingleButton/SingleButton";
import { connect } from "react-redux";
import * as actionType from "../../../../Store/actions/index";
import Icon from "../../../Icon/Icon";
import SpinnerLoader from "../../../SpinnerLoader/SpinnerLoader";
import moment from "moment";
import { http } from "../../../../RequestManager/Http";
import { getHbsDetails } from "../../../../RequestManager/QueriesAndMutations";
import {
  UPD_NETWORK_CONFIG,
  HISKY_ID,
  DEVICES_NAME,
  DESCRIPTION,
  LAT,
  LONG,
  LOCATION_NAME,
  IS_ACTIVE,
  CREATED_DATE,
  IP,
  CONTROL_PORT_ADDRESS,
  CONTROL_PORT_GW,
  CONTROL_PORT_MASK,
  DATA_PORT_ADDRESS,
  DATA_PORT_MASK,
  DATA_PORT_GW,
  HARDWARE_VERSION,
  FIRMWARE_VERSION
} from "../../../../Util/constTypes";
import { getAccountId } from "../../../../Store/localStoreageManager/localStoreageManager";
import Satellite from "../../../../assets/Satellite.svg";

class HubMoreInfo extends Component {
  state = {
    loading: true,
    inputs: {
      macId: { label: HISKY_ID, value: "" },
      deviceName: { label: DEVICES_NAME, value: "" },
      description: { label: DESCRIPTION, value: "" },
      firmware: { label: FIRMWARE_VERSION, value: "" },
      hardware: { label: HARDWARE_VERSION, value: "" },
      lat: { label: LAT, value: "" },
      long: { label: LONG, value: "" },
      locationName: { label: LOCATION_NAME, value: "" },
      isActive: { label: IS_ACTIVE, value: "" },
      lastTs: { label: CREATED_DATE, value: "" },
      externalId: { label: "External ID", value: "" },
      provider: { label: "Provider", value: "" },
      ip: { label: IP, value: "" },
      controlPortAddress: { label: CONTROL_PORT_ADDRESS, value: "" },
      controlPortGw: { label: CONTROL_PORT_GW, value: "" },
      controlPortMask: { label: CONTROL_PORT_MASK, value: "" },
      dataPortAddress: { label: DATA_PORT_ADDRESS, value: "" },
      dataPortMask: { label: DATA_PORT_MASK, value: "" },
      dataPortGw: { label: DATA_PORT_GW, value: "" },
    },
  };

  componentDidMount = () => {
    this.initializeDataHandler();
  };
  initializeDataHandler = () => {
    let copyInputs = { ...this.state.inputs };
    if (
      typeof this.props.hubInfoRx === "object" &&
      this.props.hubInfoRx.hubInfo != undefined
    ) {
      let copyDevice = { ...this.props.hubInfoRx.hubInfo };

      for (let key in copyInputs) {
        if (copyDevice[key] !== undefined) {
          copyInputs[key].value = copyDevice[key];
        }
      }
      this.setState({ inputs: copyInputs, loading: false });
    } else {
      //call api
      this.createHubInfoHttpRequest();
    }
  };
  formatHubInfoHandler = (dataSet) => {
    let dataSetCopy = { ...dataSet };
    let params = {};

    for (let key in dataSetCopy) {
      if (key === "hbsattrSet") {
        if (dataSetCopy[key].length > 0) {
          params = { ...params, ...dataSetCopy[key][0] };
        }
      } else if (key == "beam") {
        let extId = "N/A";
        let provider = "N/A";
        if (dataSetCopy[key] != null) {
          extId = dataSetCopy[key].externalId;
          provider = dataSetCopy[key].provider;
        }
        params["externalId"] = extId;
        params["provider"] = provider;
      } else if (key !== "hbsattrSet" && key !== "type") {
        params[key] = dataSetCopy[key];
      }
    }
    return params;
  };
  createHubInfoHttpRequest = () => {
    http(getHbsDetails, {
      account_id: getAccountId(),
      id: this.props.hubInfoRx.hubId,
    })
      .then((res) => {
        let copyInputs = { ...this.state.inputs };
        let copyDevice = this.formatHubInfoHandler(res.data.hbs[0]);

        for (let key in copyInputs) {
          if (copyDevice[key] !== undefined) {
            copyInputs[key].value = copyDevice[key];
          }
        }
        this.setState({ inputs: copyInputs, loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };
  onCloseHandler = () => {
    this.props.hubDataSelector({});
    this.props.resetModelSelector();
  };
  render() {
    const device = this.props.networkInfoRx;
    return (
      <div className={styles.HubMoreInfos} style={this.props.styles}>
        <h3 className={styles.header}>
          <span className={styles.headerIcon}>
            <Icon defination={{ prefix: "far", iconName: "tablet-rugged" }} />
          </span>
          Hub Info
        </h3>
        <div className={styles.breakLine}></div>
        <div
          className={styles.contentWrapper}
          style={{ opacity: this.state.loading ? 0.6 : 1 }}
        >
          <div className={styles.form}>
            <Input
              label={this.state.inputs.macId.label}
              value={
                this.state.inputs.macId.value
                  ? this.state.inputs.macId.value
                  : "N/A"
              }
              disabled={true}
              inputWrapper={styles.inputWrapper}
              isRegularText={true}
            />
            <Input
              label={this.state.inputs.deviceName.label}
              value={
                this.state.inputs.deviceName.value
                  ? this.state.inputs.deviceName.value
                  : "N/A"
              }
              disabled={true}
              inputWrapper={styles.inputWrapper}
            />

            <Input
              label={this.state.inputs.hardware.label}
              value={
                this.state.inputs.hardware.value
                  ? this.state.inputs.hardware.value
                  : "N/A"
              }
              disabled={true}
              inputWrapper={styles.inputWrapper}
            />
            <Input
              label={this.state.inputs.firmware.label}
              value={
                this.state.inputs.firmware.value
                  ? this.state.inputs.firmware.value
                  : "N/A"
              }
              disabled={true}
              inputWrapper={styles.inputWrapper}
            />
            <Input
              label={this.state.inputs.isActive.label}
              value={this.state.inputs.isActive.value === true ? "YES" : "NO"}
              disabled={true}
              inputWrapper={styles.inputWrapper}
            />
            <Input
              label={this.state.inputs.description.label}
              value={
                this.state.inputs.description.value
                  ? this.state.inputs.description.value
                  : "N/A"
              }
              disabled={true}
              inputWrapper={styles.inputWrapper}
            />
            <Input
              label={this.state.inputs.locationName.label}
              value={
                this.state.inputs.locationName.value
                  ? this.state.inputs.locationName.value
                  : "N/A"
              }
              disabled={true}
              inputWrapper={styles.inputWrapper}
            />
            <Input
              label={this.state.inputs.lastTs.label}
              value={moment(this.state.inputs.lastTs.value).format("LLL")}
              disabled={true}
              inputWrapper={styles.inputWrapper}
            />

            <div className={styles.destinationWrapper}>
              <p className={styles.destinationTitle}>
                <span className={styles.iconNet}>
                  <img
                    className={styles.iconSat}
                    src={Satellite}
                    alt="Satellite"
                  />
                </span>
                Beam Details
              </p>
              <div className={styles.destinationBreakLine}></div>
            </div>
            <Input
              label={this.state.inputs.externalId.label}
              value={this.state.inputs.externalId.value}
              disabled={true}
              inputWrapper={styles.inputWrapper}
            />
            <Input
              label={this.state.inputs.provider.label}
              value={this.state.inputs.provider.value}
              disabled={true}
              inputWrapper={styles.inputWrapper}
            />
            <div className={styles.destinationWrapper}>
              <p className={styles.destinationTitle}>
                <span className={styles.iconNet}>
                  {" "}
                  <Icon
                    defination={{
                      prefix: "fas",
                      iconName: "project-diagram",
                    }}
                  />
                </span>
            {UPD_NETWORK_CONFIG}
              </p>
              <div className={styles.destinationBreakLine}></div>
            </div>
            <Input
              label={this.state.inputs.ip.label}
              value={this.state.inputs.ip.value}
              disabled={true}
              inputWrapper={styles.inputWrapper}
            />
            <Input
              label={this.state.inputs.controlPortAddress.label}
              value={
                this.state.inputs.controlPortAddress.value
                  ? this.state.inputs.controlPortAddress.value
                  : "N/A"
              }
              disabled={true}
              inputWrapper={styles.inputWrapper}
            />
            <Input
              label={this.state.inputs.controlPortGw.label}
              value={
                this.state.inputs.controlPortGw.value
                  ? this.state.inputs.controlPortGw.value
                  : "N/A"
              }
              disabled={true}
              inputWrapper={styles.inputWrapper}
            />
            <Input
              label={this.state.inputs.controlPortMask.label}
              value={
                this.state.inputs.controlPortMask.value
                  ? this.state.inputs.controlPortMask.value
                  : "N/A"
              }
              disabled={true}
              inputWrapper={styles.inputWrapper}
            />
            <Input
              label={this.state.inputs.dataPortMask.label}
              value={
                this.state.inputs.dataPortMask.value
                  ? this.state.inputs.dataPortMask.value
                  : "N/A"
              }
              disabled={true}
              inputWrapper={styles.inputWrapper}
            />
            <Input
              label={this.state.inputs.dataPortGw.label}
              value={
                this.state.inputs.dataPortGw.value
                  ? this.state.inputs.dataPortGw.value
                  : "N/A"
              }
              disabled={true}
              inputWrapper={styles.inputWrapper}
            />
          </div>
        </div>
        <div className={styles.btnWrapper}>
          <SingleButton
            text={"close"}
            disabled={this.state.loading}
            click={this.onCloseHandler}
          />
        </div>
        <div
          className={styles.spinnerWrapper}
          style={{ display: this.state.loading ? "block" : "none" }}
        >
          <SpinnerLoader />
        </div>
      </div>
    );
  }
}
const mapStateProps = (state) => {
  return {
    hubInfoRx: state.devices.hubDataRx,
  };
};
const mapStateDispatch = (dispatch) => {
  return {
    resetComponentSelector: (data) =>
      dispatch(actionType.resetComponentSelector(data)),
    resetModelSelector: () => dispatch(actionType.resetModelSelector()),
    hubDataSelector: () => dispatch(actionType.hubDataSelector()),
  };
};
export default connect(mapStateProps, mapStateDispatch)(HubMoreInfo);
