import React, { Component } from "react";
import styles from "./InfoPolygons.module.scss";
import Icon from "../../Icon/Icon";
import moment from "moment";
import Logo from "../../../assets/logo.png";
import { http } from "../../../RequestManager/Http";
import { DEVICES_HUB } from "../../../Util/routes";
import { overLayPolygons } from "../../../RequestManager/QueriesAndMutations";
import SpinnerLoader from "../../../Componnet/SpinnerLoader/SpinnerLoader";
import { getEntityName } from "../../../Store/localStoreageManager/localStoreageManager";
import KeyValue from "../../../Componnet/KeyValue/KeyValue";
import {
  STOP,
  START,
  HISKY_ID,
  KML_CREATED,
  KML_DATE,
  UPDATE_DATE,
  HISKY,
} from "../../../Util/constTypes";
import { dataTypeBeam } from "../../../Util/globalFunc";
class InfoPolygons extends Component {
  _isMounted = true;
  _selectedLatLng = null;
  isHubBeam = window.location.pathname.includes(DEVICES_HUB);
  state = {
    listPolygons: [],
    selectedBeam: [],
    selectedBeamId: "",
    loading: true,
    cordPoly: {},
  };
  componentDidMount() {
    if (
      this.props.lat != null &&
      this.props.lng != null &&
      this.props.beamSelected == null
    ) {
      setTimeout(() => {
        if (document.getElementById("dashboradMarker") == null) {
          this.props.contralIntervalHttpCalles(STOP);
          this.createHttpRequestHandler();
        }
      }, 200);
    } else {
      if (this._isMounted) {
        let obj = this.props.beamSelected[0];
        this._selectedLatLng = {
          lat: obj.centroidLat != undefined ? obj.centroidLat : obj.center.lat,
          lng: obj.centroidLng != undefined ? obj.centroidLng : obj.center.lng,
        };
        this.setState(
          {
            listPolygons: this.props.beamSelected,
            selectedBeam: this.converObjKeyValueHandler(obj),
            selectedBeamId: `${obj.provider}-${obj.externalId}`,
            loading: false,
          },
          () => {
            this.props.setSelectedBeam(obj);
          }
        );
      }
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  createHttpRequestHandler = () => {
    http(overLayPolygons, {
      latitude: this.props.lat.toString(),
      longitude: this.props.lng.toString(),
    })
      .then((res) => {
        this.props.contralIntervalHttpCalles(START);
        if (res.data.coordinateClickedDashboard.length) {
          let obj = res.data.coordinateClickedDashboard[0];
          if (this._isMounted) {
            let selectedBeam = res.data.coordinateClickedDashboard.length
              ? res.data.coordinateClickedDashboard[0]
              : null;
            this._selectedLatLng = {
              lat: selectedBeam.centroidLat,
              lng: selectedBeam.centroidLng,
            };
            this.setState(
              {
                listPolygons: res.data.coordinateClickedDashboard,
                selectedBeam: this.converObjKeyValueHandler(obj),
                selectedBeamId: `${obj.provider}-${obj.externalId}`,
                loading: false,
              },
              () => {
                this.props.setSelectedBeam(this.state.listPolygons[0]);
              }
            );
          }
        } else {
          this.setState({ loading: false }, () => {
            this.props.contralIntervalHttpCalles(START);
          });
        }
      })
      .catch((err) => {
        this.props.contralIntervalHttpCalles(START);
      });
  };
  converObjKeyValueHandler = (obj) => {
    let arr = [];
    let excloudArr = [
      "coordinates",
      "id",
      "externalId",
      "name",
      "createdAt",
      "additionalBeam",
      "updatedAt",
      "borderColor",
      "uuid",
      "center",
      "color",
      "opacity",
      "preview_fillColor",
      "preview_borderColor",
      "centroidLng",
      "centroidLat",
    ];
    for (let key in obj) {
      if (key === "isPrimary") {
        arr.push({ label: "Prefered Beam", value: obj[key] ? "YES" : "NO" });
      } else if (key == "name") {
        arr.push({ label: "Beam name", value: obj.name });
      } else if (key == "hub") {
        let deviceName = "N/A";
        let hiskyId = "N/A";
        if (obj[key] != null) {
          deviceName = obj[key].deviceName;
          hiskyId = obj[key].macId;
        }
        arr.push({
          label: "Hub name",
          value: deviceName,
        });
        arr.push({
          label: `Hub ${HISKY_ID}`,
          value: hiskyId,
        });
      } else if (key == "updatedAt" && getEntityName() == HISKY) {
        arr.push({
          label: UPDATE_DATE,
          value:
            obj.updatedAt != null
              ? moment(obj.updatedAt).format("YYYY/MM/DD HH:mm:ss")
              : "N/A",
        });
      } else if (key == "additionalBeam" && getEntityName() == HISKY) {
        arr.push({
          label: KML_CREATED,
          value: obj[key] != null && obj[key] ? "YES" : "NO",
        });
        arr.push({
          label: KML_DATE,
          value:
            obj.createdAt != null && obj.additionalBeam
              ? moment(obj.createdAt).format("YYYY/MM/DD HH:mm:ss")
              : "N/A",
        });
      } else if (key == "externalId") {
        arr.push({
          label: "external ID",
          value: obj[key],
        });
      } else if (!excloudArr.includes(key)) {
        arr.push({
          label: key.replace(/([A-Z])/g, " $1").trim(),
          value: dataTypeBeam(key, obj[key].toString()),
        });
      }
    }
    return arr;
  };
  onClickBeamHandler = (index) => {
    let copyBeam = [...this.state.listPolygons];
    let selected = copyBeam[index];
    this._selectedLatLng = {
      lat:
        selected.centroidLat != undefined
          ? selected.centroidLat
          : selected.center.lat,
      lng:
        selected.centroidLng != undefined
          ? selected.centroidLng
          : selected.center.lng,
    };
    this.setState(
      {
        selectedBeam: this.converObjKeyValueHandler(selected),
        selectedBeamId: `${selected.provider}-${selected.externalId}`,
      },
      () => {
        this.props.setSelectedBeam(selected);
      }
    );
  };

  render() {
    return (
      <div
        className={!this.isHubBeam ? styles.InfoPolygon : styles.InfoPolygonHub}
      >
        <div
          className={styles.info}
          style={{ opacity: this.state.loading ? 0.6 : 1 }}
        >
          <h3 className={styles.header}>
            <div>
              <span className={styles.headerIcon}>
                <Icon defination={{ prefix: "far", iconName: "satellite" }} />
              </span>
              <span>
                Satellites info
                {this._selectedLatLng != null && (
                  <span className={styles.cordPoly}>{`Center [Lat:${parseFloat(
                    this._selectedLatLng.lat
                  ).toFixed(3)}, Lng:${parseFloat(
                    this._selectedLatLng.lng
                  ).toFixed(3)}]`}</span>
                )}
              </span>
            </div>
            <div className={styles.iconWrapper}>
              <span
                className={styles.eyeIcon}
                style={{ display: this.state.loading ? "none" : "block" }}
                onClick={() => this.props.closeViewPolygon(true)}
              >
                <Icon defination={{ prefix: "fal", iconName: "eye-slash" }} />
              </span>
              <span
                className={styles.iconClose}
                style={{ display: this.state.loading ? "none" : "block" }}
                onClick={() => this.props.closeViewPolygon(false)}
              >
                <Icon defination={{ prefix: "fal", iconName: "times" }} />
              </span>
            </div>
          </h3>
          <div className={styles.breakLine}></div>
          <div className={styles.data}>
            <div
              className={styles.leftSide}
              style={{ display: this.isHubBeam ? "none" : "block" }}
            >
              <ul className={styles.listWrapper}>
                {this.state.listPolygons.map((item, index) => {
                  return (
                    <li
                      key={index}
                      className={
                        `${item.provider}-${item.externalId}` ===
                        this.state.selectedBeamId
                          ? `${styles.itemBeam} ${styles.selectedBeam}`
                          : styles.itemBeam
                      }
                      onClick={() => this.onClickBeamHandler(index)}
                    >
                      <>
                        <span style={{ marginRight: "1rem" }}>
                          {index + 1}.
                        </span>
                        <span>{item.externalId}</span>
                        <span className={styles.pipe}></span>
                        <span>{item.name}</span>
                      </>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div
              className={
                !this.isHubBeam ? styles.rightSide : styles.rightSideHub
              }
            >
              {this.state.selectedBeam.length ? (
                this.state.selectedBeam.map((item, index) => {
                  return (
                    <KeyValue
                      item={item}
                      key={index}
                      index={index}
                      wrapperStyles={
                        !this.isHubBeam ? styles.item : styles.itemHub
                      }
                    />
                  );
                })
              ) : (
                <img alt="hisky-logo" src={Logo} className={styles.logo} />
              )}
            </div>
            {!this.state.loading && !this.state.listPolygons.length && (
              <p className={styles.noDataMsg}>
                There is no satellite in this coordinates
              </p>
            )}
          </div>
        </div>
        <div
          className={styles.spinnerWrapper}
          style={{ display: this.state.loading ? "block" : "none" }}
        >
          <SpinnerLoader />
        </div>
      </div>
    );
  }
}

export default InfoPolygons;
