import React, { Component } from "react";
import styles from "./MapSpinner.module.scss";
import SpinnerLoader from "../../SpinnerLoader/SpinnerLoader";
import Swal from "sweetalert2/dist/sweetalert2.js";
class MapSpinner extends Component {
  state = {
    loading: false,
  };
  componentDidMount() {
    if (this.props.callBackSpinner !== undefined) {
      this.props.callBackSpinner(this.setLoadingHandler);
    } else {
      this.setState({ loading: false }, () => {
        document.getElementsByClassName("gm-style")[0].style.opacity = "1";
      });
    }
  }
  setLoadingHandler = (callback, err = '') => {
    this.setState({ loading: !this.state.loading }, () => {
      callback();
      if (this.state.loading)
        document.getElementsByClassName("gm-style")[0].style.opacity = "0.6";
      else document.getElementsByClassName("gm-style")[0].style.opacity = "1";
      if (err != '') {
        this.fireSwalHandler("Warning", err, "warning");
      }
    });
  };
  fireSwalHandler = (title, text, type) => {
    Swal.fire({
      title: title,
      text: text,
      type: type,
      confirmButtonText: "Done",
    }).then((results) => {
      
    });
  };
  render() {
    return (
      <div
        id="map-spinner"
        className={styles.MapSpinners}
        style={{
          opacity: this.state.loading ? 0.6 : 1,
          display: this.state.loading ? "block" : "none",
        }}
      >
        <SpinnerLoader />
      </div>
    );
  }
}

export default MapSpinner;
