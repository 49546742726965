import React, { Component } from "react";
import Header from "../Header/Header";
import { Layouts, DashBoard, SpinnerWrapper } from "./Layout.module.scss";
import { connect } from "react-redux";
import Sidebar from "../SideBar/SideBar";
import Model from "../../Componnet/Model/Model";
import SpinnerLoader from "../../Componnet/SpinnerLoader/SpinnerLoader";
import UploadHistory from "../../Componnet/Model/Models/UploadHistory/UploadHistory";
import RefreshCache from "../../Componnet/RefreshCache/RefreshCache";
const CreateUpdateHub = React.lazy(() =>
  import("../../Componnet/Model/Models/CreateUpdateHub/CreateUpdateHub")
);
const AdvancedChart = React.lazy(() =>
  import("../../Componnet/Model/Models/AdvancedChart/AdvancedChart")
);
const Invokes = React.lazy(() =>
  import("../../Componnet/Model/Models/Invokes/Invokes")
);
const CreatePool = React.lazy(() =>
  import("../../Componnet/Model/Models/CreatePool/CreatePool")
);
const SubPool = React.lazy(() =>
  import("../../Componnet/Model/Models/SubPool/SubPool")
);
const UpdatesConfig = React.lazy(() =>
  import("../../Componnet/Model/Models/UpdatesConfig/UpdatesConfig")
);
const ViewTelemetry = React.lazy(() =>
  import("../../Componnet/Model/Models/ViewTelemetry/ViewTelemetry")
);
const MultiplePlanActions = React.lazy(() =>
  import("../../Componnet/Model/Models/MultiplePlanActions/MultiplePlanActions")
);
const EditUser = React.lazy(() =>
  import("../../Componnet/Model/Models/EditUser/EditUser")
);
const ViewTerminal = React.lazy(() =>
  import("../../Componnet/Model/Models/ViewTerminal/ViewTerminal")
);
const TerminalDump=React.lazy(()=> 
import("../../Componnet/Model/Models/TerminalDump/TerminalDump"))
const ViewUpdate = React.lazy(() =>
  import("../../Componnet/Model/Models/ViewUpdate/ViewUpdate")
);
const ViewTemplate = React.lazy(() =>
  import("../../Componnet/Model/Models/ViewTemplate/ViewTemplate")
);
const AssignDevice = React.lazy(() =>
  import("../../Componnet/Model/Models/AssignDevice/AssignDevice")
);
const UpdateTerminal = React.lazy(() =>
  import("../../Componnet/Model/Models/UpdateTerminal/UpdateTerminal")
);
const TerminalNetworkInfo = React.lazy(() =>
  import("../../Componnet/Model/Models/TerminalNetworkInfo/TerminalNetworkInfo")
);
const Calender = React.lazy(() =>
  import("../../Componnet/Model/Models/Calender/Calender")
);
const Channel = React.lazy(() =>
  import("../../Componnet/Model/Models/Channel/Channel")
);
const CreateAccount = React.lazy(() =>
  import("../../Componnet/Model/Models/CreateAccount/CreateAccount")
);
const CreateNewSystemLog = React.lazy(() =>
  import("../../Componnet/Model/Models/CreateNewSystemLog/CreateNewSystemLog")
);
const CreateNewTemplate = React.lazy(() =>
  import("../../Componnet/Model/Models/CreateNewTemplate/CreateNewTemplate")
);
const AdditionDataUsage = React.lazy(() =>
  import("../../Componnet/Model/Models/AdditionDataUsage/AdditionDataUsage")
);
const Timeline = React.lazy(() =>
  import("../../Componnet/Model/Models/Timeline/Timeline")
);
const AssignAccountDevice = React.lazy(() =>
  import("../../Componnet/Model/Models/AssignAccountDevice/AssignAccountDevice")
);
const PlanSubscriber = React.lazy(() =>
  import("../../Componnet/Model/Models/PlanSubscriber/PlanSubscriber")
);
const AssignDeviceToTech = React.lazy(() =>
  import("../../Componnet/Model/Models/AssignDeviceToTech/AssignDeviceToTech")
);
const AssignSubscribers = React.lazy(() =>
  import("../../Componnet/Model/Models/AssignSubscribers/AssignSubscribers")
);
const AccountPermissions = React.lazy(() =>
  import("../../Componnet/Model/Models/AccountPermissions/AccountPermissions")
);
const CurrentTerminalsStatus = React.lazy(() =>
  import("../../Componnet/Model/Models/CurrentTerminalsStatus/CurrentTerminalsStatus")
);
const CreateUpdateService = React.lazy(() =>
  import("../../Componnet/Model/Models/CreateUpdateService/CreateUpdateService")
);
const Site = React.lazy(() => import("../../Componnet/Model/Models/Site/Site"));
const CreateNewTerminal = React.lazy(() =>
  import("../../Componnet/Model/Models/CreateNewTerminal/CreateNewTerminal")
);
const EditAccount = React.lazy(() =>
  import("../../Componnet/Model/Models/EditAccount/EditAccount")
);
const CreateUpdate = React.lazy(() =>
  import("../../Componnet/Model/Models/CreateUpdate/CreateUpdate")
);
const UpdatesAllNotes = React.lazy(() =>
  import("../../Componnet/Model/Models/UpdatesAllNotes/UpdatesAllNotes")
);
const JsonDiffChecker = React.lazy(() =>
  import("../../Componnet/Model/Models/JsonDiffChecker/JsonDiffChecker")
);
const SystemVersion = React.lazy(() =>
  import("../../Componnet/Model/Models/SystemVersion/SystemVersion")
)
const AssignPlanToDevices = React.lazy(() =>
  import("../../Componnet/Model/Models/AssignPlanToDevices/AssignPlanToDevices")
);
const AssignDeviceToSite = React.lazy(() =>
  import("../../Componnet/Model/Models/AssignDeviceToSite/AssignDeviceToSite")
);

const ViewAssignDeviceToPlan = React.lazy(() =>
  import(
    "../../Componnet/Model/Models/ViewAssignDeviceToPlan/ViewAssignDeviceToPlan"
  )
);
const ViewResetTelemetry=React.lazy(()=>
  import ("../../Componnet/Model/Models/ResetTelemetry/ResetTelemetry")
)
const UploadCsv = React.lazy(() =>
  import("../../Componnet/Model/Models/UploadCsv/UploadCsv")
);
const HubMoreInfo = React.lazy(() =>
  import("../../Componnet/Model/Models/HubMoreInfo/HubMoreInfo")
);

const DetachDevicePlan = React.lazy(() =>
  import("../../Componnet/Model/Models/DetachDevicePlan/DetachDevicePlan")
);
const ActivateDevicePlan = React.lazy(() =>
  import("../../Componnet/Model/Models/ActivateDevicePlan/ActivateDevicePlan")
);
const CreateFakeUsage = React.lazy(() =>
  import("../../Componnet/Model/Models/CreateFakeUsage/CreateFakeUsage")
);
const UsageIot = React.lazy(() =>
  import("../../Componnet/Model/Models/UsageIot/UsageIot")
);
const TerminalConfiguration = React.lazy(() =>
  import(
    "../../Componnet/Model/Models/TerminalConfiguration/TerminalConfiguration"
  )
);
const UpdatePolygon = React.lazy(() =>
  import("../../Componnet/Model/Models/UpdatePolygon/UpdatePolygon")
);

const ViewSiteInfo = React.lazy(() =>
  import("../../Componnet/Model/Models/ViewSiteInfo/ViewSiteInfo")
);

class Layout extends Component {
  _showUpdateVersion = false;
  state = { autoVisible: false };
  closeSearchHeaderHandler = () => {
    if (this.state.autoVisible) {
      this.setState({ autoVisible: false });
    }
  };
  openAutoCompleteHandler = (open) => {
    this.setState({ autoVisible: open });
  };

  render() {

    return (
      <div>
        <Model visible={this.props.openModel}>
          <React.Suspense
            fallback={
              <div className={SpinnerWrapper}>
                <SpinnerLoader />
              </div>
            }
          >
            {this.props.hubRx ? (
              <CreateUpdateHub visible={this.props.hubRx} />
            ) : null}
           
            {this.props.hubMoreInfo ? (
              <HubMoreInfo visible={this.props.hubMoreInfo} />
            ) : null}
            {this.props.newTerminal ? <CreateNewTerminal /> : null}
            {this.props.assignDevice ? (
              <AssignDevice visible={this.props.assignDevice} />
            ) : null}
            {this.props.updateTerminal ? (
              <UpdateTerminal visible={this.props.updateTerminal} />
            ) : null}
            {this.props.terminalNetworkInfo ? (
              <TerminalNetworkInfo visible={this.props.terminalNetworkInfo} />
            ) : null}
            {this.props.calenderPicker ? (
              <Calender visible={this.props.calenderPicker} />
            ) : null}
            {this.props.channelRx ? <Channel /> : null}
            {this.props.createNewSystemLog ? (
              <CreateNewSystemLog visible={this.props.createNewSystemLog} />
            ) : null}
            {this.props.additionDataUsage ? <AdditionDataUsage /> : null}
            {this.props.createNewPlan ? <CreateNewTemplate /> : null}
            {this.props.serviceRx ? <CreateUpdateService /> : null}
            {/* {this.props.createNewService ? <CreateNewService /> : null} */}
            {this.props.createAccount ? <CreateAccount /> : null}
            {this.props.editPlan ? <ViewTemplate /> : null}           
            {this.props.timeLine ? <Timeline /> : null}
            {this.props.assignAccountDevice ? <AssignAccountDevice /> : null}
            {this.props.planSubscriber ? <PlanSubscriber /> : null}
            {this.props.assignDeviceToTech ? <AssignDeviceToTech /> : null}
            {this.props.assignSubscriber ? <AssignSubscribers /> : null}
            {this.props.accountPermmisions ? <AccountPermissions /> : null}
            {this.props.siteRx ? <Site /> : null}
            {this.props.editAccount ? <EditAccount /> : null}
            {this.props.createUpdate ? <CreateUpdate /> : null}
            {this.props.updatesAllNotes ? <UpdatesAllNotes /> : null}
            {this.props.jsonDiffChecker ? <JsonDiffChecker /> : null}
            {this.props.systemVersionControl ? <SystemVersion /> : null}
            {this.props.assignDeviceToSiteRx ? <AssignDeviceToSite /> : null}
            {this.props.viewAssignDeviceToPlanRx ? (
              <ViewAssignDeviceToPlan />
            ) : null}
            {this.props.currentTerminalRx?<CurrentTerminalsStatus/>:null}
            {this.props.resetTelemetryRx ?<ViewResetTelemetry/>:null}
            {this.props.assignPlanToDevicesRx ? <AssignPlanToDevices /> : null}
            {this.props.uploadCsvRx ? <UploadCsv /> : null}
            {this.props.uploadHistoryRx ? <UploadHistory /> : null}
            {this.props.detachPlanRx ? <DetachDevicePlan /> : null}
            {this.props.activatePlanRx ? <ActivateDevicePlan /> : null}
            {this.props.createFakeUsageRx ? <CreateFakeUsage /> : null}
            {this.props.viewUpdateRx ? <ViewUpdate /> : null}
            {this.props.viewTerminalRx ? <ViewTerminal /> : null}
            {this.props.usageIotRx ? <UsageIot /> : null}
            {this.props.terminalConfigRx ? <TerminalConfiguration /> : null}
            {this.props.multiplePlanActionsRx ? <MultiplePlanActions /> : null}
            {this.props.editUserRx ? <EditUser /> : null}
            {this.props.viewTelemetryRx ? <ViewTelemetry /> : null }
            {this.props.editUpdateConfigRx ? <UpdatesConfig/> : null }
            {this.props.updatePolygonsRX ? <UpdatePolygon/> :null}
           
            {this.props.viewSiteInfoRx ? (
              <ViewSiteInfo />
            ) : null}
            
            {this.props.viewRefreshCacheRx ? <RefreshCache setAuthuntication={this.props.setAuthuntication}/> : null}
            {this.props.invokesRx ? <Invokes/> : null}
            {this.props.createPoolRx ? <CreatePool/> : null}
            {this.props.subPoolRx ? <SubPool/> : null}
            {this.props.viewAdvancedChartRx ? <AdvancedChart/> : null}
            {this.props.termainlDumpRx ?<TerminalDump/> :null}
          </React.Suspense>
        </Model>
      
        <div className={Layouts}>
          <Sidebar clickAuto={this.closeSearchHeaderHandler} />
          <main>
            <Header
              autoVisible={this.state.autoVisible}
              open={this.openAutoCompleteHandler}
              clickMenu={this.onClickMenuBar}
              userProfile={this.props.userProfile}
              setAuthuntication={this.props.setAuthuntication}
            />
            <div className={DashBoard} onClick={this.closeSearchHeaderHandler}>
              {this.props.children}
            </div>
          </main>
        </div>
      </div>
    );
  }
}
const mapStateHandler = (state) => {
  return {
    userProfile: state.account.userProfile,
    channelRx: state.model.channelRx,
    openModel: state.model.model,
    assignDevice: state.model.assignDevice,
    resetTelemetryRx:state.model.resetTelemetryRx,
    hubMoreInfo: state.model.hubMoreInfo,
    updateTerminal: state.model.updateTerminal,
    terminalNetworkInfo: state.model.terminalNetworkInfo,
    calenderPicker: state.model.calenderPicker,
    createNewSystemLog: state.model.createNewSystemLog,
    createLogType: state.model.createLogType,
    additionDataUsage: state.model.additionalDataUSage,
    createNewPlan: state.model.createNewPlan,
    createAccount: state.model.createAccount,
    editPlan: state.model.editPlan,
    timeLine: state.model.timeLine,
    assignAccountDevice: state.model.assignAccountDevice,
    planSubscriber: state.model.planSubscriber,
    assignDeviceToTech: state.model.assignDeviceToTech,
    assignSubscriber: state.model.assignSubscriber,
    accountPermmisions: state.model.accountPermmisions,
    currentTerminalRx: state.model.currentTerminalRx,
    serviceRx: state.model.serviceRx,
    siteRx: state.model.siteRx,
    newTerminal: state.model.newTerminal,
    newHbs: state.model.newHbs,
    editAccount: state.model.editAccount,
    createUpdate: state.model.createUpdate,
    updatesAllNotes:state.model.updatesAllNotes,
    jsonDiffChecker:state.model.jsonDiffChecker,
    systemVersionControl:state.model.systemVersionControl,
    assignDeviceToSiteRx: state.model.assignDeviceToSiteRx,
    viewAssignDeviceToPlanRx: state.model.viewAssignDeviceToPlanRx,
    assignPlanToDevicesRx: state.model.assignPlanToDevicesRx,
    uploadCsvRx: state.model.uploadCsvRx,
    uploadHistoryRx: state.model.uploadHistoryRx,
    detachPlanRx: state.model.detachPlanRx,
    activatePlanRx: state.model.activatePlanRx,
    createFakeUsageRx: state.model.createFakeUsageRx,
    viewUpdateRx: state.model.viewUpdateRx,
    viewTerminalRx: state.model.viewTerminalRx,
    usageIotRx: state.model.usageIotRx,
    terminalConfigRx: state.model.terminalConfigRx,
    editUserRx:state.model.editUserRx,
    multiplePlanActionsRx:state.model.multiplePlanActionsRx,
    termainlDumpRx:state.model.termainlDumpRx,
    viewTelemetryRx:state.model.viewTelemetryRx,
    editUpdateConfigRx:state.model.editUpdateConfigRx,
    updatePolygonsRX:state.model.updatePolygonsRX,
    viewSiteInfoRx: state.model.viewSiteInfoRx,
    viewRefreshCacheRx: state.model.viewRefreshCacheRx,
    createPoolRx:state.model.createPoolRx,
    subPoolRx:state.model.subPoolRx,
    invokesRx:state.model.invokesRx,
    viewAdvancedChartRx:state.model.viewAdvancedChartRx,
    hubRx:state.model.hubRx

  };
};

const mapStateDispatch = (dispatch) => {
  return {};
};
export default connect(mapStateHandler, mapStateDispatch)(Layout);
