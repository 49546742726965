import React, { Component } from "react";
import styles from "./DashBoard.module.scss";
import ChartData from "./ChartData/ChartData";
import AccountAndUsers from "./AccountAndUsers/AccountAndUsers";
import DataUsageChart from "../../Componnet/DataUsageChart/DataUsageChart";
import LogsAndAlerts from "./LogsAndAlerts/LogsAndAlerts";
import Logs from "./Logs/Logs";
import Alerts from "./Alerts/Alerts";
import {
  getAccountId,
  getEntityName,
} from "../../Store/localStoreageManager/localStoreageManager";
import { connect } from "react-redux";
import { http } from "../../RequestManager/Http";
import {
  getAllDashboardInfoTechnicianAndSubscriber,
  getAllDashboardInfo,
  getBeamsAndHubs,
  getAllDashboardInfoHisky,
} from "../../RequestManager/QueriesAndMutations";
import {
  HUB,
  TERMINAL,
  MOCKUP,
  RESELLER_ENTITY,
  HISKY_ENTITY,
  COMPANY_ENTITY,
  SUBSCRIBER_ENTITY,
  TECHNICIAN_ENTITY,
  BOTH,
  IOT,
} from "../../Util/constTypes";
import SpinnerLoader from "../../Componnet/SpinnerLoader/SpinnerLoader";
import Icon from "../../Componnet/Icon/Icon";
import * as route from "../../Util/routes";
import { INTITAL_OFFSET, PAGE_LIMIT } from "../../Util/constants";
import * as actionType from "../../Store/actions/index";
import GoogleMapWrapper from "../../Componnet/Google/GoogleMapWrapper/GoogleMapWrapper";
import {
  claculateTypeTerminalHandler,
  checkVersionApp,
  getTimeZone,
} from "../../Util/globalFunc";

class DashBoard extends Component {
  _isMounted = true;
  state = {
    hubs: "",
    staticTerminal: "",
    loading: true,
    online: "",
    offline: "",
    dynamicTerminal: "",
    loadingHub: true,
    loadingTerminal: true,
    dataUsage: [],
    logsData: [],
    beams: [],
    companyData: [],
    systemLogs: [],
    minDate: "",
    maxDate: "",
    minVoip: "",
    maxVoip: "",
    runTime: "",
    loadingChart: false,
    hubMarkers: [],
    terminalMarkers: [],
    usageVoip: [],
    secondaryLoading: false,
  };

  componentDidMount() {
    this._isMounted = true;
    this.createHttpRequestHandler();
  }

  setLogsAndAccountsData = (logs, accounts) => {
    if (this._isMounted) {
      this.setState({ companyData: accounts, logsData: logs });
    }
  };
  componentWillUnmount() {
    this._isMounted = false;
  }
  conveortDataHandler = (str) => {
    return str.length > 10 ? str.substring(0, 10) + "..." : str;
  };
  createHttpRequestHandler = () => {
    let id = parseInt(getAccountId());
    switch (getEntityName()) {
      case HISKY_ENTITY:
      case RESELLER_ENTITY:
      case COMPANY_ENTITY:
        this.createHttpRequestForHISKY(id);
        break;
      case SUBSCRIBER_ENTITY:
      case TECHNICIAN_ENTITY:
        this.createHttpRequestForTECHNICIANandSUBSCRIBER(id);
      default:
        break;
    }
  };
  createHttpRequestForTECHNICIANandSUBSCRIBER = (id) => {
    http(getAllDashboardInfoTechnicianAndSubscriber, {
      account_id: id,
      display: "all",
      offset: INTITAL_OFFSET,
      limit: PAGE_LIMIT,
      prefix: "last_activity",
      usage_account_id: parseInt(getAccountId()),
      date_from: "",
      date_to: "",
      log_type: 5,
      //netType: IOT, //BOTH
      includeIot: true,
      localTz: getTimeZone(),
    })
      .then((res) => {
        if (checkVersionApp(res.data.systemVersion)) {
          this.setTechnicianOrSubscriberDataHandler(res);
        } else {
          this.props.openRefreshCacheSelector();
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  createHttpRequestForHISKY = (id) => {
    /////////////////////need to add the company and user call ///////////////////////////////////////
    let queryType = getAllDashboardInfo;
    let params = {
      account_id: id,
      display: "all",
      offset: 1,
      limit: 10,
      prefix: "last_activity",
      usage_account_id: parseInt(getAccountId()),
      date_from: "",
      date_to: "",
      hub_id: null,
      //netType: IOT, //BOTH,
      includeIot: true,
      localTz: getTimeZone(),
    };
    if (getEntityName() == HISKY_ENTITY) {
      queryType = getAllDashboardInfoHisky;
      params.activeOnly = true;
    }
    http(queryType, params)
      .then((res) => {
        if (checkVersionApp(res.data.systemVersion)) {
          this.setDataHandler(res);
        } else if (res.errors !== undefined) {
          window.location.reload(true);
        } else {
          this.props.openRefreshCacheSelector();
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  updateSystemLogsHandler = (data) => {
    this.setState({ systemLogs: data, loading: false });
  };
  setTechnicianOrSubscriberDataHandler = (res) => {
    let offlineCounts =
      res.data.accountTerminalTypesCounts.offlineCounts != null
        ? res.data.accountTerminalTypesCounts.offlineCounts
        : null;
    let onlineCounts =
      res.data.accountTerminalTypesCounts.onlineCounts != null
        ? res.data.accountTerminalTypesCounts.onlineCounts
        : null;
    this.setState(
      {
        loadingHub: false,
        logsData: res.data.userLog,
        onlineArr: res.data.onlineTerminals,
        offlineArr: res.data.offlineTerminals,
        dataUsage: res.data.usageDataV2.usage,
        loadingChart: true,
        minDate: res.data.usageDataV2.minRepTime,
        maxDate: res.data.usageDataV2.maxRepTime,
        minVoip: res.data.usageDataV2.minRepTimeVoip,
        maxVoip: res.data.usageDataV2.maxRepTimeVoip,
        runTime: res.data.usageDataV2.runTime,
        staticTerminal:
          (offlineCounts != null
            ? offlineCounts.fixedLrCount + offlineCounts.fixedRlCount
            : 0) +
          (onlineCounts != null
            ? onlineCounts.fixedLrCount + onlineCounts.fixedRlCount
            : 0),
        dynamicTerminal:
          (offlineCounts != null ? offlineCounts.dynamicCount : 0) +
          (onlineCounts != null ? onlineCounts.dynamicCount : 0),
        loadingTerminal: false,
        terminalMarkers: [
          ...res.data.onlineTerminals,
          ...res.data.offlineTerminals,
        ],
        // mockupMarkers: [],
        online:
          res.data.onlineTerminals != null
            ? res.data.onlineTerminals.length
            : 0,
        offline:
          res.data.offlineTerminals != null
            ? res.data.offlineTerminals.length
            : 0,
        systemLogs: res.data.systemLog != undefined ? res.data.systemLog : [],
        loading: false,
        usageVoip: res.data.usageDataV2.usageVoip,
      },
      () => {}
    );
  };

  resetCountOfTermianlHandler = (staticTerminalCount, dynamicTerminalCount) => {
    this.setState({
      staticTerminal: staticTerminalCount,
      dynamicTerminal: dynamicTerminalCount,
    });
  };
  setDataHandler = (res) => {
    let offlineCounts =
      res.data.accountTerminalTypesCounts.offlineCounts != null
        ? res.data.accountTerminalTypesCounts.offlineCounts
        : null;
    let onlineCounts =
      res.data.accountTerminalTypesCounts.onlineCounts != null
        ? res.data.accountTerminalTypesCounts.onlineCounts
        : null;
    if (this._isMounted) {
      this.setState(
        {
          online:
            res.data.onlineTerminals != null
              ? res.data.onlineTerminals.length
              : 0,
          offline:
            res.data.offlineTerminals != null
              ? res.data.offlineTerminals.length
              : 0,
          onlineArr: res.data.onlineTerminals, //online.termianls,
          offlineArr: res.data.offlineTerminals, //offline.termianls,
          hubs: res.data.totalHbs != undefined ? res.data.totalHbs : 0,
          loadingHub: false,
          companyData: res.data.firstAccount,
          logsData: res.data.systemLog,
          //beams: res.data.allBeams,
          dataUsage: res.data.usageDataV2.usage,
          loadingChart: true,
          minDate: res.data.usageDataV2.minRepTime,
          maxDate: res.data.usageDataV2.maxRepTime,
          minVoip: res.data.usageDataV2.minRepTimeVoip,
          maxVoip: res.data.usageDataV2.maxRepTimeVoip,
          runTime: res.data.usageDataV2.runTime,
          staticTerminal:
            (offlineCounts != null
              ? offlineCounts.fixedLrCount + offlineCounts.fixedRlCount
              : 0) +
            (onlineCounts != null
              ? onlineCounts.fixedLrCount + onlineCounts.fixedRlCount
              : 0),
          //online.staticTerminalCount + offline.staticTerminalCount,
          dynamicTerminal:
            (offlineCounts != null ? offlineCounts.dynamicCount : 0) +
            (onlineCounts != null ? onlineCounts.dynamicCount : 0),
          //offline.dynamicTerminalCount + online.dynamicTerminalCount,
          loadingTerminal: false,
          loading: false,
          // mockupMarkers: res.data.mockupTerminal,
          hubMarkers: res.data.hbs != undefined ? res.data.hbs : [],
          terminalMarkers: [
            ...res.data.onlineTerminals,
            ...res.data.offlineTerminals,
          ],
          usageVoip: res.data.usageDataV2.usageVoip,
        },
        () => {}
      );
    }
  };
  onClickWindowInfoHandler = (deviceObj) => {
    ///////////////////need to have the query of the TERMINAL finish to get the last push /////////////////////
    if (deviceObj.type === HUB) {
      this.props.history.push(`${route.DEVICES_HUB}/${deviceObj.id}`);
    } else if (deviceObj.type === TERMINAL) {
      this.props.history.push(
        `${route.DEVICES_TERMINAL}/${deviceObj.id}:${deviceObj.hiskyId}`
      );
    }
  };

  getDougnutsObjectHandler = () => {
    return {
      labels: ["Total"],
      datasets: [
        {
          borderWidth: 0,
          data: [100],
          backgroundColor: ["#eae9e9"],
          hoverBackgroundColor: ["#00BDD6", "#E5E5E5"],
          radius: "30%",
        },
      ],
      text: "",
    };
  };
  getDougnutsData = (type) => {
    if (this.state[type] === "") {
      return this.getDougnutsObjectHandler();
    } else {
      let data_s = this.getDougnutsObjectHandler();
      data_s.text = this.state[type];
      data_s.datasets[0].backgroundColor = ["#00BDD6", "#E5E5E5"];
      return data_s;
    }
  };
  middleRowBuilder = () => {
    switch (getEntityName()) {
      case HISKY_ENTITY:
      case RESELLER_ENTITY:
      case COMPANY_ENTITY:
        return (
          <div className={styles.midRow}>
            <ChartData
              icon={
                <Icon
                  defination={{ prefix: "fal", iconName: "tablet-rugged" }}
                />
              }
              headerTitle="HBS"
              loading={this.state.loadingHub}
              chartData={this.getDougnutsData("hubs")}
            />
            <ChartData
              icon={
                <Icon
                  defination={{ prefix: "far", iconName: "tablet-rugged" }}
                />
              }
              chartData={this.getDougnutsData("staticTerminal")}
              headerTitle="Fixed Terminals"
              loading={this.state.loadingTerminal}
            />
            <ChartData
              icon={
                <Icon
                  defination={{ prefix: "far", iconName: "tablet-rugged" }}
                />
              }
              chartData={this.getDougnutsData("dynamicTerminal")}
              headerTitle="Dynamic Terminals"
              loading={this.state.loadingTerminal}
            />
          </div>
        );
    }
  };
  bottomRowBuilder = () => {
    switch (getEntityName()) {
      case HISKY_ENTITY:
      case RESELLER_ENTITY:
      case COMPANY_ENTITY:
        return (
          <div className={styles.bottomRow}>
            <AccountAndUsers data={this.state.companyData} />
            <div className={styles.dashBoardChartWrapper}>
              <DataUsageChart
                data={this.state.dataUsage}
                userId={getAccountId()}
                min={this.state.minDate}
                max={this.state.maxDate}
                minVoip={this.state.minVoip}
                maxVoip={this.state.maxVoip}
                runTime={this.state.runTime}
                hideTooltip={true}
                loading={this.state.loading}
                hideUsageIcon={false}
                usageVoip={this.state.usageVoip}
                // /headerStyles={{ marginRight: "5.2%" }}
              />
            </div>
            <LogsAndAlerts data={this.state.logsData} />
          </div>
        );
      case SUBSCRIBER_ENTITY:
      case TECHNICIAN_ENTITY:
        return (
          <div className={styles.bottomRow}>
            <Logs data={this.state.logsData} />
            <div className={styles.dashBoardChartWrapper}>
              <DataUsageChart
                data={this.state.dataUsage}
                userId={getAccountId()}
                min={this.state.minDate}
                max={this.state.maxDate}
                minVoip={this.state.minVoip}
                maxVoip={this.state.maxVoip}
                runTime={this.state.runTime}
                loading={this.state.loading}
                hideTooltip={true}
                usageVoip={this.state.usageVoip}
              />
            </div>
            <Alerts data={this.state.systemLogs} />
          </div>
        );
    }
  };
  checkLoadingHandler = () => {
    return this.state.loading;
  };
  checkForPolygonsHandler = () => {
    return this.state.beams.length;
  };
  gotoUpdatesHandler = () => {
    this.props.history.push(route.UPDATES);
  };
  setLoaderHandler = () => {
    this.setState({ loading: !this.state.loading });
  };
  loadHubAndBeamsHandler = (refFunc) => {
    this.setState({ secondaryLoading: true }, () => {
      http(getBeamsAndHubs, {
        account_id: parseInt(getAccountId()),
        activeOnly: true,
      })
        .then((res) => {
          this.setState(
            {
              hubs: res.data.hbs !== null ? res.data.hbs.length : 0,
              secondaryLoading: false,
              beams: res.data.allBeams,
              hubMarkers: res.data.hbs,
              hubFilters: res.data.hbs,
            },
            () => {
              refFunc();
            }
          );
        })
        .catch((err) => {
          this.setState({ secondaryLoading: false });
        });
    });
  };
  render() {
    return (
      <div className={styles.DashBoards}>
        <div className={styles.topRow}>
          <div className={styles.header}>
            <h3 className={styles.title}>Coverage Map & Devices</h3>
            <span className={styles.iconCompass}>
              <Icon defination={{ prefix: "fal", iconName: "globe-asia" }} />
            </span>
          </div>
          <div className={styles.breakLineTitle} />
          <div
            className={styles.mapWrapper}
            style={{
              opacity:
                this.state.loading || this.state.secondaryLoading ? 0.6 : 1,
            }}
          >
            <GoogleMapWrapper
              polygon={this.state.loading ? [] : this.state.beams}
              // mockupMarkers={this.state.loading ? [] : this.state.mockupMarkers}
              onClickWindowInfoHandler={this.onClickWindowInfoHandler}
              online={this.state.online}
              offlineArr={this.state.offlineArr}
              onlineArr={this.state.onlineArr}
              offline={this.state.offline}
              showRecenter={true}
              loading={this.state.loading}
              checkCountTerminal={this.resetCountOfTermianlHandler}
              hubMarkers={this.state.hubMarkers}
              terminalMarkers={
                this.state.loading ? [] : this.state.terminalMarkers
              }
              checkForLoading={this.checkLoadingHandler}
              checkForPolygons={this.checkForPolygonsHandler}
              gotoUpdates={this.gotoUpdatesHandler}
              setLoaderHandler={this.setLoaderHandler}
              hubFilters={this.state.hubMarkers}
              loadHubAndBeams={this.loadHubAndBeamsHandler}
              totalHbs={this.state.hubs}
            />
            <div
              className={styles.spinnerWrapperMap}
              style={{
                display:
                  this.state.loading || this.state.secondaryLoading
                    ? "block"
                    : "none",
              }}
            >
              <SpinnerLoader />
            </div>
          </div>
        </div>
        {this.middleRowBuilder()}
        {this.bottomRowBuilder()}
      </div>
    );
  }
}
const mapStateProps = (state) => {
  return {};
};
const mapStateDispatch = (dispatch) => {
  return {
    openRefreshCacheSelector: () =>
      dispatch(actionType.openRefreshCacheSelector()),
  };
};
export default connect(mapStateProps, mapStateDispatch)(DashBoard);
