import React, { Component } from "react";
import BackDrop from "./BackDrop/BackDrop";

import styles from "./Model.module.scss";
class Model extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.visible) {
      document.body.classList.add("noScrollBody");
    } else {
      document.body.classList.remove("noScrollBody");
    }
    return (
      nextProps.visible !== this.props.visible ||
      nextProps.children !== this.props.children
    );
  }
  closeModelHandler = () => {};
  render() {
    let visibilityState = this.props.visible ? "block" : "none";
    return (
      <div style={{ display: visibilityState }}>
        <BackDrop
          visible={this.props.visible}
          modalClosed={this.closeModelHandler}
        />
        <div
          className={styles.Model}
          style={{
            transform: this.props.visible
              ? "translateY(0)"
              : "translateY(-100vh)",
            opacity: this.props.visible ? "1" : "0"
          }}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Model;
