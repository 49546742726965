import * as actionType from "../actions/types";
import { updateObject } from "./utilReducer";

const initialState = {
  date: "",
  typeOfDate: "",
  planHistory: [],
  resetComponentRx: false,
  resetInnerComponnetRx: "",
  reRenderRx: "",
  actionTypeRx: "",
  reRenderSidebarRx:false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_DATE:
      return updateObject(state, { date: action.payload });
    case actionType.END_DATE:
      return updateObject(state, { endDate: action.payload });
    case actionType.DATE_TYPE:
      return updateObject(state, { typeOfDate: action.payload });
    case actionType.SET_PLAN_HISTORY:
      return updateObject(state, { planHistory: action.payload });
    case actionType.RESET_COMPONENT:
      return updateObject(state, { resetComponentRx: action.payload });
    case actionType.RESET_INNER_COMPONENT:
      return updateObject(state, { resetInnerComponnetRx: action.payload });
    case actionType.RE_RENDER_COMPONENT:
      return updateObject(state, { reRenderRx: action.payload });
    case actionType.SET_ROLES:
      return updateObject(state, { rolesRx: action.payload });
    case actionType.SET_ACTION_TYPE:
      return updateObject(state, { actionTypeRx: action.payload }); 
      case actionType.RE_RENDER_SIDE_BAR: 
      return updateObject(state, { reRenderSidebarRx: action.payload });  
    default:
      return state;
  }
};

export default reducer;
