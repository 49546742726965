import React, { Component } from "react";
import SpinnerLoader from "../../../Componnet/SpinnerLoader/SpinnerLoader";
import styles from "./Logs.module.scss";
import Icon from "../../../Componnet/Icon/Icon";
import moment from "moment";
import { trimString, getEmptyRows } from "../../../Util/globalFunc";
import {
  INDEX,
  LOG_TYPE,
  GUID,
  DATE_AND_TIME,
  ID,
} from "../../../Util/constTypes";
export default class Logs extends Component {
  state = {
    data: [],
    loading: true,
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.data.length > this.state.data) {
      this.setState({ data: nextProps.data, loading: false });
    } else if (nextProps.data.length === 0) {
      this.setState({ loading: false });
    }
  }
  componentDidMount() {
    this.setState({ data: this.props.data });
  }

  convertDateHandler = (date) => {
    return moment(date).format("MM/DD/YYYY HH:MM:SS");
  };
  buildTableHeader = () => {
    let arrHeader = [ID, LOG_TYPE, DATE_AND_TIME];
    return arrHeader.map((item, index) => {
      return (
        <div key={index} className={styles.textCell}>
          <strong>{item}</strong>
        </div>
      );
    });
  };
  buildTableRows = () => {
    if (this.state.data.length === 0) {
      return getEmptyRows(styles.tableRow, 8);
    } else {
      return this.state.data.map((item, index) => {
        if (item == null) return;
        return (
          <div key={index} className={styles.tableRow}>
            <div className={styles.textCell}>{index + 1}</div>
            <div className={styles.textCell}>{item.logType}</div>

            <div className={styles.textCell}>
              {this.convertDateHandler(item.createDate)}
            </div>
          </div>
        );
      });
    }
  };
  render() {
    return (
      <div
        className={styles.Log}
        style={{ opacity: this.state.loading ? 0.5 : 1 }}
      >
        <div className={styles.header}>
          <h3 className={styles.title}>Logs</h3>
          <span className={styles.icon}>
            {/* <i className="far fa-clipboard-list"></i> */}
            <Icon defination={{ prefix: "fas", iconName: "clipboard" }} />
          </span>
        </div>
        <div className={styles.breakLineTitle} />
        <div className={styles.tableRow}>{this.buildTableHeader()}</div>
        <div className={styles.tableDataWrapper}>{this.buildTableRows()}</div>
        <div
          className={styles.spinnerWrapper}
          style={{ display: this.state.loading ? "block" : "none" }}
        >
          <SpinnerLoader />
        </div>
      </div>
    );
  }
}
