import React, { Component } from 'react'
import styles from "./Spinner.module.scss"
import LOGO from "../../assets/hiSkyLogoAnimated.gif"
export default class Spinner extends Component {
    render() {
        return (
            <div className={styles.Spin}>
                <div className={styles.InfoWrapper}>
                    <div className={styles.imgWrapper}>
                        <img src={LOGO} alt="logo-hiSky" className={styles.img}/>
                        <p className={styles.loadingMsg}>Loading .......</p></div>
                    </div>
                    
                  
            </div>
        )
    }
}
