import { combineReducers } from "redux";
import deviceReducer from "./Store/reducer/devices";
import modelController from "./Store/reducer/modelController";
import AccountsReducer from "./Store/reducer/accounts";
import CommonReducer from "./Store/reducer/common";
export default combineReducers({
  devices: deviceReducer,
  model: modelController,
  account: AccountsReducer,  
  common: CommonReducer
});
