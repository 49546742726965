import React, { Component } from "react";
import styles from "./DrawingInfoWindow.module.scss";
import Icon from "../../../Componnet/Icon/Icon";

class DrawingInfoWindow extends Component {
  isMouted = true;
  state = { loading: true, count: 0 };
  componentWillUnmount() {
    this.isMouted = false;
  }
  componentDidMount() {
    let list = this.props.markers
      .filter((item) => item.terminal != undefined)
      .map((item) => {
        return { lat: item.lat, lng: item.lng };
      });
    let count = 0;
    list.map((item) => {
      if (this.props.drawingBounds.contains(item)) {
        count++;
      }
    });
    this.updateTerminalsCount(count);
  }
  updateTerminalsCount = (count) => {
    this.setState({ count: count }, () => {
      setTimeout(() => {
        if (this.isMouted) {
          this.setState({ loading: false });
        }
      }, 1000);
    });
  };

  render() {
    return (
      <>
        <div className={styles.mainWrapper}>
          <div className={styles.drawingContent}>System Information </div>
          <div className={styles.terminalsContent}>
            <span
              className={styles.spinnerWrapper}
              style={{ display: this.state.loading ? "block" : "none" }}
            >
              <Icon
                defination={{ prefix: "fal", iconName: "spinner" }}
                isSpinner={true}
              />
            </span>
            <span
              className={styles.terminalInfo}
              style={{ display: !this.state.loading ? "block" : "none" }}
            >
              Terminals:{" "}
              <span className={styles.count}>
                {" "}
                <strong>{this.state.count}</strong>
              </span>
            </span>
          </div>
        </div>
      </>
    );
  }
}

export default DrawingInfoWindow;
