import React, { Component } from "react";
import { INBOUND_BYTE } from "../../../Util/constTypes";
import { Line } from "react-chartjs-2";
import { byteToString } from "../../../Util/globalFunc";

export default class LineChart extends Component {
  render() {
    var options = {
      responsive: true,
      maintainAspectRatio: this.props.disableAspectRatio ? false : true,
      fill: false,
      legend: {
        position: "bottom",
        display: this.props.legend,
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            return showToolTip(tooltipItem, data);
          },
          title: function (tooltipItem, data) {
            return "";
          },
        },
      },
      scales: {
        yAxes: [
          {
            type: "linear",
            scaleLabel: {
              display: true,
              labelString: this.props.yAxLabel,
            },
            ticks: {
              display: true,
              min: 0,
              steps: 10,
              stepValue: 10,
              beginAtZero: true,
            },
            gridLines: {
              display: true,
              drawBorder: true,
            },
          },
        ],
      },
    };
    return (
      <Line
        // width={this.props.width ? "" : 253}
        // height={this.props.height ? "" : 210}
        {...(this.props.height == undefined && {
          height: 210,
        })}
        {...(this.props.width == undefined && {
          width: 253,
        })}
        data={{
          labels: this.props.labelsTimeSet,
          datasets: this.props.data,
          fill: false,
        }}
        options={options}
      />
    );
  }
}
function showToolTip(tooltipItem, data) {
  if (data.datasets[0].label === INBOUND_BYTE) {
    return byteToString(parseFloat(tooltipItem.value));
  } else {
    let d = Number(tooltipItem.value);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    if (h > 0) {
      return h + ":" +m<=9?"0"+m:m + ":" + s<=9?"0:"+s +"h":s + " h";
    }
    if (m > 0) {
      return s<=9?  m+ ":0"+ s + " min": m + ":" + s + " min";
    } else {
      return s + "s";
    }
  }
}
