import React, { Component } from "react";
import PhoneInput from "react-phone-input-2";

import styles from "./Telephone.module.scss";
export default class Telephone extends Component {
  render() {
    const {
      type,
      label,
      value,
      name,
      inputWrapper,
      placeholder,
      error,
      errMsg,
      blur,
      disabled,
      id,
      min,
      onFoucs,
      autocomplete,
      step,
      hideError = false,
      isRegularText = false,
      inputStyles = {},
      iconStyles = {},
      iconDefination = {},
      format,
      defaultCountry,
    } = this.props;

    return (
      <div className={inputWrapper}>
        <label className={isRegularText ? styles.regularLabel : styles.label}>
          {label}
          {/* <span
            className={styles.spinnerWrapper}
            style={{
              visibility: this.props.showSpinner ? "visible" : "hidden",
            }}
          >
            <Icon
              defination={{ prefix: "fal", iconName: "spinner" }}
              isSpinner={true}
            />
          </span> */}
        </label>
        <PhoneInput
          inputProps={{
            name: name,
            required: true,
            autoFocus: false,
            autoComplete:"off",
            id: this.props.label,
          }}
          autocompleteSearch={false}
          disabled={disabled}
          placeholder={placeholder}
          country={defaultCountry}
          value={value}
          onChange={(val) => this.props.change(val)}
          inputClass={styles.input}
          containerClass={styles.inputParent}
        />
        {!this.props.hideError  &&  <p
          className={styles.error}
          style={{ visibility: error ? "visible" : "hidden" }}
        >
          {errMsg}
        </p>}
      </div>
    );
  }
}
