import React, { Component, Fragment } from "react";
import styles from "./FormLogin.module.scss";
import Logo from "../../../assets/logo.png";
import { http } from "../../../RequestManager/Http";
import {
  buildLoginRequest,
  buildAccountLoginRequest,
  requestResetPassword,
 
} from "../../../RequestManager/QueriesAndMutations";
import Gallery from "../Gallery/Gallery";
import Input from "../../../Componnet/Input/Input";
import { storeInLocalStorage } from "../../../Store/localStoreageManager/localStoreageManager";
import SingleButton from "../../../Componnet/SingleButton/SingleButton";
import Controller from "../../../Controller";
import Icon from "../../../Componnet/Icon/Icon";
import Model from "../../../Componnet/Model/Model";
import SignInAs from "../../../Componnet/SignInAs/SignInAs";
import { HISKY, ADMINISTRATOR } from "../../../Util/constTypes";
import { checkVersionApp } from "../../../Util/globalFunc";
import RefreshCache from "../../../Componnet/RefreshCache/RefreshCache";
class FormLogin extends Component {
  _info = {};
  state = {
    validUser: false,
    loading: false,
    sucssesMsg: "",
    errorMsg: "",
    resetpass: false,
    showSignInAsModel: false,
    signInAsLoading: false,
    signInAsErrMsg: "",
    showRefreshCacheModel: false,
    user: {
      email: {
        value: "",
        error: false,
        errMsg: "The email is not valid",
      },
      emailReset: {
        value: "",
        error: false,
        errMsg: "The email is not valid",
      },
      password: {
        value: "",
        error: false,
        type: "password",
        errMsg: "The password is not valid",
      },
    },
    signInAsEmail: {
      value: "",
      error: false,
      errMsg: "The email is not valid",
    },
  };
  componentDidMount() {
    this.initHandler();
    
  }
  initHandler = () => {
    if (!checkVersionApp(this.props.systemVersion))
      this.setState({ showRefreshCacheModel: true });
  };
 
  onChangeSignInAsEmailHandler = (event) => {
    let email = { ...this.state.signInAsEmail };
    email.value = event.target.value.trim();
    email.error = false;
    this.setState({ signInAsEmail: email, signInAsErrMsg: "" });
  };
  onClickSubmitSignInAsHandler = () => {
    if (this.checkValidSignInAsDataHandler()) {
      //api call
      this.setState(
        {
          signInAsLoading: true,
        },
        () => {
          this.createSignInAsHttpRequest();
        }
      );
    }
  };
  createSignInAsHttpRequest = () => {
    http(buildAccountLoginRequest, {
      email: this.state.signInAsEmail.value,
    })
      .then((res) => {
        if (
          res.data.loginAccount == null ||
          (res.errors != undefined && res.errors.length > 0)
        ) {
          this.signInAsErrorHandler(res.errors[0].message.replace(/_/g, " "));
         
          return;
        }
        this._info = res.data.loginAccount;
        this.setState({ signInAsLoading: false }, () => {
          this.setDataAndRedirectHandler();
        });
      })
      .catch((err) => {
        //re-think below logic on error login in
        this.signInAsErrorHandler();
      });
  };
  signInAsErrorHandler = (errMsg = "") => {
    let email = { ...this.state.signInAsEmail };
    if (errMsg == "") email.error = true;
    this.setState({
      signInAsEmail: email,
      signInAsLoading: false,
      signInAsErrMsg: errMsg,
    });
  };
  checkValidSignInAsDataHandler = () => {
    let isValid = true;
    let email = { ...this.state.signInAsEmail };
    if (email.value === "") {
      email.error = true;
      isValid = false;
    }
    this.setState({ signInAsEmail: email });
    return isValid;
  };
  checkServerRespond = (res) => {
    if (res.errors !== undefined) {
      this.setState({
        validUser: true,
        loading: false,
        errorMsg: res.errors[0].message.replace(/_/g, " "),
      });
    } else {
      const { login } = res.data;
      this._info = login;
      //check for hisky Admin role
      if (
        login.user.account.entity.name == HISKY &&
        login.user.role.name == ADMINISTRATOR
      ) {
        //store the data in global var and token in localstorage and open the sign in as model
        this.setState({ showSignInAsModel: true }, () => {
          storeInLocalStorage(
            JSON.stringify({
              token: login.token,
            })
          );
        });
      } else {
        //login in as given user
        this.setDataAndRedirectHandler();
      }
    }
  };
  setDataAndRedirectHandler = () => {
    if (this.state.signInAsLoading) return;
    let login = { ...this._info };
    let info = {
      token: login.token,
      account_id: login.user.account.id,
      lastName: login.user.lastName,
      firstName: login.user.firstName,
      userId: login.user.id,
      email: login.user.email,
      avatar: login.user.avatar,
      parentIds: login.user.account.parentIds,
      role: `${login.user.account.entity.name}-${login.user.role.name}`,
      entity_name: login.user.account.entity.name,
      phone: login.user.phone,
      freshdeskApikey: login.user.account.freshdeskApikey,
    };
    
    storeInLocalStorage(JSON.stringify(info));
    Controller.setDataPermission(login.permacc, login.isUser, login.settings);
    this.props.setAuthuntication(true, null, true);
  };

  onChangeHandler = (event) => {
    if (this.state.loading) return;
    let copyUser = { ...this.state.user };
    copyUser[event.target.name].value = event.target.value.trim();
    copyUser[event.target.name].error = false;
    this.setState({ user: copyUser, validUser: false, errorMsg: "" });
  };
  checkValidData = () => {
    let isValid = true;
    let copyUser = { ...this.state.user };
    if (!this.state.resetpass) {
      if (copyUser.email.value === "") {
        copyUser.email.error = true;
        isValid = false;
      }
      if (copyUser.password.value === "") {
        copyUser.password.error = true;
        isValid = false;
      }

      this.setState({ user: copyUser });
      return isValid;
    } else {
      if (copyUser.emailReset.value === "") {
        copyUser.emailReset.error = true;
        this.setState({ user: copyUser });
        isValid = false;
      }
      return isValid;
    }
  };
  onClickVisiblePassHandler = () => {
    let userCopy = { ...this.state.user };
    let type = userCopy.password.type === "password" ? "text" : "password";
    userCopy.password.type = type;
    this.setState({ user: userCopy });
  };
  onClickLoginHandler = (event) => {
    event.preventDefault();
    if (this.checkValidData()) {
      this.setState(
        {
          loading: true,
        },
        () => {
          this.createAHttpRequest();
        }
      );
    }
  };

  createAHttpRequest = () => {
    const { user } = this.state;
    if (!this.state.resetpass) {
      http(buildLoginRequest, {
        user_name: user.email.value.toLowerCase(),
        password: user.password.value,
      })
        .then((res) => {
          ///version
          this.checkServerRespond(res);
        })

        .catch((err) => {
         
        });
    } else {
      http(requestResetPassword, {
        email: this.state.user.emailReset.value.toLowerCase(),
      })
        .then((res) => {
          this.checkResponedOfServerHandler(res);
        })
        .catch((err) => {});
    }
  };
  checkResponedOfServerHandler = (res) => {
    if (res.errors !== undefined) {
      this.setState({
        validUser: true,
        loading: false,
        errorMsg: res.errors[0].message.replace(/_/g, " "),
      });
    } else {
      this.setState({
        loading: false,
        validUser: true,
        sucssesMsg: "we have e-mailed your password reset link.",
      });
    }
  };
  onClickResetPassHandler = () => {
    if (this.state.loading) return;
    this.setState({ resetpass: !this.state.resetpass });
  };
  changeVisiblePasswordHandler = () => {
    let user = { ...this.state.user };
    user["password"].type =
      user["password"].type === "password" ? "text" : "password";
    this.setState({ user: user });
  };
  render() {
    const { password, email, emailReset } = this.state.user;
    return (
      <>
        {this.state.showRefreshCacheModel && (
          <Model visible={true}>
            <RefreshCache  setAuthuntication={this.props.setAuthuntication}/>
          </Model>
        )}
        <div className={styles.Login}>
          {this.state.showSignInAsModel && (
            <Model visible={true}>
              <SignInAs
                continueToNMS={this.setDataAndRedirectHandler}
                onChangeHandler={this.onChangeSignInAsEmailHandler}
                email={this.state.signInAsEmail}
                onSubmitHandler={this.onClickSubmitSignInAsHandler}
                loading={this.state.signInAsLoading}
                errorMsg={this.state.signInAsErrMsg}
              />
            </Model>
          )}

          <Gallery />
          <div className={styles.fromLoginWrapper}>
            <form onSubmit={this.onClickLoginHandler}>
              <div className={styles.logo}>
                <img className={styles.photo} src={Logo} alt="logo-hi-sky" />
              </div>

              <h4 className={styles.secondHeader}>
                {!this.state.resetpass ? "Welcome back" : "Reset password"}
              </h4>
              <p className={styles.msg}>
                {!this.state.resetpass
                  ? "Please sign into your account"
                  : "Please enter an email"}
                <span
                  className={styles.spinnerIcon}
                  style={{
                    visibility: !this.state.loading ? "hidden" : "visible",
                  }}
                >
                  <Icon
                    isSpinner={true}
                    defination={{ prefix: "fal", iconName: "spinner" }}
                  />
                </span>
              </p>

              <div className={styles.breakLine}></div>
              <p
                className={styles.error}
                style={{
                  visibility: this.state.validUser ? "visible" : "hidden",
                }}
              >
                {"" + this.state.errorMsg}
              </p>
              <div className={styles.inputGroup}>
                {!this.state.resetpass ? (
                  <Fragment>
                    <Input
                      name="email"
                      inputWrapper={styles.inputWrapper}
                      placeholder="Email here..."
                      label="Username"
                      error={email.error}
                      errMsg={email.errMsg}
                      change={(e) => this.onChangeHandler(e)}
                      value={email.value}
                    />
                    <Input
                      name="password"
                      inputWrapper={styles.inputWrapper}
                      placeholder="Password here..."
                      label="Password"
                      type={this.state.user.password.type}
                      error={password.error}
                      errMsg={password.errMsg}
                      change={(e) => this.onChangeHandler(e)}
                      value={password.value}
                      iconDefination={{
                        prefix: "far",
                        iconName:
                          password.type == "password" ? "eye-slash" : "eye",
                      }}
                      iconStyles={styles.visiblePassword}
                      iconAction={this.changeVisiblePasswordHandler}
                    />
                  </Fragment>
                ) : (
                  <Input
                    name="emailReset"
                    inputWrapper={styles.inputWrapper}
                    placeholder="Email here..."
                    label="Email"
                    type={this.state.user.emailReset.type}
                    error={emailReset.error}
                    errMsg={emailReset.errMsg}
                    change={(e) => this.onChangeHandler(e)}
                    value={emailReset.value}
                  />
                )}
              </div>
              <p className={styles.resetLinkMsg}>{this.state.sucssesMsg}</p>
              <div className={styles.breakLine}></div>

              <div
                className={
                  this.state.loading
                    ? `${styles.resetPassWrapper} ${styles.disabledLink}`
                    : styles.resetPassWrapper
                }
              >
                {!this.state.resetpass ? (
                  <p
                    className={styles.msgPass}
                    onClick={this.onClickResetPassHandler}
                  >
                    forgot password
                  </p>
                ) : (
                  <p
                    className={styles.msgPass}
                    onClick={this.onClickResetPassHandler}
                  >
                    <span className={styles.goBackIcon}>
                      <Icon
                        defination={{
                          prefix: "fal",
                          iconName: "long-arrow-left",
                        }}
                      />
                    </span>
                    Go Back
                  </p>
                )}
              </div>
              <div className={styles.btnSubmitControl}>
                <SingleButton
                  text={!this.state.resetpass ? "login" : "reset password"}
                  click={this.onClickLoginHandler}
                  disabled={this.state.loading}
                />
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default FormLogin;
