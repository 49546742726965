import React, { Component } from "react";
import Icon from "../../Icon/Icon";
import styles from "./UpdateMarker.module.scss";
class UpdateMarker extends Component {
  render() {
    return (
      <>
        <div
          className={
            this.props.selected
              ? `${styles.custommarker} ${styles.selected}`
              : styles.custommarker
          }
        >
          {this.props.title}
          <span
            className={styles.starIcon}
            style={{ visibility: this.props.isPrimary ? "visible" : "hidden" }}
          >
            <Icon defination={{ prefix: "fas", iconName: "star" }} />
          </span>
        </div>
      </>
    );
  }
}
export default UpdateMarker;
