import { isLocalhost, getHost } from "./Util/browser";

// API section
const TIMEOUT = 80000;
const HEADERS = {};
// const hisky360_host = "www.hisky360.com";
// const hisky360_in = "www.hisky360.in";
// const qa_nms_server = "qa.hiskynms.com";
// const hisky360 = "hisky360";
// const integration_nms="integration.hiskynms.com"
// const dev_nms = "dev.hiskynms.com";
// const dev_jooule_server="hisky-nms-dev.jooule.com"
// const simulator_server = "hisky-nms-simulation.jooule.com";
// const jooule_intgration_server = "hisky-nms-integration.jooule.com";
// let HOST = isLocalhost() ? "dev.hiskynms.com" : window.location.host;
// HOST = HOST.includes("hisky360")
//   ? window.location.host.includes("hisky360.com")
//     ? hisky360_host
//     : hisky360_in
//   : isLocalhost()
//   ? dev_nms
//   : window.location.host;
// const PROTO = ![
//   qa_nms_server,
//   hisky360,
//   simulator_server,
//   jooule_intgration_server,
//   dev_jooule_server,
//   hisky360_host
// ].includes(HOST)
//   ? "http"
//   : "https";
const HOST ="iotarsat.com.ar";
const PROTO = "https"
console.log("PRORTO", PROTO);
console.log("HOST", HOST);
const API_URL = `${PROTO}://${HOST}:8000/api/graphql`;
const EXPORTS_URL = `${PROTO}://${HOST}:8000/static`;
export default Object.freeze({
  api: {
    API_URL,
    TIMEOUT,
    HEADERS,
    EXPORTS_URL,
  },
});
