import {
  RESET_MODEL,
  OPEN_ADD_DEVICE,
  OPEN_INVITE_USER,
  OPEN_ASSIGN_DEVICE,
  OPEN_UPDATE_TERMINAL,
  OPEN_CALENDER,
  OPEN_CREATE_LOG_TYPE,
  OPEN_CREATE_NEW_SYSTEM_LOG,
  ADDITIONAL_DATA_USAGE,
  OPEN_NEW_PLAN,
  OPEN_CREATE_ACCOUNT,
  OPEN_EDIT_PLAN,
  OPEN_TIME_LINE,
  OPEN_ASSIGN_DEVICE_ACCOUNT,
  OPEN_ASSIGN_DEVICE_TO_TECH,
  OPEN_PLAN_SUBSCRIBER,
  OPEN_ASSIGN_SUBSCRIBERS,
  OPEN_ACCOUNT_PERMISIONS,
  OPEN_NEW_SERVICE,
  OPEN_SERVICE,
  OPEN_CHANNEL,
  OPEN_SITE,
  OPEN_ADD_TERMIANL,
  OPEN_ADD_HUB,
  OPEN_CREATE_UPDATE,
  OPEN_UPDATES_ALL_NOTES,
  OPEN_JSON_DIFF_CHECKER,
  OPEN_SYSTEM_VERSION_CONTROL,
  OPEN_EDIT_ACCOUNT,
  OPEN_ASSIGN_DEVICE_TO_SITE,
  OPEN_VIEW_PLAN_OF_DEVICE,
  MODEL_TOGGLE_COMPONENT,
  OPEN_ASSIGN_DEVICE_TO_PLAN,
  OPEN_UPLOAD_CSV,
  OPEN_UPLOAD_HISTORY,
  OPEN_DETACH_PLAN,
  OPEN_ACTIVATE_PLAN,
  OPEN_FAKE_USAGE,
  OPEN_TERMINAL_NETWORK_INFO,
  OPEN_VIEW_UPDATE,
  OPEN_VIEW_TERMINAL,
  OPEN_USAGE_IOT,
  OPEN_HUB_INFO,
  OPEN_TERMINAL_CONFIGURATION,
  OPEN_EDIT_USER,
  OPEN_VIEW_TERMINAL_ON_MAP,
  OPEN_VIEW_PLAN_OF_DEVICE_ON_MAP,
  OPEN_MULTIPLE_PLAN_ACTIONS,
  OPEN_VIEW_TELEMETRY,
  OPEN_VIEW_HUB_ON_MAP,
  OPEN_UPDATES_CONFIG,
  OPEN_UPDATE_POYLGONS,
  OPEN_VIEW_SITE_INFO_ON_MAP,
  OPEN_VIEW_SITE_INFO,
  OPEN_VIEW_REFRESH_CACHE,
  OPEN_VIEW_RESET_TELEMETRY,
  OPEN_CREATE_POOL,
  OPEN_VIEW_INVOKES,
  OPEN_VIEW_SUB_POOL,
  OPEN_ADVANCED_CHART,
  OPEN_TERMIANL_DUMP,
  OPEN_HUB,
  OPEN_CURRENT_ONLINE_TERMINALS
} from "./types";

export const resetTelemetrySelector = () => {
  return {
    type: OPEN_VIEW_RESET_TELEMETRY,
  };
};

export const openEditUserSelector = () => {
  return {
    type: OPEN_EDIT_USER,
  };
};
export const resetModelSelector = () => {
  return {
    type: RESET_MODEL,
  };
};
export const openUpdateTerminal = () => {
  return {
    type: OPEN_UPDATE_TERMINAL,
  };
};
export const openAddDeviceModel = () => {
  return {
    type: OPEN_ADD_DEVICE,
  };
};
export const openAssignDevice = () => {
  return {
    type: OPEN_ASSIGN_DEVICE,
  };
};
export const openInviteModel = () => {
  return {
    type: OPEN_INVITE_USER,
  };
};
export const openCalenderSelector = () => {
  return {
    type: OPEN_CALENDER,
  };
};
export const openAddSystemLogSelector = () => {
  return {
    type: OPEN_CREATE_NEW_SYSTEM_LOG,
  };
};
export const openCreateLogType = () => {
  return {
    type: OPEN_CREATE_LOG_TYPE,
  };
};
export const openAdditionalDataUsage = () => {
  return {
    type: ADDITIONAL_DATA_USAGE,
  };
};
export const openCreateNewPlan = () => {
  return {
    type: OPEN_NEW_PLAN,
  };
};

export const openCreateAccount = () => {
  return {
    type: OPEN_CREATE_ACCOUNT,
  };
};
export const openEditPlan = () => {
  return {
    type: OPEN_EDIT_PLAN,
  };
};



export const openTimeLine = () => {
  return {
    type: OPEN_TIME_LINE,
  };
};

export const openAssignAccountDevice = () => {
  return {
    type: OPEN_ASSIGN_DEVICE_ACCOUNT,
  };
};
export const openPlanSubscriber = () => {
  return {
    type: OPEN_PLAN_SUBSCRIBER,
  };
};

export const openAssignDeviceToTech = () => {
  return {
    type: OPEN_ASSIGN_DEVICE_TO_TECH,
  };
};

export const openAssignSubscribers = () => {
  return {
    type: OPEN_ASSIGN_SUBSCRIBERS,
  };
};

export const openAccountPermissions = () => {
  return {
    type: OPEN_ACCOUNT_PERMISIONS,
  };
};
export const openCreateNewService = () => {
  return {
    type: OPEN_NEW_SERVICE,
  };
};
export const openServiceSelector = () => {
  return {
    type: OPEN_SERVICE,
  };
};
export const openChannelSelector = () => {
  return {
    type: OPEN_CHANNEL,
  };
};
export const openSiteSelector = () => {
  return {
    type: OPEN_SITE,
  };
};
export const openAddTerminalSelector = () => {
  return {
    type: OPEN_ADD_TERMIANL,
  };
};

export const openHubSelector = () => {
  return {
    type: OPEN_HUB,
  };
};
export const openCreateUpdateSelector = () => {
  return {
    type: OPEN_CREATE_UPDATE,
  };
};
export const openUpdateAllNotesSelector = () => {
  return {
    type: OPEN_UPDATES_ALL_NOTES,
  };
};
export const openJsonDiffSelector = () => {
  return {
    type: OPEN_JSON_DIFF_CHECKER,
  };
};
export const openSystemVersionSelector = () => {
  return {
    type: OPEN_SYSTEM_VERSION_CONTROL,
  };
};
export const openEditAccountSelecter = () => {
  return {
    type: OPEN_EDIT_ACCOUNT,
  };
};
export const openAssignDeviceToSiteSelecter = () => {
  return {
    type: OPEN_ASSIGN_DEVICE_TO_SITE,
  };
};

export const openViewAssignPlanToDeviceSelecter = () => {
  return {
    type: OPEN_VIEW_PLAN_OF_DEVICE,
  };
};
export const openViewAssignPlanToDeviceOnMapSelecter = () => {
  return {
    type: OPEN_VIEW_PLAN_OF_DEVICE_ON_MAP,
  };
};
export const toggleModelComponnentSelecter = (from, to) => {
  return {
    type: MODEL_TOGGLE_COMPONENT,
    payload: { from: from, to: to },
  };
};

export const openAssignPlanToDevicesSelecter = () => {
  return {
    type: OPEN_ASSIGN_DEVICE_TO_PLAN,
  };
};
export const openUploadCsvSelector = () => {
  return {
    type: OPEN_UPLOAD_CSV,
  };
};
export const openUploadHistorySelector = () => {
  return {
    type: OPEN_UPLOAD_HISTORY,
  };
};
export const openDetachPlanSelector = () => {
  return {
    type: OPEN_DETACH_PLAN,
  };
};
export const openActivatePlanSelector = () => {
  return {
    type: OPEN_ACTIVATE_PLAN,
  };
};
export const openCreateFakeUsageSelector = () => {
  return {
    type: OPEN_FAKE_USAGE,
  };
};
export const openTerminalNetworkInfo = (terminal) => {
  return {
    type: OPEN_TERMINAL_NETWORK_INFO,
    payload: terminal,
  };
};
export const openViewUpdateSelector = () => {
  return {
    type: OPEN_VIEW_UPDATE,
  };
};
export const openViewTerminalSelector = () => {
  return {
    type: OPEN_VIEW_TERMINAL,
  };
};
export const openViewTerminalOnMapSelector = () => {
  return {
    type: OPEN_VIEW_TERMINAL_ON_MAP,
  };
};
export const openHubInfoOnMapSelector = () => {
  return {
    type: OPEN_VIEW_HUB_ON_MAP,
  };
};
export const openUsageIotSelecter = () => {
  return {
    type: OPEN_USAGE_IOT,
  };
};
export const openHubInfo = () => {
  return {
    type: OPEN_HUB_INFO,
  };
};
export const openTerminalConfigurationSelecter = () => {
  return {
    type: OPEN_TERMINAL_CONFIGURATION,
  };
};
export const openMultiplePlanActionsSelector = () => {
  return {
    type: OPEN_MULTIPLE_PLAN_ACTIONS,
  };
};
export const openViewTelemetrySelector = () => {
  return {
    type: OPEN_VIEW_TELEMETRY,
  };
};
export const openUpdatesConfigSelecter = () => {
  return {
    type: OPEN_UPDATES_CONFIG,
  };
};

export const openUpdatePolygonSelecter = () => {
  return {
    type: OPEN_UPDATE_POYLGONS,
  };
};

export const openViewSiteInfoonMapSelecter = () => {
  return {
    type: OPEN_VIEW_SITE_INFO_ON_MAP,
  };
};
export const openViewSiteInfoSelecter = () => {
  return {
    type: OPEN_VIEW_SITE_INFO,
  };
};

export const openRefreshCacheSelector = () => {
  return {
    type: OPEN_VIEW_REFRESH_CACHE,
  };
};
export const openCreatePool = () => {
  return {
    type: OPEN_CREATE_POOL,
  };
};
export const openInvokesSelector = () => {
  return {
    type: OPEN_VIEW_INVOKES,
  };
};
export const openViewSubPool = () => {
  return {
    type: OPEN_VIEW_SUB_POOL,
  };
};
export const openAdvancedChartSelector = () => {
  return {
    type: OPEN_ADVANCED_CHART,
  };
};
export const openTermianlDumpSelecter=()=>{
  return{
    type:OPEN_TERMIANL_DUMP
  }
}
export const openCurrentTerminalsSelecter=()=>{
  return{
    type:OPEN_CURRENT_ONLINE_TERMINALS
  }
}