import * as actionType from "../actions/types";
import { updateObject } from "./utilReducer";

const initialState = {
  userEdit: "",
  userProfile: {},
  accountUnderWork: {},
  newAccountAdded: {},
  accountInfo: {}, ///////use this
  selectedUser: {},
  editAccountRx: {},
  serviceDataRx: {},
  planEdit: {},
  editSite: {},
  newSite: {},
  viewUpdateRx: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.EDIT_USER:
      return updateObject(state, { userEdit: action.payload });
    case actionType.USER_PROFILE:
      return updateObject(state, { userProfile: action.payload });
    case actionType.ACCOUNT_INFO:
      return updateObject(state, { accountInfo: action.payload });
    case actionType.SELECTED_USER_INFO:
      return updateObject(state, { selectedUser: action.payload });
    case actionType.ACCOUNT_UNDER_WORK:
      return updateObject(state, { accountUnderWork: action.payload });
    case actionType.NEW_ACCOUNT_ADDED:
      return updateObject(state, { newAccountAdded: action.payload });
    case actionType.EDIT_ACCOUNT_INFO:
      return updateObject(state, { editAccountRx: action.payload });
    case actionType.SERVICE_DATA:
      return updateObject(state, { serviceDataRx: action.payload });
    case actionType.EDIT_PLAN:
      return updateObject(state, { planEdit: action.payload });
    case actionType.NEW_SITE:
      return updateObject(state, { newSite: action.payload });
    case actionType.EDIT_SITE:
      return updateObject(state, { editSite: action.payload });
    case actionType.VIEW_UPDATE:
      return updateObject(state, { viewUpdateRx: action.payload });
    default:
      return state;
  }
};

export default reducer;
