import React, { Component, Fragment } from "react";
import styles from "./Tabs.module.scss";
class Tabs extends Component {
  handleChangeHandler = (index, item) => {
    this.props.click(index, item);
  };

  render() {
    return (
      <div
        {...(this.props.tabsWrapper != undefined && {
          className: this.props.tabsWrapper,
        })}       
      >
        <ul className={styles.inline}>
          {this.props.tabArray.map((item, index) => {
            let tabstyle = index == this.props.selected ? styles.selected : "";
            return (
              <li
                className={tabstyle}
                key={index}
                onClick={() => this.handleChangeHandler(index, item)}
                style={
                  ({
                    opacity: this.props.isPreview ? 0.6 : "",

                    pointerEvents: this.props.isPreview ? "none" : "",
                  },
                  { ...this.props.customStyles })
                }
              >
                {item}
              </li>
            );
          })}
        </ul>
        <div className={styles.tab}></div>
      </div>
    );
  }
}
export default Tabs;
