import React, { Component } from "react";
import styles from "./ViewAssignDeviceToPlan.module.scss";
import Input from "../../../../Componnet/Input/Input";
import { connect } from "react-redux";
import * as actionType from "../../../../Store/actions/index";
import Icon from "../../../Icon/Icon";
import SpinnerLoader from "../../../SpinnerLoader/SpinnerLoader";
import SingleButton from "../../../SingleButton/SingleButton";
import moment from "moment";
import { CLOSE, HISKY_ID,PHONE_NUMBER,PO_ID,PLAN_NAME,SERVICE_TYPE,ACTIVATION_DATE,ATTACHED_DATE} from "../../../../Util/constTypes";
import { getServiceTypes } from "../../../../Util/globalFunc";
import { http } from "../../../../RequestManager/Http";
import { getTerminalAndAttachedPlanDetails } from "../../../../RequestManager/QueriesAndMutations";
class ViewAssignDeviceToPlan extends Component {
  noPlaneAttach = false;
  state = {
    title: "",
    planAssignDate: "",
    poId: "",
    activationDate: "",
    mockup: false,
    loading: true,
    id: "",
    planActivateDate: "",
    hiSkyId: "",
    serviceType: "",
    phoneNumber:""
  };

  componentDidMount() {
    let id = parseInt(this.props.accountInfoRx.device.id);
    http(getTerminalAndAttachedPlanDetails, {
      id: id,
    })
      .then((res) => {
        this.setDataHandler(res.data.terminalInstance);
      })
      .catch((err) => {});
  }

  setDataHandler = (data) => {
    if (data !== null && data.plan !== null) {
      let title = data.plan.template.title ? data.plan.template.title : "N/A";
      let planAssignDate = data.planAssignDate
        ? this.converDataHandler(data.planAssignDate)
        : "N/A";
        let phoneNumber='';//data.plan.planPhoneNumber!==null &&data.plan.planPhoneNumber.phoneNumber!==null ?data.plan.planPhoneNumber.phoneNumber:"N/A" for all service types
      let poId = data.plan.poId && data.plan !== null ? data.plan.poId : "N/A";
      let mockup = data.mockup && data.mockup !== null ? data.mockup : false;
      let id = data.plan !== null ? data.plan.id : "N/A";
      let serviceType =
        data.plan !== null &&
        data.plan.template.services !== undefined &&
        data.plan.template.services.length > 0
          ? getServiceTypes(data.plan.template.services)
          : "N/A";
      let hiskyId = data.hiskyId;
      let planActivateDate = data.plan.planActivate
        ? this.converDataHandler(data.plan.planActivateDate)
        : "N/A";
      this.setState({
        poId,
        title,
        planAssignDate,
        mockup,
        loading: false,
        id,
        phoneNumber,
        planActivateDate,
        hiskyId: data.hiskyId,
        serviceType,
      });
    } else {
      if (data.plan === null) {
        this.noPlaneAttach = true;
      }
      this.setState({
        loading: false,
        hiskyId: data.hiskyId !== null ? data.hiskyId : "N/A",
        title: "N/A",
        planAssignDate: "N/A",
        poId: "N/A",
        activationDate: "N/A",
        id: "N/A",
        planActivateDate: "N/A",
        serviceType:'N/A'
      });
    }
  };
  converDataHandler = (ts) => {
    return moment(ts).format("MM/DD/YYYY HH:mm:ss");
  };
  render() {
    return (
      <div className={styles.ViewAssignDeviceToPlans} style={this.props.styles}>
        <h3 className={styles.header}>
          <span className={styles.headerIcon}>
            <Icon defination={{ prefix: "fal", iconName: "info-circle" }} />
          </span>
          Attached Plan
        </h3>
        <div className={styles.breakLine}></div>
        <p
          className={styles.noPlanMsg}
          style={{ visibility: this.noPlaneAttach ? "visible" : "hidden" }}
        >
          No plan is attached
        </p>
        <div
          className={styles.form}
          style={{ opacity: this.state.loading ? 0.6 : 1 }}
        >
          <Input
            label={HISKY_ID}
            value={this.state.hiskyId !== undefined ? this.state.hiskyId : ""}
            disabled={true}
            inputWrapper={styles.inputWrapper}
            isRegularText={true}
          />
          <Input
            label="plan ID"
            value={this.state.id}
            disabled={true}
            inputWrapper={styles.inputWrapper}
          />
          <Input
            label={PLAN_NAME}
            value={this.state.title}
            disabled={true}
            inputWrapper={styles.inputWrapper}
          />
          <Input
            label={PO_ID}
            isRegularText={true}
            value={this.state.poId}
            disabled={true}
            inputWrapper={styles.inputWrapper}
          />
          <Input
            label={ATTACHED_DATE}
            value={this.state.planAssignDate}
            disabled={true}
            inputWrapper={styles.inputWrapper}
          />
          <Input
            label={ACTIVATION_DATE}
            value={this.state.planActivateDate}
            disabled={true}
            inputWrapper={styles.inputWrapper}
          />
          <Input
            label={SERVICE_TYPE}
            value={this.state.serviceType}
            disabled={true}
            inputWrapper={styles.inputWrapper}
          />
          {/* <Input for all serviceTYpes
            label={PHONE_NUMBER}
            value={this.state.phoneNumber}
            disabled={true}
            inputWrapper={styles.inputWrapper}
          /> */}
          <label
            className={styles.label}
            style={{ visibility: this.state.mockup ? "visible" : "hidden" }}
          >
            {"This is a mockup device"}
          </label>
        </div>
        <div className={styles.btnWrapper}>
          <SingleButton
            text={CLOSE}
            click={() => this.props.resetModelSelector()}
          />
        </div>
        <div
          className={styles.spinnerWrapper}
          style={{ display: this.state.loading ? "block" : "none" }}
        >
          <SpinnerLoader />
        </div>
      </div>
    );
  }
}
const mapStateProps = (state) => {
  return {
    accountInfoRx: state.account.accountInfo,
  };
};
const mapStateDispatch = (dispatch) => {
  return {
    accountInfoSelector: (accountInfo) =>
      dispatch(actionType.accountInfoSelector(accountInfo)),
    resetModelSelector: () => dispatch(actionType.resetModelSelector()),
  };
};
export default connect(mapStateProps, mapStateDispatch)(ViewAssignDeviceToPlan);
