import React, { Component, Fragment } from "react";
import styles from "./CopyApiKey.module.scss";
import Icon from "../Icon/Icon";
import CopyToClipboard from "react-copy-to-clipboard";
import Input from "../Input/Input";
class CopyApiKey extends Component {
  _isMouted = true;
  state = {
    apikeyCopied: false,
  };
  onCopyHandler = () => {
    if (
      this.props.apiKey == "" ||
      this.props.apiKey == null ||
      this.props.disabled
    )
      return;
    this.setState({ apikeyCopied: true }, () => {
      setTimeout(() => {
        if (this._isMouted) {
          this.setState({ apikeyCopied: false });
        }
      }, 3000);
    });
  };
  componentWillUnmount() {
    this._isMouted = false;
  }
  render() {
    return (
      <div className={styles.copyApiKey}>
        <div className={styles.titleWrapper}>
          <p
            className={
              this.props.title !== undefined ? this.props.title : styles.title
            }
          >
            {" "}
            {this.props.icon && (
              <span
                className={
                  this.props.iconNet !== undefined
                    ? this.props.iconNet
                    : styles.iconNet
                }
              >
                <Icon
                  defination={{
                    prefix: "fas",
                    iconName: "key",
                  }}
                />
              </span>
            )}
            Terminal API key{" "}
          </p>
        </div>
        <div
          className={
            this.props.breakLine !== undefined
              ? this.props.breakLine
              : styles.breakLine
          }
        ></div>
        <div className={styles.apikeyInputs}>
          <Input
            value={this.props.apiKey==null?'':this.props.apiKey}
            name={"API key"}
            label={""}
            info={true}
            errMsg={"Terminal API Key is copied"}
            disabled={true}
            placeholder={
              this.props.apiKey == null || this.props.apiKey == ""
                ? "No Terminal API Key Assigned"
                : ""
            }
            error={this.state.apikeyCopied}
            type={"text"}
            inputWrapper={styles.apiInputWrapper}
         
            disabled={true}
          />
          <CopyToClipboard
            text={this.props.apiKey}
            onCopy={() => this.onCopyHandler()}
          >
            <div
              className={styles.copyWrapper}
              style={{opacity:
                this.props.apiKey === undefined ||
                this.props.apiKey === "" ||
                this.props.apiKey == null ||
                this.props.disabled?.6:1}
              }
            >
              <span className={styles.copyIcon}>
                <Icon defination={{ prefix: "fal", iconName: "copy" }} />
              </span>
            </div>
          </CopyToClipboard>
        </div>
      </div>
    );
  }
}

export default CopyApiKey;
