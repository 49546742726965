import React, { Component } from "react";
import clusterM from "../../../assets/ClusterMarker.svg";
import { TERMINAL_ONLINE_COLOR } from "../../../Util/constants";
import ShortcutMenu from "../ShortcutMenu/ShortcutMenu";
import styles from "./ClusterMarker.module.scss";
export default class clusterMarker extends Component {
  render() {
    return (
      <>
        <div
          className={styles.ClusterMarkers}
          onClick={() =>
            this.props.click(this.props.lat, this.props.lng, this.props.id)
          }
          onContextMenu={(info, name) =>
           this.props.rightClickActive != undefined &&
           this.props.rightClickActive &&
           this.props.onClickRight(this.props.id, this.props.id) //first param data of terminals with id, hiskyid
          }
        >
          <img src={clusterM} className={styles.marker} ></img>
          {this.props.onlineCount>=1 && <span
            className={styles.dotColor}
            style={{ backgroundColor: TERMINAL_ONLINE_COLOR }}
          ></span>}
          {this.props.count && (
            <span className={styles.count}>{this.props.count}</span>
          )}
        </div>
        {this.props.activeShortcutMenu != undefined &&
          Object.keys(this.props.activeShortcutMenu).length > 0 &&
          this.props.activeShortcutMenu.name == this.props.id && ( 
            <ShortcutMenu
              data={this.props}
              onClickRightItem={this.props.onClickRightItem}
              getTransmittingTerminals={this.props.getTransmittingTerminals}
              shortcutClicked={this.props.shortcutClicked}
            />
          )}
      </>
    );
  }
}
