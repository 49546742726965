import * as actionType from "../actions/types";
import { updateObject } from "./utilReducer";

const initialState = {
  model: false,
  modelOnMapRx: false,

  editUserRx: false,
  addDevice: false,
  inviteUser: false,
  assignDevice: false,
  hubMoreInfo: false,
  updateTerminal: false,
  terminalNetworkInfo: false,
  calenderPicker: false,
  resetTelemetryRx: false,
  createNewSystemLog: false,
  createLogType: false,
  additionalDataUsage: false,
  createNewPlan: false,
  createAccount: false,
  editPlan: false,
  assignPlan: false,
  multiplePlan: false,
  currentTerminalRx: false,
  timeLine: false,
  assignAccountDevice: false,
  assignDeviceToTech: false,
  planSubscriber: false,
  assignSubscriber: false,
  accountPermmisions: false,
  createNewService: false,
  serviceRx: false,
  channelRx: false,
  siteRx: false,
  newTerminal: false,
  newHbs: false,
  createUpdate: false,
  updatesAllNotes: false,
  jsonDiffChecker: false,
  editAccount: false,
  systemVersionControl: false,
  assignDeviceToSiteRx: false,
  viewAssignDeviceToPlanRx: false,
  viewAssignDeviceToPlanOnMapRx: false,
  assignPlanToDevicesRx: false,
  uploadCsvRx: false,
  uploadHistoryRx: false,
  detachPlanRx: false,
  activatePlanRx: false,
  createFakeUsageRx: false,
  viewUpdateRx: false,
  viewTerminalRx: false,
  viewTerminalOnMapRx: false,
  viewHubOnMapRx: false,
  multiplePlanActionsRx: false,
  usageIotRx: false,
  viewTelemetryRx: false,
  editUpdateConfigRx: false,
  updatePolygonsRX: false,
  viewSiteInfoRx: false,
  viewSiteInfoOnMapRx: false,
  viewRefreshCacheRx: false,
  createPoolRx: false,
  subPoolRx: false,
  invokesRx: false,
  viewAdvancedChartRx: false,
  termainlDumpRx: false,
  hubRx: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.OPEN_EDIT_USER:
      return updateObject(state, {
        model: !state.model,
        editUserRx: !state.editUserRx,
      });
    case actionType.OPEN_ADD_DEVICE:
      return updateObject(state, {
        model: !state.model,
        addDevice: true,
      });

    case actionType.OPEN_INVITE_USER:
      return updateObject(state, {
        model: !state.model,
        inviteUser: true,
      });

    case actionType.OPEN_VIEW_RESET_TELEMETRY:
      return updateObject(state, {
        model: !state.model,
        resetTelemetryRx: true,
      });
    case actionType.OPEN_HUB_INFO:
      return updateObject(state, { model: !state.model, hubMoreInfo: true });
    case actionType.OPEN_ASSIGN_DEVICE:
      return updateObject(state, { model: !state.model, assignDevice: true });
    case actionType.RESET_MODEL:
      return initialState;
    case actionType.OPEN_CANNEL:
      return updateObject(state, { model: !state.model, addChannel: true });
    case actionType.OPEN_UPDATE_TERMINAL:
      return updateObject(state, { model: !state.model, updateTerminal: true });
    case actionType.OPEN_TERMINAL_NETWORK_INFO:
      return updateObject(state, {
        model: !state.model,
        terminalNetworkInfo: true,
      });
    case actionType.OPEN_CALENDER:
      return updateObject(state, { model: !state.model, calenderPicker: true });
    case actionType.OPEN_CREATE_LOG_TYPE:
      return updateObject(state, { model: !state.model, createLogType: true });
    ////
    case actionType.OPEN_CURRENT_ONLINE_TERMINALS:
      return updateObject(state, {
        model: !state.model,
        currentTerminalRx: true,
      });
    ///
    case actionType.OPEN_CREATE_NEW_SYSTEM_LOG:
      return updateObject(state, {
        model: !state.model,
        createNewSystemLog: true,
      });
    case actionType.ADDITIONAL_DATA_USAGE:
      return updateObject(state, {
        model: !state.model,
        additionalDataUsage: true,
      });

    case actionType.OPEN_ADD_TERMIANL:
      return updateObject(state, { model: !state.model, newTerminal: true });

    case actionType.OPEN_NEW_PLAN:
      return updateObject(state, { model: !state.model, createNewPlan: true });

    case actionType.OPEN_CREATE_ACCOUNT:
      return updateObject(state, { model: !state.model, createAccount: true });
    case actionType.OPEN_EDIT_PLAN:
      return updateObject(state, { model: !state.model, editPlan: true });
    case actionType.OPEN_TERMIANL_DUMP:
      return updateObject(state, { model: !state.model, termainlDumpRx: true });

    case actionType.OPEN_PLAN_SUBSCRIBER:
      return updateObject(state, { model: !state.model, planSubscriber: true });
    case actionType.OPEN_TIME_LINE:
      return updateObject(state, {
        model: !state.model,
        timeLine: true,
      });
    case actionType.OPEN_ASSIGN_DEVICE_ACCOUNT:
      return updateObject(state, {
        model: !state.model,
        assignAccountDevice: true,
      });
    case actionType.OPEN_ASSIGN_DEVICE_TO_TECH:
      return updateObject(state, {
        model: !state.model,
        assignDeviceToTech: true,
      });
    case actionType.OPEN_ASSIGN_SUBSCRIBERS:
      return updateObject(state, {
        assignSubscriber: true,
        model: !state.modle,
      });

    case actionType.OPEN_ACCOUNT_PERMISIONS:
      return updateObject(state, {
        model: !state.model,
        accountPermmisions: true,
      });
    case actionType.OPEN_NEW_SERVICE:
      return updateObject(state, {
        model: !state.model,
        createNewService: true,
      });
    case actionType.OPEN_SERVICE:
      return updateObject(state, { model: !state.model, serviceRx: true });

    case actionType.OPEN_CHANNEL:
      return updateObject(state, { channelRx: true, model: !state.model });
    case actionType.OPEN_SITE:
      return updateObject(state, { siteRx: true, model: !state.model });
    case actionType.OPEN_EDIT_ACCOUNT:
      return updateObject(state, { editAccount: true, model: !state.model });
    case actionType.OPEN_CREATE_UPDATE:
      return updateObject(state, { createUpdate: true, model: !state.model });
    case actionType.OPEN_UPDATES_ALL_NOTES:
      return updateObject(state, {
        updatesAllNotes: true,
        model: !state.model,
      });
    case actionType.OPEN_JSON_DIFF_CHECKER:
      return updateObject(state, {
        jsonDiffChecker: true,
        model: !state.model,
      });
    case actionType.OPEN_SYSTEM_VERSION_CONTROL:
      return updateObject(state, {
        systemVersionControl: true,
        model: !state.model,
      });
    case actionType.OPEN_UPLOAD_CSV:
      return updateObject(state, { uploadCsvRx: true, model: !state.model });
    case actionType.OPEN_UPLOAD_HISTORY:
      return updateObject(state, {
        uploadHistoryRx: true,
        model: !state.model,
      });
    case actionType.OPEN_ASSIGN_DEVICE_TO_SITE:
      return updateObject(state, {
        assignDeviceToSiteRx: true,
        model: !state.model,
      });
    case actionType.OPEN_VIEW_PLAN_OF_DEVICE:
      return updateObject(state, {
        viewAssignDeviceToPlanRx: true,
        model: !state.model,
      });
    case actionType.OPEN_VIEW_PLAN_OF_DEVICE_ON_MAP:
      return updateObject(state, {
        viewAssignDeviceToPlanOnMapRx: true,
        modelOnMapRx: !state.modelOnMapRx,
      });
    case actionType.OPEN_ASSIGN_DEVICE_TO_PLAN:
      return updateObject(state, {
        assignPlanToDevicesRx: true,
        model: !state.model,
      });
    case actionType.MODEL_TOGGLE_COMPONENT:
      return updateObject(state, {
        [action.payload.from.toString()]: false,
        [action.payload.to.toString()]: true,
      });
    case actionType.OPEN_DETACH_PLAN:
      return updateObject(state, {
        detachPlanRx: true,
        model: !state.model,
      });
    case actionType.OPEN_ACTIVATE_PLAN:
      return updateObject(state, {
        activatePlanRx: true,
        model: !state.model,
      });
    case actionType.OPEN_FAKE_USAGE:
      return updateObject(state, {
        createFakeUsageRx: true,
        model: !state.model,
      });
    case actionType.OPEN_VIEW_UPDATE:
      return updateObject(state, {
        viewUpdateRx: true,
        model: !state.model,
      });
    case actionType.OPEN_VIEW_TERMINAL:
      return updateObject(state, {
        viewTerminalRx: true,
        model: !state.model,
      });
    case actionType.OPEN_VIEW_TERMINAL_ON_MAP:
      return updateObject(state, {
        viewTerminalOnMapRx: true,
        modelOnMapRx: !state.modelOnMapRx,
      });
    case actionType.OPEN_VIEW_HUB_ON_MAP:
      return updateObject(state, {
        viewHubOnMapRx: true,
        modelOnMapRx: !state.modelOnMapRx,
      });
    case actionType.OPEN_USAGE_IOT:
      return updateObject(state, {
        usageIotRx: true,
        model: !state.model,
      });
    case actionType.OPEN_MULTIPLE_PLAN_ACTIONS:
      return updateObject(state, {
        multiplePlanActionsRx: true,
        model: !state.model,
      });
    case actionType.OPEN_TERMINAL_CONFIGURATION:
      return updateObject(state, {
        terminalConfigRx: true,
        model: !state.model,
      });
    case actionType.OPEN_VIEW_TELEMETRY:
      return updateObject(state, {
        viewTelemetryRx: true,
        model: !state.model,
      });
    case actionType.OPEN_UPDATES_CONFIG:
      return updateObject(state, {
        editUpdateConfigRx: true,
        model: !state.model,
      });

    case actionType.OPEN_UPDATE_POYLGONS:
      return updateObject(state, {
        updatePolygonsRX: true,
        model: !state.model,
      });

    case actionType.OPEN_VIEW_SITE_INFO:
      return updateObject(state, {
        viewSiteInfoRx: true,
        model: !state.model,
      });
    case actionType.OPEN_VIEW_SITE_INFO_ON_MAP:
      return updateObject(state, {
        viewSiteInfoOnMapRx: true,
        modelOnMapRx: !state.modelOnMapRx,
      });

    case actionType.OPEN_VIEW_REFRESH_CACHE:
      return updateObject(state, {
        viewRefreshCacheRx: true,
        model: !state.model,
      });
    case actionType.OPEN_CREATE_POOL:
      return updateObject(state, {
        createPoolRx: true,
        model: !state.model,
      });
    case actionType.OPEN_VIEW_INVOKES:
      return updateObject(state, {
        invokesRx: true,
        model: !state.model,
      });
    case actionType.OPEN_VIEW_SUB_POOL:
      return updateObject(state, {
        subPoolRx: true,
        model: !state.model,
      });
    case actionType.OPEN_ADVANCED_CHART:
      return updateObject(state, {
        viewAdvancedChartRx: true,
        model: !state.model,
      });
    case actionType.OPEN_HUB:
      return updateObject(state, { model: !state.model, hubRx: true });
    default:
      return state;
  }
};

export default reducer;
