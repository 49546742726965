import React, { Component, PureComponent } from "react";
import styles from "./CustomMapLocation.module.scss";
import SearchMarker from "../../assets/searchMarker.svg";
import PenSquare from "../../assets/PenSquare.svg";
import Icon from "../../Componnet/Icon/Icon";
import { getEntityName } from "../../Store/localStoreageManager/localStoreageManager";
import Controller from "../../Controller";
import Sites from "./Sites";
import {
  HISKY_ENTITY,
  OPEN,
  INSERT,
  UPDATE_SITES_DATA,
  HIDE,
} from "../../Util/constTypes";
import HubFilters from "./HubFilters/HubFilters";
const DEFAULT_FILTERS = {
  offline: true,
  online: true,
};
export default class CustomLocation extends PureComponent {
  intialValues = {
    lat: 0,
    lng: 0,
    hiskyID: "",
  };
  googleHight = document.getElementById("map_canvas").clientHeight;
  _originalSitesList = [];
  clickCount = 0;
  singleClickTimer = null;
  state = {
    customPosition: this.intialValues,
    showCustomPosition: false, //this.props.isCustomSearchOpen,
    invalidPostions: false,
    filterMode: DEFAULT_FILTERS,
    entityName: getEntityName().toLowerCase(),
    ismapModelOpen: false,
    showSitesList: false,
    sitesList: [],
    terminalsCount: null,
    sitesRemoved: {},
    search: "",
    isNmsToolOn: false,
    showHubsFilter: false,
    hubFilters: [],
    excludedHubs: [],
    isFullScreen: false,
    loading: false,
    disabledBtn: false,
    disabledLatLng: false,
    disabledHiskyId: false,
    disableCustomSearchBtn: false,
  };

  componentDidMount() {
    if (this.props.updateSitesListRef !== undefined) {
      this.props.updateSitesListRef(this.updateListHandler);
    }

    if (this.props.refHubFiltersControls !== undefined) {
      //this.props.refHubFiltersControls(this.setHubsDataHandler);
    }
    if (this.props.siteLoadSetDisabled !== undefined) {
      this.props.siteLoadSetDisabled(this.setModeSiteBtnHandler);
    }
    if (this.props.refFullscreenControls !== undefined) {
      this.props.refFullscreenControls(this.setFullscreenHandler);
    }
    if (this.props.refDisableCustomSearchBtn !== undefined) {
      this.props.refDisableCustomSearchBtn(this.setCustomSearchBtnHandler);
    }
  }
  //Hub filters
  setHubsDataHandler = (data) => {
    this.setState({ hubFilters: data });
  };
  setModeSiteBtnHandler = () => {
    this.setState({ disabledBtn: false });
  };
  setCustomSearchBtnHandler = () => {
    this.setState({ disableCustomSearchBtn: false });
  };
  updateListHandler = (action, data) => {
    if (action === OPEN) {
      this.setState({
        showSitesList: true,
        sitesList: [],
        loading: true,
        filterMode: DEFAULT_FILTERS,
      });
    } else if (action === HIDE) {
      this.setState({
        showSitesList: false,
        sitesList: [],
        loading: false,
        filterMode: DEFAULT_FILTERS,
      });
    } else if (action === INSERT) {
      this._originalSitesList = [...data.list];
      this.setState({
        loading: false,
        sitesList: this.filterDataHandler(data.list),
        terminalsCount:
          this.state.terminalsCount === null
            ? data.terminalsCount
            : this.state.terminalsCount,
      });
    } else if (action == UPDATE_SITES_DATA) {
      let listCopy = [...this.state.sitesList];
      this.setState({
        sitesList: data.list != undefined ? data.list : listCopy,
        terminalsCount: data.terminalsCount,
      });
    }
  };
  filterDataHandler = (data) => {
    let temp = [];
    let search = this.state.search.toLowerCase();
    if (search.trim() === "") {
      return data;
    }
    for (let i = 0; i < data.length; i++) {
      if (data[i].title.toLowerCase().indexOf(search) !== -1) {
        temp.push(data[i]);
      }
    }
    return temp;
  };
  ischecked = (item) => {
    const index = this.state.excludedHubs.indexOf(parseInt(item.id));
    if (index > -1) return false;
    else return true;
  };
  onCheckHandler = (item) => {
    let excludeList = [...this.state.excludedHubs];
    const index = excludeList.indexOf(parseInt(item.id));
    if (index > -1) excludeList.splice(index, 1);
    else excludeList.push(parseInt(item.id));

    this.setState({ excludedHubs: excludeList }, () => {
      this.props.excludedHubs(this.state.excludedHubs);
    });
  };
  resetSitesRemoved = () => {
    this.setState({ sitesRemoved: {} });
  };
  showLatLngHandler = () => {
    this.props.checkStatusScreen("control", (res) => {
      if (res) return;
      this.setState(
        { showCustomPosition: !this.state.showCustomPosition },
        () => {
          this.props.customSearchOpenHandler(this.state.showCustomPosition);
          if (!this.state.showCustomPosition) {
            this.setState(
              {
                invalidPostions: false,
                customPosition: this.intialValues,
                disabledLatLng: false,
                disabledHiskyId: false,
              },
              () => {
                // this.props.setSearchHiskyId("");
              }
            );
          }
        }
      );
    });
  };
  showSitesOnClickHandler = () => {
    //close the polyline
    this.setState({ disabledBtn: !this.state.disabledBtn }, () => {
      this.props.cancelPolyline();
      this.props.showSites();
    });
  };
  showSitesHandler = () => {
    this.clickCount++;
    clearTimeout(this.singleClickTimer);
    this.singleClickTimer = null;
    this.singleClickTimer = setTimeout(() => {
      this.clickCount = 0;
      this.showSitesOnClickHandler();
    }, 100);
  };
  onchangeHandler = (event) => {
  
    let customPositionCopy = { ...this.state.customPosition };
    let disabledHiskyIdTemp = event.target.value == "" ? false : true;
    customPositionCopy[event.target.name] = event.target.value.trim();
    this.setState(
      {
        customPosition: customPositionCopy,
        invalidPostions: false,
        disabledHiskyId: disabledHiskyIdTemp,
      },
      () => {}
    );
  };
  onChangeHiskyIdHandler = (event) => {
    let customPositionCopy = { ...this.state.customPosition };
    customPositionCopy[event.target.name] = event.target.value.trim();
    let disabledLatLngTemp = event.target.value === "" ? false : true;
    this.setState({
      customPosition: customPositionCopy,
      disabledLatLng: disabledLatLngTemp,
    });
  };
  moveToLocationHandler = () => {
    this.props.checkStatusScreen("control", (res) => {
      let invalidPositions = false,
        invalidHiskyId = false;
      let intiateHiskyIdSearch = false,
        intiatePositionsSerch = false;
      if (!res) {
        if (
          this.state.customPosition.lat != "" &&
          this.state.customPosition.lng != ""
        ) {
          if (
            this.checkValidLat(this.state.customPosition.lat) &&
            this.checkValidLng(this.state.customPosition.lng)
          ) {
            intiatePositionsSerch = true;
            invalidPositions = false;
          } else {
            invalidPositions = true;
          }
        } else if (
          (this.state.customPosition.hiskyID.trim() == "" &&
            (this.state.customPosition.lat == "" ||
              this.state.customPosition.lng == "")) ||
          (this.state.customPosition.hiskyID.trim() != "" &&
            (this.state.customPosition.lat != "" ||
              this.state.customPosition.lng != ""))
        )
          invalidPositions = true;
        if (this.state.customPosition.hiskyID.trim() !== "") {
          if (
            this.state.customPosition.hiskyID.trim() !== "" &&
            !isNaN(this.state.customPosition.hiskyID.trim())
          ) {
            intiateHiskyIdSearch = true;
            invalidHiskyId = false;
          } else {
            invalidHiskyId = true;
          }
        }
        if (!invalidPositions && !invalidHiskyId) {
          this.setState(
            {
              invalidPostions: false,
              disableCustomSearchBtn: !this.state.disableCustomSearchBtn,
            },
            () => {
              intiatePositionsSerch &&
                this.props.moveToLocationHandler(this.state.customPosition);
              if (intiateHiskyIdSearch) {
                this.props.setSearchHiskyId(this.state.customPosition.hiskyID);
              }
            }
          );
        } else this.setState({ invalidPostions: true });
      }
    });
  };

  onClickDrawingManagerHandler = () => {
    this.props.checkStatusScreen("control", (res) => {
      if (res) return;
      this.setState({ isNmsToolOn: !this.state.isNmsToolOn }, () => {
        if (this.state.isNmsToolOn) {
          this.props.deleteDrawing();
          this.props.addDrawingManagerHandler();
        } else {
          this.props.deleteDrawing();
        }
      });
    });
  };

  onClickStatHandler = (type) => {
    let copy = { ...this.state.filterMode };
    copy[type] = !copy[type];
    this.setState({ filterMode: copy }, () => {
      this.props.changeModeSiteonline(type, this.state.filterMode[type]);
    });
  };
  checkValidLng(value) {
    if (!value) return false;
    //if(value >=-180 && value<=180) return true; else return false;
    //var lngExp = /^-?([1]?[1-7][1-9]|[1]?[1-8][0]|[1-9]?[0-9])(?:(?:\.[0-9]{1,6})?)/;
    // var lngExp =
    //   /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/;
    if (parseFloat(value)>180 ||parseFloat(value)<-180 ) {
      return false;
    } else {
      return true;
    }
  }
  checkValidLat(value) {
    if (!value) return false;
    //if(value >=-90 && value<=90) return true; else return false;
    //var latExp = /^-?([0-8]?[0-9]|[0-9]0)\.{0,1}\d{1,6}/;
   // var latExp =
     // /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/;
    if (parseFloat(value)>90 ||parseFloat(value)<-90 ) {
      return false;
    } else {
      return true;
    }
  }
  onClickSiteItemHandler = (item, index) => {
    let listCopy = [...this.state.sitesList];
    let sitesRemovedCopy = { ...this.state.sitesRemoved };
    if (listCopy[index].checked == undefined) {
      listCopy[index].checked = false;
    } else {
      listCopy[index].checked = !listCopy[index].checked;
    }
    if (!listCopy[index].checked) {
      sitesRemovedCopy[item.siteId] = { ...item };
      //check
    } else {
      delete sitesRemovedCopy[item.siteId];
      //uncheck
    }
    this.props.allowRecenter(listCopy[index].checked);
    this.setState({ sitesRemoved: sitesRemovedCopy }, () => {
      this.props.filterSitesAndDevices(sitesRemovedCopy);
    });
  };
  onMouseBtnHandler = () => {
    this.props.checkStatusScreen("control", (res) => {
      this.setState({ ismapModelOpen: res });
    });
  };
  onClickClearHandler = () => {
    let copyFilter = { ...this.state.filterMode };
    copyFilter.online = true;
    copyFilter.offline = true;
    this.setState({ sitesRemoved: {}, filterMode: copyFilter });
    this.props.resetSitesAndDevices((data) => {
      this.setState({
        terminalsCount: data.terminalsCount,
      });
    });
  };
  onChangeSearchHandler = (event) => {
    if (event.target.value.trim() === "") {
      this.setState({
        search: "",
        sitesList: this._originalSitesList,
      });
    } else {
      this.setState({ search: event.target.value }, () => {
        this.onClickSearchHandler();
      });
    }
  };
  onKeyDownHandler = (e) => {
    if (e.key === "Enter") {
      this.onClickSearchHandler();
    }
  };
  onSearchClearHandler = () => {
    this.setState({
      search: "",
      sitesList: this._originalSitesList,
    });
  };

  onClickSearchHandler = () => {
    let search = this.state.search.trim().toLowerCase();
    if (search === "") {
      return;
    } else {
      let arrMatch = [];
      this._originalSitesList.map((site) => {
        //loop and push all matched devices
        try {
          if (site.title.toLowerCase().indexOf(search) !== -1) {
            arrMatch.push(site);
          }
        } catch (error) {}
      });

      this.setState({
        sitesList: arrMatch,
      });
    }
  };
  setHubFiltersDataHandler = (data) => {
    this.setState({ hubFilters: data });
  };
  showHubsFilterHandler = () => {
    this.setState(
      { showHubsFilter: !this.state.showHubsFilter, excludedHubs: [] },
      () => {
        this.props.refHubFiltersControls(
          this.setHubFiltersDataHandler,
          this.state.showHubsFilter
        );
      }
    );
  };
  setFullscreenHandler = (data) => {
    this.setState({ isFullScreen: data }, () => {});
  };
  render() {
    return (
      <div className={styles.leftWrapperDiv} id="contralLeft">
        <div className={styles.topRow}>
          {getEntityName() == HISKY_ENTITY && (
            <div>
              <button
                id="hubsFilterControl"
                onClick={this.showHubsFilterHandler}
                className={styles.sitesBtn}
                disabled={this.state.disabledBtn}
                onMouseOver={this.onMouseBtnHandler}
                style={{
                  cursor: this.state.ismapModelOpen ? "default" : "pointer",
                }}
              >
                <span className={styles.icon}>
                  <Icon
                    defination={{ prefix: "fas", iconName: "tablet-rugged" }}
                  />
                </span>
                <span style={{ marginRight: ".5rem", marginLeft: "0.5rem" }}>
                  {"Hubs"}
                </span>
                {this.state.showHubsFilter && (
                  <span className={styles.closeIcon}>
                    <Icon defination={{ prefix: "fas", iconName: "times" }} />
                  </span>
                )}
              </button>
            </div>
          )}
          {(Controller.getActionAuth("nms", "SITES") ||
            Controller.getActionAuth("bss", "SITES")) &&
            getEntityName() != HISKY_ENTITY && (
              <div>
                <button
                  id="sitesControl"
                  onClick={this.showSitesHandler}
                  className={styles.sitesBtn}
                  onMouseOver={this.onMouseBtnHandler}
                  style={{
                    cursor: this.state.ismapModelOpen ? "default" : "pointer",
                  }}
                  disabled={this.state.disabledBtn}
                >
                  <span className={styles.icon}>
                    <Icon
                      defination={{ prefix: "fas", iconName: "globe-americas" }}
                    />
                  </span>
                  <span style={{ marginRight: ".5rem" }}>{"Sites"}</span>
                  {this.state.showSitesList && (
                    <span className={styles.closeIcon}>
                      <Icon defination={{ prefix: "fas", iconName: "times" }} />
                    </span>
                  )}
                </button>
              </div>
            )}
          <div>
            {(Controller.getActionAuth("nms", "SEARCH_MAP") ||
              Controller.getActionAuth("bss", "SEARCH_MAP")) && (
              <button
                id="polygonControl"
                onClick={this.showLatLngHandler}
                className={styles.searchBtn}
                onMouseOver={this.onMouseBtnHandler}
                style={{
                  cursor: this.state.ismapModelOpen ? "default" : "pointer",
                }}
              >
                <span className={styles.tool}>
                  <img
                    className={styles.iconSearch}
                    src={SearchMarker}
                    alt="pen"
                  />
                  Search
                </span>
                {this.state.showCustomPosition && (
                  <span className={styles.closeIcon}>
                    <Icon defination={{ prefix: "fas", iconName: "times" }} />
                  </span>
                )}
              </button>
            )}
          </div>
          {(Controller.getActionAuth("nms", "NMS_TOOL") ||
            Controller.getActionAuth("bss", "NMS_TOOL")) && (
            <div>
              <button
                id="drawingControl"
                className={styles.nmsTool}
                onMouseOver={this.onMouseBtnHandler}
                style={{
                  cursor: this.state.ismapModelOpen ? "default" : "pointer",
                }}
                onClick={this.onClickDrawingManagerHandler}
              >
                <span className={styles.tool}>
                  <img
                    className={styles.iconSearch}
                    src={PenSquare}
                    alt="pen"
                  />
                  NMS Tool
                </span>
                {this.state.isNmsToolOn && (
                  <span className={styles.closeIcon}>
                    <Icon defination={{ prefix: "fas", iconName: "times" }} />
                  </span>
                )}
              </button>
            </div>
          )}
        </div>
        <div className={styles.wrapperSiteAndInputs}>
          <div
            id="locationWrapperInputs"
            style={{
              display: this.state.showCustomPosition ? "block" : "none",
            }}
            className={
              this.state.showCustomPosition
                ? `${styles.leftDiv} ${styles.open}`
                : styles.leftDiv
            }
          >
            <div className={styles.inputsWrapper}>
              <input
                type="text"
                value={
                  this.state.customPosition.lat
                    ? this.state.customPosition.lat
                    : ""
                }
                name={"lat"}
                placeholder="Latitude"
                disabled={this.state.disabledLatLng}
                className={styles.LatLngInput}
                onChange={(e) => this.onchangeHandler(e)}
              />
              <input
                type="text"
                value={
                  this.state.customPosition.lng
                    ? this.state.customPosition.lng
                    : ""
                }
                name={"lng"}
                disabled={this.state.disabledLatLng}
                placeholder="Longitude"
                className={styles.LatLngInput}
                onChange={(e) => this.onchangeHandler(e)}
              />
              <input
                type="text"
                value={
                  this.state.customPosition.hiskyID
                    ? this.state.customPosition.hiskyID
                    : ""
                }
                name={"hiskyID"}
                disabled={this.state.disabledHiskyId}
                placeholder="hiSky ID"
                className={styles.inputHiskySearch}
                onChange={(e) => this.onChangeHiskyIdHandler(e)}
              />
              <button
                id="recenter"
                onClick={this.moveToLocationHandler}
                className={styles.okBtn}
                disabled={this.state.disableCustomSearchBtn}
              >
                <Icon defination={{ prefix: "fas", iconName: "search" }} />
              </button>
            </div>
            <div
              className={
                this.state.invalidPostions
                  ? `${styles.error} ${styles.showError}`
                  : styles.error
              }
            >
              Invalid data
            </div>
          </div>

          {this.state.showSitesList && (
            <Sites
              sitesList={this.state.sitesList}
              onClickSiteItem={this.onClickSiteItemHandler}
              showSites={this.showSitesHandler}
              removeSite={Object.keys(this.state.sitesRemoved)}
              terminalsCount={this.state.terminalsCount}
              clearHandler={this.onClickClearHandler}
              click={this.onClickSearchHandler}
              googleDiv={this.googleHight}
              change={(e) => this.onChangeSearchHandler(e)}
              value={this.state.search}
              onKeyDown={this.onkeyDownHandler}
              clickClear={this.onSearchClearHandler}
              resetSitesRemoved={this.resetSitesRemoved}
              clickStat={this.onClickStatHandler}
              online={this.state.filterMode.online}
              offline={this.state.filterMode.offline}
              showCustomPosition={this.state.showCustomPosition}
              fullScreen={this.props.checkForFullscreen}
              isFullScreen={this.state.isFullScreen}
              loading={this.state.loading}
            />
          )}
          {this.state.showHubsFilter && (
            <HubFilters
              hubFilters={this.state.hubFilters}
              closeFilters={this.showHubsFilterHandler}
              showCustomPosition={this.state.showCustomPosition}
              googleDiv={this.googleHight}
              onCheck={this.onCheckHandler}
              ischecked={this.ischecked}
              isFullScreen={this.state.isFullScreen}
            />
          )}
        </div>
      </div>
    );
  }
}
