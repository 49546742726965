import React, { Component, Fragment } from "react";
import styles from "./AccountAndUsers.module.scss";
import SpinnerLoader from "../../../Componnet/SpinnerLoader/SpinnerLoader";
import moment from "moment";
import { withRouter } from "react-router-dom";
import Icon from "../../../Componnet/Icon/Icon";
import * as route from "../../../Util/routes";
import { getEmptyRows } from "../../../Util/globalFunc";
import Controller from "../../../Controller";
class AccountAndUsers extends Component {
  state = {
    data: [],
    loading: true,
  };
  componentDidMount() {
    
    this.intDataHandler(this.props.data);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.data.length >= this.state.data.length) {
      this.intDataHandler(nextProps.data);
    }
  }
  intDataHandler = (data) => {
    if (data.length === 0) {
      this.setState({ loading: false });
    }

    this.setState({ loading: false, data: data });
  };
  getFormatDateHandler = (date) => {
    return date !== undefined || date != null
      ? moment(date).format("MM/DD/YYYY")
      : "N/A";
  };
  getImageHandler = (image) => {
    if (image == null || image == undefined || image==="") {
      return (
        <span className={styles.iconAvatar}>
          <Icon defination={{ prefix: "far", iconName: "user-circle" }} />
        </span>
      );
    } else {
      return <img className={styles.image} src={image} alt="account logo" />;
    }
  };
  onClickUserHadnler = () => {
    if (Controller.getAuthComponent("bss", "ACCOUNTS")) {
      this.props.history.push(route.ACCOUNTS_TREE);
    }
  };
  getGeneralInfoHandler = (item) => {
    if (item.firstName === "") {
      return null;
    } else {
      let nameDetail;
      if(item.users[0].account.name !== '' && item.users[0].account.name !== undefined && item.users[0].account.name !== null){
        nameDetail = item.users[0].account.name;
      } else {
        nameDetail = item.users[0].firstName
          ? item.users[0].firstName + ' '
          : "" + item.users[0].lastName
          ? item.users[0].lastName
          : ""
      }
      return (
        <div>
          <span className={styles.infoUser}>
            {nameDetail}
          </span>
          {/* <span className={styles.infoUser}>
            {item.users[0].lastName.lastName
              ? item.users[0].lastName.lastName
              : ""}
          </span> */}
        </div>
      );
    }
  };
  buildCardsHandler = () => {
    if (this.state.data.length === 0) {
      return getEmptyRows(styles.card, 10);
    } else {
      return this.state.data.map((item, index) => {
        return (
          <div
            key={index}
            className={styles.card}
            onClick={this.onClickUserHadnler}
          >
            <p className={styles.index}>{`${index + 1}.`}</p>
            <div className={styles.imageCardWrapper}>
              {this.getImageHandler(item.users[0].avatar)}
            </div>
            <div className={styles.infoCard}>
              <Fragment>{this.getGeneralInfoHandler(item)}</Fragment>
              <p>{item.users[0].email ? item.users[0].email : "N/A"}</p>
              <p className={styles.dateBelow}>
                {this.getFormatDateHandler(item.users[0].dateJoined)}
              </p>
            </div>
            <p className={styles.dateRight}>
              {this.getFormatDateHandler(item.users[0].dateJoined)}
            </p>
          </div>
        );
      });
    }
  };
  render() {
    return (
      <div className={styles.AccountAndUsers}>
        <div className={styles.header}>
          <h3 className={styles.title}>Accounts & Users</h3>
          <span className={styles.icon}>
            <Icon defination={{ prefix: "fal", iconName: "building" }} />
          </span>
        </div>
        <div className={styles.breakLineTitle} />
        <div className={styles.companyDataWrapper}>
          {this.buildCardsHandler()}
        </div>
        <div
          className={styles.spinnerWrapper}
          style={{ display: this.state.loading ? "block" : "none" }}
        >
          <SpinnerLoader msg="Loading Company" />
        </div>
      </div>
    );
  }
}
export default withRouter(AccountAndUsers);
