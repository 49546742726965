import React, { Component } from "react";
import { HISKY_ID } from "../../Util/constTypes";
import styles from "./KeyValue.module.scss";
export default class KeyValue extends Component {
  render() {
    const { index = 0, wrapperStyles, labelStyles, item } = this.props;
   
    return (
      <div
        className={wrapperStyles ? wrapperStyles : styles.itemWrapper}
        key={index ? index : ""}
      >
        <label
          className={
            item.label.toLowerCase() === HISKY_ID.toLowerCase()
              ? `${styles.itemLabel} ${styles.genericLabel}`
              : styles.itemLabel
          }
        >
          {item.label.toLowerCase()==HISKY_ID.toLowerCase() ?HISKY_ID:item.label}
        </label>
        <label
          className={styles.itemValue}
          style={labelStyles ? labelStyles : {}}
        >
          {item.value ? item.value : "N/A"}
        </label>
      </div>
    );
  }
}
