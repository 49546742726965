import React, { Component } from "react";
import styles from "./ResetPassword.module.scss";
import Gallery from "../Gallery/Gallery";
import Logo from "../../../assets/logo.png";
import Icon from "../../../Componnet/Icon/Icon";
import Input from "../../../Componnet/Input/Input";
import SingleButton from "../../../Componnet/SingleButton/SingleButton";
import { http } from "../../../RequestManager/Http";
import { updatePasswordRequest} from "../../../RequestManager/QueriesAndMutations";
import * as routes from "../../../Util/routes";
class ResetPassword extends Component {
  state = {
    loading: false,
    validUser: false,
    passwords: {
      firstpass: {
        value: "",
        type: "password",
        error: false,
        errMsg: "The password is not valid",
      },
      secondPass: {
        value: "",
        error: false,
        type: "password",
        errMsg: "The email is not valid",
      },
    },
  };
  componentDidMount() {}
  validPasswordHandler = () => {
    let isValid = true;
    let passowrds = { ...this.state.passwords };
    if (passowrds.secondPass.value === "" || passowrds.firstpass.value === "") {
      passowrds.firstpass.error = true;
      this.setState({ passowrds });
      isValid = false;
    } else if (passowrds.secondPass.value !== passowrds.firstpass.value) {
      passowrds.firstpass.error = true;
      this.setState({ passowrds });
      isValid = false;
    }
    return isValid;
  };
  checkresponServerHandler = (res) => {
    if (res.errors !== undefined) {
      this.setState({
        validUser: true,
        loading: false,
        errorMsg: res.errors[0].message.replace(/_/g, " "),
      });
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      const continue_to = urlParams.get('continue_to');
      if (continue_to){
     
        window.location.href = "//" + decodeURIComponent(continue_to);
      }else{
        this.props.history.push(routes.LOGIN);
      }
    }
  };
  changeVisiblePasswordHandler = (name) => {
    let pass = { ...this.state.passwords };
    pass[name].type =
      pass[name].type === "password" ? "text" : "password";

    this.setState({ passowrds: pass });
  };
  onClickreSetHandler = (event) => {
    event.preventDefault();
    if (this.validPasswordHandler()) {
      this.setState({ loading: true }, () => {
        http(updatePasswordRequest, {
          pw_update_token: this.props.match.params.id,
          password: this.state.passwords.secondPass.value,
        })
          .then((res) => {
            this.checkresponServerHandler(res);
          })
          .catch((err) => {});
      });
    }
  };

  onChangeHandler = (event) => {
    if (this.state.loading) return;
    let copyUser = { ...this.state.passwords };
    copyUser[event.target.name].value = event.target.value.trim();
    copyUser[event.target.name].error = false;
    this.setState({ passwords: copyUser, validUser: false, errorMsg: "" });
  };
  render() {
    const { firstpass, secondPass } = this.state.passwords;
    return (
      <div className={styles.ResetPasswords}>
        <Gallery />
        <div className={styles.resetPasswordWrapper}>
          <form onSubmit={this.onClickreSetHandler}>
            <div className={styles.logo}>
              <img className={styles.photo} src={Logo} alt="logo-hi-sky" />
            </div>
            <h4 className={styles.secondHeader}>Change password</h4>
            <p className={styles.msg}>
              Please enter new passowrd
              <span
                className={styles.spinnerIcon}
                style={{
                  visibility: !this.state.loading ? "hidden" : "visible",
                }}
              >
                <Icon
                  isSpinner={true}
                  defination={{ prefix: "fal", iconName: "spinner" }}
                />
              </span>
            </p>
            <div className={styles.inputGroup}>
              <Input
                name="firstpass"
                inputWrapper={styles.inputWrapper}
                placeholder="New password"
                label="new Password"
                error={firstpass.error}
                type={firstpass.type}
                errMsg={firstpass.errMsg}
                iconDefination={{ prefix: "far", iconName: "eye-slash" }}
                iconStyles={styles.visiblePassword}
                iconAction={()=>this.changeVisiblePasswordHandler('firstpass')}
                change={(e) => this.onChangeHandler(e)}
                value={firstpass.value}
              />
              <Input
                name="secondPass"
                inputWrapper={styles.inputWrapper}
                placeholder="Repeat password"
                label="repeat Password"
                error={secondPass.error}
                type={secondPass.type}
                errMsg={secondPass.errMsg}
                iconDefination={{ prefix: "far", iconName: "eye-slash" }}
                iconStyles={styles.visiblePassword}
                iconAction={()=>this.changeVisiblePasswordHandler('secondPass')}
                change={(e) => this.onChangeHandler(e)}
                value={secondPass.value}
              />
            </div>
            <div className={styles.breakLine}></div>
            <p
              className={styles.error}
              style={{
                visibility: this.state.validUser ? "visible" : "hidden",
              }}
            >
              {this.state.errorMsg}
            </p>
            <div className={styles.btnSubmitControl}>
              <SingleButton
                text="reset"
                click={this.onClickreSetHandler}
                disabled={this.state.loading}
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
