import React, { Component, Fragment } from "react";
import styles from "./DataUsageChart.module.scss";
import moment from "moment";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import LineChart from "../Charts/LineChart/LineChart";
import Icon from "../Icon/Icon";
import { connect } from "react-redux";
import {
  INBOUND,
  OUTBOUND,
  OUTGOING_SECONDS,
  INCOMING_SECONDS,
  OUT_CALL,
  IOT,
  VOIP,
  IN_CALL,
  INBOUND_BYTE,
  OUTBOUND_BYTE,
  BYTES,
  SECONDS,
  LOCAL_TIME_LEGAL_MESSAGE
} from "../../Util/constTypes";
import * as actionType from "../../Store/actions/index";
import { byteToString, getTimeZone } from "../../Util/globalFunc";
import { getEntityName } from "../../Store/localStoreageManager/localStoreageManager";
import Tabs from "../Tabs/Tabs";
import { USAGE_TABS } from "../../Util/constants";

import { http } from "../../RequestManager/Http";
import { getDataUsageV2 } from "../../RequestManager/QueriesAndMutations";
import * as route from "../../Util/routes";
class DataUsageChart extends Component {
  netType = IOT;
  IN_KEY = "totalSend";
  OUT_KEY = "totalRecv";
  key_voip_in = "incomingDuration";
  key_voip_out = "outgoingDuration";
  key_iot_in = "totalSend";
  key_iot_out = "totalRecv";
  state = {
    loading: true,
    sendRecv: [],
    toggle: false,
    labelsTimeSet: [],
    tab: 0,
    totalSend: 0,
    totalDuration: 0,
    dataSetIot: [], ///server data
    dataSetVoip: [], ////server data
    data: [], ////chart
    datafilter: {
      inbound: {
        data: {},
        show: true,
      },
      outbound: {
        data: {},
        show: true,
      },
    },
  };
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.data.length !== this.state.dataSetIot.length ||
      nextProps.userId != this.props.userId
    ) {
      this.setState(
        {
          tab: 0,
          loading: false,
          dataSetIot: nextProps.data,
        },
        () => {
          this.initKeysHandler(this.state.tab);
          this.bulidDataSetChartHandler(nextProps.data, IOT);
        }
      );
    }
  }
  initKeysHandler = (tab) => {
    this.IN_KEY = tab === 0 ? this.key_iot_in : this.key_voip_in;
    this.OUT_KEY = tab == 0 ? this.key_iot_out : this.key_voip_out;
  };
  componentDidMount() {
    this.setState(
      {
        loading: false,
        dataSetIot: this.props.data,
        tab: 0,
        dataSetVoip:
          this.props.usageVoip !== undefined ? this.props.usageVoip : [],
      },
      () => {
        this.bulidDataSetChartHandler([...this.state.dataSetIot], IOT);
      }
    );
  }
  convertDateHandler = (ts) => {
    return moment(ts).format("MM/DD/YYYY HH:mm:ss");
  };

  bulidDataSetChartHandler = (data, type) => {
    let recv = {};
    let send = {};
    let dates = [];
    let totalReceive = 0;
    let totalSend = 0;
    var startDate = moment().format("MM-DD-YYYY");
    startDate = moment(startDate, "MM-DD-YYYY")
      .subtract(6, "days")
      .format("MM-DD-YYYY");
    for (let i = 0; i < 7; i++) {
      if (i == 0) {
        dates.push(startDate);
        recv[startDate] = 0;
        send[startDate] = 0;
      } else {
        startDate = moment(startDate, "MM-DD-YYYY")
          .add(1, "days")
          .format("MM-DD-YYYY");
        recv[startDate] = 0;
        send[startDate] = 0;
        dates.push(startDate);
      }
    }
    for (let i = 0; i < data.length; i++) {
      let dataSet = data[i];
      let local = dataSet.date;
      recv[moment(local).format("MM-DD-YYYY")] = dataSet[this.IN_KEY];
      send[moment(local).format("MM-DD-YYYY")] = dataSet[this.OUT_KEY];
      totalSend += dataSet[this.OUT_KEY];
      totalReceive += dataSet[this.IN_KEY];
    }
    let sendAndRecv = [
      {
        data: Object.values(recv),
        label: this.state.tab === 0 ? INBOUND_BYTE : INCOMING_SECONDS,
        borderColor: "#00BDD6",
        pointBackgroundColor: "#00BDD6",
        pointBorderWidth: 4,
        pointHoverRadius: 6,
        fill: false,
        pointHitRadius: 10,
        name: "inbound",
        backgroundColor: "#00BDD6",
      },
      {
        data: Object.values(send),
        label: this.state.tab == 0 ? OUTBOUND_BYTE : OUTGOING_SECONDS,
        pointBorderWidth: 4,
        pointHoverRadius: 6,
        fill: false,
        pointHitRadius: 10,
        pointBackgroundColor: "#C4C4C4",
        borderColor: "#C4C4C4",
        backgroundColor: "#C4C4C4",
        name: "outbound",
      },
    ];
    this.setState(
      {
        totalReceive: totalReceive,
        totalSend: totalSend,
        sendRecv: sendAndRecv,
        labelsTimeSet: dates,
        data: sendAndRecv,
        loading: false,
        dataSetIot: type === IOT ? data : this.state.dataSetIot,
        dataSetVoip: type === VOIP ? data : this.state.dataSetVoip,
      },
      () => {}
    );
  };
  removeAddDataChartHandler = (key_d, status) => {
    let copy_filter = { ...this.state.datafilter };
    let data_copy = [...this.state.data];

    if (status) {
      if (data_copy.length === 0) {
        let temp = { ...copy_filter[key_d].data };
        copy_filter[key_d].data = {};
        data_copy.push(temp);
        return { data_copy: data_copy, copy_filter: copy_filter };
      }
      if (data_copy[0].name === key_d) {
        let temp = { ...copy_filter[key_d].data };
        copy_filter[key_d].data = {};
        data_copy.push(temp);
        return { data_copy: data_copy, copy_filter: copy_filter };
      } else {
        let temp = { ...copy_filter[key_d].data };
        copy_filter[key_d].data = {};
        data_copy.push(temp);
        return { data_copy: data_copy, copy_filter: copy_filter };
      }
    }
    for (let i = 0; i < data_copy.length; i++) {
      if (data_copy[i].name === key_d) {
        copy_filter[key_d].data = data_copy[i];
        data_copy.splice(i, 1);

        return { data_copy: data_copy, copy_filter: copy_filter };
      }
    }
  };
  onClickLegendHandler = (name) => {
    if (this.props.loading || this.state.loading) return;
    let res = this.removeAddDataChartHandler(
      name,
      !this.state.datafilter[name].show
    );

    let copy_filter_data = { ...this.state.datafilter };
    copy_filter_data = res.copy_filter;
    let data = [...this.state.data];
    data = [...res.data_copy];
    copy_filter_data[name].show = !this.state.datafilter[name].show;
    this.setState({ datafilter: copy_filter_data, data: data }, () => {});
  };

  converSecondsHandler = (time) => {
    if (time < 3600) {
      return new Date(time * 1000).toISOString().substr(14, 5);
    } else {
      return new Date(time * 1000).toISOString().substr(11, 8);
    }
  };
  createHtttpRequesthandler = () => {
    let obj = {
      prefix: "7D",
      account_id: this.props.accountId,
      date_from: "",
      date_to: "",
      //netType: VOIP,
      includeVoip: true,
      localTz: getTimeZone(),
    };

    http(getDataUsageV2, obj)
      .then((res) => {
        this.bulidDataSetChartHandler(res.data.usageDataV2.usageVoip, VOIP);
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };
  getTotalAmountsOfChart = () => {
    if (this.state.loading || this.props.loading) return;

    return (
      <Fragment>
        <span className={styles.amountWrapper} style={{ color: "#C4C4C4" }}>
          <p style={{ paddingRight: "0.2rem" }}>
            {this.state.tab == 0 ? OUTBOUND : OUT_CALL}
          </p>
          <p style={{ textTransform: "capitalize" }}>
            {this.state.tab == 0
              ? byteToString(this.state.totalSend)
              : this.converSecondsHandler(this.state.totalSend)}
          </p>
        </span>
        <span className={styles.amountWrapper} style={{ color: "#00BDD6" }}>
          <p style={{ paddingRight: "0.2rem" }}>
            {this.state.tab == 0 ? INBOUND : IN_CALL}
          </p>
          <p style={{ textTransform: "capitalize" }}>
            {this.state.tab == 0
              ? byteToString(this.state.totalReceive)
              : this.converSecondsHandler(this.state.totalReceive)}
          </p>
        </span>
      </Fragment>
    );
  };
  onClickChangeTabHandler = (tab) => {
    if (!this.state.loading) {
      this.initKeysHandler(tab);
      let dataFilter_copy = { ...this.state.datafilter };

      dataFilter_copy.inbound.show = true;
      dataFilter_copy.inbound.data = {};
      dataFilter_copy.outbound.show = true;
      dataFilter_copy.outbound.data = {};
      this.setState({ datafilter: dataFilter_copy, tab: tab }, () => {
        if (
          this.state.tab !== 0 &&
          this.state.dataSetVoip.length == 0 &&
          window.location.pathname.indexOf(route.DEVICES_HUB) == -1
        ) {
          this.setState({ loading: true }, () => {
            this.createHtttpRequesthandler();
          });
        } else {
          this.bulidDataSetChartHandler(
            this.state.tab == 0
              ? this.state.dataSetIot
              : this.state.dataSetVoip,
            this.state.tab == 0 ? IOT : VOIP
          );
        }
      });
    }
  };
  tabContentBuilder = () => {
    return (
      <>
        <div
          // className={styles.topWrapper}
          className={
            this.props.topWrapperstyles
              ? this.props.topWrapperstyles
              : styles.topWrapper
          }
        >
          <div className={styles.totalAmountWrapper}>
            {this.getTotalAmountsOfChart()}
          </div>
          <div
            className={styles.datesWrapper}
            style={{
              visibility: this.state.sendRecv.length ? "visible" : "hidden",
            }}
          >
            <span className={styles.dateDuration}>
              {this.props.min && this.props.min !== "0" && this.state.tab == 0 && (
                <>
                  <p className={styles.text}>Start: </p>
                  <p>{this.convertDateHandler(this.props.min)}</p>
                </>
              )}
              {this.props.minVoip &&
                this.props.minVoip !== "0" &&
                this.state.tab == 1 && (
                  <>
                    <p className={styles.text}>Start: </p>
                    <p>{this.convertDateHandler(this.props.minVoip)}</p>
                  </>
                )}
            </span>
            <span className={styles.dateDuration}>
              {this.props.max && this.props.max !== "0" && this.state.tab == 0 && (
                <>
                  <p className={styles.text}>End: </p>
                  <p>{this.convertDateHandler(this.props.max)}</p>
                </>
              )}
              {this.props.maxVoip &&
                this.props.maxVoip !== "0" &&
                this.state.tab == 1 && (
                  <>
                    <p className={styles.text}>End: </p>
                    <p>{this.convertDateHandler(this.props.maxVoip)}</p>
                  </>
                )}
            </span>
          </div>
        </div>

        <div
          className={
            this.props.styles ? this.props.styles : styles.lineChartWrapper
          }
        >
          <div className={styles.customLegend}>
            <div className={styles.typeWrapper}>
              <span
                onClick={() => this.onClickLegendHandler("inbound")}
                className={`${styles.rectangle}`}
                style={{
                  cursor:
                    this.props.loading || this.state.loading
                      ? "not-allowed"
                      : "pointer",
                  backgroundColor: "#00BDD6",
                  opacity: this.state.datafilter.inbound.show ? 1 : 0.6,
                }}
              >
                <span
                  className={styles.slantLine}
                  style={{
                    visibility: this.state.datafilter.inbound.show
                      ? "hidden"
                      : "visible",
                  }}
                ></span>
              </span>
              <span className={styles.nameChart}>
                {this.state.tab === 0 ? INBOUND : IN_CALL}
              </span>
            </div>
            <div className={styles.typeWrapper}>
              <span
                onClick={() => this.onClickLegendHandler("outbound")}
                className={`${styles.rectangle}`}
                style={{
                  cursor:
                    this.props.loadingChart || this.state.loading
                      ? "not-allowed"
                      : "pointer",
                  backgroundColor: "#C4C4C4",
                  opacity: this.state.datafilter.outbound.show ? 1 : 0.6,
                }}
              >
                <span
                  className={styles.slantLine}
                  style={{
                    visibility: this.state.datafilter.outbound.show
                      ? "hidden"
                      : "visible",
                  }}
                ></span>
              </span>
              <span className={styles.nameChart}>
                {this.state.tab === 0 ? OUTBOUND : OUT_CALL}
              </span>
            </div>
          </div>
          <LineChart
            height={this.props.height}
            width={this.props.width}
            data={this.state.data}
            legend={false}
            labelsTimeSet={this.state.labelsTimeSet}
            disableAspectRatio={this.props.disableAspectRatio}
            yAxLabel={this.state.tab == 0 ? BYTES : SECONDS}
          />
          <div className={styles.runtimeWrapper}>
            {getEntityName() == "hiSky" && this.props.runTime != "" && (
              <span>
                <span className={styles.durationText}>Run Time: </span>
                <span>
                  {this.props.runTime != "" && this.props.runTime != null
                    ? parseFloat(this.props.runTime).toFixed(5)
                    : "N/A"}
                </span>
              </span>
              
            )}
              <p className={styles.durationText}>{LOCAL_TIME_LEGAL_MESSAGE}</p>
          </div>
        
        </div>
      </>
    );
  };
  render() {
    return (
      <div
        className={`${styles.DashBoarChartUsage} ${this.props.shadow}`}
        style={{ opacity: this.state.loading ? 0.6 : 1 }}
      >
        <div className={styles.header} style={this.props.headerStyles}>
          <h3 className={styles.title}>IOT Data Usage</h3>
          {/* <Tabs
            click={this.onClickChangeTabHandler}
            tabArray={USAGE_TABS}
            selected={this.state.tab}
            {...(window.location.pathname == ROOT && {
              tabsWrapper: styles.tabsWrapper,
            })}
            {...(window.location.pathname == ROOT && {
              customStyles: { padding: "0.2rem  0.2rem 0.2rem 0.8rem" },
            })}
          /> */}

          {!this.props.hideUsageIcon && (
            <span
              className={
                this.props.hideTooltip
                  ? `${styles.icon} ${styles.iconNoCursor}`
                  : styles.icon
              }
            >
              {!this.props.hideTooltip && (
                <span className={styles.toolTip}>Usage</span>
              )}
              <Icon defination={{ prefix: "fal", iconName: "chart-line" }} />
            </span>
          )}
        </div>

        <div className={styles.breakLineTitle} />
        {this.tabContentBuilder()}

        <div
          className={styles.spinnerWrapper}
          style={{ display: this.state.loading ? "block" : "none" }}
        >
          <SpinnerLoader />
        </div>
      </div>
    );
  }
}
const mapStateProps = (state) => {
  return {
    updateSiteModelRx: state.model.editSite,
    resetComponentRx: state.common.resetComponentRx,
    resetInnerComponnetRx: state.common.resetInnerComponnetRx,
    reRenderRX: state.common.reRenderRx,
  };
};
const mapStateDispatch = (dispatch) => {
  return {
    addNewSiteSelector: (site) => dispatch(actionType.addNewSiteSelector(site)),
    updateSiteSelector: (site) => dispatch(actionType.updateSiteSelector(site)),
    accountInfoSelector: (accountInfo) =>
      dispatch(actionType.accountInfoSelector(accountInfo)),
    openAssignDeviceToSiteSelecter: () =>
      dispatch(actionType.openAssignDeviceToSiteSelecter()),
    resetInnerComponentSelector: (data) =>
      dispatch(actionType.resetInnerComponentSelector(data)),
    reRenderComponentSelector: (data) =>
      dispatch(actionType.reRenderComponentSelector(data)),
  };
};
export default connect(mapStateProps, mapStateDispatch)(DataUsageChart);
