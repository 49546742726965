import React, { Component } from "react";
import styles from "./SearchBox.module.scss";
import Icon from "../Icon/Icon";
export default class SearchBox extends Component {
  render() {
    const {
      value,
      name,
      inputWrapper,
      placeholder,
      error,
      errMsg,
      id,
    } = this.props;

    return (
      <div
        className={
          this.props.inputWrapper ? inputWrapper : styles.searchByTypes
        }
      >
        <label className={styles.titleSearch}>{this.props.title}</label>
        <div className={styles.searchWrapper}>
          <input
            name={name}
            className={styles.input}
            type="text"
            onChange={this.props.change}
            value={value}
            id={id}
            placeholder={placeholder}
            onKeyDown={this.props.onKeyDown}
          />
          <span
            className={styles.clear}
            onClick={this.props.clickClear}
            style={{ visibility: value !== "" ? "visible" : "hidden" }}
          >
            <Icon defination={{ prefix: "fas", iconName: "times" }} />
          </span>
          <span className={styles.iconSearch} onClick={this.props.click}>
            <Icon defination={{ prefix: "fas", iconName: "search" }} />
          </span>
        </div>
        <p
          className={styles.error}
          style={{ visibility: error ? "visible" : "hidden" }}
        >
          {errMsg}
        </p>
      </div>
    );
  }
}
