import * as actionType from "../actions/types";
import { updateObject } from "./utilReducer";

const initialState = {
  editChannel: {},
  typeOfDate: "",
  deviceId: "",
  updateTerminal: {}, 
  hubDataRx: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    
    case actionType.DATE_TYPE:
      return updateObject(state, { typeOfDate: action.payload });
   
    case actionType.DEVICE_ID:
      return updateObject(state, { deviceId: action.payload });
    case actionType.EDIT_CHANNEL:
      return updateObject(state, { editChannel: action.payload });
    /** terminals and devices */
    case actionType.UPDATE_DEVICE_TERMINAL:
      return updateObject(state, { updateTerminal: action.payload });
   
    case actionType.HUB_DATA:
      return updateObject(state, { hubDataRx: action.payload });
   
    default:
      return state;
  }
};

export default reducer;
