import React from "react";
import Icon from "../Componnet/Icon/Icon";
import MapPinRed from "../assets/MapPinRed.svg";
import MapBluePin from "../assets/MapPinBlue.svg";
import MapGreyPin from "../assets/MapPinGrey.svg";
import MapSearchPin from "../assets/MapSearchPin.svg";

import MapPinGreen from "../assets/MapPinGreen.svg";
import SiteFlag from "../assets/Site.svg";
import { Slide } from "react-toastify";
import * as route from "./routes";
import { isLocalhost } from "./browser";
let host = 'iotarsat.com.ar';
export const INTEGRATION_SERVER = "integration.hiskynms.com";
export const PAGE_LIMIT = 10;
export const PAGE_LIMIT_SYSTEM_LOGS = 20;
export const PAGE_LIMIT_USER_DEVICES = 10;
export const INTITAL_OFFSET = 1;
export const CLOUDINARY_URL = "https://api.cloudinary.com/v1_1/hisky/upload";
export const CLOUDINARY_UPLOAD_PRESET = "iduhvjfq";
export const PICTURE = "picture";
export const FILE = "file";
export const CSV="csv"
export const UPLOAD_PRESET = "upload_preset";
export const GOOGLE_MAP_API_KEY = "AIzaSyAXxhLDqgKZGkewUojgnFfOk96d8JZRbrw";
export const DEFAULT_MAP_LOCATION = [{ lat: 32.0853, lng: 34.7818 }];
export const PROD_HOST = "www.hisky360.com";
let proto =  "https:";
export const TERMINAL_SAMPLE_FILES = {
  csv: `${proto}//${host}:8000/static/samples/terminal.csv`,
  excel: `${proto}//${host}:8000/static/samples/terminal.xlsx`,
};

export const CURRENT_VERSION = "V2.5.0";

export const MIN_POOL_PREFIX = 295;
export const POOL_HISKY_ID_LENGTH = 14;
export const DEFAULT_EXACT_VALUE = false;

export const TERMINAL_ONLINE_COLOR = "rgb(76 175 80)"; //chnage in index.scss
export const SNR_HISTORY_INTIAL_RECORDS_LIMIT = 2000;
/**
 * Log Status options
 */
export const LOGS_STATUS_LIST = [
  {
    id: "",
    label: "Select option",
    icon: "",
    color: "",
  },
  {
    id: "1",
    label: "Software",
    icon: <Icon defination={{ prefix: "far", iconName: "stream" }} />,
    color: "#00bdd6",
  },
  {
    id: "2",
    label: "Digital",
    icon: <Icon defination={{ prefix: "far", iconName: "brackets" }} />,
    color: "#00bdd6",
  },

  {
    id: "3",
    label: "Hardware",
    icon: <Icon defination={{ prefix: "far", iconName: "microchip" }} />,
    color: "#00bdd6",
  },
  {
    id: "4",
    label: "Ticket",
    icon: <Icon defination={{ prefix: "far", iconName: "ticket" }} />,
    color: "#00bdd6",
  },
  {
    id: "5",
    label: "Alert",
    icon: <Icon defination={{ prefix: "far", iconName: "siren" }} />,
    color: "#ff1a1a",
  },
  {
    id: "6",
    label: "Info",
    icon: <Icon defination={{ prefix: "far", iconName: "info-square" }} />,
    color: "#00bdd6",
  },
  {
    id: "7",
    label: "Terminal",
    icon: <Icon defination={{ prefix: "far", iconName: "tablet-rugged" }} />,
    color: "#00bdd6",
  },
  {
    id: "8",
    label: "Hub",
    icon: <Icon defination={{ prefix: "fal", iconName: "tablet-rugged" }} />,
    color: "#00bdd6",
  },
];

export const SL_TYPES = [
  { id: 1, name: "Software" },
  { id: 2, name: "Digital" },
  { id: 3, name: "Hardware" },
  { id: 4, name: "Ticket" },
  { id: 5, name: "Alert" },
  { id: 6, name: "Info" },
  { id: 7, name: "Terminal Info" },
  { id: 8, name: "Hub Info" },
];
/**
 * Google map Pins
 */
export const GOOGLE_PINS = {
  terminal: MapPinGreen, //MapPinPurple,
  hub: MapBluePin,
  mockup: MapGreyPin,
  offlineTerminal: MapPinRed,
  site: SiteFlag,
  searchPin: MapSearchPin,
};

/**Google map buttons */

export const GOOGLE_BUTTONS = {
  hisky: {
    search: true,
    drawingTool: true,
    mockups: true,
    center: true,
    polygonShow: true,
    customLocation: true,
  },
  subscriber: {
    search: false,
    drawingTool: false,
    mockups: false,
    center: true,
    polygonShow: false,
    customLocation: false,
  },
  technician: {
    search: false,
    drawingTool: false,
    mockups: false,
    center: true,
    polygonShow: false,
    customLocation: false,
  },
  company: {
    search: true,
    drawingTool: true,
    mockups: true,
    center: true,
    polygonShow: true,
    customLocation: true,
  },
  reseller: {
    search: true,
    drawingTool: true,
    mockups: true,
    center: true,
    polygonShow: true,
    customLocation: true,
  },
};

/* polygons borders colors */
export const POLYGON_SETTINGS = {
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: "#00bdd6",
  fillOpacity: 0.6,
  strokeColor: "#00aac0",
};
/* toastfiy options */
export const NOTIFICATION_OPTIONS = {
  transition: Slide,
  closeOnClick: true,
  autoClose: 3000,
  //toastId: "customId",
  hideProgressBar: false,
  pauseOnHover: true,
};

// phone verification error codes
export const TELEPHONE_VERIFICATION_MESSAGES = {
  1: "Invalid phone number",
  2: "Region is locked",
  3: "Number not verified",
};
export const TOKEN_VERIFICATION_MESSAGES = {
  1: "Invalid phone number",
  2: "Failed to get token associated to number",
};

//Beam keys
export const BEAM_KEYS = [
  "provider",
  "beam_name",
  "tx_freq",
  "rx_freq",
  "pol_tx",
  "pol_rx",
  "modulation_tx",
  "modulation_rx",
  "spread_factor_tx",
  "spread_factor_rx",
  "pre_interval_tx",
  "pre_interval_rx",
  "symbol_rate_tx",
  "symbol_rate_rx",
  "tx_freq_pol",
  "sat_longitude",
  "has_alt",
  "id",
  "coordinates",
];
//exact(include descendents) list of routes which are excluded
export const EXCLUDED_ROUTES_FOR_EXACT = [
  route.DEVICES_HUBS,
  route.PLANS_SERVICES,
  route.PLANS_TEMPLATES,
  route.UPDATES,
];
export const EXCLUDED_DEFAULT_VALUE_FOR_EXACT = [route.LOGS_SYSTEM];

export const INTITATOR_LIST = [
  { id: 0, name: "Incoming call" },
  { id: 1, name: "Outgoing call" },
];
export const FAKE_USAGE_IOT = "fakeUsage";
export const FAKE_USAGE_VOIP = "fakeUsageVoip";
export const ACTIVITY_IOT = "usageData";
export const ACTIVITY_VOIP = "usageDataVoip";
export const USAGE_TABS = ["IOT", "VOIP"];
export const IOT_USAGE_TYPES = ["kb", "mb", "bytes"];
export const VOIP_USAGE_TYPES = ["minutes", "hours", "seconds"];
