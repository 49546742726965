import {
  USER_PROFILE,
  ACCOUNT_UNDER_WORK,
  ACCOUNT_INFO,
  SELECTED_USER_INFO,
  EDIT_ACCOUNT_INFO,
  VIEW_UPDATE,
  NEW_SITE,
  EDIT_SITE,
  EDIT_PLAN,
  SERVICE_DATA
} from "./types";

export const userProfileUpdate = (userProfile) => {
  return { type: USER_PROFILE, payload: userProfile };
};

export const accountInfoSelector = (accountInfo) => {
  return {
    type: ACCOUNT_INFO,
    payload: accountInfo,
  };
};
export const addSelectedUserInfoSelector = (userInfo) => {
  return {
    type: SELECTED_USER_INFO,
    payload: userInfo,
  };
};
export const accountUnderWork = (account) => {
  return {
    type: ACCOUNT_UNDER_WORK,
    payload: account,
  };
};

export const editAccountSelecter = (account) => {
  return {
    type: EDIT_ACCOUNT_INFO,
    payload: account,
  };
};
/* other store functions */
export const viewUpdateSelector = (data) => {
  return {
    type: VIEW_UPDATE,
    payload: data,
  };
};
export const addNewSiteSelector = site => {
  return {
    type: NEW_SITE,
    payload: site
  };
};
export const updateSiteSelector = site => {
    return {
    type: EDIT_SITE,
    payload: site
  };
};
export const serviceDataSelector = service => {
  return {
  type: SERVICE_DATA,
  payload: service
}
};
export const editPlanHandler = plan => {
  return {
    type: EDIT_PLAN,
    payload: plan
  };
};

