import React, { Component } from "react";
import { Link } from "react-router-dom";
import styles from "./SingleLink.module.scss";
import Icon from "../../../Componnet/Icon/Icon";
class SingleLink extends Component {
  getBackGroundColorFull = () => {
    return this.props.menu && this.props.name.toLowerCase() === this.props.activeLink.toLowerCase()
      ? "#0AA1B5"
      : null;
  };
  getBackGroundColorSmall = () => {
    return !this.props.menu && this.props.name === this.props.activeLink
      ? "#0AA1B5"
      : null;
  };
  linkHtml = () => {
    return (
      <>
        <span
          className={this.props.menu ? styles.iconLink : styles.iconLinkClose}
          style={{ color: this.getBackGroundColorSmall() }}
        >
          <Icon defination={this.props.iconDefination} />
        </span>
        <span
          className={this.props.menu ? styles.textLink : styles.textLinkClose}
        >
          {this.props.linkName}
        </span>
      </>
    );
  };
  render() {
    return (
      <div
        className={
          this.props.menu ? styles.SingleLinks : styles.SingleLinksClose
        }
        onClick={() => this.props.click(this.props.name)}
        id={this.props.name}
        style={{
          backgroundColor: this.getBackGroundColorFull()
        }}
      >
        {this.props.isExternal ? (
          <a
            href={this.props.link}
            target="_blank"
            rel="noopener noreferrer"
            className={this.props.menu ? styles.link : styles.linkClose}
          >
            {this.linkHtml()}
          </a>
        ) : (
          <Link
            className={this.props.menu ? styles.link : styles.linkClose}
            to={this.props.link}
          >
            {this.linkHtml()}
          </Link>
        )}
      </div>
    );
  }
}

export default SingleLink;
