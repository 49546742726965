import React, { Component } from "react";
import styles from "./ViewSiteInfo.module.scss";
import Input from "../../../../Componnet/Input/Input";
import { connect } from "react-redux";
import * as actionType from "../../../../Store/actions/index";
import Icon from "../../../Icon/Icon";
import SpinnerLoader from "../../../SpinnerLoader/SpinnerLoader";
import SingleButton from "../../../SingleButton/SingleButton";
import { http } from "../../../../RequestManager/Http";
import { getSiteInfo} from "../../../../RequestManager/QueriesAndMutations";
import {
  CLOSE,
  DEVICES_NAME,
  INDEX,
  HISKY_ID,
  TYPE,
  STATUS,
} from "../../../../Util/constTypes";
import Telephone from "../../../Telephone/Telephone";
import { getTypeOfTerminal } from "../../../../Util/globalFunc";
class ViewSiteInfo extends Component {
  _isMounted=true
  state = {
    loading: true,
    site: {
      account: {
        type: "text",
        name: "companyName",
        value: "",
        label: "Account",
        placeholder: "Enter post code",
        errMsg: "error",
      },
      name: {
        type: "text",
        value: "",
        label: "Name",
        placeholder: "Enter name",
        errMsg: "error",
      },
      phone: {
        type: "text",
        value: "",
        label: "Telephone",
        placeholder: "Enter Telephone",
        errMsg: "error",
      },

      legalPostalCode: {
        type: "text",
        name: "legalPostalCode",
        value: "",
        label: "Post Code",
        placeholder: "Enter post code",
        errMsg: "error",
      },
      address: {
        type: "text",
        name: "address",
        value: "",
        label: "Address",
        placeholder: "Enter adddress",
        errMsg: "error",
      },
    },
    devices: [],
    tableHeight: 20,
  };

  componentDidMount() {
    this.getHeightHandler();
    window.addEventListener("resize", this.getHeightHandler);
    let id = parseInt(this.props.accountInfoRx.site.siteId);
    http(getSiteInfo, {
      siteId: id,
    })
      .then((res) => {
        this.setDataHandler(res.data.site);
      })
      .catch((err) => {});
  }
  componentWillUnmount(){
   this._isMounted=false
  }
  getHeightHandler = () => {
    if (
      document.getElementById("mainDiv") == null ||
      document.getElementById("header") == null ||
      document.getElementById("form") == null ||
      document.getElementById("tblHeader") == null ||
      document.getElementById("btns") == null
    )
      return;
    let mainDivHeight = document.getElementById("mainDiv").clientHeight;
    let headerHeight = document.getElementById("header").clientHeight;
    let formHeight = document.getElementById("form").clientHeight;
    let tblHeaderHeight = document.getElementById("tblHeader").clientHeight;
    let btnsHeight = document.getElementById("btns").clientHeight;

    let tableHeight =
      mainDivHeight -
      (headerHeight + formHeight + tblHeaderHeight + btnsHeight + 170);
    this.setState({ tableHeight: tableHeight });
  };
  setDataNameAccountHandler = (account) => {
    if (account !== null && Object.keys(account).length) {
      if (account.name !== "" && account.name !== undefined) {
        return account.name;
      } else {
        return account.owner.firstName + " " + account.owner.lastName;
      }
    } else {
      return "N/A";
    }
  };
  setDataHandler = (data) => {
    let copySite = { ...this.state.site };
    let copyDevices = data.devices;
    copySite["account"].value =
      data.account !== null
        ? this.setDataNameAccountHandler(data.account)
        : "N/A";
    copySite["name"].value = data.name ? data.name : "N/A";
    copySite["phone"].value = data.phone ? data.phone : "N/A";

    copySite["legalPostalCode"].value = data.legalAddress.postCode
      ? data.legalAddress.postCode
      : "N/A";
    copySite["address"].value = data.legalAddress.addressLine1.concat(
      ",",
      " ",
      data.legalAddress.country
    );
    if ( this._isMounted){
      this.setState(
        {
          site: copySite,
          isMouted: true,
          loading: false,
          devices: copyDevices,
        },
        () => {}
      );
    }
  
  };
  converInputToArrayHandler = () => {
    let temp = [];
    let copySite = { ...this.state.site };
    for (let key in copySite) {
      temp.push({
        name: key,
        value: copySite[key].value,
        label: copySite[key].label,
        placeholder: copySite[key].placeholder,
      });
    }
    return temp;
  };
  getInputFormHandler = () => {
    let inputs = this.converInputToArrayHandler();
    return inputs.map((item, index) => {
      if (item.name === "phone") {
        return (
          <Telephone
            key={index}
            label={item.label}
            value={item.value}
            type={item.type}
            name={item.name}
            placeholder={item.label}
            inputWrapper={styles.inputWrapper}
            defaultCountry={"us"} //for create mode
            disabled={true}
          />
        );
      } else {
        return (
          <Input
            value={item.value}
            key={index}
            name={item.name}
            label={item.label}
            inputWrapper={
              item.name == "address"
                ? styles.addressWrapper
                : styles.inputWrapper
            }
            placeholder={item.placeholder}
            disabled={true}
          />
        );
      }
    });
  };
  getIconTypeDevice = (terminalType) => {
    if (terminalType === 1 || terminalType == 3 || terminalType == 4) {
      return (
        <span>
          <Icon defination={{ prefix: "far", iconName: "tablet-rugged" }} />
          <span className={styles.typeText}>
            {getTypeOfTerminal(terminalType)}
          </span>
        </span>
      );
    } else if (terminalType === 2) {
      return (
        <span>
          <Icon defination={{ prefix: "far", iconName: "tablet-rugged" }} />
          <span className={styles.typeText}>Dynamic</span>
        </span>
      );
    } else {
      return <span>N/A</span>;
    }
  };
  buildSitesTableHeaderHandler = () => {
    let header = [INDEX, TYPE, HISKY_ID, DEVICES_NAME, STATUS];
    return header.map((item, index) => {
      return (
        <div key={index} className={
          item == HISKY_ID
            ? `${styles.textCellHeader} ${styles.genericLabel}`
            : styles.textCellHeader
        }>
          <strong>{item}</strong>
        </div>
      );
    });
  };
  buildSitesTableRowHandler = () => {
    try {
      let listArrayTableRows = null;
      let data = [...this.state.devices];
      if (data.length > 0) {
        listArrayTableRows = data.map((item, index) => {
          
          return (
            <div key={index} className={styles.tableRow}>
              <div className={styles.textCell}>{index + 1}</div>

              <div className={styles.textCell}>
                {item.terminalType
                  ? this.getIconTypeDevice(item.terminalType)
                  : "N/A"}
              </div>
              <div className={`${styles.textCell} ${styles.hisSkyTooltip}`}>
                <span className={styles.toolTip}>
                  {item.hiskyId ? item.hiskyId : "N/A"}
                </span>
                {item.hiskyId ? item.hiskyId : "N/A"}
              </div>

              <div className={styles.textCell}>
                {item.deviceName ? item.deviceName : "N/A"}
              </div>
              {item.lastTelemetry!==null ?<div className={styles.textCell}>
                {item.isOnline ? <span className={styles.statusIconGreen}>
                  <Icon defination={{ prefix: "fas", iconName: "circle" }} />
                </span> : 
                <span className={styles.statusIconRed}>
                  <Icon defination={{ prefix: "fas", iconName: "circle" }} />
                </span>}
                {item.isOnline ? "Online" : "Offline"}
              </div>:<div className={styles.textCell}>Not connected</div>}
            </div>
          );
        });
      } else if (data.length === 0 && !this.state.loading) {
        listArrayTableRows = (
          <div className={styles.tableRow}>
            <div className={styles.notextCell}>
              There are no assigned terminals
            </div>
          </div>
        );
      } else {
        listArrayTableRows = <div></div>;
      }
      return listArrayTableRows;
    } catch (error) {}
  };
  onClickCloseHandler = () => {
    this.props.accountInfoSelector({});
    this.props.resetModelSelector();
  };
  render() {
    return (
      <div className={styles.ViewSite} style={this.props.styles} id="mainDiv">
        <div
          className={styles.info}
          style={{ opacity: this.state.loading ? 0.6 : 1 }}
        >
          <h3 className={styles.header} id="header">
            <span className={styles.headerIcon}>
              <Icon
                defination={{ prefix: "fas", iconName: "globe-americas" }}
              />
            </span>
            Site Info
          </h3>
          <div className={styles.breakLine}></div>

          <div className={styles.formWrapper}>
            <div className={styles.form} id="form">
              {this.getInputFormHandler()}
            </div>
            <>
              <h3 className={styles.titleTerminal}>Assigned Terminals</h3>
              <div className={styles.breakLine}></div>
              <div
                className={styles.table}
                style={{
                  opacity: this.state.loading ? 0.6 : 1,
                }}
              >
                <div className={styles.tableRow} id="tblHeader">
                  {this.buildSitesTableHeaderHandler()}
                </div>
                <div
                  className={styles.tableContent}
                  style={{ height: this.state.tableHeight }}
                >
                  {this.buildSitesTableRowHandler()}
                </div>
              </div>
              <div className={styles.btnWrapper} id="btns">
                <SingleButton
                  text={CLOSE}
                  click={this.onClickCloseHandler}
                  disabled={this.state.loading}
                />
              </div>
            </>
          </div>
        </div>

        <div
          className={styles.spinnerWrapper}
          style={{ display: this.state.loading ? "block" : "none" }}
        >
          <SpinnerLoader msg="" />
        </div>
      </div>
    );
  }
}
const mapStateProps = (state) => {
  return {
    accountInfoRx: state.account.accountInfo,
  };
};
const mapStateDispatch = (dispatch) => {
  return {
    accountInfoSelector: (accountInfo) =>
      dispatch(actionType.accountInfoSelector(accountInfo)),
    resetModelSelector: () => dispatch(actionType.resetModelSelector()),
  };
};
export default connect(mapStateProps, mapStateDispatch)(ViewSiteInfo);
