import React, { Component } from "react";
import styles from "./LinkExpand.module.scss";
import { Link } from "react-router-dom";
import Icon from "../../../Componnet/Icon/Icon";
class LinkExpand extends Component {
  state = {
    open: false,
  };
  onClickExpandLinkHandler = () => {
    this.setState({ open: !this.state.open });
  };
  onClickOpenMenuHandler = () => {
    this.props.clickReOpenMenu();
    this.setState({ open: true });
  };
  getPrefixUrlHandler = () => {
    return (
      window.location.href.split("/").includes(this.props.prefix) &&
      this.state.open
    );
  };
  render() {
    return (
      <div className={styles.LinkExpands}>
        <div
          onClick={this.onClickExpandLinkHandler}
          className={this.props.menu ? styles.mainTitle : styles.mainTitleClose}
        >
          <div
            onClick={this.props.menu ? null : this.onClickOpenMenuHandler}
            className={this.props.menu ? styles.iconLink : styles.linkClose}
          >
            <Icon
              defination={this.props.iconDefination}
              styles={styles.iconImage}
            />
          </div>
          <div
            className={styles.textLink}
            style={{ display: this.props.menu ? "block" : "none" }}
          >
            {this.props.prefix}
          </div>
          <span
            className={styles.icon}
            id={!this.state.open?this.props.prefix+"plus":this.props.prefix+"minus"}
            style={{ display: this.props.menu ? "block" : "none" }}
          >
            {this.state.open ? (
              <Icon
                defination={{ prefix: "fas", iconName: "minus" }}
                styles={styles.icon}
              />
            ) : (
              <Icon
                defination={{ prefix: "far", iconName: "plus" }}
                styles={styles.icon}
              />
            )}
          </span>
        </div>
        <ul
          className={
            this.state.open && this.props.menu
              ? `${styles.open} ${styles.subMenu}`
              : styles.subMenu
          }
        >
          {this.props.linkArray.map((item, index) => {
            return (
              <li
                key={index}
                name={item.name}
                className={styles.subRouteLink}
                onClick={() => this.props.click(item.name)}
                style={{
                  backgroundColor:
                    this.props.selectLink === item.name ? "#0AA1B5" : null,
                }}
              >
                <Link to={item.link} className={styles.link}>
                  <span
                    className={
                      this.props.menu ? styles.iconLink : styles.iconLinkClose
                    }
                  >
                    <Icon
                      defination={item.iconDefination}
                      styles={styles.iconImage}
                    />
                  </span>
                  <span
                    className={
                      this.state.menu ? styles.textLink : styles.textLinkClose
                    }
                  >
                    {item.displayName
                      ? item.displayName.replace("-", " ")
                      : item.name}
                  </span>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default LinkExpand;
