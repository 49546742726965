import React, { Component } from "react";
import styles from "./ViewTerminal.module.scss";
import Input from "../../../Input/Input";
import SingleButton from "../../../../Componnet/SingleButton/SingleButton";
import { connect } from "react-redux";
import * as actionType from "../../../../Store/actions/index";
import Icon from "../../../Icon/Icon";
import SpinnerLoader from "../../../SpinnerLoader/SpinnerLoader";
import moment from "moment";
import { http } from "../../../../RequestManager/Http";
import { viewTerminalInfo } from "../../../../RequestManager/QueriesAndMutations";
import CopyApiKey from "../../../CopyApiKey/CopyApiKey";
import {
  getTypeOfTerminal,
  checkForIpValue,
  checkForPortValue,
} from "../../../../Util/globalFunc";
import {
  LOOPBACK,
  IM_NETWORK_CONFIG,
  UPD_NETWORK_CONFIG,
  HISKY_ID,
  CLOSE,
  MAC,
  DEST_IP,
  DEST_PORT,
  RECV_PORT,
  TERMINAL_TYPE,
  DESCRIPTION,
  DEVICES_NAME,
  DEFAULT_LOCATION_NAME,
  TRANSPORT_LAYER,
  DNS,
  STATUS_ENABLE,
  OWNER
} from "../../../../Util/constTypes";
import Controller from "../../../../Controller";
class ViewTerminal extends Component {
  state = {
    loading: true,
    data: {},
  };

  componentDidMount() {
    let id = parseInt(this.props.accountInfoRx.device.id);
    http(viewTerminalInfo, {
      id: id,
    })
      .then((res) => {
        if (res.data.terminalInstance != null) {
          this.setDataHandler(res.data.terminalInstance);
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((err) => {});
  }
  setDataHandler = (data) => {
    this.setState({ data: data, loading: false }, () => {});
  };

  converDataHandler = (ts) => {
    return moment(ts).format("MM/DD/YYYY HH:mm:ss");
  };
  getOwnerNameHandler = () => {
    if (Object.keys(this.state.data).length === 0) {
      return "N/A";
    } else {
      if (Object.keys(this.state.data.lastAssigned).length) {
        if (
          this.state.data.lastAssigned.tAccount.name !== "" &&
          this.state.data.lastAssigned.tAccount.name !== undefined
        ) {
          return this.state.data.lastAssigned.tAccount.name;
        } else if (this.state.data.lastAssigned.tAccount.users.length) {
          return (
            this.state.data.lastAssigned.tAccount.users[0].firstName +
            " " +
            this.state.data.lastAssigned.tAccount.users[0].lastName
          );
        } else {
          return "N/A";
        }
      } else {
        return "N/A";
      }
    }
  };
  render() {
    return (
      <div className={styles.ViewTerminals} style={this.props.styles}>
        <h3 className={styles.header}>
          <span className={styles.headerIcon}>
            <Icon defination={{ prefix: "fal", iconName: "list" }} />
          </span>
          Terminal Details
        </h3>
        <div className={styles.breakLine}></div>
        {/* <p
          className={styles.label}
          style={{
            visibility:
              this.state.data.mockup === undefined
                ? "hidden"
                : this.state.data.mockup
                ? "visible"
                : "hidden",
          }}
        >
          This is a mockup device
        </p> */}
        <div className={styles.dataWrapper}>
          <div
            className={styles.form}
            style={{ opacity: this.state.loading ? 0.6 : 1 }}
          >
            <Input
              label={HISKY_ID}
              isRegularText={true}
              value={
                this.state.data.hiskyId !== undefined
                  ? this.state.data.hiskyId
                  : ""
              }
              disabled={true}
              inputWrapper={styles.inputWrapper}
            />
            <Input
              label={DEVICES_NAME}
              value={
                this.state.data.deviceName !== undefined
                  ? this.state.data.deviceName
                  : ""
              }
              disabled={true}
              inputWrapper={styles.inputWrapper}
            />
            <Input
              label={TERMINAL_TYPE}
              value={
                Object.keys(this.state.data).length
                  ? getTypeOfTerminal(this.state.data.terminalType)
                  : "N/A"
              }
              disabled={true}
              inputWrapper={styles.inputWrapper}
            />
            <Input
              label={DESCRIPTION}
              value={
                this.state.data.description !== undefined
                  ? this.state.data.description
                  : ""
              }
              disabled={true}
              inputWrapper={styles.inputWrapper}
            />
          
            <Input
              label={OWNER}
              isRegularText={true}
              value={this.getOwnerNameHandler()}
              disabled={true}
              inputWrapper={styles.inputWrapper}
            />

            <Input
              label={DEFAULT_LOCATION_NAME}
              value={
                this.state.data.locationName == undefined ||
                this.state.data.locationName == ""
                  ? "N/A"
                  : this.state.data.locationName
              }
              disabled={true}
              inputWrapper={styles.inputWrapper}
            />
            <Input
              label={STATUS_ENABLE}
              value={
                this.state.data.isActive &&
                this.state.data.isActive !== undefined
                  ? "YES"
                  : "NO"
              }
              disabled={true}
              inputWrapper={styles.inputWrapper}
            />
          </div>
          <p className={styles.destinationTitle}>
            <span className={styles.iconNet}>
              <Icon
                defination={{
                  prefix: "fas",
                  iconName: "project-diagram",
                }}
              />
            </span>
            {UPD_NETWORK_CONFIG}
          </p>
          <div className={styles.destinationBreakLine}></div>
          <div className={styles.form}>
            <Input
              label={DEST_IP}
              value={checkForIpValue(this.state.data.destIp)}
              disabled={true}
              inputWrapper={styles.inputWrapper}
            />
            <Input
              label={DEST_PORT}
              value={checkForPortValue(this.state.data.destPort)}
              disabled={true}
              inputWrapper={styles.inputWrapper}
            />
            <Input
              label={RECV_PORT}
              value={this.state.data.connectionType!=4?checkForPortValue(this.state.data.recvPort):"N/A"}
              disabled={true}
              inputWrapper={styles.inputWrapper}
            />
              <Input
              label={DNS}
              value={this.state.data.destUrl!==null?this.state.data.destUrl:""}
              disabled={true}
              inputWrapper={styles.inputWrapper}
            />
            <Input
              label={TRANSPORT_LAYER}
              value={this.state.data.connectionType!=undefined?this.state.data.connectionType==1?"UDP":"TCP":""}
              disabled={true}
              inputWrapper={styles.inputWrapper}
            />
            <Input
              label={LOOPBACK}
              value={
                this.state.data.loopback == undefined
                  ? "N/A"
                  : this.state.data.loopback
                  ? "On"
                  : "Off"
              }
              disabled={true}
              inputWrapper={styles.inputWrapper}
            />
          </div>
          <p className={styles.destinationTitle}>
            <span className={styles.iconNet}>
              <Icon
                defination={{
                  prefix: "fas",
                  iconName: "project-diagram",
                }}
              />
            </span>
            {IM_NETWORK_CONFIG}
          </p>
          <div className={styles.destinationBreakLine}></div>
          <div className={styles.form}>
            <Input
              label={DEST_IP}
              value={checkForIpValue(this.state.data.destIpIm)}
              disabled={true}
              inputWrapper={styles.inputWrapper}
            />
            <Input
              label={DEST_PORT}
              value={checkForPortValue(this.state.data.destPortIm)}
              disabled={true}
              inputWrapper={styles.inputWrapper}
            />
            <Input
              label={RECV_PORT}
              value={checkForPortValue(this.state.data.recvPortIm)}
              disabled={true}
              inputWrapper={styles.inputWrapper}
            />
          </div>
          {Controller.getActionAuth("bss", "VIEW_TERMINAL_API_KEY") && (
            <div className={styles.formApi}>
              <CopyApiKey
                title={styles.destinationTitleApi}
                iconNet={styles.iconNet}
                disabled={this.state.loading}
                breakLine={styles.destinationBreakLineApi}
                icon={true}
                apiKey={
                  Object.keys(this.state.data).length
                    ? this.state.data.apiKey
                    : ""
                }
              />
            </div>
          )}
        </div>
        <div className={styles.btnWrapper}>
          <SingleButton
            text={CLOSE}
            disabled={this.state.loading}
            click={() => this.props.resetModelSelector()}
          />
        </div>
        <div
          className={styles.spinnerWrapper}
          style={{ display: this.state.loading ? "block" : "none" }}
        >
          <SpinnerLoader />
        </div>
      </div>
    );
  }
}
const mapStateProps = (state) => {
  return {
    accountInfoRx: state.account.accountInfo,
  };
};
const mapStateDispatch = (dispatch) => {
  return {
    accountInfoSelector: (accountInfo) =>
      dispatch(actionType.accountInfoSelector(accountInfo)),
    resetModelSelector: () => dispatch(actionType.resetModelSelector()),
    resetInnerComponentSelector: (data) =>
      dispatch(actionType.resetInnerComponentSelector(data)),
  };
};
export default connect(mapStateProps, mapStateDispatch)(ViewTerminal);
