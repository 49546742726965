import { requestApi } from "./RequestManager";

export const http = (apiBuilderCallback, data, type = null) => {
  if (type === null) {
    return requestApi(apiBuilderCallback(data), type)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location.reload();
        } else return Promise.reject(err.data);
      });
  } else {
    return requestApi(data, type)
      .then((res) => {
        
        return Promise.resolve(res);
      })
      .catch((err) => {
        return Promise.reject(err.data);
      });
  }
};
