import React, { Component } from "react";
import { Doughnut, Chart } from "react-chartjs-2";
var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
  draw: function () {
    
    originalDoughnutDraw.apply(this, arguments);
   
    var chart = this.chart.chart;
    var ctx = chart.ctx;
    var width = chart.width;
    var height = chart.height;
    var fontSize = (height / 90).toFixed(2);
    ctx.font = fontSize + "em Poppins";
    ctx.textBaseline = "middle";
    var text = chart.config.data.text,
      textX = Math.round((width - ctx.measureText(text).width) / 2),
      textY = height / 2;

    ctx.fillText(text, textX, textY);
  },
});
export default class DoughnutChart extends Component {
  render() {
    let options = {
      responsive: true,
      animateRotate: true,
      maintainAspectRatio: true,
      legend: {
        position: "bottom",
        display: false,
      },
      cutoutPercentage: 70,
      tooltips: {
        enabled: this.props.options!=undefined ?  this.props.options.tooltip : false,
      },
    };

    return (
      <div className={this.props.chartWrapper}>
        <Doughnut
          width={window.innerWidth <= 1400 ? 90 : 130}
          options={options}
          data={this.props.chartData}
        />
      </div>
    );
  }
}
