import React, { Component } from "react";
import SingleButton from "../../SingleButton/SingleButton";
import styles from "./HubppActions.module.scss";
export default class HubppActions extends Component {
  render() {  
    return (
      <div className={styles.hubppActions}>
        {this.props.showPreviewBtn && (
          <SingleButton
            text="Preview"
            style={{ marginRight: "1rem" }}
            click={this.props.previewAction}
          />
        )}
        <SingleButton text="Apply" click={this.props.applyAction} />
      </div>
    );
  }
}
