import React, { Component } from "react";
import styles from "./SideBar.module.scss";
import { withRouter } from "react-router-dom";
import LinkExpand from "./LinkExpand/LinkExpand";
import SingleLink from "./SingleLink/SingleLink";
import { getAccountId } from "../../Store/localStoreageManager/localStoreageManager";
import {
  DASHBOARD,
  LOGS,
  REPORTS,
  SUPPORT,
  ACCOUNTS,
  ACCOUNT,
} from "../../Util/constTypes";
import * as route from "../../Util/routes";
import Controller from "../../Controller";
import { getLogsRouteList } from "../../Util/globalFunc";
class AccountTechnician extends Component {
  render() {
    return (
      <div>
        <div className={styles.linkGroup}>
          <div
            className={styles.subTitle}
            style={{ textAlign: this.props.menu ? null : "center" }}
          >
            {this.props.menu ? "Nms" : ""}
          </div>
          <SingleLink
            name={DASHBOARD}
            menu={this.props.menu}
            iconDefination={{
              prefix: "fas",
              iconName: "chart-line",
            }}
            activeLink={this.props.activeLink}
            click={this.props.onClickLinkHandler}
            link={route.ROOT}
            linkName={DASHBOARD}
          />

          {getLogsRouteList().length > 0 && (
            <LinkExpand
              prefix={LOGS}
              pcreate
              menu={this.props.menu}
              clickReOpenMenu={this.props.onClickMenuBarHandler}
              selectLink={this.props.activeLink}
              click={this.props.onClickLinkHandler}
              iconDefination={{
                prefix: "far",
                iconName: "folder",
              }}
              linkArray={getLogsRouteList()}
            />
          )}
          {Controller.getAuthComponent("bss", "ACCOUNTS") && (
            <SingleLink
              name={ACCOUNTS}
              menu={this.props.menu}
              iconDefination={{
                prefix: "far",
                iconName: "user",
              }}
              activeLink={this.props.activeLink}
              click={this.props.onClickLinkHandler}
              link={route.ACCOUNTS_TECHNICIAN + "/" + parseInt(getAccountId())}
              linkName={ACCOUNT}
            />
          )}
          {/* <div
            className={styles.subTitle}
            style={{ textAlign: this.props.menu ? null : "center" }}
          >
            {this.props.menu ? "bss / OSS" : ""}
          </div> */}
          {Controller.getAuthComponent("bss", "SUPPORT") && (
            <SingleLink
              name={SUPPORT}
              menu={this.props.menu}
              iconDefination={{
                prefix: "far",
                iconName: "user-headset",
              }}
              activeLink={this.props.activeLink}
              click={this.props.onClickLinkHandler}
              link={route.SUPPORT}
              isExternal={true}
              linkName={SUPPORT}
            />
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(AccountTechnician);
