import React, { Component } from "react";
import CheckBox from "../CheckBox/CheckBox";
import Icon from "../Icon/Icon";
import SearchBox from "../SearchBox/SearchBox";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import styles from "./Sites.module.scss";
export default class Sites extends Component {
  hightOfContral = document.getElementById("contralLeft").clientHeight;

  componentWillUnmount() {
    this.props.resetSitesRemoved();
  }
  getSitesListHandler = () => {
    if (!this.props.sitesList.length)
      return <div className={styles.site}>No Sites Found</div>;
    let list = this.props.sitesList.map((item, index) => {
      return (
        <div
          id={item.id}
          key={index}
          className={styles.site}
         
        >
          <CheckBox
            labelMsg={`${item.name}-${item.country}`}
            checkBoxWrapper={styles.checkboxWrapper}
            value={!this.props.removeSite.includes(item.siteId)}
            click={() => this.props.onClickSiteItem(item, index)}
            name={item.name + item.siteTerminalCount}
            customStyles={{padding:'0.5rem 0rem'}}
          />

          <div className={styles.countWrapper}>
            <span style={{ marginRight: "0.5rem" }}>
              Total:&nbsp;
              {item.siteTerminalCount
                ? item.siteTerminalCount.online + item.siteTerminalCount.offline
                : 0}
            </span>
            <span style={{ marginRight: "0.5rem" }}>
              Online:&nbsp;
              {item.siteTerminalCount ? item.siteTerminalCount.online : 0}
            </span>
            <span>
              Offline:&nbsp;
              {item.siteTerminalCount ? item.siteTerminalCount.offline : 0}
            </span>
          </div>
        </div>
      );
    });
    return list;
  };
  render() {
    return (
      <>
        <div
          className={styles.sitesMainWrapper}
          style={{
            marginTop: !this.props.showCustomPosition ? "1rem" : "0",
            height: this.props.isFullScreen
              ? (this.props.showCustomPosition ? 100 - 20 : 100 - 15) + "vh"
              : !this.props.showCustomPosition
              ? this.props.googleDiv - this.hightOfContral - 30 + "px"
              : this.props.googleDiv - this.hightOfContral - 40 - 40 + "px",
          }}
        >
          <SearchBox
            name="searchSites"
            title=""
            placeholder={"Search Sites"}
            errmsg={""}
            change={this.props.change}
            click={this.props.click}
            value={this.props.value}
            onKeyDown={this.props.onKeyDown}
            clickClear={this.props.clickClear}
          />
          <div className={styles.sitesListWrapper}>
            {this.getSitesListHandler()}
          </div>
          <div className={styles.infoWrapper}>
            <div
              className={styles.itemWrapper}
              onClick={() => this.props.clickStat("online")}
            >
              <span className={styles.statusIconGreen}>
                <Icon defination={{ prefix: "fas", iconName: "circle" }} />
              </span>
              <span
                className={!this.props.online ? styles.slantLine : ""}
              ></span>
              Online:&nbsp;
              {this.props.terminalsCount != null
                ? this.props.terminalsCount.online
                : 0}
            </div>
            <div
              className={styles.itemWrapper}
              onClick={() => this.props.clickStat("offline")}
            >
              <span className={styles.statusIconRed}>
                <Icon defination={{ prefix: "fas", iconName: "circle" }} />
              </span>
              <span
                className={!this.props.offline ? styles.slantLine : ""}
              ></span>
              Offline:&nbsp;{" "}
              {this.props.terminalsCount != null
                ? this.props.terminalsCount.offline
                : 0}
            </div>
            <div
              className={styles.itemWrapper}
              onClick={this.props.clearHandler}
              style={{ cursor: "pointer" }}
            >
              Clear
              <span className={styles.iconClear}>
                <Icon defination={{ prefix: "fal", iconName: "backspace" }} />
              </span>
            </div>
          </div>
          <div
            className={styles.spinnerWrapper}
            style={{ display: this.props.loading ? "block" : "none" }}
          >
            <SpinnerLoader />
          </div>
        </div>
      </>
    );
  }
}
