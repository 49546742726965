import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styles from "./SignInAs.module.scss";
import Input from "../Input/Input";
import Icon from "../Icon/Icon";
import { SUBMIT } from "../../Util/constTypes";
import SingleButton from "../SingleButton/SingleButton";
class SignInAs extends Component {
  onKeyDownHandler = (e) => {
    if (e.key === "Enter") {
      this.props.onSubmitHandler();
    }
  };
  render() {
    return (
      <div className={styles.Signinas} onKeyDown={this.onKeyDownHandler}>
        <h3 className={styles.header}>
          <span className={styles.headerIcon}>
            <Icon defination={{ prefix: "fal", iconName: "sign-in-alt" }} />
          </span>
          Log in as
          <span
            className={styles.loadingIcon}
            style={{ visibility: this.props.loading ? "visible" : "hidden" }}
          >
            <Icon
              defination={{ prefix: "fal", iconName: "spinner" }}
              isSpinner={true}
            />
          </span>
        </h3>

        <div className={styles.breakLine}></div>
        <div
          className={styles.wrapper}
          style={{ opacity: this.props.loading ? 0.6 : 1 }}
        >
          <Input
            name="email"
            inputWrapper={styles.inputWrapper}
            placeholder="Email here..."
            label="Email"
            error={this.props.email.error}
            errMsg={this.props.email.errMsg}
            change={(e) => this.props.onChangeHandler(e)}
            value={this.props.email.value}
          />
          <p
            className={styles.errMsg}
            style={{ visibility: this.props.errorMsg!='' ? "visible" : "hidden" }}
          >
            {this.props.errorMsg}
          </p>
          <div
            className={
              this.props.loading
                ? `${styles.linkWrapper}${styles.disabledLink}`
                : styles.linkWrapper
            }
          >
            <p className={styles.msgPass} onClick={this.props.continueToNMS}>
              Continue to NMS
            </p>
          </div>
          <div className={styles.btnWrapper}>
            <SingleButton
              text={SUBMIT}
              click={this.props.onSubmitHandler}
              disabled={this.props.loading}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(SignInAs);
