import React, { Component } from "react";
import styles from "./SideBar.module.scss";
import { withRouter } from "react-router-dom";
import LinkExpand from "./LinkExpand/LinkExpand";
import SingleLink from "./SingleLink/SingleLink";
import {
  DASHBOARD,
  LOGS,
  BSS_USER_LOGS,
  USER_LOGS_DISPLAY_NAME,
  SYSTEM_LOGS,
  SYSTEM_LOGS_DISPLAY_NAME,
  CDR,
  PLANS,
  SUPPORT,
  ACCOUNTS,
  SUBSCRIPTIONS,
  BSS_DASHBOARD,
  USERS,
  USER_LOGS_LINK,
  SYSTEM_LOGS_LINK
} from "../../Util/constTypes";
import Controller from "../../Controller";
import * as route from "../../Util/routes";

class AccountReseller extends Component {
  getLogsLinks = () => {
    let logs = [];
    if (Controller.getAuthComponent("bss", USER_LOGS_LINK))
      logs.push({
        link: route.LOGS_USER,
        name: BSS_USER_LOGS,
        displayName: USER_LOGS_DISPLAY_NAME,
        iconDefination: {
          prefix: "fas",
          iconName: "clipboard",
        },
      });
    if (Controller.getAuthComponent("bss",  SYSTEM_LOGS_LINK))
      logs.push({
        link: route.LOGS_SYSTEM,
        name: SYSTEM_LOGS,
        displayName: SYSTEM_LOGS_DISPLAY_NAME,
        iconDefination: {
          prefix: "fas",
          iconName: "clipboard-list",
        },
      });
    return logs;
  };
  render() {
    return (
      <div>
        <div className={styles.linkGroup}>
          <div
            className={this.props.menu ? styles.subTitle : styles.subTitleClose}
            style={{
              textAlign: this.props.menu ? null : "center",
              marginTop: "4.5rem",
            }}
          >
            {this.props.menu ? "bss / OSS" : ""}
          </div>
          <SingleLink
            name={BSS_DASHBOARD}
            menu={this.props.menu}
            iconDefination={{
              prefix: "fas",
              iconName: "chart-line",
            }}
            activeLink={this.props.activeLink}
            click={this.props.onClickLinkHandler}
            link={route.ROOT}
            linkName={DASHBOARD}
          />
          {Controller.getAuthComponent("bss", "PLANS_ASSIGN") && (
            <LinkExpand
              prefix={PLANS}
              menu={this.props.menu}
              clickReOpenMenu={this.props.onClickMenuBarHandler}
              selectLink={this.props.activeLink}
              click={this.props.onClickLinkHandler}
              iconDefination={{
                prefix: "far",
                iconName: "folder",
              }}
              linkArray={[
                {
                  link: route.PLANS_ASSIGN,
                  name: SUBSCRIPTIONS,
                  iconDefination: {
                    prefix: "fal",
                    iconName: "history",
                  },
                },
              ]}
            />
          )}
          {Controller.getAuthComponent("bss", "USERS") && (
            <SingleLink
              name={USERS}
              menu={this.props.menu}
              iconDefination={{
                prefix: "fas",
                iconName: "users",
              }}
              activeLink={this.props.activeLink}
              click={this.props.onClickLinkHandler}
              link={route.USERS}
              linkName={USERS}
            />
          )}
          {Controller.getAuthComponent("bss", "ACCOUNTS") && (
            <SingleLink
              name={ACCOUNTS}
              menu={this.props.menu}
              iconDefination={{
                prefix: "far",
                iconName: "user",
              }}
              activeLink={this.props.activeLink}
              click={this.props.onClickLinkHandler}
              link="#"
              linkName={ACCOUNTS}
            />
          )}

          {Controller.getAuthComponent("bss", "ACTIVITY") && (
            <SingleLink
              name={CDR}
              menu={this.props.menu}
              iconDefination={{
                prefix: "fal",
                iconName: "satellite",
              }}
              activeLink={this.props.activeLink}
              click={this.props.onClickLinkHandler}
              link={route.ACTIVITY}
              linkName={CDR}
            />
          )}

          {(Controller.getAuthComponent("bss", "USER_LOGS") ||
            Controller.getAuthComponent("bss", "SYSTEM_LOGS")) && (
            <LinkExpand
              prefix={LOGS}
              menu={this.props.menu}
              clickReOpenMenu={this.props.onClickMenuBarHandler}
              selectLink={this.props.activeLink}
              click={this.props.onClickLinkHandler}
              iconDefination={{
                prefix: "far",
                iconName: "folder",
              }}
              linkArray={this.getLogsLinks()}
            />
          )}

          {Controller.getAuthComponent("bss", "SUPPORT") && (
            <SingleLink
              name={SUPPORT}
              menu={this.props.menu}
              iconDefination={{
                prefix: "far",
                iconName: "user-headset",
              }}
              activeLink={this.props.activeLink}
              click={this.props.onClickLinkHandler}
              link={route.SUPPORT}
              isExternal={true}
              linkName={SUPPORT}
            />
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(AccountReseller);
