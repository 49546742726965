import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import styles from "./Google.module.scss";
import ReactDOM from "react-dom";
import {
  ROOT,
  UPDATES,
  HUB_PP_SIMULATOR,
  DEVICES_HUB,
} from "../../Util/routes";
import MapRightActionBtns from "../MapRightActionBtns/MapRightActionBtns";
import CustomMapLocation from "../CustomMapLocation/CustomMapLocation";
import { converStringToJson } from "../../Util/globalFunc";
import CustomMarker from "./Marker/Marker";
import UpdateMarker from "./UpdateMarker/UpdateMarker";
import Controller from "../../Controller";
import DashboardMarker from "./DashboardMarker/DashboardMarker";
import DrawingInfoWindow from "./DrawingInfoWindow/DrawingInfoWindow";
import MapLeftActionBtns from "../MapLeftActionBtns/MapLeftActionBtns";
import InfoPolygons from "./InfoPolygons/InfoPolygons";
import {
  DEFAULT_MAP_LOCATION,
  POLYGON_SETTINGS,
  GOOGLE_PINS,
  NOTIFICATION_OPTIONS,
} from "../../Util/constants";
import * as actionType from "../../Store/actions/index";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ViewTerminal from "../Model/Models/ViewTerminal/ViewTerminal";
import ViewAssignDeviceToPlan from "../Model/Models/ViewAssignDeviceToPlan/ViewAssignDeviceToPlan";
import TerminalsInfo from "./TerminalsInfo/TerminalsInfo";
import { generateMarkerId } from "../../Util/globalFunc";
// import UpdatePolygonInfoWindow from "./UpdatePolygonInfoWindow/UpdatePolygonInfoWindow";
// import ShowLabels from "./ShowLabels/ShowLabels";

import ZoomControls from "../MapControl/ZoomControls/ZoomControls";
import {
  DOT_MARKER,
  HUB,
  MOCKUP,
  SITE,
  TERMINAL,
  LOCATE_BEAM,
  LOCATE_TERMINALS,
  ASSIGNED_HUB_DETAILS,
  STOP,
  START,
  CLUSTER,
  GO_TO_PAGE,
  SHOW_TRAIL,
  CLOSE_LOCATE_BEAM,
  CANCEL_TRAIL,
  BEAM,
  TELEMETRY_HISTORY,
  DELETE_POLY_DOT,
  BEAM_HIGHLIGHT_COLOR,
} from "../../Util/constTypes";
import ViewSiteInfo from "../Model/Models/ViewSiteInfo/ViewSiteInfo";
import HubMoreInfo from "../Model/Models/HubMoreInfo/HubMoreInfo";
import { DEVICES_TERMINAL } from "../../Util/routes";
import AddLatLng from "./AddLatLng/AddLatLng";
import supercluster from "supercluster";
import ClusterMarker from "./ClusterMarker/ClusterMarker";
import HubppActions from "./HubppActions/HubppActions";
import MapSpinner from "./MapSpinner/MapSpinner";
import { http } from "../../RequestManager/Http";
import {
  getHubTerminalsById,
  overLayPolygons,
  terminalMapSearch,
} from "../../RequestManager/QueriesAndMutations";
import SearchPinInfoWindow from "./SearchPinInfoWindow/SearchPinInfoWindow";
import ShortcutMenu from "./ShortcutMenu/ShortcutMenu";
import { toast } from "react-toastify";
//  const KEY_GOOGLE = "AIzaSyAXxhLDqgKZGkewUojgnFfOk96d8JZRbrw";
const CLUSTER_LEVEL_ZOOM = 11;
// Return map bounds based on list of places
const getMapBounds = (map, maps, places) => {
  if (maps != null && places.length > 0) {
    const bounds = new maps.LatLngBounds();
    places.forEach((place) => {
      bounds.extend(new maps.LatLng(place.lat, place.lng));
    });
    return bounds;
  }
};
class Google extends Component {
  KEY_GOOGLE= Controller.getAuthComponent("settings", "googleMapsApiKey");
  _clusterClickCount = 0;
  _dashboardMarkerInstance = null;
  _currentZoom = null;
  _init_Zoom_level = -1;
  _selectedShape = null;
  _allowRecenter = true;
  _coordinateSearchOn = false;
  drawingManager;
  _isNonDrawingMode = true;
  polylineManager = null;
  _infowindow;
  _drawingInfowindow;
  polygonManager = [];
  _buttonsPlaced = false;
  _terminalInfoPlaced = false;
  _poliLineId = 0;
  _showLabelsPlaced = false;
  _customLatLngFormPlaced = false;
  _updateBeamsPlaced = false;
  _singleMarker = {};
  _isScrolling = false;
  _isFullscreenRef = null;
  _disableCustomSearchBtnRef = null;
  _allowPolyLineCenter = true;
  _updatePloygon = [];
  _zoomButtonsPlaced = false;
  _hubppActionsPlaced = false;
  clickCount = 0;
  singleClickTimer = null;
  _hubFilterOn = false;
  _orgPolygons = [];
  _locateBeamHubIndex = null;
  _glowBeamInterval = null;
  _showHubMarkers = false;
  _shortcutClicked = false;
  _disableRightClick = false;
  _searchMarker = null;
  _clearGlowingTimer = null;
  _refShowTrailTextBox = null;
  _isDrag = false;
  _terminalInfoPlacedOnHubDetail = false;
  _boundToKmlPolygon = true;
  _beamSelected = null;
  state = {
    searchedCustomHiskyId: null,
    showMap: false,
    mapApiLoaded: false,
    mapInstance: null,
    mapApi: null,
    google: null,
    isFullScreenMode: false,
    showPolygon: false,
    showAllPolygons: true,
    drawingBounds: [],
    showDrawingInfoWindow: false,
    isCustomSearchOpen: false,
    ismapModelOpen: false,
    openedinfoWindowslist: {},
    showlabelsOnPolygon: false,
    mapBounds: [],
    activeShortcutMenu: {},
    beamRightMenu: {},
    beamsLoading: false,
  };

  // Fit map to its bounds after the api is loaded
  apiIsLoaded = (map, maps, places) => {
    if (maps == null) return;
    // Bind the resize listener
    this.setState(
      {
        mapApiLoaded: true,
        mapInstance: map,
        mapApi: maps,
      },
      () => {
        if (this.props.apiIsLoadedTrigger != undefined) {
          this.props.apiIsLoadedTrigger();
        }

        maps.event.addListener(map, "zoom_changed", this.onZoomHandler);
        maps.event.addListener(map, "click", (mapsMouseEvent) => {
          if (
            this.state.activeShortcutMenu != undefined &&
            this.state.activeShortcutMenu.item != undefined &&
            this.state.activeShortcutMenu.item.type == DOT_MARKER
          ) {
            setTimeout(() => {
              window.location.pathname !== ROOT &&
                !this._shortcutClicked &&
                this.mapClickHandler(mapsMouseEvent);
            }, 200);
          } else {
            window.location.pathname !== ROOT &&
              this.mapClickHandler(mapsMouseEvent);
          }
        });
        const mapDiv = document.getElementById("map_canvas");
        maps.event.addDomListener(mapDiv, "wheel", () => {
          this._isScrolling = true;
          this._allowRecenter = false;
        });
        let contentString = "<div id='dws'></div>";
        this._infowindow = new maps.InfoWindow();
        this._infowindow.setContent(contentString);
        this._infowindow.open(map);

        let drawingContentString = "<div id='dmI'></div>";
        this._drawingInfowindow = new maps.InfoWindow();
        this._drawingInfowindow.setContent(drawingContentString);
        this._drawingInfowindow.open(map);
      }
    );
  };
  /**
   * Lifecycle methods
   */
  componentDidMount() {
    this.setState(
      {
        showMap: true,
        showlabelsOnPolygon: this.props.showlabelsOnPolygon
          ? this.props.showlabelsOnPolygon
          : false,
      },
      () => {
        if (window.location.pathname == UPDATES) this._allowRecenter = false;
      }
    );
    if (this.props.refPolygonClick !== undefined) {
      this.props.refPolygonClick(
        this.showPolygonDashboardHandler,
        this.state.showPolygon
      );
    }
    if (this.props.refUpdatePolyLineId !== undefined) {
      this.props.refUpdatePolyLineId(this.updatePolyLineIdHandler);
    }
    if (this.props.refCloseInfoWindow !== undefined) {
      this.props.refCloseInfoWindow(this.closePolygonInfoWindowHandler);
    }
    if (this.props.refBeamItemClicked !== undefined) {
      this.props.refBeamItemClicked((e, beamSelected) => {
        this.openPolygonInfoWindow(e, beamSelected);
      });
    }
  }
  componentWillUnmount() {
    this._dashboardMarkerInstance != undefined &&
      this._dashboardMarkerInstance();
    document.getElementsByClassName("gm-style")[0].style.opacity = "1";
  }
  componentWillReceiveProps(nextprops) {
    if (nextprops.mapId != undefined && this.prevId != nextprops.mapId) {
      this.prevId = nextprops.mapId;
      this._isScrolling = false;
      this._allowRecenter = true;
      this.setState({ openedinfoWindowslist: {} });
    }
  }
  componentDidUpdate(nextprops) {
    if (this.state.mapInstance == null) return;
    if (this.state.mapInstance != null && !this._buttonsPlaced) {
      var mapRightActionBtns = document.createElement("div");
      mapRightActionBtns.setAttribute("id", "mapRightActionBtnsWrapper");
      this.state.mapInstance.controls[
        this.state.mapApi.ControlPosition.TOP_RIGHT
      ].clear();
      this.state.mapInstance.controls[
        this.state.mapApi.ControlPosition.TOP_RIGHT
      ].push(mapRightActionBtns);
      if (mapRightActionBtns != null) {
        ReactDOM.render(
          <MapRightActionBtns
            disabled={this.state.isFullScreenMode}
            recenterMapHandler={
              window.location.pathname == HUB_PP_SIMULATOR &&
              this.props.polyLineId != undefined
                ? this.recenterToPolyLineHandler
                : this.recenterMaptoDefaultHandler
            }
            applyZoomConvertToMarkers={this.applyZoomConvertToMarkers}
            showPolygon={this.state.showPolygon}
            btnOptions={this.props.btnOptions}
            showPolygonHandler={this.showPolygonDashboardHandler}
            checkStatusScreen={this.checkStatusScreenHandler}
            fullScreenModeHandler={this.fullScreenModeHandler}
            handleESCPress={this.handleESCPress}
            isFullScreenMode={this.state.isFullScreenMode}
            ismapModelOpen={
              this.props.viewAssignDeviceToPlanOnMapRx ||
              this.props.viewTerminalOnMapRx ||
              this.props.viewHubOnMapRx ||
              this.props.viewSiteInfoOnMapRx
            }
            showAllPolygons={this.state.showAllPolygons}
            checkForLoading={this.props.checkForLoading}
            checkForPolygons={this.props.checkForPolygons}
            gotoUpdates={this.props.gotoUpdates}
            showToastMessage={this.props.showToastMessage}
            loadHubAndBeams={this.props.loadHubAndBeams}
            contralIntervalHttpCalles={this.props.contralIntervalHttpCalles}
          />,
          mapRightActionBtns
        );
        this._buttonsPlaced = true;
      }
      if (this.props.btnOptions.recenterBtn) {
        var mapLeftActionBtns = document.createElement("div");
        mapLeftActionBtns.setAttribute("id", "mapLeftActionBtnsWrapper");
        this.state.mapInstance.controls[
          this.state.mapApi.ControlPosition.TOP_LEFT
        ].clear();
        this.state.mapInstance.controls[
          this.state.mapApi.ControlPosition.TOP_LEFT
        ].push(mapLeftActionBtns);
        if (
          window.location.pathname == ROOT &&
          mapLeftActionBtns != null &&
          this.state.mapInstance != null
        ) {
          ReactDOM.render(
            <>
              <CustomMapLocation
                setSearchHiskyId={
                  this.resetAllowRecenterAndLocateHiskyIdHandler
                }
                moveToLocationHandler={(e) => this.moveToLocationHandler(e)}
                siteLoadSetDisabled={this.props.siteLoadSetDisabled}
                refDisableCustomSearchBtn={this.refDisableCustomSearchBtn}
                checkStatusScreen={this.checkStatusScreenHandler}
                addDrawingManagerHandler={this.addDrawingManagerHandler}
                updateSitesListRef={this.props.updateSitesListRef}
                deleteDrawing={() =>
                  this.deleteDrawingHandler(this._selectedShape)
                }
                customSearchOpenHandler={(data) =>
                  this.customSearchOpenHandler(data)
                }
                checkForFullscreen={this.checkForFullscreenHandler}
                showSites={this.props.showSites} //func/////////////
                changeModeSiteonline={this.props.changeModeSiteonline}
                filterSitesAndDevices={(sitesRemoved) => {
                  //this._allowRecenter = true;
                  this.props.filterSitesAndDevices(sitesRemoved);
                }}
                resetSitesAndDevices={this.props.resetSitesAndDevices}
                hubFilters={this.props.hubFilters}
                refHubFiltersControls={(refFunc, hubOpened) => {
                  this._hubFilterOn = hubOpened;
                  if (!this._hubFilterOn) this._showHubMarkers = false;
                  this.props.refHubFiltersControls(refFunc);
                  if (!this._hubFilterOn && !this.state.showPolygon) {
                    this.closeLocateBeamHandler();
                  }
                }}
                excludedHubs={this.props.excludedHubs}
                refFullscreenControls={this.refFullscreenControls}
                cancelPolyline={this.cancelPolylineOnSiteModeHandler}
                allowRecenter={this.allowRecenterHandler}
              />
            </>,
            mapLeftActionBtns
          );
          //if (this.props.callBackSpinner !== undefined) {
          var mapSpinner = document.createElement("div");
          mapSpinner.setAttribute("id", "mapSpinner");
          this.state.mapInstance.controls[
            this.state.mapApi.ControlPosition.CENTER
          ].clear();
          this.state.mapInstance.controls[
            this.state.mapApi.ControlPosition.CENTER
          ].push(mapSpinner);
          ReactDOM.render(
            <MapSpinner callBackSpinner={this.props.callBackSpinner} />,
            mapSpinner
          );
          //}
        } else if (
          this.props.polyLineId != undefined ||
          this.props.selectPolygon
        ) {
          ReactDOM.render(
            <AddLatLng
              addLocationToPolyLine={this.props.addLocationToPolyLine}
            />,
            mapLeftActionBtns
          );
        }
      }
    }
    //zoom controls

    if (
      this.state.isFullScreenMode &&
      window.location.pathname === HUB_PP_SIMULATOR &&
      !this._hubppActionsPlaced &&
      this.props.polyLineId != undefined
    ) {
      this._hubppActionsPlaced = true;
      this._zoomButtonsPlaced = false;
      //Zoom controls
      var zoomBtns = document.createElement("div");
      zoomBtns.setAttribute("id", "zoomControlsWrapper");
      zoomBtns.setAttribute("class", styles.zoomControls);
      this.state.mapInstance.controls[
        this.state.mapApi.ControlPosition.BOTTOM_RIGHT
      ].clear();
      this.state.mapInstance.controls[
        this.state.mapApi.ControlPosition.BOTTOM_RIGHT
      ].push(zoomBtns);
      if (zoomBtns != null) {
        ReactDOM.render(
          <>
            <HubppActions
              //showBtns={this.state.isFullScreenMode}
              showPreviewBtn={this.props.polyLineId != undefined}
              previewAction={this.props.previewAction}
              applyAction={this.huppApplyAction}
            />

            <ZoomControls
              zoomin={this.zoominHandler}
              zoomout={this.zoomoutHandler}
            />
          </>,
          zoomBtns
        );
      }
    } else if (
      !this._zoomButtonsPlaced &&
      (!this.state.isFullScreenMode ||
        window.location.pathname !== HUB_PP_SIMULATOR)
    ) {
      //Zoom controls
      var zoomBtns = document.createElement("div");
      zoomBtns.setAttribute("id", "zoomControlsWrapper");
      zoomBtns.setAttribute("class", styles.zoomControls);
      this.state.mapInstance.controls[
        this.state.mapApi.ControlPosition.BOTTOM_RIGHT
      ].clear();
      this.state.mapInstance.controls[
        this.state.mapApi.ControlPosition.BOTTOM_RIGHT
      ].push(zoomBtns);
      if (zoomBtns != null) {
        ReactDOM.render(
          <>
            <ZoomControls
              zoomin={this.zoominHandler}
              zoomout={this.zoomoutHandler}
            />
          </>,
          zoomBtns
        );
      }
      this._zoomButtonsPlaced = true;
      this._hubppActionsPlaced = false;
    }

    if (this.state.isFullScreenMode) {
      var mapBottomBtns = document.createElement("div");
      mapBottomBtns.setAttribute("id", "mapBotomBtnsWrapper");

      if (
        window.location.pathname == ROOT &&
        mapBottomBtns != null &&
        this.state.mapInstance != null &&
        !this._terminalInfoPlaced
      ) {
        this.state.mapInstance.controls[
          this.state.mapApi.ControlPosition.BOTTOM_LEFT
        ].clear();
        this.state.mapInstance.controls[
          this.state.mapApi.ControlPosition.BOTTOM_LEFT
        ].push(mapBottomBtns);
        ReactDOM.render(
          <div className={styles.terminalInfo}>
            <TerminalsInfo
              setData={this.props.onSetDataHandler}
              hubsCount={this.props.hubsCount}
              onlineCount={this.props.onlineCount}
              mockupsCount={this.props.mockupsCount}
              offlineCount={this.props.offlineCount}
              types={this.props.types}
              isSiteMode={this.props.isSiteMode}
              refToGoogleMap={this.props.refToGoogleMap}
              sendCount={this.props.sendCount}
              preserveLastModeView={this.props.preserveLastModeView}
              checkForFullscreen={this.checkForFullscreenHandler}
              updateHubDataFilter={this.props.updateHubDataFilter}
            />
          </div>,
          mapBottomBtns
        );
        this._terminalInfoPlaced = true;
      }
    }
    if (!this.state.isFullScreenMode && this.state.mapInstance != null) {
      var mapBottomBtns = document.getElementById("mapBotomBtnsWrapper");
      if (mapBottomBtns != null) {
        this.state.mapInstance.controls[
          this.state.mapApi.ControlPosition.BOTTOM_LEFT
        ].clear();
        this._terminalInfoPlaced = false;
      }
    }
    //updates route close button on map will be used for fullscreen mode
    let showLatLngForm =
      this.props.showLatLngForm != undefined && this.props.showLatLngForm();
    // if (
    //   !this._showLabelsPlaced &&
    //   this.state.mapInstance != null &&
    //   window.location.pathname == UPDATES &&
    //   !showLatLngForm
    // ) {
    //   var mapBottomBtns = document.createElement("div");
    //   mapBottomBtns.setAttribute("id", "mapUpdatesTopBtnsWrapper");

    //   this.state.mapInstance.controls[
    //     this.state.mapApi.ControlPosition.TOP_LEFT
    //   ].clear();
    //   this.state.mapInstance.controls[
    //     this.state.mapApi.ControlPosition.TOP_LEFT
    //   ].push(mapBottomBtns);

    //   ReactDOM.render(
    //     <>
    //       <ShowLabels
    //         value={this.state.showlabelsOnPolygon}
    //         showLabelsClick={this.showLabelsHandler}
    //       />
    //     </>,
    //     mapBottomBtns
    //   );
    //   this._showLabelsPlaced = true;
    //   this._customLatLngFormPlaced = false;
    // }

    if (
      showLatLngForm &&
      !this._customLatLngFormPlaced &&
      this.state.mapInstance != null &&
      window.location.pathname == UPDATES
    ) {
      var mapBottomBtns = document.createElement("div");
      mapBottomBtns.setAttribute("id", "mapUpdatesTopBtnsWrapper");

      this.state.mapInstance.controls[
        this.state.mapApi.ControlPosition.TOP_LEFT
      ].clear();
      this.state.mapInstance.controls[
        this.state.mapApi.ControlPosition.TOP_LEFT
      ].push(mapBottomBtns);

      ReactDOM.render(
        <>
          <AddLatLng
            addLocationToPolyLine={this.props.addLocationToPolyLine}
            show={this.props.selectPolygon}
          />
        </>,
        mapBottomBtns
      );
      this._customLatLngFormPlaced = true;
      this._showLabelsPlaced = false;
    }

    if (
      this.props.showInfo != undefined &&
      this.props.showInfo.length > 0 &&
      this.state.mapInstance != null &&
      !this._terminalInfoPlacedOnHubDetail
    ) {
      var mapLeftActionBtns = document.createElement("div");
      mapLeftActionBtns.setAttribute("id", "mapLeftActionBtnsWrapper");
      this.state.mapInstance.controls[
        this.state.mapApi.ControlPosition.TOP_LEFT
      ].clear();
      this.state.mapInstance.controls[
        this.state.mapApi.ControlPosition.TOP_LEFT
      ].push(mapLeftActionBtns);
      if (mapLeftActionBtns != null && this.state.mapInstance != null) {
        ReactDOM.render(
          <MapLeftActionBtns showInfo={this.props.showInfo} />,
          mapLeftActionBtns
        );
        this._terminalInfoPlacedOnHubDetail = true;
      }
    }
  }
  /**
   * Ref actions
   */
  refFullscreenControls = (ref) => {
    this._isFullscreenRef = ref;
  };
  refDisableCustomSearchBtn = (ref) => {
    this._disableCustomSearchBtnRef = ref;
  };
  stopDashboardMarkerHandler = (ref) => {
    this._dashboardMarkerInstance = ref;
  };

  /**
   *  Map buttons actions
   */
  onZoomHandler = () => {
    let zoomChangeBoundsListener = this.state.mapApi.event.addListener(
      this.state.mapInstance,
      "bounds_changed",
      () => this.boundsChnagedHandler(zoomChangeBoundsListener)
    );
  };
  zoominHandler = () => {
    let currentZoom = this.state.mapInstance.getZoom();
    if (currentZoom == 22) return;
    this.state.mapInstance.setZoom(currentZoom + 1);
    this._isScrolling = true;
    this._allowRecenter = false;
  };
  zoomoutHandler = () => {
    let currentZoom = this.state.mapInstance.getZoom();
    if (currentZoom == 3) return;
    this.state.mapInstance.setZoom(currentZoom - 1);
    this._isScrolling = true;
    this._allowRecenter = false;
  };
  handleESCPress = (event) => {
    if (document.fullscreenElement == null && this.state.isFullScreenMode) {
      this.setState({ isFullScreenMode: !this.state.isFullScreenMode }, () => {
        this._isFullscreenRef != null &&
          this._isFullscreenRef(this.state.isFullScreenMode);
        this.modelCloseHandler();
      });
    }
  };
  fullScreenModeHandler = () => {
    this.setState({ isFullScreenMode: !this.state.isFullScreenMode }, () => {
      if (this.props.isFullScreen !== undefined) {
        this.props.isFullScreen(this.state.isFullScreenMode);
      }
      var elementToSendFullscreen = this.state.mapInstance.getDiv().firstChild;
      if (this.isFullscreen(elementToSendFullscreen)) {
        if (window.location.pathname.includes(DEVICES_HUB)) {
          this.closePolygonInfoWindowHandler();
        }
        this.exitFullscreen();
        this.modelCloseHandler();
        this._isFullscreenRef != null &&
          this._isFullscreenRef(this.state.isFullScreenMode);
      } else {
        this.requestFullscreen(elementToSendFullscreen);
        this._isFullscreenRef != null &&
          this._isFullscreenRef(this.state.isFullScreenMode);
      }
    });
  };
  moveToLocationHandler = (customPosition) => {
    this.removeSearchMarkerHandler();
    this.polygonInfowindowClosed();
    let currentZoom = this.state.mapInstance.getZoom();
    this._coordinateSearchOn = true;
    this._allowRecenter = false; // setting false when location is true
    const mapAPi = this.state.mapApi;
    let bounds = new mapAPi.LatLngBounds();
    bounds.extend({
      lat: parseFloat(customPosition.lat),
      lng: parseFloat(customPosition.lng),
    });
    this.state.mapInstance.fitBounds(bounds);
    this.state.mapInstance.setZoom(currentZoom);
    const image = {
      url: GOOGLE_PINS["searchPin"],
      scaledSize: new mapAPi.Size(30, 30),
    };
    this._searchMarker = new mapAPi.Marker({
      position: {
        lat: parseFloat(customPosition.lat),
        lng: parseFloat(customPosition.lng),
      },
      map: this.state.mapInstance,
      icon: image,
    });
    this._disableCustomSearchBtnRef();
    this.state.mapApi.event.addListener(
      this._searchMarker,
      "click",
      this.showSearchPinInfoWindowHandler
    );
  };
  getTerminalLocationBySearchHandler = (hiskyId) => {
    http(terminalMapSearch, {
      hiskyId: hiskyId,
      siteMode: this.props.isSiteMode,
    })
      .then((res) => {
        if (res.data.terminalMapSearch.message == null) {
          this.props.mapSpinner(() => {
            this.setState({ searchedCustomHiskyId: hiskyId }, () => {
              //move to location
              let terminal_loc = res.data.terminalMapSearch.terminal;
              const mapAPi = this.state.mapApi;
              let bounds = new mapAPi.LatLngBounds();
              if (
                terminal_loc.lastTelemetry !== null &&
                terminal_loc.lastTelemetry.lastLat !== null &&
                terminal_loc.lastTelemetry.lastLong !== null
              ) {
                bounds.extend({
                  lat: parseFloat(terminal_loc.lastTelemetry.lastLat),
                  lng: parseFloat(terminal_loc.lastTelemetry.lastLong),
                });
              } else {
                bounds.extend({
                  lat: parseFloat(terminal_loc.lat),
                  lng: parseFloat(terminal_loc.lng),
                });
              }
              this.state.mapInstance.fitBounds(bounds);
              this.state.mapInstance.setZoom(11); //below cluster level
              this._allowRecenter = false;
              this._disableCustomSearchBtnRef();
              this.props.contralIntervalHttpCalles != undefined &&
                this.props.contralIntervalHttpCalles(START);
            });
          }, "");
        } else {
          //show err msg on swral
          this.props.mapSpinner(() => {
            this._disableCustomSearchBtnRef();
            this.props.contralIntervalHttpCalles != undefined &&
              this.props.contralIntervalHttpCalles(START);
          }, res.data.terminalMapSearch.message);
        }
      })
      .catch((err) => {});
  };
  resetAllowRecenterAndLocateHiskyIdHandler = (hiskyId) => {
    this.props.contralIntervalHttpCalles != undefined &&
      this.props.contralIntervalHttpCalles(STOP);
    this.setState({ searchedCustomHiskyId: null }, () => {
      this.props.mapSpinner(() =>
        this.getTerminalLocationBySearchHandler(hiskyId)
      );
    });
  };
  showSearchPinInfoWindowHandler = (e) => {
    if (document.getElementById("dws") !== null) {
      ReactDOM.render(
        <SearchPinInfoWindow latLng={e.latLng.toJSON()} />,
        document.getElementById("dws")
      );

      this._infowindow.setPosition(e.latLng);
      this._infowindow.open(this.state.mapInstance);
      this.state.mapApi.event.addListener(
        this._infowindow,
        "closeclick",
        this.polygonInfowindowClosed
      );
    }
  };
  removeSearchMarkerHandler = () => {
    if (this._searchMarker == null) return;
    this._searchMarker.setMap(null);
  };
  showLabelsHandler = (callBack) => {
    this.setState(
      { showlabelsOnPolygon: !this.state.showlabelsOnPolygon },
      () => {
        callBack(this.state.showlabelsOnPolygon);
      }
    );
  };
  applyZoomConvertToMarkers = (isZoomOn) => {
    //save current zoom set zoom to 12
    if (isZoomOn) {
      this._currentZoom = this.state.mapInstance.getZoom();
      let center = this.state.mapInstance.getCenter();
      let latLng = {
        lat: parseFloat(center.lat()),
        lng: parseFloat(center.lng()),
      };
      this._allowRecenter = false;
      const bounds = getMapBounds(this.state.mapInstance, this.state.mapApi, [
        latLng,
      ]);
      this.state.mapInstance.fitBounds(bounds);
      this.state.mapInstance.setZoom(12);
    } else {
      this.state.mapInstance.setZoom(this._currentZoom);
    }
  };
  /**
   * NMS tool actions
   */
  addDrawingManagerHandler = () => {
    this.deleteDrawingHandler(this._selectedShape);
    //clear infowindow of polygon and markers
    this.polygonInfowindowClosed();
    this.closeInfoWindow();
    let mapApi = this.state.mapApi;
    this._isNonDrawingMode = false;
    this.drawingManager = new mapApi.drawing.DrawingManager({
      drawingMode: mapApi.drawing.OverlayType.RECTANGLE,
      drawingControl: false,
      drawingControlOptions: {
        position: mapApi.ControlPosition.TOP_CENTER,
        drawingModes: ["rectangle"],
      },
      map: this.state.mapInstance,
      rectangleOptions: {
        strokeWeight: 0,
        fillOpacity: 0.2,
        editable: true,
        draggable: false,
      },
    });
    this.state.mapApi.event.addListener(
      this.drawingManager,
      "overlaycomplete",
      this.onDrawCompleteHandler
    );
    this.state.mapApi.event.addListener(
      this.state.mapInstance,
      "click",
      this.clearSelection
    );
    this.state.mapApi.event.addListener(
      this.drawingManager,
      "drawingmode_changed",
      this.clearSelection
    );
    this._allowRecenter = false;
  };

  deleteDrawingHandler = (selectedShape) => {
    this.drawingInfowindowClosed();
    if (this.drawingManager != null) {
      this.drawingManager.setMap(null);
    }
    if (this._selectedShape != undefined) {
      this._selectedShape.setMap(null);
      this._selectedShape = null;
    }
    this._isNonDrawingMode = true;
    this.setState({
      showDrawingInfoWindow: false,
    });
  };
  getCombinedBounds = (e) => {
    let newBounds = new this.state.mapApi.LatLngBounds();
    var bounds = e.overlay.getBounds();
    var start = bounds.getNorthEast();
    var end = bounds.getSouthWest();
    var center = bounds.getCenter();
    newBounds.extend({
      lat: parseFloat(start.lat()),
      lng: parseFloat(start.lng()),
    });
    newBounds.extend({
      lat: parseFloat(end.lat()),
      lng: parseFloat(end.lng()),
    });
    newBounds.extend({
      lat: parseFloat(center.lat()),
      lng: parseFloat(center.lng()),
    });
    return newBounds;
  };
  onDrawCompleteHandler = (event) => {
    var newShape = event.overlay;
    if (event.type !== this.state.mapApi.drawing.OverlayType.MARKER) {
      this.drawingManager.setDrawingMode(null);
      if (event.type == "rectangle") {
        var bounds = event.overlay.getBounds();

        this._selectedShape = newShape;
        this._selectedShape.setEditable(false);
        var newBounds = this.getCombinedBounds(event);
        this.setState({ drawingBounds: newBounds }, () => {
          let currentZoom = this.state.mapInstance.getZoom();
          this.state.mapInstance.fitBounds(bounds);
          this.state.mapInstance.setZoom(currentZoom);
          this.drawingManager.setMap(null);
        });
        /**
         * show info window on click
         */
        this.state.mapApi.event.addListener(
          newShape,
          "click",
          this.showDrawingInfoWindowHandler
        );
      }
    }
  };
  clearSelection = () => {
    this.drawingManager.setMap(null);
  };
  //infowindow actions
  showDrawingInfoWindowHandler = (e) => {
    this.setState(
      {
        showDrawingInfoWindow: true,
      },
      () => {
        this._selectedShape.setEditable(false);
        if (document.getElementById("dmI") !== null) {
          ReactDOM.render(
            <DrawingInfoWindow
              markers={this.props.markers}
              drawingBounds={this.state.drawingBounds}
            />,
            document.getElementById("dmI")
          );
          this._drawingInfowindow.setPosition(e.latLng);
          this._drawingInfowindow.open(this.state.mapInstance);
          this.state.mapApi.event.addListener(
            this._drawingInfowindow,
            "closeclick",
            this.drawingInfowindowClosed
          );
        }
      }
    );
  };
  drawingInfowindowClosed = () => {
    this._drawingInfowindow.close();

    let contentString = "<div id='dmI'></div>";
    this._drawingInfowindow = new this.state.mapApi.InfoWindow();
    this._drawingInfowindow.setContent(contentString);
    this._drawingInfowindow.open(this.state.mapInstance);
  };
  closeInfoWindow = (key) => {
    this._allowRecenter = false;
    this.setState({ openedinfoWindowslist: {} });
  };
  /**
   * PolyLine actions(Navigation GPS trace, dots or lines on updates and hubpp)
   */
  /**
   * Bounding to marker on no gps trace
   * @param {*} terminalId
   * @returns
   */
  boundToMarkerHandler = (terminalId) => {
    let terminalTransmitting = this.props.markers.filter((item) => {
      return item.id == terminalId;
    });
    let coor;
    if (terminalTransmitting.length == 0) return;
    if (
      terminalTransmitting[0].lastLat != null &&
      terminalTransmitting[0].lastLong != null
    ) {
      coor = {
        lat: terminalTransmitting[0].lastLat,
        lng: terminalTransmitting[0].lastLong,
      };
    } else {
      coor = {
        lat: terminalTransmitting[0].lat,
        lng: terminalTransmitting[0].lng,
      };
    }
    const bounds = getMapBounds(this.state.mapInstance, this.state.mapApi, [
      coor, //latest position of marker
    ]);
    this.state.mapInstance.fitBounds(bounds);
    this.state.mapInstance.setZoom(13);
    this._allowRecenter = false;
    this._allowPolyLineCenter = false;
  };
  drawPolylineHandler = (ployLineCordinates) => {
    //props.polyline
    if (this._poliLineId == 0) return;
    this.deletePolylineHandler();
    var flightPlanCoordinates = ployLineCordinates;
    if (flightPlanCoordinates.length) {
      let mapApi = this.state.mapApi;
      this.polylineManager = new mapApi.Polyline({
        path: flightPlanCoordinates,
        geodesic: true,
        strokeColor: this.state.showPolygon ? "white" : "#00bdd6",
        strokeOpacity: 1.0,
        strokeWeight: 2,
        scale: 5,
      });
      this.polylineManager.setMap(this.state.mapInstance);
    }

    /**
     * bounding to the marker latlat, last lng by default // marker coordinates and navigation[0] are
     * set allowrecenter false inorder to stop bounding back
     * set zoom 13 more than cluster zoom level
     */
    if (this._allowPolyLineCenter && flightPlanCoordinates.length) {
      const bounds = getMapBounds(this.state.mapInstance, this.state.mapApi, [
        flightPlanCoordinates[0], //latest position of marker
      ]);
      this.state.mapInstance.fitBounds(bounds);
      this.state.mapInstance.setZoom(13);
      this._allowRecenter = false;
      this._allowPolyLineCenter = false;
    } else if (this._allowPolyLineCenter && flightPlanCoordinates.length == 0) {
      let terminalTransmitting = this.props.markers.filter((item) => {
        return item.id == this._poliLineId;
      });
      let coor;
      if (
        terminalTransmitting[0].lastLat != null &&
        terminalTransmitting[0].lastLong != null
      ) {
        coor = {
          lat: terminalTransmitting[0].lastLat,
          lng: terminalTransmitting[0].lastLong,
        };
      } else {
        coor = {
          lat: terminalTransmitting[0].lat,
          lng: terminalTransmitting[0].lng,
        };
      }
      const bounds = getMapBounds(this.state.mapInstance, this.state.mapApi, [
        coor, //latest position of marker
      ]);
      this.state.mapInstance.fitBounds(bounds);
      this.state.mapInstance.setZoom(13);
      this._allowRecenter = false;
      this._allowPolyLineCenter = false;
    }
  };
  getpolyLineCordinatesHandler = (terminalId = null, time = null) => {
    if (terminalId != this._poliLineId) {
      if (this.props.updateTrannsmittingTerminals != undefined)
        this.props.updateTrannsmittingTerminals(this._poliLineId); //call to the parent
    }
    this.props.setPoliyLine(terminalId, time); //parent api call
    this.closeDashBoradMarkerHandler(); // close the dahboard marker before api call of gps trace
  };
  updatePolyLineIdHandler = (terminalId) => {
    if (this._poliLineId != terminalId) this._allowPolyLineCenter = true;
    this._poliLineId = terminalId; //apiu call finshes
  };
  deletePolylineHandler = (terminalId = null) => {
    //terminal id only availble for dashboard
    if (this.polylineManager != undefined) {
      this.polylineManager.setMap(null);
      this.polylineManager = null;
    }

    if (this._poliLineId == 0) {
      this.props.cancelPoliyLine != undefined &&
        this.props.cancelPoliyLine(terminalId);
      if (window.location.pathname != ROOT) this._allowRecenter = true;
    }
  };
  cancelPoliyLineHandler = (terminalId) => {
    if (this._poliLineId > 0) {
      this._poliLineId = 0;
      this.deletePolylineHandler(terminalId);
    }
  };
  /**
   * drawing polyline dots and lines
   *
   */
  onClickShowDeletePolyDotHandler = (name) => {
    this.setState(
      { activeShortcutMenu: { item: { type: DOT_MARKER }, name } },
      () => {}
    );
  };
  getPolyLineDots = () => {
    if (!this.state.mapApiLoaded) return;
    let markers;
    if (this.props.polyLine.length) {
      if (this.props.showMarkerDots) {
        markers = this.props.polyLine.map((pos, index) => {
          return (
            <CustomMarker
              key={pos.id}
              lat={parseFloat(pos.lat)}
              lng={parseFloat(pos.lng)}
              type={DOT_MARKER}
              details={pos}
              markerClickHandler={this.polylineDotClickHandler}
              name={pos.id}
              rightClickActive={true}
              onClickRight={this.onClickShowDeletePolyDotHandler}
              activeShortcutMenu={this.state.activeShortcutMenu}
              onClickRightItem={this.decodeRightClickHandler}
              shortcutClicked={this.shortcutClickedHandler}
            />
          );
        });
      }
      this._allowPolyLineCenter && this.recenterToPolyLineHandler();
      this.drawHubPolylineHandler();
    } else {
      this.deletePolylineHandler();
    }
    return markers;
  };
  getPolyLineAndPolygonDots = () => {
    if (!this.state.mapApiLoaded) return;
    let markers;
    if (this.props.polyLine.length && this.props.showMarkerDots) {
      this.deleteUpdatePolygonsHandler();
      markers = this.props.polyLine.map((pos, index) => {
        return (
          <CustomMarker
            key={pos.id}
            lat={parseFloat(pos.lat)}
            lng={parseFloat(pos.lng)}
            type={DOT_MARKER}
            details={pos}
            name={pos.id}
            //markerClickHandler={this.props.addLocationToPolyLine}
            markerClickHandler={this.polylineDotClickHandler}
            rightClickActive={true}
            onClickRight={this.onClickShowDeletePolyDotHandler}
            activeShortcutMenu={this.state.activeShortcutMenu}
            onClickRightItem={this.decodeRightClickHandler}
            shortcutClicked={this.shortcutClickedHandler}
          />
        );
      });
      this._allowPolyLineCenter && this.recenterToPolyLineHandler();
      this.drawHubPolylineHandler();
    } else if (this.props.showKMLPolygon && this.props.kmlPolygon.length) {
      this.deletePolylineHandler();
      this.getKMLPolygonDrawHandler();
    } else {
      this.deletePolylineHandler();
      this._boundToKmlPolygon = true;
      if (this.props.polyLine.length) this.getPolygonDrawHandler();
      else this.deleteUpdatePolygonsHandler();
    }
    return markers;
  };
  polylineDotClickHandler = (latLng) => {
    
    if (this._shortcutClicked) return;
    this.setState({ activeShortcutMenu: {} }, () => {
      this.props.addLocationToPolyLine(latLng);
    });

    // this.clickCount++;
    // this.singleClickTimer = setTimeout(() => {
    //   if (this.clickCount === 1) {
    //     this.clickCount = 0;
    //     this.props.addLocationToPolyLine(latLng);
    //   } else {
    //     this.clickCount = 0;
    //     clearTimeout(this.singleClickTimer);
    //     this.props.deletePolylineDot(latLng);
    //   }
    // }, 500);
  };
  recenterToPolyLineHandler = () => {
    if (this.state.mapInstance == null || this.props.polyLine.length == 0)
      return;
    const bounds = getMapBounds(
      this.state.mapInstance,
      this.state.mapApi,
      this.props.polyLine
    );
    this.state.mapInstance.fitBounds(bounds);
    this._allowPolyLineCenter = false;
    //set zoom only on update model to remain on same
    this.props.selectPolygon && this.state.mapInstance.setZoom(3);
  };
  /**
   * hubpp polyline actions
   */
  huppApplyAction = () => {
    this.fullScreenModeHandler();
    this.props.applyAction();
  };
  drawHubPolylineHandler = () => {
    if (this._poliLineId == 0 && this.props.polyLineId == undefined) return;
    this._poliLineId = this.props.polyLineId;
    this.deletePolylineHandler();
    let mapApi = this.state.mapApi;

    this.polylineManager = new mapApi.Polyline({
      path: this.props.polyLine,
      geodesic: true,
      strokeColor: "#00bdd6",
      strokeOpacity: 1.0,
      strokeWeight: 2,
      scale: 5,
    });
    this.polylineManager.setMap(this.state.mapInstance);
  };

  /**
   * Polygon actions
   *
   */

  clearPolygonsHandler = () => {
    let polygonlist = this.polygonManager;
    polygonlist.map((item, index) => {
      item.setMap(null);
    });
    this.polygonManager = [];
  };
  showRightMenuForPolygon = (e, data) => {
    if (this._disableRightClick) {
      return;
    }
    clearTimeout(this._clearGlowingTimer);
    this._clearGlowingTimer = null;
    this.setState(
      {
        beamsLoading: true,
        beamRightMenu: {
          latLng: e.latLng.toJSON(),
          beamList: [],
          id: data.externalId,
        },
        activeShortcutMenu: { item: data.externalId, name: data.externalId },
      },
      () => {
        this.props.contralIntervalHttpCalles(STOP);
        http(overLayPolygons, {
          latitude: e.latLng.toJSON().lat,
          longitude: e.latLng.toJSON().lng,
        })
          .then((res) => {
            this.setState(
              {
                beamRightMenu: {
                  latLng: e.latLng.toJSON(),
                  beamList: res.data.coordinateClickedDashboard, //converStringToJson(data.coordinates),
                  id: data.externalId,
                },
                beamsLoading: false,
              },
              () => {
                this.props.contralIntervalHttpCalles(START);
                this._refShowTrailTextBox != null &&
                  document.getElementById("shortcutMenu") != null &&
                  this._refShowTrailTextBox();
              }
            );
          })
          .catch((err) => {});
      }
    );
  };
  plotPolygonsHandler = (polygons) => {
    let polygonCoordinates = [];
    polygons.map((item, index) => {
      let parsedCoordinates = converStringToJson(item.coordinates);
      if (item.checked || this.state.showAllPolygons) {
        let strokeColor = POLYGON_SETTINGS.strokeColor;
        let strokeWeight = POLYGON_SETTINGS.strokeWeight;
        let fillColor = POLYGON_SETTINGS.fillColor;

        //fillcolor
        if (item.color != undefined && item.color != "") fillColor = item.color;
        //stroke color
        if (item.borderColor != undefined && item.borderColor != "")
          strokeColor = item.borderColor;

        // Construct the polygon.
        const poly = new this.state.mapApi.Polygon({
          id:
            item.hub != null && window.location.pathname.includes(DEVICES_HUB)
              ? item.hub.id
              : item.externalId,
          paths: parsedCoordinates,
          strokeColor: strokeColor,
          strokeOpacity: POLYGON_SETTINGS.strokeOpacity,
          strokeWeight: strokeWeight,
          fillColor: fillColor,
          fillOpacity:
            item.opacity == undefined || item.opacity == ""
              ? POLYGON_SETTINGS.fillOpacity
              : item.opacity,
        });

        poly.setMap(this.state.mapInstance);

        this.closePolygonInfoWindowHandler();
        poly.addListener(
          "click",
          (e) =>
            window.location.pathname === ROOT
              ? this.polygonClick(e, { ...item })
              : this.showUpdatePolygonHandler(e, item, true, index), // click for polygon on hub page,
          true
        );
        window.location.pathname == ROOT &&
          poly.addListener("contextmenu", (e) => {
            if (!this._isNonDrawingMode) return;
            setTimeout(() => {
              this.showRightMenuForPolygon(e, item);
            }, 100);
          });
        this.polygonManager.push(poly);
        //bounding to the polygons on showing polygons

        const bounds = new window.google.maps.LatLngBounds();
        parsedCoordinates.forEach((place) => {
          bounds.extend(new window.google.maps.LatLng(place.lat, place.lng));
        });
        let centerCordinates = bounds.getCenter();
        polygonCoordinates.push({
          lat: centerCordinates.lat(),
          lng: centerCordinates.lng(),
        });
      }
    });
    //fibounds to polygons
    if (
      (polygonCoordinates.length && this._allowRecenter) ||
      (polygonCoordinates.length &&
        window.location.pathname.includes(DEVICES_HUB))
    ) {
      const focusBounds = getMapBounds(
        this.state.mapInstance,
        this.state.mapApi,
        polygonCoordinates
      );
      this.state.mapInstance.fitBounds(focusBounds);
    }
  };
  clearGlowBeamDataHandler = () => {
    this.setState(
      {
        beamRightMenu: {},
      },
      () => {
        clearInterval(this._glowBeamInterval);
        this._glowBeamInterval = null;
        this._locateBeamHubIndex = null;
        clearTimeout(this._clearGlowingTimer);
        this._clearGlowingTimer = null;
        this._refShowTrailTextBox != null &&
          document.getElementById("shortcutMenu") != null &&
          this._refShowTrailTextBox();
      }
    );
  };
  getGlowingHubBeamHandler = () => {
    return this._locateBeamHubIndex;
  };
  showPolygonDashboardHandler = () => {
    //apicall to get hubs and beams
    //let beams = this.props.loadHubAndBeams()
    this._singleMarker = {};
    //resetiing beam glowing
    this.setState(
      {
        showPolygon: !this.state.showPolygon,
        activeShortcutMenu: {}, // for clearing right click menu if open
        beamRightMenu: {}, //for clearing beam right menu
      },
      () => {
        // Define the LatLng coordinates for the polygon's path.
        if (this.state.showPolygon) {
          // to calll the ref func on hub details page to close beam shown on update hub model open
          if (this.props.refPolygonClick !== undefined) {
            this.props.refPolygonClick(
              this.showPolygonDashboardHandler,
              this.state.showPolygon
            );
          }
          //clear the polygons before building again
          this.clearPolygonsHandler();
          this.plotPolygonsHandler(this.props.polygon);
          if (
            window.location.pathname.includes(DEVICES_HUB) &&
            this.props.polygon.length
          ) {
            this._allowRecenter = false;
            //set the zoom to 5
            this.state.mapInstance.setZoom(5);
          }
          if (
            this._locateBeamHubIndex != null &&
            Object.keys(this._locateBeamHubIndex).length > 0
          ) {
            clearInterval(this._glowBeamInterval);
            this._glowBeamInterval = null;
            let polygonList = this.polygonManager;
            polygonList.map((item, index) => {
              if (item.id == this._locateBeamHubIndex.id) {
                this._locateBeamHubIndex = {
                  index: index,
                  orignalDefaultColor: item.strokeColor,
                  id: item.id,
                };
                item.setOptions({ strokeColor: "#fff" });
                //glow the polygon selected
                this.glowHubBeamHandler(this._locateBeamHubIndex);
              }
            });
          }
        } else {
          this.globalResetPolygonsDataHandler();
          //as bounding to the polygons, recenter to default view on polygons hide
          if (window.location.pathname.includes(DEVICES_HUB)) {
            this._allowRecenter = true;
          }
          this.recenterMapHandler();
        }
      }
    );
  };
  globalResetPolygonsDataHandler = () => {
    this.polygonInfowindowClosed();

    this.closePolygonInfoWindowHandler();
    this.clearPolygonsHandler();
    this._locateBeamHubIndex = null;
  };
  showPolygonHandler = (showPolygon = null) => {
    //updates page
    // Define the LatLng coordinates for the polygon's path.
    if (showPolygon) {
      //clear the polygons before building again
      this.clearPolygonsHandler();
      let coordinates = [];
      let infoWindowPosition = null;
      this.props.polygon.map((item, index) => {
        let key = this.props.isPreviewPolygon ? "id" : "uuid";
        if (
          this.props.deletedBeams != undefined &&
          this.props.deletedBeams[item[key]] != undefined
        ) {
          return;
        } // not showing the deleted polygons

        let strokeColor = POLYGON_SETTINGS.strokeColor;
        let strokeWeight = POLYGON_SETTINGS.strokeWeight;
        let fillColor = POLYGON_SETTINGS.fillColor;
        let opacity = POLYGON_SETTINGS.fillOpacity;
        if (item.opacity != undefined && item.opacity != "")
          opacity = item.opacity;

        //fillcolor
        if (item.color != undefined && item.color != "") {
          fillColor = item.color;
        }
        //stroke color
        if (item.borderColor != undefined && item.borderColor != "")
          strokeColor = item.borderColor;
        if (
          this.props.beamSelected != undefined &&
          Object.keys(this.props.beamSelected).length &&
          this.props.beamSelected.id === item.id
        ) {
          infoWindowPosition = new this.state.mapApi.LatLng(
            item.center.lat,
            item.center.lng
          );
          strokeColor = BEAM_HIGHLIGHT_COLOR;
          this._beamSelected = index;
          fillColor =
            this.props.beamSelected.preview_fillColor != "" &&
            this.props.beamSelected.preview_fillColor != undefined
              ? this.props.beamSelected.preview_fillColor
              : item.color != undefined && item.color != ""
              ? item.color
              : POLYGON_SETTINGS.fillColor;
          strokeWeight = 3;
        }
        // Construct the polygon.

        const poly = new this.state.mapApi.Polygon({
          id: this.props.isPreviewPolygon ? item.id : item.externalId,
          paths: this.props.isPreviewPolygon
            ? item.coordinates
            : converStringToJson(item.coordinates),
          strokeColor: strokeColor,
          strokeOpacity: POLYGON_SETTINGS.strokeOpacity,
          strokeWeight: strokeWeight,
          fillColor: fillColor,
          fillOpacity: opacity,
        });

        poly.setMap(this.state.mapInstance);
        coordinates.push({
          lat: item.center.lat,
          lng: item.center.lng,
          title: item.name,
          id: item.id,
        });
        poly.addListener("click", (e) => {
          this.showUpdatePolygonHandler(e, [item], false, index);
        });
        this.polygonManager.push(poly);
      });

      //get the bounds and fitbounds with list of available beams only if on updates route

      const bounds = getMapBounds(
        this.state.mapInstance,
        this.state.mapApi,
        coordinates
      );
      if (!this._updateBeamsPlaced) this.state.mapInstance.fitBounds(bounds);
      this._updateBeamsPlaced = true;
      //open info window on polygon by default
    } else {
      let polygonlist = this.polygonManager;

      polygonlist.map((item, index) => {
        item.setMap(null);
      });
      this.polygonManager = [];
      //check if polygon exsists then only infowindow is need
      //if (polygonlist.length) this.polygonInfowindowClosed();
    }
  };
  getPolygonDrawHandler = () => {
    if (!this.state.mapApiLoaded) return;
    let strokeColor = POLYGON_SETTINGS.strokeColor;
    let strokeWeight = POLYGON_SETTINGS.strokeWeight;
    let fillColor = POLYGON_SETTINGS.fillColor;
    let opacity = POLYGON_SETTINGS.fillOpacity;

    // Construct the polygon.
    let mapApi = this.state.mapApi;
    let coor = this.props.polyLine.map(({ lat, lng }) => {
      return { lat, lng };
    });
    const poly = new mapApi.Polygon({
      paths: coor,
      strokeColor: strokeColor,
      strokeOpacity: POLYGON_SETTINGS.strokeOpacity,
      strokeWeight: strokeWeight,
      fillColor: fillColor,
      fillOpacity: opacity,
    });

    poly.setMap(this.state.mapInstance);
    this._updatePloygon.push(poly);
  };
  getKMLPolygonDrawHandler = () => {
    if (!this.state.mapApiLoaded) return;
    let strokeColor = "red";
    let strokeWeight = POLYGON_SETTINGS.strokeWeight;
    let fillColor = "yellow";
    let opacity = POLYGON_SETTINGS.fillOpacity;

    // Construct the polygon.
    let mapApi = this.state.mapApi;
    let coor = this.props.kmlPolygon.map(({ lat, lng }) => {
      return { lat, lng };
    });
    const poly = new mapApi.Polygon({
      paths: coor,
      strokeColor: strokeColor,
      strokeOpacity: POLYGON_SETTINGS.strokeOpacity,
      strokeWeight: strokeWeight,
      fillColor: fillColor,
      fillOpacity: opacity,
    });

    poly.setMap(this.state.mapInstance);
    this._updatePloygon.push(poly);
    const bounds = new window.google.maps.LatLngBounds();

    bounds.extend(new window.google.maps.LatLng(coor[0].lat, coor[0].lng));
    bounds.extend(
      new window.google.maps.LatLng(
        coor[coor.length - 1].lat,
        coor[coor.length - 1].lng
      )
    );
    if (this._boundToKmlPolygon) {
      this.state.mapInstance.fitBounds(bounds);
      this.state.mapInstance.setZoom(5);
      this._boundToKmlPolygon = false;
    }
  };
  deleteUpdatePolygonsHandler = () => {
    let polygonlist = this._updatePloygon;
    polygonlist.map((item, index) => {
      item.setMap(null);
    });
    this._updatePloygon = [];
  };
  closePolygonInfoWindowHandler = (keepHighlited = false) => {
    if (document.getElementById("polygonInfo") !== null) {
      document.getElementById("polygonInfo").remove();
      //bound to beam
      if (
        this._beamSelected != null &&
        keepHighlited &&
        window.location.pathname != ROOT &&
        this.props.polygon[this._beamSelected] != undefined
      ) {
        let prev_zoom = this.state.mapInstance.getZoom();
        const bounds = getMapBounds(this.state.mapInstance, this.state.mapApi, [
          this.props.polygon[this._beamSelected].center,
        ]);
        this.state.mapInstance.fitBounds(bounds);
        this.state.mapInstance.setZoom(prev_zoom);
      }
      if (
        !keepHighlited &&
        this._beamSelected != null &&
        this.props.polygon[this._beamSelected] != undefined
      ) {
        let strokeColor =
          this.props.polygon[this._beamSelected].borderColor == "" ||
          this.props.polygon[this._beamSelected].borderColor == undefined
            ? POLYGON_SETTINGS.strokeColor
            : this.props.polygon[this._beamSelected].borderColor;
        this.polygonManager[this._beamSelected].setOptions({ strokeColor });
        this._beamSelected = null;
      }
    }
  };
  setSelectedBeamHandler = (beam) => {
    //let externalId = this.props.isPreviewPolygon ? beam.id : beam.externalId;
    if (this.props.onClickItemOfListPolygon != undefined) {
      this.props.onClickItemOfListPolygon(beam);
    }
    if (window.location.pathname == ROOT) {
      let polygonList = this.polygonManager;
      polygonList.map((item, index) => {
        let externalId = beam.externalId;
        if (item.id == externalId) {
          item.setOptions({ strokeColor: BEAM_HIGHLIGHT_COLOR });
          this._beamSelected = index;
        } else {
          let strokeColor =
            this.props.polygon[index].borderColor == "" ||
            this.props.polygon[index].borderColor == undefined
              ? POLYGON_SETTINGS.strokeColor
              : this.props.polygon[index].borderColor;
          item.setOptions({ strokeColor });
        }
      });
    }
    //highlight the list polygon
  };
  setPolygonInfoWindowHandler = (e, beamSelected = null) => {
   
    if (this._shortcutClicked) return;
    this.closeDashBoradMarkerHandler();
    var polygonInfo = document.createElement("div");
    polygonInfo.setAttribute("id", "polygonInfo");
    polygonInfo.setAttribute("class", styles.polygonInfo);
    let lat =
      e != null && window.location.pathname == ROOT
        ? e.latLng.lat()
        : beamSelected[0].centroidLat != undefined
        ? beamSelected[0].centroidLat
        : beamSelected[0].center.lat;
    let lng =
      e != null && window.location.pathname == ROOT
        ? e.latLng.lng()
        : beamSelected[0].centroidLng != undefined
        ? beamSelected[0].centroidLng
        : beamSelected[0].center.lng;
    ReactDOM.render(
      <InfoPolygons
        lat={lat}
        lng={lng}
        contralIntervalHttpCalles={
          this.props.contralIntervalHttpCalles != undefined
            ? this.props.contralIntervalHttpCalles
            : () => {}
        }
        closeViewPolygon={this.closePolygonInfoWindowHandler}
        listPolygons={[]}
        setSelectedBeam={this.setSelectedBeamHandler}
        beamSelected={beamSelected}
      />,
      polygonInfo
    );
    this.state.mapInstance.controls[
      this.state.mapApi.ControlPosition.CENTER
    ].push(polygonInfo);
  };
  polygonClick = (e, props) => {
    if (this._isDrag) return;
    if (!this._isNonDrawingMode) {
      this.showDrawingInfoWindowHandler(e);
      return;
    }
    if (document.getElementById("polygonInfo") !== null) {
      this.closePolygonInfoWindowHandler();
      this.setPolygonInfoWindowHandler(e);
    } else {
      setTimeout(() => {
        this.setPolygonInfoWindowHandler(e);
      }, 100);
    }
  };
  //update beam info
  updateBeamAndCloseInfoWindowHandler = (data) => {
    this.props.updateBeamInfo(data);
    setTimeout(() => {
      this.polygonInfowindowClosed();
    }, 2000);
  };
  //for Updates route when clicked on polygon
  showUpdatePolygonHandler = (e, beamSelected, autoopen, index) => {
    let list = [];
    var ismatched = false;
    this.polygonManager.map((item, index) => {
      ismatched = window.google.maps.geometry.poly.containsLocation(
        e.latLng,
        item
      );
      if (ismatched) {
        list.push(this.props.polygon[index]);
       
      }
    });
    beamSelected = list;
 
    if (this.props.selectPolygon != undefined && this.props.selectPolygon) {
      this.polylineDotClickHandler({lat:e.latLng.lat(),lng:e.latLng.lng()});
      return
     
    }
    if (!autoopen) {
      this.openPolygonInfoWindow(e, beamSelected);
    } else {
      this.openPolygonInfoWindow(e, beamSelected);
    }
  };
  /**
   * Polygon infoWindows
   */
  openPolygonInfoWindow = (e, beamSelected = null) => {
    if (document.getElementById("polygonInfo") !== null) {
      this.closePolygonInfoWindowHandler();
      this.setPolygonInfoWindowHandler(e, beamSelected);
    } else {
      setTimeout(() => {
        this.setPolygonInfoWindowHandler(e, beamSelected);
      }, 100);
    }
  };
  polygonInfowindowClosed = () => {
    
    this._infowindow.close();
    let contentString = "<div id='dws'></div>";
    this._infowindow = new this.state.mapApi.InfoWindow();
    this._infowindow.setContent(contentString);
    this._infowindow.open(this.state.mapInstance);
    //If update route clear the preview color values of beamselected
    // if (window.location.pathname == UPDATES) {
    //   this.props.updateBeamPreview({});
    // }
  };
  /**
   * building cluster markers
   *
   */
  getpoints = (points) => {
    const geojsonPoints = points.map((item) => {
      let obj;
      if ( item.siteId != undefined) {
        //is mockup

        obj = {
          type: item.type,
          lng: item.lng,
          lat: item.lat,
          hiskyId: item.hiskyId,
          id: item.id,
          markerId: generateMarkerId(item.hiskyId),
          isOnline: item.isOnline,
          title: item.deviceName,
          terminalType: item.terminalType,
        };
      } else
        obj = {
          type: TERMINAL,
          lng: item.lng,
          lat: item.lat,
          hiskyId: item.terminal.hiskyId,
          id: item.terminal.id,
          markerId: generateMarkerId(item.terminal.hiskyId),
          isOnline: item.terminal.isOnline,
          title: item.terminal.deviceName,
          terminalType: item.terminal.terminalType,
        };
      return {
        type: "Feature",
        properties: {
          itemId: obj.hiskyId,
          data: obj,
        },
        geometry: {
          type: "Point",
          coordinates: [obj.lng, obj.lat],
        },
      };
    });
    return geojsonPoints;
  };

  getsupercluster = (points, options) => {
    var cl = new supercluster(options);
    this.clRef = cl;
    cl.load(this.getpoints(points));
    return cl.getClusters(
      this.state.mapBounds,
      this.state.mapInstance.getZoom()
    );
  };
  getClusters = () => {
    const clusters = this.getsupercluster(this.props.markers, {
      minZoom: 0,
      maxZoom: CLUSTER_LEVEL_ZOOM,
      radius: 30,
      minPoints: 1,
    });
    return clusters;
  };
  checkClickCountHandler = (latitude, longitude, clusterId) => {
    if (this._isDrag) return;
    this._clusterClickCount++;
    setTimeout(() => {
      if (this._clusterClickCount === 1) {
        this._clusterClickCount = 0;
        this.onclickClusterMarkerHandler(latitude, longitude, clusterId);
      } else {
        this._clusterClickCount = 0;
        this.boundToClusterMarkers(clusterId);
      }
    }, 300);
  };
  boundToClusterMarkers = (clusterId) => {
    let markersInCluster = this.clRef
      .getLeaves(clusterId, "Infinity", 0)
      .map((item) => {
        return {
          lat:
            item.properties.data.lastLat != undefined
              ? item.properties.data.lastLat
              : item.properties.data.lat,
          lng:
            item.properties.data.lastLong != undefined
              ? item.properties.data.lastLong
              : item.properties.data.lng,
        };
      });
    const bounds = getMapBounds(
      this.state.mapInstance,
      this.state.mapApi,
      markersInCluster
    );
    this.state.mapInstance.fitBounds(bounds);
    this._allowRecenter = false;
  };
  onclickClusterMarkerHandler = (latitude, longitude, clusterId) => {
    if (!this._isNonDrawingMode) return;
    if (this.polygonManager.length > 0) {
      this.closePolygonInfoWindowHandler();
    }
    let markersInCluster = this.clRef
      .getLeaves(clusterId, "Infinity", 0)
      .map((item) => {
        return {
          isDynamic: item.properties.data.terminalType === 2,
          isOnline: item.properties.data.isOnline,
          type: item.properties.data.type,
          id: item.properties.data.id,
          hiskyId: item.properties.data.hiskyId,
          title: item.properties.data.title,
        };
      });
    this.openDashBoardMarkerHandler(markersInCluster, latitude, longitude);
  };
  /**
   * Dashboard marker click actions
   *
   */
  closeDashBoradMarkerHandler = () => {
    if (document.getElementById("dashboradMarker") !== null) {
      if (this._poliLineId == 0) this._allowPolyLineCenter = true; // if polyline is already on dont recenter
      document.getElementById("dashboradMarker").remove();
      this._dashboardMarkerInstance != undefined &&
        this._dashboardMarkerInstance();
    }
  };
  resetPolyLineCenterHandler = () => {
    this._allowPolyLineCenter = true;
  };
  panToHubHandler = (data) => {
    this._showHubMarkers = true;
    //close dash markr infowindow
    this.closeDashBoradMarkerHandler();
    //opening the infowindow
    let list = {};
    let key = "hub" + data.macId;
    list[key] = {
      deviceName: data.deviceName,
      id: data.id,
      isActive: data.isActive,
      lat:
        data.lastTelemetry == null ? data.lat : data.lastTelemetry.locationLat,
      lng:
        data.lastTelemetry == null
          ? data.long
          : data.lastTelemetry.locationLong,
      macId: data.macId,
      type: HUB,
    };

    this.setState({ openedinfoWindowslist: list }, () => {
      let markersList = Object.values(this.state.openedinfoWindowslist);
      const bounds = getMapBounds(
        this.state.mapInstance,
        this.state.mapApi,
        markersList
      );
      this.state.mapInstance.fitBounds(bounds);
      this.state.mapInstance.setZoom(3); // check zoom level : TODO
    });
  };
  openDashBoardMarkerHandler = (arr = [], lat, lng, showHubDetails = false) => {
    // if (!this._isNonDrawingMode || this.polygonManager.length > 0) return;
    if (!this._isNonDrawingMode) return;

    if (this.polygonManager.length > 0) {
      this.closePolygonInfoWindowHandler();
    }
    this.closeDashBoradMarkerHandler();
    var dashboradMarker = document.createElement("div");
    dashboradMarker.setAttribute("id", "dashboradMarker");
    ReactDOM.render(
      <DashboardMarker
        lat={lat}
        lng={lng}
        contralIntervalHttpCalles={this.props.contralIntervalHttpCalles}
        closeViewMarker={this.closeDashBoradMarkerHandler}
        resetPolyLineCenter={this.resetPolyLineCenterHandler}
        termianls={arr}
        redirectToTelemetryPage={this.redirectToTelemetryPageHandler}
        drawPolyline={(data, time) =>
          this.getpolyLineCordinatesHandler(data, time)
        }
        cancelPolyline={this.cancelPoliyLineHandler}
        getTransmittingTerminals={this.props.getTransmittingTerminals}
        stopMarkerInstance={this.stopDashboardMarkerHandler}
        boundToMarker={this.boundToMarkerHandler}
        showHubDetails={showHubDetails}
        //hubId={hubId}
        panToHub={this.panToHubHandler}
      />,
      dashboradMarker
    );
    this.state.mapInstance.controls[
      this.state.mapApi.ControlPosition.CENTER
    ].push(dashboradMarker);
  };
  /**
   * Map button actions stop
   */

  /**
   *
   * Check/ return values : actions
   */
  customSearchOpenHandler = (isOpen) => {
    if (!isOpen) {
      this._coordinateSearchOn = false;
      this.setState({ searchedCustomHiskyId: null }, () => {
        this.removeSearchMarkerHandler();
        this.polygonInfowindowClosed();
      });
    }
  };
  checkForFullscreenHandler = () => {
    return this.state.isFullScreenMode;
  };
  allowRecenterHandler = (val) => {
    this._allowRecenter = val;
  };
  redirectToTelemetryPageHandler = (item) => {
    this.props.history.push(
      DEVICES_TERMINAL + "/" + item.id + ":" + item.hiskyId
    );
  };
  getMarkersHandler = () => {
    return this.props.markers;
  };
  isInfoWindowOpenHandler = (key) => {
    return this.state.openedinfoWindowslist.hasOwnProperty(key);
  };

  resetBoundsHandler = () => {
    //TODO
    // check its usage : TODO
    let markersList; //list of markers whose infowindows are open
    if (Object.keys(this.state.openedinfoWindowslist).length) {
      markersList = Object.values(this.state.openedinfoWindowslist);
    } else {
      markersList = this.getMarkersHandler();
    }
    if (markersList.length >= 1) {
      if (
        markersList.length == 1 &&
        Object.keys(this._singleMarker).length &&
        parseFloat(this._singleMarker.lat) == parseFloat(markersList[0].lat) &&
        parseFloat(this._singleMarker.lng) == parseFloat(markersList[0].lng)
      ) {
      } else {
        const bounds = getMapBounds(
          this.state.mapInstance,
          this.state.mapApi,
          markersList
        );
        this.state.mapInstance.fitBounds(bounds);
      }
    }
  };
  /**
   * Place marker actions
   */
  onClickRightHandler = (item, name) => {
    //check if nms tool is open
    if (!this._isNonDrawingMode) return;
    if (this.state.showPolygon)
      // this bool is required for checks only when polygon mode is on
      this.disableRightClickHandler();
    this.setState(
      { activeShortcutMenu: { item: item, name: name }, beamRightMenu: {} },
      () => {}
    );
  };
  glowHubBeamHandler = (data) => {
    let index = data.index;
    let polygonList = this.polygonManager;
    let defaultColor = polygonList[index].strokeColor;
    this._glowBeamInterval = setInterval(() => {
      polygonList[index].setOptions({ strokeColor: defaultColor });
      if (polygonList[index].strokeColor != "#fff") defaultColor = "#fff";
      else defaultColor = data.orignalDefaultColor;
    }, 1000);
    this._clearGlowingTimer = setTimeout(() => {
      this.closeLocateBeamHandler();
    }, 5000);
  };
  locateBeamHandler = (data) => {
    let boundList;
    let hubPosition = { lat: data.lat, lng: data.lng };
    let polygons = [];
    this.props.polygon.map((item, index) => {
      if (item.hub != null && item.hub.id == data.id) {
        let parsedCoordinates = converStringToJson(item.coordinates);
        boundList = parsedCoordinates;
        polygons.push(item);
      }
    });
    this.setState({ activeShortcutMenu: {} }, () => {
      if (this.state.showPolygon) {
        //already on polygon mode
        let polygonList = this.polygonManager;
        polygonList.map((item, index) => {
          if (item.id == data.id) {
            this._locateBeamHubIndex = {
              index: index,
              orignalDefaultColor: item.strokeColor,
              id: item.id, //hubid
            };
            item.setOptions({ strokeColor: "#fff" });
            //glow the polygon selected
            this.glowHubBeamHandler(this._locateBeamHubIndex);
          }
        });
      } else {
        this.globalResetPolygonsDataHandler();
        if (polygons.length) {
          this.plotPolygonsHandler(polygons);
          this._locateBeamHubIndex = {
            index: 0,
            orignalDefaultColor: this.polygonManager[0].strokeColor,
            id: this.polygonManager[0].id, //hub id which is inturn a polygon  id
          };
          this.glowHubBeamHandler(this._locateBeamHubIndex);
        }
      }
      //and then bound to hub and polygons if polygon exsit
      if (polygons.length) {
        boundList.push(hubPosition);
        const bounds = getMapBounds(
          this.state.mapInstance,
          this.state.mapApi,
          boundList
        );
        this.state.mapInstance.fitBounds(bounds);
        this._allowRecenter = false;
      } else {
        toast.info("No Beam attached", NOTIFICATION_OPTIONS);
        this.closeLocateBeamHandler();
      }
    });
  };
  locateHubTerminals = (data) => {
    this.props.contralIntervalHttpCalles != undefined &&
      this.props.contralIntervalHttpCalles(STOP);
    http(getHubTerminalsById, {
      id: data.id,
    })
      .then((res) => {
        if (res.data.hbsTerminals.length > 0) {
          let terminals = res.data.hbsTerminals.map((v) => ({
            ...v,
            isDynamic: v.terminalType === 2,
            type: TERMINAL,
            title: v.deviceName,
          }));
          this.openDashBoardMarkerHandler(terminals, data.lat, data.lng);
        } else {
          this.props.contralIntervalHttpCalles != undefined &&
            this.props.contralIntervalHttpCalles(START);
        }
      })
      .catch((err) => {});
  };
  closeLocateBeamHandler = () => {
    if (this.state.showPolygon) {
      let polygonlist = this.polygonManager;
      polygonlist.map((item, index) => {
        if (
          this._locateBeamHubIndex != null &&
          item.id == this._locateBeamHubIndex.id
        )
          item.setOptions({
            strokeColor: this._locateBeamHubIndex.orignalDefaultColor,
          });
      });
    } else {
      this.polygonInfowindowClosed();
      this.clearPolygonsHandler();
      this._locateBeamHubIndex = null;
    }
    this.clearGlowBeamDataHandler();
  };
  shortcutClickedHandler = () => {
    this._shortcutClicked = true;
    setTimeout(() => {
      this._shortcutClicked = false;
    }, 200);
  };
  disableRightClickHandler = () => {
    this._disableRightClick = true;
    setTimeout(() => {
      this._disableRightClick = false;
    }, 200);
  };
  decodeRightClickHandler = (e, action, data) => {
    e.stopPropagation();
    this.shortcutClickedHandler();
    switch (action) {
      case DELETE_POLY_DOT: //updates
        this.props.deletePolylineDot({ id: data.name });
        break;
      case LOCATE_BEAM: //hub
        this.locateBeamHandler(data);
        break;
      case CLOSE_LOCATE_BEAM: //hub
        this.closeLocateBeamHandler(data);
        break;
      case LOCATE_TERMINALS: //hub
        this.locateHubTerminals(data);
        break;
      case GO_TO_PAGE: //cluster && single marker, mockup
        this.redirectToTelemetryPageHandler(data);
        break;
      case SHOW_TRAIL: //cluster && single marker
        this.resetPolyLineCenterHandler();
        this.getpolyLineCordinatesHandler(data.id, 0);
        break;
      case CANCEL_TRAIL:
        this.cancelPoliyLineHandler(data.id);
        break;
      case ASSIGNED_HUB_DETAILS: //cluster && single marker, mockup
        //getHubDetailstoTerminal
        this.openDashBoardMarkerHandler(
          data.markersInCluster == undefined
            ? [
                {
                  id: data.terminalInfo.id,
                  hiskyId: data.terminalInfo.hiskyId,
                  title: data.terminalInfo.title,
                  isOnline: data.terminalInfo.isOnline,
                  type: data.terminalInfo.type,
                  isMockup: data.terminalInfo.isMockup,
                },
              ]
            : data.markersInCluster,
          data.lat,
          data.lng,
          true
        );
        break;
    }
    this.setState({ activeShortcutMenu: {} }, () => {});
  };
  getMarkerHandler = (pos) => {
    let markerId = this.getMarkerId(pos);
    return (
      <CustomMarker
        key={markerId}
        lat={parseFloat(pos.lat)}
        lng={parseFloat(pos.lng)}
        macId={pos.macId}
        //name={pos.title}
        id={pos.id}
        name={markerId}
        lastActivityDate={pos.lastActivityDate}
        type={pos.type}
        hiskyId={pos.hiskyId}
        title={pos.title}
        onClickRight={this.onClickRightHandler}
        rightClickActive={this.props.rightClickActive}
        activeShortcutMenu={this.state.activeShortcutMenu}
        openedinfoWindowslist={this.state.openedinfoWindowslist}
        click={this.props.moreInfoClick}
        isInfoWindowOpen={this.isInfoWindowOpenHandler(markerId)}
        closeInfoWindow={this.closeInfoWindow}
        onClickRightItem={this.decodeRightClickHandler}
        shortcutClicked={this.shortcutClickedHandler}
        disableRightClick={this.disableRightClickHandler}
        markerClickHandler={
          window.location.pathname == ROOT &&
          (pos.type == TERMINAL)
            ? () => {
                if (this._isDrag) return;
                this.openDashBoardMarkerHandler(
                  [
                    {
                      id: pos.id,
                      hiskyId: pos.hiskyId,
                      isOnline: pos.isOnline,
                      type: pos.type,
                      isDynamic: pos.terminalType === 2,
                      title: pos.title,
                    },
                  ],
                  pos.lat,
                  pos.lat
                );
              }
            : this.markerClickHandler
        }
        terminalInfo={pos}
        online={pos.isOnline}
        isFullScreenMode={this.state.isFullScreenMode}
        getTransmittingTerminals={this.props.getTransmittingTerminals}
        getGlowingHubBeam={this.getGlowingHubBeamHandler}
        trailCoordinates={this.props.polyLine}
        shouldBounce={
          this.state.searchedCustomHiskyId != null &&
          this.state.searchedCustomHiskyId == pos.hiskyId &&
          (pos.type == TERMINAL )
        }
      />
    );
  };
  getCustomMarkersHandler = () => {
    if (!this.state.mapApiLoaded) return;
    let markersList = this.props.markers;
    let markers;
    if (window.location.pathname == ROOT) {
      markers = this.getClusters().map((cluster) => {
        let item = cluster.geometry.coordinates;

        if (!cluster.properties.cluster) {
          return this.getMarkerHandler(cluster.properties.data);
        } else {
          //get list of terminals asscoated with cluster
          let onlineCount = 0;
          let markersInCluster = this.clRef
            .getLeaves(cluster.id, "Infinity", 0)
            .map((item) => {
              if (item.properties.data.isOnline) onlineCount++;

              return {
                id: item.properties.data.id,
                hiskyId: item.properties.data.hiskyId,
                // isMockup: item.properties.data.type == MOCKUP,
                isOnline: item.properties.data.isOnline,
                title: item.properties.data.title,
              };
            });
          return (
            <ClusterMarker
              key={cluster.id}
              id={cluster.id}
              lat={item[1]}
              lng={item[0]}
              count={cluster.properties.point_count}
              //click={this.onclickClusterMarkerHandler}
              click={this.checkClickCountHandler}
              type={CLUSTER}
              markersInCluster={markersInCluster}
              rightClickActive={this.props.rightClickActive}
              onClickRight={this.onClickRightHandler}
              onClickRightItem={this.decodeRightClickHandler}
              activeShortcutMenu={this.state.activeShortcutMenu}
              shortcutClicked={this.shortcutClickedHandler}
              onlineCount={onlineCount}
            />
          );
        }
      });
    } else {
      markers = markersList.map((pos, index) => {
        return this.getMarkerHandler(pos);
      });
    }
    let hubMarkers = [];
    if (
      this.props.hubFilters != undefined &&
      this.props.types.hubs & (this._hubFilterOn || this._showHubMarkers)
    ) {
      hubMarkers = this.props.hubFilters.map((pos, index) => {
        if (this.props.excludedHubsList.indexOf(parseInt(pos.id)) == -1) {
          pos.lng = pos.lastTelemetry != null ? pos.lastTelemetry.lng : pos.lng;
          pos.lat = pos.lastTelemetry != null ? pos.lastTelemetry.lat : pos.lat;
          pos.type = HUB;
          pos.id = parseInt(pos.id);
          return this.getMarkerHandler(pos);
        }
      });
    }

    //fitbounds
    //if polygon is on set bounds tot it else set it to markers
    if (this._allowRecenter && this._isNonDrawingMode) {
      this.recenterMapHandler();
    }

    //Polyline : terminal GPS trace
    if (this.props.polyLine !== undefined) {
      this.drawPolylineHandler(this.props.polyLine);
    }
    return markers.concat(hubMarkers);
  };
  getUpdatesMarkersHandler = () => {
    if (!this.state.mapApiLoaded) return;

    if (this.props.polygon != undefined) {
      //show the polygons
      let showpolygons = this.props.polygon.length ? true : false;
      this.showPolygonHandler(showpolygons);
      //showing the labels on polygons
      let key = this.props.isPreviewPolygon ? "id" : "uuid";
      // if (this.state.showlabelsOnPolygon) { // code to show labels on beam on updates page DONOT REMOVE
      //   return this.props.coordinatesForLabels.map((pos, index) => {
      //     if (
      //       this.props.deletedBeams != undefined &&
      //       this.props.deletedBeams[pos.uniqueId] != undefined
      //     )
      //       return;
      //     return (
      //       <UpdateMarker
      //         key={index}
      //         lat={pos.lat}
      //         lng={pos.lng}
      //         id={pos.id}
      //         title={pos.title}
      //         isPrimary={pos.isPrimary}
      //         selected={pos.id == this.props.beamSelected.id}
      //       />
      //     );
      //   });
      // }
    }
  };
  markerClickHandler = (item, key) => {
    if (this._isDrag && window.location.pathname == ROOT) return;
    //this.polygonManager.length > 0  || document.getElementById("polygonInfo")!=null
    if (!this._isNonDrawingMode) return;
    if (this.polygonManager.length > 0) {
      this.closePolygonInfoWindowHandler();
    }
    let list = {};
    list[key] = { ...item };
    this.setState({ openedinfoWindowslist: list });
  };
  /**
   * Recenter actions
   */
  recenterMaptoDefaultHandler = () => {
    let markersList = this.getMarkersHandler();
    if (this.state.mapInstance == null) return;
    if (!markersList.length) {
      markersList = DEFAULT_MAP_LOCATION;
    }
    const bounds = getMapBounds(
      this.state.mapInstance,
      this.state.mapApi,
      markersList
    );
    this.state.mapInstance.fitBounds(bounds);
    if (this.state.mapInstance.getZoom() > CLUSTER_LEVEL_ZOOM) {
      this.state.mapInstance.setZoom(CLUSTER_LEVEL_ZOOM);
    } else {
      this.state.mapInstance.setZoom(this.state.mapInstance.getZoom());
    }

    this._allowRecenter = true;
    this._isScrolling = false;
    this._coordinateSearchOn = false;
    this._showHubMarkers = false;
    this.setState(
      { openedinfoWindowslist: {}, activeShortcutMenu: {}, beamRightMenu: {} },
      () => {
        if (this.state.showPolygon) this.showPolygonDashboardHandler();
        else this.globalResetPolygonsDataHandler();
        /* delete the drawing manager   if opened on re-center*/
        this.deleteDrawingHandler(this._selectedShape);
        //this.deletePolylineHandler();
        this.cancelPoliyLineHandler();
        //resetiing beam glowing
        this.clearGlowBeamDataHandler();
        //removing search markers if any
        this.removeSearchMarkerHandler();
      }
    );
  };
  recenterMapHandler = () => {
    if (!this._allowRecenter) return;
    if (
      this.state.mapInstance == null ||
      (this.state.showPolygon && this.props.markers.length == 0)
    )
      return;
    let markers;
    if (Object.keys(this.state.openedinfoWindowslist).length) {
      markers = Object.values(this.state.openedinfoWindowslist);
    } else markers = this.getMarkersHandler();
    if (!markers.length) {
      markers = DEFAULT_MAP_LOCATION;
    }
    const bounds = getMapBounds(
      this.state.mapInstance,
      this.state.mapApi,
      markers
    );
    if (markers.length > 1) this._singleMarker = {};
    if (
      window.location.pathname != UPDATES &&
      !(
        Object.keys(this._singleMarker).length &&
        parseFloat(this._singleMarker.lat) == parseFloat(markers[0].lat) &&
        parseFloat(this._singleMarker.lng) == parseFloat(markers[0].lng)
      ) &&
      !this.state.showPolygon
    ) {
      this.state.mapInstance.fitBounds(bounds);
      if (this._init_Zoom_level == -1) {
        this._init_Zoom_level = this.state.mapInstance.getZoom();
      }
    }
    if (markers.length == 1) {
      this._singleMarker = { lat: markers[0].lat, lng: markers[0].lng };
      if (this._allowRecenter) {
        this.state.mapInstance.fitBounds(bounds);
      }
    } else {
      this._singleMarker = {};
    }
    if (this.state.mapInstance.getZoom() >= 22) this.boundsChnagedHandler();
    this._allowRecenter = true;
  };
  boundsChnagedHandler = (zoomChangeBoundsListener) => {
    if (this.state.mapInstance.getZoom() > 11 && this._allowRecenter) {
      // Change max/min zoom here
      if (
        window.location.pathname == UPDATES ||
        Object.keys(this.state.openedinfoWindowslist).length
      )
        this.state.mapInstance.setZoom(3);
      else if (
        Object.keys(this._singleMarker).length &&
        this.getMarkersHandler().length
      ) {
        if (window.location.pathname == ROOT) {
          this.state.mapInstance.setZoom(11);
        } else this.state.mapInstance.setZoom(5);
      } else this.state.mapInstance.setZoom(11);
    }
    this.state.mapApi.event.removeListener(zoomChangeBoundsListener);
  };
  /**
   * Site mode of Dashboard map functions
   */
  cancelPolylineOnSiteModeHandler = () => {
    if (this._poliLineId > 0) {
      this._poliLineId = 0;
    }
    if (this.polylineManager != undefined) {
      this.polylineManager.setMap(null);
      this.polylineManager = null;
    }
    this.props.cancelPoliyLineOnSites != undefined &&
      this.props.cancelPoliyLineOnSites();
  };
  getMarkersSiteHandler = () => {
    return this.props.siteRender.map((item, index) => {
      return this.getMarkerHandler(item);
    });
  };
  /**
   * Global:
   */
  checkStatusScreenHandler = (type, callBack) => {
    switch (type) {
      case "control":
        callBack(
          this.props.viewTerminalOnMapRx ||
            this.props.viewHubOnMapRx ||
            this.props.viewAssignDeviceToPlanOnMapRx ||
            this.props.viewSiteInfoOnMapRx
        );
        break;
      default:
        break;
    }
  };
  getMarkerId = (pos) => {
    let markerId;
    if (pos.terminal != undefined) {
      markerId = generateMarkerId(pos.terminal.hiskyId);
    } else if (pos.hiskyId != undefined) {
      markerId = generateMarkerId(pos.hiskyId);
    } else if (pos.macId != undefined) {
      markerId = HUB + generateMarkerId(pos.macId);
    } else if (pos.type == SITE) {
      markerId = SITE + pos.id;
    }
    return markerId;
  };
  mapDragHandler = () => {
    this._isDrag = true;
    this._allowRecenter = false;
    this._allowPolyLineCenter = false;
    if (this.state.isFullScreenMode && window.location.pathname == ROOT) {
      this.props.resetModelSelector();
    }
  };
  mapDragEndHandler = () => {
    this._isDrag = false;
  };
  modelCloseHandler = () => {
    if (this.props.viewTerminalOnMapRx) {
      this.props.resetModelSelector();
      this.props.openViewTerminalSelector();
    } else if (this.props.viewAssignDeviceToPlanOnMapRx) {
      this.props.openViewAssignPlanToDeviceSelecter();
    } else if (this.props.viewSiteInfoOnMapRx) {
      this.props.openViewSiteInfoSelecter();
    } else if (this.props.viewHubOnMapRx) {
      this.props.openHubInfo();
    }
  };

  isFullscreen(element) {
    return (
      (document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement) == element
    );
  }

  requestFullscreen(element) {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullScreen) {
      element.webkitRequestFullScreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.msRequestFullScreen) {
      element.msRequestFullScreen();
    }
  }
  exitFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msCancelFullScreen) {
      document.msCancelFullScreen();
    }
  }
  mapClickHandler = (mapsMouseEvent) => {
    if (this._shortcutClicked) return;
    if (this.props.getLatLng != undefined)
      this.props.getLatLng(mapsMouseEvent.latLng.toJSON());
    else if (
      this.props.addLocationToPolyLine != undefined &&
      (this.props.polyLineId != undefined || this.props.selectPolygon)
    ) {
      //close infowindows if any
      this.polygonInfowindowClosed();
      this.polylineDotClickHandler(mapsMouseEvent.latLng.toJSON());
    }
  };
  resetActiveShortCutMenu = () => {
    this.setState({ activeShortcutMenu: {}, beamRightMenu: {} }, () => {});
  };
  refShowTrailTextBoxHandler = (ref) => {
    this._refShowTrailTextBox = ref;
  };

  render() {
    return (
      <>
        <div
          className={styles.mapWrapper}
          style={{
            position: "relative !important",
            height: "100%",

            maxHeight: this.props.customStyle
              ? this.props.customStyle.maxHeight
              : "",
          }}
          onKeyDown={this.escHandler}
          id="map_canvas"
        >
          {this.state.showMap && (
            <GoogleMapReact
              bootstrapURLKeys={{
                key: this.KEY_GOOGLE,
                libraries: ["drawing", "places"].join(","),
                language: "en",
              }}
              yesIWantToUseGoogleMapApiInternals
              defaultZoom={11}
              defaultCenter={
                this.props.defaultCenter.length
                  ? this.props.defaultCenter
                  : [32.0853, 34.7818]
              }
              onGoogleApiLoaded={({ map, maps }) => {
                this.apiIsLoaded(map, maps, this.props.markers);
              }}
              options={{
                fullscreenControl: false,
                zoomControl: false,
                disableDoubleClickZoom: true,
              }}
              onDrag={this.mapDragHandler}
              onDragEnd={this.mapDragEndHandler}
              onClick={(e) =>
                window.location.pathname == ROOT &&
                this.resetActiveShortCutMenu()
              }
              onChange={({ center, zoom, bounds }) => {
                if (window.location.pathname == ROOT) {
                  this.setState({
                    mapBounds: [
                      bounds.nw.lng,
                      bounds.se.lat,
                      bounds.se.lng,
                      bounds.nw.lat,
                    ],
                  });
                }
              }}
            >
              <>
                {(this.props.viewTerminalOnMapRx ||
                  this.props.viewHubOnMapRx ||
                  this.props.viewAssignDeviceToPlanOnMapRx ||
                  this.props.viewSiteInfoOnMapRx) &&
                  this.state.isFullScreenMode && (
                    <div className={styles.backdrop}></div>
                  )}
                {this.props.viewTerminalOnMapRx &&
                this.state.isFullScreenMode ? (
                  <ViewTerminal styles={{ top: "0", left: "0", zIndex: 100 }} />
                ) : null}
                {this.props.viewHubOnMapRx && this.state.isFullScreenMode ? (
                  <HubMoreInfo styles={{ top: "0", left: "0", zIndex: 100 }} />
                ) : null}
                {this.props.viewAssignDeviceToPlanOnMapRx &&
                this.state.isFullScreenMode ? (
                  <ViewAssignDeviceToPlan
                    styles={{ top: "0", left: "0", zIndex: 100 }}
                  />
                ) : null}
                {this.props.viewSiteInfoOnMapRx &&
                this.state.isFullScreenMode ? (
                  <ViewSiteInfo styles={{ top: "0", left: "0", zIndex: 100 }} />
                ) : null}
              </>
              {/* polyline dots or dotts and polygons */}
              {(window.location.pathname == HUB_PP_SIMULATOR ||
                window.location.pathname == UPDATES) &&
              this.props.polyLineId != undefined
                ? window.location.pathname == HUB_PP_SIMULATOR
                  ? this.getPolyLineDots()
                  : this.getPolyLineAndPolygonDots()
                : this.getCustomMarkersHandler()}

              {/* Updates polygon */}
              {window.location.pathname == UPDATES &&
                this.getUpdatesMarkersHandler()}

              {/* sites marker */}
              {window.location.pathname == ROOT &&
                this.props.isSiteMode &&
                this.getMarkersSiteHandler()}
              {(Object.keys(this.state.beamRightMenu).length > 0 ||
                this.state.beamsLoading) &&
                this.state.beamRightMenu.id != undefined &&
                this.state.beamRightMenu.id ==
                  this.state.activeShortcutMenu.name && (
                  <ShortcutMenu
                    data={{
                      type: BEAM,
                      beamsList: this.state.beamRightMenu.beamList,
                    }}
                    onClickRightItem={this.decodeRightClickHandler}
                    shortcutClicked={this.shortcutClickedHandler}
                    //trailCoordinates={this.state.beamRightMenu.data}
                    //beamRightMenuArr={this.state.beamRightMenuArr}
                    lat={this.state.beamRightMenu.latLng.lat}
                    lng={this.state.beamRightMenu.latLng.lng}
                    refShowTrailTextBox={this.refShowTrailTextBoxHandler}
                    beamsLoading={this.state.beamsLoading}
                  />
                )}
            </GoogleMapReact>
          )}
        </div>
      </>
    );
  }
}

//export default Google;
const mapStateProps = (state) => {
  return {
    viewTerminalOnMapRx: state.model.viewTerminalOnMapRx,
    viewHubOnMapRx: state.model.viewHubOnMapRx,
    viewAssignDeviceToPlanOnMapRx: state.model.viewAssignDeviceToPlanOnMapRx,
    viewSiteInfoOnMapRx: state.model.viewSiteInfoOnMapRx,
    openModelOnMapRx: state.model.modelOnMapRx,
  };
};
const mapStateDispatch = (dispatch) => {
  return {
    reRenderComponentSelector: (data) =>
      dispatch(actionType.reRenderComponentSelector(data)),
    resetModelSelector: () => dispatch(actionType.resetModelSelector()),
    openViewTerminalSelector: () =>
      dispatch(actionType.openViewTerminalSelector()),
    openViewAssignPlanToDeviceSelecter: () =>
      dispatch(actionType.openViewAssignPlanToDeviceSelecter()),
    openViewSiteInfoSelecter: () =>
      dispatch(actionType.openViewSiteInfoSelecter()),
    openHubInfo: () => dispatch(actionType.openHubInfo()),
  };
};
export default withRouter(connect(mapStateProps, mapStateDispatch)(Google));
