export const getLocalStorageInfo = () => {
  let data = localStorage.getItem("info");
  if (data === null) {
    return null;
  } else {
    let token = JSON.parse(data).token;
    return token;
  }
};
export const storeInLocalStorage = (data) => {
  localStorage.setItem("info", data);
};
export const storeVersionNo = (data) => {
  localStorage.setItem("version_no", data);
};
export const getVersionNo = () => {
  let ver_no = localStorage.getItem("version_no");
  if (ver_no == undefined || ver_no==null) return "";
  else return ver_no;
};
export const storeRolesLocalStorage = (data) => {
  localStorage.setItem("roles", data);
};
export const getUserRoles = () => {
  var data = localStorage.getItem("roles");
  return JSON.parse(data);
};
export const getUserId = () => {
  let data = localStorage.getItem("info");
  if (data == null) {
    return "";
  } else {
    return JSON.parse(data).userId;
  }
};
export const removeInfoLocalStorage = () => {
  localStorage.removeItem("info");
  localStorage.removeItem("roles");
};
export const getAccountId = () => {
  let data = localStorage.getItem("info");
  if (data === null) {
    return null;
  } else {
    let id = JSON.parse(data).account_id;
    return id;
  }
};
export const getFreshDeskApiKey = () => {
  let data = localStorage.getItem("info");
  if (data === null) {
    return null;
  } else {
    return JSON.parse(data).freshdeskApikey;
  }
};
export const getParentIds = () => {
  let data = localStorage.getItem("info");
  if (data === null) {
    return "";
  } else {
    let parentIds = JSON.parse(data).parentIds;
    return parentIds;
  }
};
///////this method will only be called in the user profile when the admin is updating his profile ///////////
export const getInfoForUpdateProfile = () => {
  let data = localStorage.getItem("info");
  if (data == null) {
    return null;
  } else {
    return {
      firstName: JSON.parse(data).firstName,
      lastName: JSON.parse(data).lastName,
      userId: JSON.parse(data).userId,
      avatar: JSON.parse(data).avatar,
      account_id: JSON.parse(data).account_id,
      parentIds: JSON.parse(data).parentIds,
      token: JSON.parse(data).token,
      role: JSON.parse(data).role,
      entity_name: JSON.parse(data).entity_name,
      phone: JSON.parse(data).phone,
      email: JSON.parse(data).email,
      freshdeskApikey: JSON.parse(data).freshdeskApikey,
    };
  }
};
export const getBasicInfo = () => {
  let data = localStorage.getItem("info");
  if (data == null) {
    return null;
  } else {
    return {
      firstName: JSON.parse(data).firstName,
      lastName: JSON.parse(data).lastName,
      userId: JSON.parse(data).userId,
      avatar: JSON.parse(data).avatar,
      email: JSON.parse(data).email,
      role: JSON.parse(data).role,
      account_id: JSON.parse(data).account_id,
      parentIds: JSON.parse(data).parentIds,
    };
  }
};
export const getEntityName = () => {
  let data = localStorage.getItem("info");
  if (data === null) {
    return "";
  } else {
    return JSON.parse(data).entity_name;
  }
};
export const getCurrentUserRole = () => {
  let data = localStorage.getItem("info");
  if (data === null) {
    return "";
  } else {
    var role = JSON.parse(data).role.split("-");
    if (role.length > 1) {
      return role[1];
    } else {
      return role[0];
    }
  }
};
export const setAccountTreeStatus = (tree, type = "tree") => {
  try {
    localStorage.setItem(type, tree);
  } catch {}
};
export const getTreeAccountStatus = () => {
  try {
    let tree = localStorage.getItem("tree");
    if (tree) {
      return { page: JSON.parse(tree).page, visible: JSON.parse(tree).visible };
    } else {
      return { page: "accounts", visible: false };
    }
  } catch {
    return undefined;
  }
};
/**
 * get
 * @param {'tree', 'technicianCustomersTree'} type
 */
export const getvisibleColorStatusTree = (type = "tree") => {
  try {
    let tree = localStorage.getItem(type);
    if (tree) {
      return JSON.parse(tree).visible;
    } else {
      return false;
    }
  } catch {
    return false;
  }
};

export const getCompanyTreeStatus = () => {
  try {
    let tree = localStorage.getItem("technicianCustomersTree");
    if (tree) {
      return { page: JSON.parse(tree).page, visible: JSON.parse(tree).visible };
    } else {
      return { page: "technicianCustomers", visible: false };
    }
  } catch {
    return undefined;
  }
};
