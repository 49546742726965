import React, { Component,Fragment } from "react";
import styles from "./Button.module.scss"
export default class Button extends Component {
  render() {
    const { text,disabled,id,overRideStyle } = this.props;
    return (
      <Fragment>
        <button disabled={disabled} className={styles.btn} style={overRideStyle} id={id !==undefined ?id:text}
         onClick={this.props.click}>
          {text}
        </button>
      </Fragment>
    );
  }
}
