import React, { Component, Fragment } from "react";
import styles from "./ShortcutMenu.module.scss";
import {
  ASSIGNED_HUB_DETAILS,
  BEAM,
  CANCEL_TRAIL,
  CLOSE_LOCATE_BEAM,
  CLUSTER,
  DELETE_POLY_DOT,
  DOT_MARKER,
  GO_TO_PAGE,
  HISKY_ENTITY,
  HUB,
  LOCATE_BEAM,
  LOCATE_TERMINALS,
  MOCKUP,
  RESELLER_ENTITY,
  SHOW_TRAIL,
  TERMINAL,
} from "../../../Util/constTypes";
import Satellite from "../../../assets/Satellite.svg";
import Icon from "../../Icon/Icon";
import { getEntityName } from "../../../Store/localStoreageManager/localStoreageManager";
import Controller from "../../../Controller";
import CopyToClipboard from "react-copy-to-clipboard";
import SingleButton from "../../SingleButton/SingleButton";
import { converStringToJson } from "../../../Util/globalFunc";
import { TERMINAL_ONLINE_COLOR } from "../../../Util/constants";
export default class ShortcutMenu extends Component {
  _isMounted = true;
  state = {
    activeSubMenu: "",
    showTrailCoor: false,
    coorCopied: false,
    beamData: {},
    selectedBeam: "",
  };
  componentDidMount() {
    if (this.props.refShowTrailTextBox != undefined) {
      this.props.refShowTrailTextBox(this.closeShowTrailHandler);
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  setSubmenuHandler = (e, type) => {
    this.props.shortcutClicked();
    e.stopPropagation();
    this.setState({ activeSubMenu: type }, () => {});
  };
  getColorOfTerminalHandler = (item) => {
    if (item.isMockup) {
      return "#bdbdbd";
    }
    if (item.isOnline) {
      return TERMINAL_ONLINE_COLOR;
    } else {
      return "red";
    }
  };
  closeShowTrailHandler = () => {
    if (this._isMounted) this.setState({ showTrailCoor: false, beamData: {} });
  };
  showTrailCoorHandler = (e) => {
    this.props.shortcutClicked();
    e.stopPropagation();

    this.setState({ showTrailCoor: !this.state.showTrailCoor });
  };
  showBeamCoorHandler = (e, data) => {
    this.props.shortcutClicked();
    e.stopPropagation();

    this.setState({
      beamData: {
        coordinates: converStringToJson(data.coordinates),
        title: data.provider,
      },
      selectedBeam: `${data.provider}-${data.externalId}`,
    });
  };
  getCoordinates = (coordinates) => {
    if (coordinates == undefined) return "";
    let formatedData = [...coordinates];
    if (formatedData.length)
      return formatedData
        .map(function (elem) {
          return elem.lat + "," + elem.lng;
        })
        .join("\n");

    return "";
  };
  onCopyHandler = () => {
    //e.stopPropagation();
    this.setState({ coorCopied: true }, () => {
      setTimeout(() => {
        this.setState({ coorCopied: false });
      }, 3000);
    });
  };
  stopPropagationHandler = (e) => {
    this.props.shortcutClicked();
    e.stopPropagation();
  };
  getSubmenuHandler = (actionType) => {
    return this.props.data.markersInCluster.map((item, index) => {
      return (
        <div
          className={
            item.isMockup && actionType == SHOW_TRAIL
              ? `${styles.disabled} ${styles.item}`
              : styles.item
          }
          onClick={(e) =>
            item.isMockup && actionType == SHOW_TRAIL
              ? {}
              : this.props.onClickRightItem(e, actionType, item)
          }
          key={index}
        >
          <div className={styles.subItemWrapper}>
            <span
              className={styles.statusIcon}
              style={{
                color: this.getColorOfTerminalHandler(item),
              }}
            >
              <Icon defination={{ prefix: "fas", iconName: "circle" }} />
            </span>

            <div className={styles.textWrapper}>
              <span>{item.title}</span>
              <span>{item.hiskyId}</span>
            </div>
          </div>
        </div>
      );
    });
  };
  getShorcutMenuHandler = (data) => {
    if (data.type === HUB) {
      let glowingHubBeam = this.props.getGlowingHubBeam();
      let isGlowing =
        glowingHubBeam != null &&
        Object.keys(glowingHubBeam).length &&
        glowingHubBeam.id == data.id;
      return (
        <Fragment>
          <div
            className={styles.item}
            onClick={(e) =>
              this.props.onClickRightItem(
                e,
                isGlowing ? CLOSE_LOCATE_BEAM : LOCATE_BEAM,
                this.props.data
              )
            }
          >
            <img src={Satellite} className={styles.imgSat} alt="Satellite" />
            {isGlowing ? "Close locate beam" : "Locate beam"}{" "}
          </div>
          <div
            className={styles.item}
            onClick={(e) =>
              this.props.onClickRightItem(e, LOCATE_TERMINALS, this.props.data)
            }
          >
            <span className={styles.terminalIcon}>
              <Icon defination={{ prefix: "far", iconName: "tablet-rugged" }} />
            </span>
            Assigned Terminals
          </div>
        </Fragment>
      );
    } else if (data.type == CLUSTER) {
      return (
        <Fragment>
          <div
            className={
              "trail" === this.state.activeSubMenu
                ? `${styles.item} ${styles.selectedItem}`
                : styles.item
            }
          >
            <div className={styles.titleWrapper}>
              <div
                onClick={(e) => this.setSubmenuHandler(e, "trail")}
                className={styles.itemWrapper}
              >
                <span
                  className={
                    "trail" === this.state.activeSubMenu
                      ? `${styles.terminalIcon} ${styles.selectedItem}`
                      : styles.terminalIcon
                  }
                >
                  <Icon defination={{ prefix: "far", iconName: "route" }} />
                </span>
                <span className={styles.titleIconWrapper}>
                  Show trail
                  <span
                    className={
                      "trail" === this.state.activeSubMenu
                        ? `${styles.terminalIcon} ${styles.selectedItem}`
                        : styles.terminalIcon
                    }
                  >
                    <Icon
                      defination={{ prefix: "far", iconName: "chevron-right" }}
                    />
                  </span>
                </span>
              </div>
              <div
                className={styles.subMenu}
                style={{
                  visibility:
                    this.state.activeSubMenu == "trail" ? "visible" : "hidden",
                }}
              >
                {this.getSubmenuHandler(SHOW_TRAIL)}
              </div>
            </div>
          </div>
          {((getEntityName() === RESELLER_ENTITY &&
            Controller.getAuthComponent("nms", "TERMINAL_DETAIL")) ||
            getEntityName() === HISKY_ENTITY) && (
            <div
              className={
                "page" === this.state.activeSubMenu
                  ? `${styles.item} ${styles.selectedItem}`
                  : styles.item
              }
            >
              <div className={styles.titleWrapper}>
                <div
                  onClick={(e) => this.setSubmenuHandler(e, "page")}
                  className={styles.itemWrapper}
                >
                  <span
                    className={
                      "page" === this.state.activeSubMenu
                        ? `${styles.terminalIcon} ${styles.selectedItem}`
                        : styles.terminalIcon
                    }
                  >
                    <Icon
                      defination={{ prefix: "far", iconName: "tablet-rugged" }}
                    />
                  </span>
                  <span className={styles.titleIconWrapper}>
                    Go to page
                    <span
                      className={
                        "page" === this.state.activeSubMenu
                          ? `${styles.terminalIcon} ${styles.selectedItem}`
                          : styles.terminalIcon
                      }
                    >
                      <Icon
                        defination={{
                          prefix: "far",
                          iconName: "chevron-right",
                        }}
                      />
                    </span>
                  </span>
                </div>
                <div
                  className={styles.subMenu}
                  style={{
                    visibility:
                      this.state.activeSubMenu == "page" ? "visible" : "hidden",
                  }}
                >
                  {this.getSubmenuHandler(GO_TO_PAGE)}
                </div>
              </div>
            </div>
          )}
          {getEntityName() == HISKY_ENTITY && (
            <div
              className={styles.item}
              onClick={(e) =>
                this.props.onClickRightItem(
                  e,
                  ASSIGNED_HUB_DETAILS,
                  this.props.data
                )
              }
            >
              <span className={styles.terminalIcon}>
                <Icon
                  defination={{ prefix: "fal", iconName: "tablet-rugged" }}
                />
              </span>
              Assigned hub
            </div>
          )}
        </Fragment>
      );
    } else if (data.type == TERMINAL || data.type == MOCKUP) {
      let transmittingTer =
        this.props.getTransmittingTerminals != undefined
          ? this.props.getTransmittingTerminals()
          : {};
      return (
        <Fragment>
          {data.type == TERMINAL && (
            <>
              <div className={styles.item}>
                <div className={styles.titleWrapper}>
                  <div
                    onClick={(e) =>
                      this.props.onClickRightItem(
                        e,
                        transmittingTer[this.props.data.id] != undefined
                          ? CANCEL_TRAIL
                          : SHOW_TRAIL,
                        this.props.data
                      )
                    }
                  >
                    <span className={styles.terminalIcon}>
                      <Icon defination={{ prefix: "far", iconName: "route" }} />
                    </span>
                    {transmittingTer[this.props.data.id] != undefined
                      ? "Cancel trail"
                      : "Show trail"}
                  </div>
                </div>
              </div>
              {transmittingTer[this.props.data.id] != undefined && (
                <div
                  className={
                    this.state.showTrailCoor
                      ? `${styles.item} ${styles.selectedItem}`
                      : styles.item
                  }
                >
                  <div className={styles.titleWrapper}>
                    <div
                      onClick={(e) => this.showTrailCoorHandler(e)}
                      className={styles.titleIconWrapper}
                    >
                      <div>
                        <span
                          className={
                            this.state.showTrailCoor
                              ? `${styles.terminalIcon} ${styles.selectedItem}`
                              : styles.terminalIcon
                          }
                        >
                          <Icon
                            defination={{ prefix: "far", iconName: "list" }}
                          />
                        </span>
                        Trail coordinates
                      </div>
                      <span
                        className={
                          this.state.showTrailCoor
                            ? `${styles.rightIcon} ${styles.selectedItem}`
                            : styles.rightIcon
                        }
                      >
                        <Icon
                          defination={{
                            prefix: "far",
                            iconName: "chevron-right",
                          }}
                        />
                      </span>
                      <div
                        className={styles.subMenu}
                        style={{
                          visibility: this.state.showTrailCoor
                            ? "visible"
                            : "hidden",
                          height: "30rem",
                          width: "38rem",
                        }}
                        onClick={this.stopPropagationHandler}
                      >
                        <div className={styles.iconsWrapper}>
                          <div className={styles.copyWrapper}>
                            <CopyToClipboard
                              text={this.getCoordinates(
                                this.props.trailCoordinates
                              )}
                              onCopy={() => this.onCopyHandler()}
                            >
                              <span className={styles.copyIcon}>
                                <SingleButton
                                  text={"Copy data"}
                                  style={{
                                    padding: "0.3rem 0.5rem",
                                  }}
                                />
                              </span>
                            </CopyToClipboard>
                            <p
                              className={styles.copyMsg}
                              style={{
                                visibility: this.state.coorCopied
                                  ? "visible"
                                  : "hidden",
                              }}
                            >
                              Coordinates are copied
                            </p>
                          </div>
                          <span
                            className={styles.iconClose}
                            onClick={(e) => this.showTrailCoorHandler(e)}
                          >
                            <Icon
                              defination={{ prefix: "fal", iconName: "times" }}
                            />
                          </span>
                        </div>
                        <textarea
                          className={styles.textarea}
                          value={this.getCoordinates(
                            this.props.trailCoordinates
                          )}
                          placeholder="Example Latitude,Longitude"
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          {((getEntityName() === RESELLER_ENTITY &&
            Controller.getAuthComponent("nms", "TERMINAL_DETAIL")) ||
            getEntityName() === HISKY_ENTITY) && (
            <div
              className={styles.item}
              onClick={(e) =>
                this.props.onClickRightItem(e, GO_TO_PAGE, this.props.data)
              }
            >
              <div className={styles.titleWrapper}>
                <span className={styles.terminalIcon}>
                  <Icon
                    defination={{ prefix: "far", iconName: "tablet-rugged" }}
                  />
                </span>
                Go to page
              </div>
            </div>
          )}
          {getEntityName() == HISKY_ENTITY && (
            <div
              className={styles.item}
              onClick={(e) =>
                this.props.onClickRightItem(
                  e,
                  ASSIGNED_HUB_DETAILS,
                  this.props.data
                )
              }
            >
              <span className={styles.terminalIcon}>
                <Icon
                  defination={{ prefix: "fal", iconName: "tablet-rugged" }}
                />
              </span>
              Assigned hub
            </div>
          )}
        </Fragment>
      );
    } else if (data.type === BEAM) {
      return (
        <>
          {this.props.data.beamsList.map((item, index) => {
            let isSelected =
              `${item.provider}-${item.externalId}` === this.state.selectedBeam;
            return (
              <div
                className={
                  isSelected
                    ? `${styles.item} ${styles.selectedItem}`
                    : styles.item
                }
                key={index}
              >
                <div className={styles.titleWrapper}>
                  <div
                    onClick={(e) => this.showBeamCoorHandler(e, item)}
                    className={styles.titleIconWrapper}
                  >
                    <div>
                      <span className={styles.terminalIcon}>
                        <img
                          className={styles.iconSat}
                          src={Satellite}
                          alt="Satellite"
                          style={{
                            filter: isSelected ? "brightness(0) invert(1)" : "",
                          }}
                        />
                      </span>
                      {item.provider}
                    </div>
                    <span
                      className={
                        isSelected
                          ? `${styles.rightIcon} ${styles.selectedItem}`
                          : styles.rightIcon
                      }
                    >
                      <Icon
                        defination={{
                          prefix: "far",
                          iconName: "chevron-right",
                        }}
                      />
                    </span>
                    {index == 0 && this.getBeamsSubmneu()}
                  </div>
                </div>
              </div>
            );
          })}
        </>
      );
    } else if (data.type == DOT_MARKER) {
      return (
        <div className={styles.item}>
          <div className={styles.titleWrapper}>
            <div
              onClick={(e) => {
                this.props.onClickRightItem(
                  e,
                  DELETE_POLY_DOT,
                  this.props.data
                );
              }}
            >
              <span className={styles.terminalIcon}>
                <Icon defination={{ prefix: "fal", iconName: "trash" }} />
              </span>
              Delete
            </div>
          </div>
        </div>
      );
    }
  };
  getBeamsSubmneu = () => {
    let beamdata = this.state.beamData;
    return (
      <>
        <div
          className={styles.subMenu}
          style={{
            visibility:
              Object.keys(this.state.beamData).length > 0
                ? "visible"
                : "hidden",
            height: "30rem",
            width: "38rem",
          }}
          onClick={this.stopPropagationHandler}
        >
          {/* <span className={styles.beamTitle}>{beamdata.title} Coordinates</span> */}
          <div className={styles.iconsWrapper}>
            <div className={styles.copyWrapper}>
              <CopyToClipboard
                text={this.getCoordinates(beamdata.coordinates)}
                onCopy={() => this.onCopyHandler()}
              >
                <span className={styles.copyIcon}>
                  <SingleButton
                    text={"Copy data"}
                    style={{
                      padding: "0.3rem 0.5rem",
                    }}
                  />
                </span>
              </CopyToClipboard>
              <p
                className={styles.copyMsg}
                style={{
                  visibility: this.state.coorCopied ? "visible" : "hidden",
                }}
              >
                Coordinates are copied
              </p>
            </div>
            <span
              className={styles.iconClose}
              onClick={(e) => this.closeShowTrailHandler(e)}
            >
              <Icon defination={{ prefix: "fal", iconName: "times" }} />
            </span>
          </div>
          <textarea
            className={styles.textarea}
            value={this.getCoordinates(beamdata.coordinates)}
            placeholder="Example Latitude,Longitude"
            disabled={true}
          />
        </div>
      </>
    );
  };
  render() {
    return (
      <div
        className={styles.ShortcutMenus}
        id="shortcutMenu"
        onClick={this.stopPropagationHandler}
        //style={{ opacity: this.props.beamsLoading ? 0.8 : 1 }}
      >
        {this.getShorcutMenuHandler(this.props.data)}
        <span
          className={`${styles.terminalIcon} ${styles.spinnerWrapper}`}
          style={{ display: this.props.beamsLoading ? "block" : "none" }}
        >
          <Icon
            defination={{ prefix: "far", iconName: "spinner" }}
            isSpinner={true}
          />
        </span>
      </div>
    );
  }
}
