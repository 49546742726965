import React, { Component } from "react";
import styles from "./SideBar.module.scss";
import { withRouter } from "react-router-dom";
import LinkExpand from "./LinkExpand/LinkExpand";
import SingleLink from "./SingleLink/SingleLink";
import { getAccountId } from "../../Store/localStoreageManager/localStoreageManager";
import {
  DASHBOARD,
  LOGS,  
  CDR,
  PLANS,
  SUPPORT,
  SUBSCRIPTIONS,
  BSS_DASHBOARD,
  ACCOUNT,
} from "../../Util/constTypes";
import Controller from "../../Controller";
import * as route from "../../Util/routes";
import {getLogsRouteList} from "../../Util/globalFunc";
class AccountSubscriber extends Component {
  
  render() {
    return (
      <div>
        <div className={styles.linkGroup}>
          <div
            className={this.props.menu ? styles.subTitle : styles.subTitleClose}
            style={{
              textAlign: this.props.menu ? null : "center",
              marginTop: "4.5rem",
            }}
          >
            {this.props.menu ? "bss / OSS" : ""}
          </div>
          <SingleLink
            name={BSS_DASHBOARD}
            menu={this.props.menu}
            iconDefination={{
              prefix: "fas",
              iconName: "chart-line",
            }}
            activeLink={this.props.activeLink}
            click={this.props.onClickLinkHandler}
            link={route.ROOT}
            linkName={DASHBOARD}
          />
          {Controller.getAuthComponent("bss", "PLANS_ASSIGN") && (
            <LinkExpand
              prefix={PLANS}
              menu={this.props.menu}
              clickReOpenMenu={this.props.onClickMenuBarHandler}
              selectLink={this.props.activeLink}
              click={this.props.onClickLinkHandler}
              iconDefination={{
                prefix: "far",
                iconName: "folder",
              }}
              linkArray={[
                {
                  link: route.PLANS_ASSIGN,
                  name: SUBSCRIPTIONS,
                  iconDefination: {
                    prefix: "fal",
                    iconName: "history",
                  },
                },
              ]}
            />
          )}
          {Controller.getAuthComponent("bss", "ACCOUNTS") && (
            <SingleLink
              name={ACCOUNT}
              menu={this.props.menu}
              iconDefination={{
                prefix: "far",
                iconName: "user",
              }}
              activeLink={this.props.activeLink}
              click={this.props.onClickLinkHandler}
              link={route.ACCOUNTS_SUBSCRIBER + "/" + parseInt(getAccountId())}
              linkName={ACCOUNT}
            />
          )}
          {Controller.getAuthComponent("bss", "ACTIVITY") && (
            <SingleLink
              name={CDR}
              menu={this.props.menu}
              iconDefination={{
                prefix: "fal",
                iconName: "satellite",
              }}
              activeLink={this.props.activeLink}
              click={this.props.onClickLinkHandler}
              link={route.ACTIVITY}
              linkName={CDR}
            />
          )}

          {getLogsRouteList().length > 0 && (<LinkExpand
            prefix={LOGS}
            menu={this.props.menu}
            clickReOpenMenu={this.props.onClickMenuBarHandler}
            selectLink={this.props.activeLink}
            click={this.props.onClickLinkHandler}
            iconDefination={{
              prefix: "far",
              iconName: "folder",
            }}
            linkArray={getLogsRouteList()}
          />)}

          {Controller.getAuthComponent("bss", "SUPPORT") && (
            <SingleLink
              name={SUPPORT}
              menu={this.props.menu}
              iconDefination={{
                prefix: "far",
                iconName: "user-headset",
              }}
              activeLink={this.props.activeLink}
              click={this.props.onClickLinkHandler}
              link={route.SUPPORT}
              isExternal={true}
              linkName={SUPPORT}
            />
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(AccountSubscriber);