import React, { Component } from "react";
import Doughnut from "../../../Componnet/Charts/Doughnut/DoughnutChart";
import styles from "./ChartData.module.scss";
import SpinnerLoader from "../../../Componnet/SpinnerLoader/SpinnerLoader";
export default class ChartData extends Component {
  state = {
    loading: true
  };
  componentDidMount() {
    this.setState({ loading: this.props.loading });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.loading !== this.state.loading) {
      this.setState({ loading: nextProps.loading }, () => {
      });
    }
  }  
  render() {
    return (
      <div
        className={styles.ChartDatas}
        style={{ opacity: this.state.loading ? 0.4 : 1 }}
      >
        <div className={styles.header}>
          <p
            className={styles.title}
          >{`${this.props.headerTitle} information`}</p>
          <span className={styles.iconTitle}>{this.props.icon}</span>
        </div>
        <div className={styles.breakLineTitle} />        
        <div className={styles.infoChart}>
          <div className={styles.wrapperDougnut}>
            <Doughnut              
              chartData={this.props.chartData}
              options={this.props.options}
            />
            {this.props.total === 0 && (
              <span className={styles.templateColor}></span>
            )}            
          </div>
          <div className={styles.infoWrapper}>
            <p className={styles.titleDoughnut}>
              {`Total ${this.props.headerTitle}`}
              <span className={styles.userAmount}>{this.props.chartData.text}</span>
            </p>
          </div>
        </div>
        <div
          className={styles.spinnerWrapper}
          style={{ display: this.state.loading ? "block" : "none" }}
        >
          <SpinnerLoader/>
        </div>
      </div>
    );
  }
}
