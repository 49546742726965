import {
  SET_DATE,
  DATE_TYPE,
  SET_PLAN_HISTORY,
  RESET_COMPONENT,
  RESET_INNER_COMPONENT,
  RE_RENDER_COMPONENT,
  SET_ROLES,
  SET_ACTION_TYPE,
  SET_ACTION_MODE,
  RE_RENDER_SIDE_BAR
} from "./types";
import axios from "axios";
import {DOWNLOAD_FAILURE} from "../../Util/constTypes"
import Config from "../../config";
import { getLocalStorageInfo } from "../localStoreageManager/localStoreageManager";

import { getTimeZoneDiff } from "../../Util/globalFunc";
export const setDate = (date) => {
  return {
    type: SET_DATE,
    payload: date,
  };
};
export const setTypeofDate = (data) => {
  return {
    type: DATE_TYPE,
    payload: data,
  };
};
export const savePlanHistorySelecter = (history) => {
  return {
    type: SET_PLAN_HISTORY,
    payload: history,
  };
};
export const resetComponentSelector = (data) => {
  return {
    type: RESET_COMPONENT,
    payload: data,
  };
};
export const reRenderSideBarSelector=(data)=>{
  return {
    type: RE_RENDER_SIDE_BAR,
    payload: data,
  };
}
export const resetInnerComponentSelector = (data) => {
  return {
    type: RESET_INNER_COMPONENT,
    payload: data,
  };
};
export const reRenderComponentSelector = (data) => {
  return {
    type: RE_RENDER_COMPONENT,
    payload: data,
  };
};
export const setRolesSelector = (data) => {
  return {
    type: SET_ROLES,
    payload: data,
  };
};
export const setActionType = (data) => {
  return {
    type: SET_ACTION_TYPE,
    payload: "closeLoader",
  };
};

/**
 * get the pdf or excel
 */
const queryForExport = (exportParams) => {
  return {
    query:
      "mutation ($model: String!, $export_method: ExportOptions!, $search: String, $location: String, $offset: Int, $limit: Int, $start_date: String,\n  $end_date: String\n, $id: Int, $usedStatus: Int, $rangeMin:String, $rangeMax:String, $additionalIds: [Int],$timezone:String, $exact:Boolean, $hiskyIds: [String],$intCategoryChoice:Int) {  createReportExport(model: $model, exportMethod: $export_method, location: $location, offset: $offset, limit: $limit, search: $search, startDate: $start_date, endDate: $end_date, id: $id, usedStatus: $usedStatus,  rangeMin:$rangeMin, rangeMax:$rangeMax, additionalIds:$additionalIds,timezone:$timezone, exact:$exact, hiskyIds:$hiskyIds,intCategoryChoice:$intCategoryChoice) {\n    message\n    location\n  }\n}",
    variables: exportParams,
  };
};

export const exportFileSelecter = (exportParams, callback) => {
  exportParams.timezone = getTimeZoneDiff();
  let data = queryForExport(exportParams);
  let TOKEN = getLocalStorageInfo();
  return (dispatch) => {
    return axios
      .request({
        method: "post",
        data: data,
        url: Config.api.API_URL,
        headers: {
          Authorization: TOKEN ? `JWT ${TOKEN}` : null,
        },
      })
      .then((res) => {
        let actionType = "DownloadSuccess";
        var msg = {};
        if (res.data.errors != undefined && res.data.errors.length) {
          actionType = DOWNLOAD_FAILURE;
          msg.message =
            res.data.errors[0].message !== undefined
              ? res.data.errors[0].message.replace("_", " ")
              : DOWNLOAD_FAILURE;
        } else {
          let file = res.data.data.createReportExport.location;
          if (
            exportParams.export_method == "EXCEL" ||
            exportParams.export_method == "CSV"
          ) {
            window.open(Config.api.EXPORTS_URL + file.split("static")[1]);
          } else {
            var prntWin = window.open();
            prntWin.document.write(
              "<html><head><title>" +
                exportParams.model +
                "</title></head><body>" +
                '<embed width="100%" height="100%" name="plugin" src="' +
                Config.api.EXPORTS_URL +
                file.split("static")[1] +
                '" ' +
                'type="application/pdf" internalinstanceid="21"></body></html>'
            );
            prntWin.document.close();
          }
          //
        }
        dispatch(callback(actionType,msg));
      })
      .catch((err) => {});
  };
};
