////////////////////////Global////////////////////
export const ROOT = "/";
export const RESETPASSWORD = "/reset-password";
export const SITES = "/sites";
export const UPDATES = "/updates";
export const ACTIVITY = "/activity";
export const PERMISSIONS = "/permissions";
export const LOGIN = "/login";
export const SETPASSWORD = "/set-password";
export const SUPPORT = "#";
export const REPORTS = "/reports";
export const BILLING = "/billing";
export const USERS = "/users";
export const PRODUCTION = "/production";
export const STATISTICS = "/statistics";
//////////////////////Logs ////////////////////////
export const LOGS_USER = "/logs/user-logs";
export const LOGS_SYSTEM = "/logs/system-logs";
///////////////////Accounts//////////////////////////
export const ACCOUNTS_TABLE = "/accounts/table-view";
export const ACCOUNTS_TREE = "/accounts/tree-view";
export const ACCOUNTS_USAGEDATA = "/accounts/usage-data";
export const ACCOUNTS_TECHNICIAN = "/accounts/technician";
export const ACCOUNTS_SUBSCRIBER = "/accounts/subscriber";
/////////////////////DEVICES//////////////////////////
export const DEVICES_TERMINALS = "/devices/terminals";
export const DEVICES_HUBS = "/devices/hubs";
export const DEVICES_TERMINAL = "/devices/terminal";
export const DEVICES_HUB = "/devices/hub";
export const HUB_PP_SIMULATOR="/settings/hub-simulator"
////////////////////////Plans//////////////////////////
export const PLANS = "/plans";
export const PLANS_SERVICES = "/plans/services";
export const PLANS_TEMPLATES = "/plans/templates";
export const PLANS_HISTORY = "/plans/history";
export const PLANS_ASSIGN = "/plans/assign";
