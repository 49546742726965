import React, { Component } from "react";
import styles from './AddLatLng.module.scss';
export default class AddLatLng extends Component {
  intialValues = {
    lat: 0,
    lng: 0,
  };
  state = {
    customPosition: this.intialValues,
    invalidPostions: false,
  };
  onchangeHandler = (event) => {
    let customPositionCopy = { ...this.state.customPosition };
    customPositionCopy[event.target.name] = event.target.value.trim();
    this.setState(
      { customPosition: customPositionCopy, invalidPostions: false },
      () => {}
    );
  };
  checkValidLat(value) {//51.428899,-0.181799
    if (!value) return false;    
    //var latExp = /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/;
    if (parseFloat(value)>90 ||parseFloat(value)<-90) {
      return false;
    } else {
      return true; 
    }
  }
  checkValidLng(value) {
    if (!value) return false;    
    //var lngExp = /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/;
    if (parseFloat(value)>180 ||parseFloat(value)<-180) {
      return false;
    } else {
      return true;
    }
  }
  moveToLocationHandler = () => {
    if (
      this.checkValidLat(this.state.customPosition.lat) &&
      this.checkValidLng(this.state.customPosition.lng)
    ) {
      let customPosition = {...this.state.customPosition}
      this.setState({ invalidPostions: false, customPosition: this.intialValues }, () => {
        this.props.addLocationToPolyLine({lat:parseFloat(customPosition.lat), lng:parseFloat(customPosition.lng)});
      });
    } else {
      this.setState({ invalidPostions: true });
    }
  };
  render() {
    return (
      <div id="locationWrapperInputs" className={styles.mainWrapper}>
        <div className={styles.inputsWrapper}>
          <input
            type="text"
            value={
              this.state.customPosition.lat ? this.state.customPosition.lat : ""
            }
            name={"lat"}
            placeholder="Latitude"
            className={styles.LatLngInput}
            onChange={(e) => this.onchangeHandler(e)}
          />
          <input
            type="text"
            value={
              this.state.customPosition.lng ? this.state.customPosition.lng : ""
            }
            name={"lng"}
            placeholder="Longitude"
            className={styles.LatLngInput}
            onChange={(e) => this.onchangeHandler(e)}
          />
          <button onClick={this.moveToLocationHandler} className={styles.btn}>
            Add
          </button>
        </div>
        <div
          className={
            this.state.invalidPostions
              ? `${styles.error} ${styles.showError}`
              : styles.error
          }
        >
          Enter valid lat and lng
        </div>
      </div>
    );
  }
}
