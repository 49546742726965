import React, { Component } from "react";
import styles from "./Marker.module.scss";
import ViewWindowInfo from "../ViewWindowInfo/ViewWindowInfo";
import { ROOT } from "../../../Util/routes";
import Icon from "../../Icon/Icon";
import { GOOGLE_PINS } from "../../../Util/constants";
import {
  TERMINAL,
  HUB,
  MOCKUP,
  SITE,
  DOT_MARKER,
} from "../../../Util/constTypes";
import ShortcutMenu from "../ShortcutMenu/ShortcutMenu";
class Marker extends Component {
  getMarkerHandler = () => {
    if (this.props.type === HUB) {
      return GOOGLE_PINS[this.props.type];
    } else if (this.props.type === "terminal") {
      return this.props.online
        ? GOOGLE_PINS["terminal"]
        : GOOGLE_PINS["offlineTerminal"];
    }
  };
  getPinHandler = () => {
    if (this.props.type == DOT_MARKER) {
      return (
        <span
          className={styles.colorIcon}
          onClick={() => this.props.markerClickHandler(this.props.details)}
          onContextMenu={(e,info, name) =>{
           
            this.props.rightClickActive != undefined &&
            this.props.rightClickActive && this.props.onClickRight(this.props.name)}
          }
        >
          <Icon defination={{ prefix: "far", iconName: "circle" }} />
        </span>
      );
    }
    if (this.props.type != SITE) {
      return (
        <img
          onContextMenu={(info, name) =>
            this.props.rightClickActive != undefined &&
            this.props.rightClickActive &&
            this.props.onClickRight(this.props.terminalInfo, this.props.name)
          }
          className={styles.pin}
          src={this.getMarkerHandler()}          
          onClick={(e, info, name) => {
           
            this.props.shortcutClicked();
            this.props.markerClickHandler(
              this.props.terminalInfo,
              this.props.name
            );
          }}
          name={this.props.name}
        />
      );
    }
  };
  render() {
    const { id, type, macId, hiskyId, title, lastActivityDate, key } =
      this.props;
    let pinType = type;
    if (!this.props.online && type == TERMINAL) {
      pinType = "offlineTerminal";
    }
    return (
      <>
        <div className={this.props.shouldBounce ? `${styles.custommarker} ${styles.bounce}` : styles.custommarker}>
          {this.getPinHandler()}
          {this.props.type == SITE &&
            !Object.keys(this.props.openedinfoWindowslist).includes(
              SITE + id
            ) && (
              <div
                className={styles.siteMarkerWrapper}
                onClick={(e) => {
                  e.stopPropagation();
                  this.props.shortcutClicked();
                  this.props.markerClickHandler(
                    this.props.terminalInfo,
                    this.props.name
                  );
                }}
              >
                <img
                  src={GOOGLE_PINS[this.props.type]}
                  className={styles.siteMarker}
                />

                <span
                  className={styles.sitesCount}
                  onContextMenu={(e) => {
                    this.props.disableRightClick();
                  }}
                >
                  {this.props.terminalInfo.siteTerminalCount}
                </span>
              </div>
            )}
        </div>

        {this.props.isInfoWindowOpen && (
          <>
            <div
              className={styles.infoWindow}
              onClick={(e) => {
                e.stopPropagation();
                this.props.shortcutClicked();
              }}
              onContextMenu={(e) => {
                this.props.disableRightClick();
              }}
            >
              <button
                className={styles.closebtn}
                onClick={() => this.props.closeInfoWindow(this.props.name)}
              >
                <span className={styles.icon}>
                  <Icon defination={{ prefix: "fal", iconName: "times" }} />
                </span>
              </button>
              <ViewWindowInfo
                headline={this.props.headline}
                id={this.props.id}
                type={type}
                title={title}
                macId={macId}
                lastActivityDate={lastActivityDate}
                hiskyId={hiskyId}
                click={this.props.click}
                showMoreInfoBtn={window.location.pathname === ROOT}
                terminalInfo={this.props.terminalInfo}
                isFullScreenMode={this.props.isFullScreenMode}
              />
            </div>
            <div className={styles.arrow}></div>
          </>
        )}
        {this.props.activeShortcutMenu != undefined &&
          Object.keys(this.props.activeShortcutMenu).length > 0 &&
          this.props.activeShortcutMenu.name == this.props.name && (
            <ShortcutMenu
              data={this.props}
              onClickRightItem={this.props.onClickRightItem}
              getTransmittingTerminals={this.props.getTransmittingTerminals}
              trailCoordinates={this.props.trailCoordinates}
              getGlowingHubBeam={this.props.getGlowingHubBeam}
              shortcutClicked={this.props.shortcutClicked}
            />
          )}
      </>
    );
  }
}
export default Marker;
