import React, { Component } from "react";
import styles from "./SideBar.module.scss";
import { Link, withRouter } from "react-router-dom";
import Logo from "../../assets/newLogo.png";
import {
  getTreeAccountStatus,
  getEntityName,
  getAccountId,
  getVersionNo,
} from "../../Store/localStoreageManager/localStoreageManager";
import {
  DASHBOARD,
  SYSTEM_LOGS,
  NMS_USER_LOGS,
  USER_LOGS_DISPLAY_NAME,
  ACCOUNTS,
  SUBSCRIPTIONS,
  SERVICES,
  TEMPLATES,
  ENTITY_NAME,
  TERMINALS,
  TECHNICIAN_ENTITY,
  SUBSCRIBER_ENTITY,
  TREE_VIEW,
  RESELLER_ENTITY,
  COMPANY_ENTITY,
  USERS,
  HISKY_ENTITY,
  TERMINAL,
} from "../../Util/constTypes";
import { HUBS } from "../DashBoard/Type";
import Icon from "../../Componnet/Icon/Icon";
import * as route from "../../Util/routes";
import AccountHisky from "./AccountHisky";
import AccountTechnician from "./AccountTechnician";
import AccountSubscriber from "./AccountSubscriber";
import AccountReseller from "./AccountReseller";
import AccountCompany from "./AccountCompany";
import { connect } from "react-redux";
import * as actionType from "../../Store/actions/index";

class SideBar extends Component {
  state = {
    activeLink: "",
    menu: true,
  };
  componentDidMount() {
    this.initActiveLink();
  }
  componentWillReceiveProps(nextProps){
    if (nextProps.reRenderSidebarRx){
      this.props.reRenderSideBarSelector(false)
      this.initActiveLink()
    }
  }
  initActiveLink = () => {
    if (window.location.pathname === "/") {
      this.setState({ activeLink: DASHBOARD });
    } else {
      if (window.location.pathname.split("/")[2] === TREE_VIEW) {
        this.setState({ activeLink: ACCOUNTS });
      } else if (window.location.pathname.split("/")[2] === USERS) {
        this.setState({ activeLink: USERS });
      } else if (window.location.pathname.split("/")[2] === SUBSCRIPTIONS) {
        this.setState({ activeLink: SUBSCRIPTIONS });
      } else if (window.location.pathname.split("/")[2] === TEMPLATES) {
        this.setState({ activeLink: TEMPLATES });
      } else if (window.location.pathname.split("/")[2] === SERVICES) {
        this.setState({ activeLink: SERVICES });
      } else if (
        window.location.pathname.split("/")[2] === USER_LOGS_DISPLAY_NAME
      ) {
        this.setState({ activeLink: NMS_USER_LOGS });
      } else if (window.location.pathname.split("/")[2] === SYSTEM_LOGS) {
        this.setState({ activeLink: SYSTEM_LOGS });
      } else if (window.location.pathname.split("/")[2] === TERMINALS || window.location.pathname.split("/")[2] === TERMINAL) {
        this.setState({ activeLink: TERMINALS });
      } else if (window.location.pathname.split("/")[2] === HUBS) {
        this.setState({ activeLink: HUBS });
      } else {
        this.setState({
          activeLink: window.location.pathname.replace("/", ""),
        });
      }
    }
  };
  onClickLinkHandler = (link) => {
    this.setState({ activeLink: link });
    if (link === ACCOUNTS) {
      if (getEntityName() === TECHNICIAN_ENTITY) {
        this.props.history.push(
          route.ACCOUNTS_TECHNICIAN + "/" + parseInt(getAccountId())
        );
      } else if (getEntityName() === SUBSCRIBER_ENTITY) {
        this.props.history.push(
          route.ACCOUNTS_SUBSCRIBER + "/" + parseInt(getAccountId())
        );
      } else {
        this.props.history.push(route.ACCOUNTS_TREE);
      }
    }
  };
  getActiveLink = (name) => {
    return this.state.activeLink === name;
  };
  onClickMenuBarHandler = () => {
    this.setState({ menu: !this.state.menu });
  };
  getBackGroundColorSmall = (name) => {
    return !this.state.menu && name == this.state.activeLink ? "#0AA1B5" : null;
  };
  getBackGroundColorFull = (name) => {
    return this.state.menu && name == this.state.activeLink ? "#0AA1B5" : null;
  };

  linksSetHandler = () => {
    switch (getEntityName()) {
      case ENTITY_NAME:
        return (
          <AccountHisky
            menu={this.state.menu}
            activeLink={this.state.activeLink}
            initActiveLink={this.initActiveLink}
            onClickLinkHandler={this.onClickLinkHandler}
            getActiveLink={this.getActiveLink}
            onClickMenuBarHandler={this.onClickMenuBarHandler}
            getBackGroundColorSmall={this.getBackGroundColorSmall}
            getBackGroundColorFull={this.getBackGroundColorFull}
          />
        );
      case SUBSCRIBER_ENTITY:
        return (
          <AccountSubscriber
            menu={this.state.menu}
            activeLink={this.state.activeLink}
            initActiveLink={this.initActiveLink}
            onClickLinkHandler={this.onClickLinkHandler}
            getActiveLink={this.getActiveLink}
            onClickMenuBarHandler={this.onClickMenuBarHandler}
            getBackGroundColorSmall={this.getBackGroundColorSmall}
            getBackGroundColorFull={this.getBackGroundColorFull}
          />
        );
      case COMPANY_ENTITY:
        return (
          <AccountCompany
            menu={this.state.menu}
            activeLink={this.state.activeLink}
            initActiveLink={this.initActiveLink}
            onClickLinkHandler={this.onClickLinkHandler}
            getActiveLink={this.getActiveLink}
            onClickMenuBarHandler={this.onClickMenuBarHandler}
            getBackGroundColorSmall={this.getBackGroundColorSmall}
            getBackGroundColorFull={this.getBackGroundColorFull}
          />
        );
      case RESELLER_ENTITY:
        return (
          <AccountReseller
            menu={this.state.menu}
            activeLink={this.state.activeLink}
            initActiveLink={this.initActiveLink}
            onClickLinkHandler={this.onClickLinkHandler}
            getActiveLink={this.getActiveLink}
            onClickMenuBarHandler={this.onClickMenuBarHandler}
            getBackGroundColorSmall={this.getBackGroundColorSmall}
            getBackGroundColorFull={this.getBackGroundColorFull}
          />
        );
      case TECHNICIAN_ENTITY:
        return (
          <AccountTechnician
            menu={this.state.menu}
            activeLink={this.state.activeLink}
            initActiveLink={this.initActiveLink}
            onClickLinkHandler={this.onClickLinkHandler}
            getActiveLink={this.getActiveLink}
            onClickMenuBarHandler={this.onClickMenuBarHandler}
            getBackGroundColorSmall={this.getBackGroundColorSmall}
            getBackGroundColorFull={this.getBackGroundColorFull}
          />
        );
    }
  };

  render() {
    return (
      <div
        className={styles.SideBars}
        onClick={this.props.clickAuto}
        style={{ width: this.state.menu ? "17%" : "5%" }}
      >
        <div
          className={
            this.state.menu ? styles.menuWrapper : styles.imageWrapperClose
          }
        >
          <div className={styles.imageWrapper}>
            <Link to="/" className={styles.imageLogo}>
              <img
                src={Logo}
                className={styles.imageLogo}
                alt="logo"
                style={{ display: this.state.menu ? "block" : "none" }}
              />
            </Link>
            <div className={styles.versionWrapper}>
              {this.state.menu && (
                <p className={styles.version}>
                  {getVersionNo() === undefined ? "" : getVersionNo()}
                </p>
              )}
              { this.state.menu && <span
                className={styles.iconVersion}
                onClick={this.props.openSystemVersionSelector}
              >
                <Icon defination={{ prefix: "fal", iconName: "file-alt" }} />
              </span>}
            </div>
          </div>
          <div
            className={this.state.menu ? styles.iconBar : styles.iconBarClose}
            onClick={this.onClickMenuBarHandler}
          >
            <Icon defination={{ prefix: "far", iconName: "bars" }} />
          </div>
        </div>
        {this.linksSetHandler()}
      </div>
    );
  }
}
const mapStateHandler = (state) => {
  return {
    reRenderSidebarRx: state.common.reRenderSidebarRx,
  };
};

const mapStateDispatch = (dispatch) => {
  return {
    openSystemVersionSelector: () =>
      dispatch(actionType.openSystemVersionSelector()),
      reRenderSideBarSelector: (data) =>
      dispatch(actionType.reRenderSideBarSelector(data)),
  };
};
export default withRouter(connect(mapStateHandler, mapStateDispatch)(SideBar));
