import React, { Component } from "react";
import Logo from "../../../assets/logo.png";
import styles from "./SearchPinInfoWindow.module.scss";
export default class SearchPinInfoWindow extends Component {
  render() {
    return (
      <div className={styles.ViewWindow}>
        <img src={Logo} className={styles.img} alt="logo-hiSky" />
        <span className={styles.title}>Search result</span>
        <span className={styles.infoText}>Latitude: {this.props.latLng.lat}</span>
        <span className={styles.infoText}>Longitude: {this.props.latLng.lng}</span>
      </div>
    );
  }
}
