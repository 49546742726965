import * as route from "./Util/routes";
import { getEntityName } from "./Store/localStoreageManager/localStoreageManager";
import {
  HISKY_ENTITY,
  HOST_IP,
  RESELLER_ENTITY,
  SUBSCRIBER,
  SUBSCRIBER_ENTITY,
  TECHNICIAN_ENTITY,
} from "./Util/constTypes";

class Controller {
  instance = Controller.instance || new Controller();
  static setDataPermission(dataSet, isUser = false, settings = {}) {
    /////////////////////////////////////////this function get call only once on the App.js/////////////////////////
    // let nms = JSON.parse(data.nms);
    // let bss = JSON.parse(data.bss);
    let data = {
      nms: {
        component: {},
        actions: {},
      },
      bss: {
        component: {},
        actions: {},
      },
      isUser: false,
    };
    let numRoutes = 0;
    let routesRemove = 0;
    let listToIgnore = ["DASHBOARD", "SUPPORT", "UPDATES"];
    let bssKeys = [
      "BSS__USER_LOGS",
      "BSS__SYSTEM_LOGS",
      "BSS__SYSTEM_LOGS__CREATE_LOG",
      "BSS__SYSTEM_LOGS__EXPORT_SYSTEM_LOGS",
      "BSS__USER_LOGS__EXPORT_USER_LOGS",
    ];
    for (let i = 0; i < dataSet.length; i++) {
      let type = dataSet[i].Perm.permKey.split("__");

      if (type.length == 2) {
        //components
        if (
          getEntityName() == RESELLER_ENTITY &&
          bssKeys.includes(dataSet[i].Perm.permKey)
        ) {
          data[dataSet[i].Perm.appName].component[
            "BSS_" + type[type.length - 1]
          ] = dataSet[i].View;
        } else {
          data[dataSet[i].Perm.appName].component[type[type.length - 1]] =
            dataSet[i].View;
        }
        if (dataSet[i].View) {
          numRoutes++;
        }
        ///check for terminal route permission to give terminal details route access
        if (
          type[type.length - 1] == "TERMINAL_DETAIL" &&
          !data[dataSet[i].Perm.appName].component["TERMINAL"]
        )
          listToIgnore.push("TERMINAL_DETAIL");
        ///
        if (listToIgnore.includes(type[type.length - 1]) && dataSet[i].View) {
          routesRemove++;
        }
      } else if (type.length > 2) {
        //features
        if (
          getEntityName() == RESELLER_ENTITY &&
          bssKeys.includes(dataSet[i].Perm.permKey)
        ) {
          data[dataSet[i].Perm.appName].actions[
            "BSS_" + type[type.length - 1]
          ] = dataSet[i].View;
        } else {
          data[dataSet[i].Perm.appName].actions[type[type.length - 1]] =
            dataSet[i].View;
        }
      }
    }
    this.instance = {
      nms: data.nms,
      bss: data.bss,
      numRoutes: numRoutes - routesRemove,
      isUser: isUser,
      settings: { component: settings },
    };

    return this.instance.numRoutes;
    ////create a instance.nms object
    /////create a instance.bss object
  }
  static isUser() {
    return this.instance.isUser;
  }
  static getNumOfRoutes() {
    if (getEntityName() == HISKY_ENTITY)
      return Controller.getAllRoutes().length;

    return this.instance.numRoutes;
  }
  static getRouteArray() {
    let obj = {
      ...this.instance.nms.component,
      ...this.instance.bss.component,
    };
    let routes = [];
    if (getEntityName() == HISKY_ENTITY) {
      return Controller.getAllRoutes();
    }
    let listToIgnore = ["DASHBOARD", "SUPPORT", "UPDATES"];
    if (!Controller.checkForTerminals(obj, "TERMINAL_DETAIL")) {
      listToIgnore.push("TERMINAL_DETAIL");
    }
    for (let key in obj) {
      if (obj[key] && !listToIgnore.includes(key)) {
        routes.push(Controller.checkRoutePermssion(key));
      }
    }
    return routes;
  }
  static checkForTerminals = (obj, key) => {
    return (
      (key == "TERMINAL_DETAIL" &&
        obj["TERMINAL"] != undefined &&
        obj["TERMINAL"]) ||
      key != "TERMINAL_DETAIL"
    );
  };
  static getAllRoutes() {
    let routes = [
      {
        name: "UserLogs",
        route: route.LOGS_USER,
        import: "./Container/Logs/UsersLogs/UsersLogs",
      },
      {
        name: "SystemLogs",
        route: route.LOGS_SYSTEM,
        import: "./Container/Logs/SystemLogs",
      },
      {
        name: "TreeView",
        route: route.ACCOUNTS_TREE,
        import: "./Container/TreeView/TreeView",
      },

      {
        name: "TerminalDeivces",
        route: route.DEVICES_TERMINALS,
        import: "./Container/TerminalDeivces/TerminalDeivces",
      },
      {
        name: "HubsDevices",
        route: route.DEVICES_HUBS,
        import: "./Container/HubsDevices/HubsDevices",
      },
      {
        name: "Terminal",
        route: route.DEVICES_TERMINAL + "/:id",
        import: "./Container/Terminal/Terminal",
      },

      {
        name: "Permissions",
        route: route.PERMISSIONS,
        import: "./Container/Permissions/Permissions",
      },
      {
        name: "Hubs",
        route: route.DEVICES_HUB + "/:id",
        import: "./Container/Hubs/Hubs",
      },
      {
        name: "Cdr",
        route: route.ACTIVITY,
        import: "./Container/Cdr/Cdr",
      },
      {
        name: "Services",
        route: route.PLANS_SERVICES,
        import: "./Container/Services/Services",
      },
      {
        name: "Templates",
        route: route.PLANS_TEMPLATES,
        import: "./Container/TemplatePlan/TemplatePlan",
      },
      {
        name: "History",
        route: route.PLANS_HISTORY,
        import: "./Container/History/History",
      },
      {
        name: "Updates",
        route: route.UPDATES,
        import: "./Container/Updates/Updates",
      },
      {
        name: "Reports",
        route: route.REPORTS,
        import: "./Container/Reports/Reports",
      },
      {
        name: "Users",
        route: route.USERS,
        import: "./Container/Users/Users",
      },
    ];
    if (
      this.instance.settings.component.productionModule != undefined &&
      this.instance.settings.component.productionModule&&getEntityName() == HISKY_ENTITY
    ) {
      routes.push({
        name: "Production",
        route: route.PRODUCTION,
        import: "./Container/PoolManager/PoolManager",
      });
    }
    if (getEntityName() == HISKY_ENTITY) {
      routes.push({
        name: "Statistics",
        route: route.STATISTICS,
        import: "./Container/Statistics/Statistics",
      });
    }
    if (
      this.instance.settings.component.simulator != undefined &&
      this.instance.settings.component.simulator
    ) {
      routes.push({
        name: "Hubpp",
        route: route.HUB_PP_SIMULATOR,
        import: "./Container/Hubpp/Hubpp",
      });
    }
    return routes;
  }
  static checkRoutePermssion = (routeType) => {
    {
      switch (routeType) {
        case "BSS_USER_LOGS":
          return {
            name: "UserLogs",
            route: route.LOGS_USER,
            import: "./Container/Logs/UsersLogs/UsersLogs",
          };
        case "BSS_SYSTEM_LOGS":
          return {
            name: "SystemLogs",
            route: route.LOGS_SYSTEM,
            import: "./Container/Logs/SystemLogs",
          };
        case "USER_LOGS":
          return {
            name: "UserLogs",
            route: route.LOGS_USER,
            import: "./Container/Logs/UsersLogs/UsersLogs",
          };
        case "SYSTEM_LOGS":
          return {
            name: "SystemLogs",
            route: route.LOGS_SYSTEM,
            import: "./Container/Logs/SystemLogs",
          };
        // case "SUBSCRIBER_ACCOUNT":
        //   return {
        //     name: "AccountSubscriber",
        //     route: route.ACCOUNTS_SUBSCRIBER,
        //     import: "./Container/AccountSubscriber/AccountSubscriber",
        //   };
        // case "ACCOUNT":
        //   //TECHNICAIN_ACCOUNT
        //   return {
        //     name: "AccountTechnician",
        //     route: route.ACCOUNTS_TECHNICIAN,
        //     import: "./Container/AccountTechnician/AccountTechnician",
        //   };
        case "ACCOUNTS":
          if (getEntityName() == SUBSCRIBER_ENTITY)
            return {
              name: "AccountSubscriber",
              route: route.ACCOUNTS_SUBSCRIBER,
              import: "./Container/AccountSubscriber/AccountSubscriber",
            };
          else if (getEntityName() == TECHNICIAN_ENTITY)
            return {
              name: "AccountTechnician",
              route: route.ACCOUNTS_TECHNICIAN,
              import: "./Container/AccountTechnician/AccountTechnician",
            };
          else
            return {
              name: "TreeView",
              route: route.ACCOUNTS_TREE,
              import: "./Container/TreeView/TreeView",
            };
        case "TERMINAL":
          return {
            name: "TerminalDeivces",
            route: route.DEVICES_TERMINALS,
            import: "./Container/TerminalDeivces/TerminalDeivces",
          };
        case "HBS":
          return {
            name: "HubsDevices",
            route: route.DEVICES_HUBS,
            import: "./Container/HubsDevices/HubsDevices",
          };
        case "TERMINAL_DETAIL":
          return {
            name: "Terminal",
            route: route.DEVICES_TERMINAL + "/:id",
            import: "./Container/Terminal/Terminal",
          };
        case "PERMISSIONS":
          return {
            name: "Permissions",
            route: route.PERMISSIONS,
            import: "./Container/Permissions/Permissions",
          };
        case "HBS_DETAIL":
          return {
            name: "Hubs",
            route: route.DEVICES_HUB + "/:id",
            import: "./Container/Hubs/Hubs",
          };
        case "ACTIVITY":
          return {
            name: "Cdr",
            route: route.ACTIVITY,
            import: "./Container/Cdr/Cdr",
          };
        case "SERVICES":
          return {
            name: "Services",
            route: route.PLANS_SERVICES,
            import: "./Container/Services/Services",
          };
        case "TEMPLATES":
          return {
            name: "Templates",
            route: route.PLANS_TEMPLATES,
            import: "./Container/TemplatePlan/TemplatePlan",
          };
        case "HISTORY":
          return {
            name: "History",
            route: route.PLANS_HISTORY,
            import: "./Container/History/History",
          };
        case "PLANS_ASSIGN":
          return {
            name: "PlansAssign",
            route: route.PLANS_ASSIGN,
            import: "./Container/PlansAssign/PlansAssign",
          };
        // case "UPDATES":
        //   return {
        //     name: "Updates",
        //     route: route.UPDATES,
        //     import: "./Container/Updates/Updates",
        //   };
        case "REPORTS":
          return {
            name: "Reports",
            route: route.REPORTS,
            import: "./Container/Reports/Reports",
          };
        case "USERS":
          return {
            name: "Users",
            route: route.USERS,
            import: "./Container/Users/Users",
          };
        case "PRODUCTION":
          return {
            name: "Production",
            route: route.PRODUCTION,
            import: "./Container/PoolManager/PoolManager",
          };
        case "STATISTICS":
          return {
            name: "Statistics",
            route: route.STATISTICS,
            import: "./Container/Statistics/Statistics",
          };
        default:
          break;
      }
    }
  };
  static getActionAuth(app, btn) {
    if (getEntityName() == HISKY_ENTITY) return true;
    if (typeof this.instance === "object") {
      let obj = { ...this.instance[app] };
      if (obj.actions[btn] == undefined) return false;
      else return obj.actions[btn];
    }

    return false;
  }

  static getAuthComponent(app, componnet) {
    let listToIgnore = [
      "SUBSCRIBER_ACCOUNT",
      "TECHNICIAN_ACCOUNT",
      "PLANS_ASSIGN",
    ];
    if (
      getEntityName() == HISKY_ENTITY &&
      !listToIgnore.includes(componnet) &&
      app != "settings"
    ) {
      if (app == "bss" && componnet == "SYSTEM_LOGS") {
        return false;
      } else {
        return true;
      }
    }

    if (typeof this.instance === "object") {
      let obj = { ...this.instance[app] };
      if (obj.component[componnet] == undefined) return false;
      else return obj.component[componnet];
    }
    return false;
  }
}

export default Controller;
