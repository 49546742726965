import React, { Component, Fragment } from "react";
import SearchAutocomplete from "./SearchAutocomplete/SearchAutocomplete";
import styles from "./Header.module.scss";
import Logo from "../../assets/newLogo.png";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actionType from "../../Store/actions/index";
import Controller from "../../Controller";
import {
  removeInfoLocalStorage,
  getBasicInfo,
  getAccountId,
  getUserId,
  getEntityName,
} from "../../Store/localStoreageManager/localStoreageManager";
import Icon from "../../Componnet/Icon/Icon";
import * as route from "../../Util/routes";
import {
  ADMINISTRATOR,
  COMPANY_ENTITY,
  EDIT,
  EDIT_PROFILE_ICON,
  HISKY,
  HISKY_ENTITY,
  RESELLER_ENTITY,
  SUBSCRIBER_ENTITY,
  TECHNICIAN_ENTITY,
  UPDATE_USER_ICON,
} from "../../Util/constTypes";
class Header extends Component {
  state = {
    selectBy: "",
    searchText: "",
    userName: "",
    avatar: "",
    email: "",
    role: "",
  };
  componentWillReceiveProps(nextProps) {
    if (Object.keys(nextProps.userProfile).length > 0) {
      this.updateAvatar(nextProps.userProfile);
    }
  }
  updateAvatar = (userProfile) => {
    this.setState(
      {
        userName: userProfile.firstName,
        avatar: userProfile.avatar,
        email: userProfile.email,
        phone: userProfile.phone,
      },
      () => {
        this.props.userProfileUpdate({});
      }
    );
  };
  componentDidMount() {
    let user = getBasicInfo();
    this.setState(
      {
        userName: user.firstName,
        avatar: user.avatar,
        email: user.email,
        role: this.getRoleHandler(user.role),//user.role,
        phone: user.phone,
        account_id: user.account_id,
      },
      () => {}
    );
  }
  getRoleHandler = (role) => {
    let roles = role.split('-');
    let username = roles[0], rolename='' ;
    if (getEntityName() == HISKY_ENTITY || getEntityName() == RESELLER_ENTITY || getEntityName() == COMPANY_ENTITY) {
      username = `${roles[0]}`;
      rolename = roles[1] == ADMINISTRATOR ? '-Admin':`-${roles[1]}`
    }
    return `${username}${rolename}`
  };
  getAvatarImage = () => {
    if (this.state.avatar === "" || this.state.avatar == undefined) {
      return (
        <span className={styles.iconAvatar}>
          <Icon defination={{ prefix: "far", iconName: "user-circle" }} />
        </span>
      );
    } else {
      return (
        <Fragment>
          <img
            src={this.state.avatar}
            className={styles.imgAvatar}
            alt="User"
          />
        </Fragment>
      );
    }
  };
  onChangeHandler = (event) => {
    this.setState({ searchText: event.target.value }, () => {});
  };
  onClickLogoutHandler = () => {
    removeInfoLocalStorage();
    //this.props.setAuth(false);
    this.props.setAuthuntication(false);
    this.props.history.push(route.LOGIN);
  };
  getAccountInfoDataHandler = () => {
    if (Controller.isUser()) {
      this.props.addSelectedUserInfoSelector({id:getUserId(),mode:EDIT,from:"header"});
      this.props.openEditUserSelector();
    } else {
      this.props.addSelectedUserInfoSelector({id:parseInt(getAccountId()),mode:EDIT});
      this.props.openEditAccountSelecter();
    }
  };
  checkEditAccountPermisionHandler = () => {
    if (Controller.isUser()) {
      return Controller.getActionAuth("bss", UPDATE_USER_ICON);
    } else {
      return (
        Controller.getActionAuth("nms", EDIT_PROFILE_ICON) ||
        Controller.getActionAuth("bss", EDIT_PROFILE_ICON)
      );
    }
  };
  render() {
    return (
      <div className={styles.Headers}>
        <SearchAutocomplete
          open={this.props.open}
          autoVisible={this.props.autoVisible}
        />
        <div className={styles.logo}>
          <Link className={styles.linkLogo} to="/">
            <img className={styles.imgLogo} src={Logo} alt="logo-hiSky" />
          </Link>
        </div>
        <nav className={styles.dropdownMenu}>
          <ul>
            <li>
              <div className={styles.welcomeTitleWrapper}>
                <div>
                  <span className={styles.titleWelcome}>{`Welcome ${
                    this.state.userName ? this.state.userName : ""
                  }`}</span>
                  <span className={styles.arrowIcon}>
                    <Icon defination={{ prefix: "fal", iconName: "cog" }} />
                  </span>
                </div>
                <div>
                  <span
                    className={styles.roleTitle}
                  >{`${this.state.role}`}</span>
                </div>
              </div>

              <ul>
                <div className={styles.generalInfo} style={{textTransform: this.state.userName !== undefined && this.state.userName.indexOf(HISKY)>-1 ?'none':''}}>
                  {this.getAvatarImage()}
                  <div className={styles.infoUser}>
                    <span>
                      {this.state.userName !== "" &&
                      this.state.userName !== undefined
                        ? `${this.state.userName}`
                        : "N/A"}
                    </span>
                    <span style={{ textTransform: "initial" }}>
                      {this.state.email}
                    </span>
                  </div>
                </div>
                <hr className={styles.breakLine} />
                <div className={styles.actionLinkWrapper}>
                  {this.checkEditAccountPermisionHandler() && (
                    <div
                      className={styles.myAccount}
                      onClick={this.getAccountInfoDataHandler}
                    >
                      My Account
                    </div>
                  )}
                  <span
                    className={styles.item}
                    onClick={this.onClickLogoutHandler}
                  >
                    Logout
                  </span>
                </div>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

const mapStateProps = (state) => {
  return {};
};
const mapStateDispatch = (dispatch) => {
  return {
    // setAuth: (auth) => dispatch(actionType.setAuth(auth)),
    resetInnerComponentSelector: (data) =>
      dispatch(actionType.resetInnerComponentSelector(data)),
    userProfileUpdate: (userProfile) =>
      dispatch(actionType.userProfileUpdate(userProfile)),
    openEditAccountSelecter: () =>
      dispatch(actionType.openEditAccountSelecter()),
    addSelectedUserInfoSelector: (userInfo) =>
      dispatch(actionType.addSelectedUserInfoSelector(userInfo)),
    setRolesSelector: (data) => dispatch(actionType.setRolesSelector(data)),
    setActionType: (data) => dispatch(actionType.setActionType(data)),
    openEditUserSelector: () => dispatch(actionType.openEditUserSelector()),
    addSelectedUserInfoSelector: (userInfo) =>
      dispatch(actionType.addSelectedUserInfoSelector(userInfo)),
  };
};
export default connect(mapStateProps, mapStateDispatch)(withRouter(Header));
