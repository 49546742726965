import {
  DATE_TYPE,
  DEVICE_ID,
  EDIT_CHANNEL,
  UPDATE_DEVICE_TERMINAL,
  HUB_DATA,
} from "./types";

export const setDateType = (data) => {
  return {
    type: DATE_TYPE,
    payload: data,
  };
};

export const setDeviceId = (id) => {
  return {
    type: DEVICE_ID,
    payload: id,
  };
};

export const updateChannelSelector = (channel) => {
  return {
    type: EDIT_CHANNEL,
    payload: channel,
  };
};

/**
 * terminals and hubs
 */
export const updateTerminalSelector = (terminal) => {
  return {
    type: UPDATE_DEVICE_TERMINAL,
    payload: terminal,
  };
};

export const hubDataSelector = (hubData) => {
  return {
    type: HUB_DATA,
    payload: hubData,
  };
};
